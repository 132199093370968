import React from "react";
import MainSellerDashboard from "../components/Dashboard/MainSellerDashboard";
import MainLayout from "../components/Layouts";

const MainSellerDashboardPage = () => {
  return (
    <MainLayout>
      <MainSellerDashboard />
    </MainLayout>
  );
}; 

export default MainSellerDashboardPage;
