import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopChatList from "../../../components/Shop/Chats/index";

const ShopChatPage = () => {
  return (
    <MainLayout>
      <ShopChatList />
    </MainLayout>
  );
};

export default ShopChatPage;
