import { PageHeader } from "antd";
import cn from "classnames";
import s from "./Breadcrumb.module.css";

const HomeBreadCrumb = ({
  title,
  subTitle,
  extra,
  childComponent,
}: any): any => {
  return (
    <div className={s.root} style={{ background: "./../../bg-breadcrumb.svg" }}>
      {/* <PageHeader
        className={`${childComponent ? "pt-1 pb-0 px-2 rounded-2xl" : "px-0"}`}
        ghost={false}
        onBack={() => window.history.back()}
        title={title}
        subTitle={subTitle}
        extra={extra}
      /> */}
    </div>
  );
};

export default HomeBreadCrumb;
