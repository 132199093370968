import { SHOW_SEARCHBAR, PRODUCT_SEARCH } from "./searchType";

const initialState = {
  showSearch: false,
  loading: false,
  productSearch: {
    shopId: ''
  }
};

const searchReducer = (
  state = initialState,
  action = { type: "", payload: {} }
) => {
  switch (action.type) {
    case SHOW_SEARCHBAR: {
      state = { ...state, ...action.payload };
      return state;
    }

    case PRODUCT_SEARCH: {
      state = { ...state, ...action.payload };
      return state;
    }

    default: {
      state.showSearch = false;
      return state;
    }
  }
};

export default searchReducer;
