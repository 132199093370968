import React, { FC } from "react";
import cn from "classnames";
import s from "./Steps.module.css";
import Container from "../../ui/Container";
import SignUpImg from "../../../images/icon/signup.png";
import AddProfileImg from "../../../images/icon/add-user.png";
import AddAddressImg from "../../../images/icon/add-file.png";
import AddBankImg from "../../../images/icon/atm-card.png";
import AddProductImg from "../../../images/icon/packing-list.png";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const HomeSteps: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);

  const DATAS = [
    {
      icon: SignUpImg,
      title: "Signup for Free",
      description:
        "Registration is free and requires only your mobile phone number and a password.",
    },
    {
      icon: AddProfileImg,
      title: "Add Profile Information",
      description:
        "Update a merchant profile by providing all of the required details to build a valuable profile.",
    },
    {
      icon: AddAddressImg,
      title: "Create a shop",
      description:
        "You have to add at least one shop to continue doing business.",
    },
    {
      icon: AddBankImg,
      title: "Add Bank Information",
      description:
        "To get your sales amount automatically, add your bank information.",
    },
    {
      icon: AddProductImg,
      title: "List Products",
      description:
        "After you've created your shop, you'll want to add and manage your products.",
    },
  ];

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="block transition-colors duration-150 text-secondary">
          <div className="text-center">
            <div className="w-full lg:max-w-3xl mx-auto">
              <h2 className="text-red-600 text-2xl lg:text-4xl font-semibold mb-6 uppercase">
                5 Simple Steps to Start Selling
              </h2>
              <p className="text-md lg:text-lg text-gray-500">
                Jachai helps online merchants with business expansion, sales
                growth, customer acquisition, and brand exposure. Working with
                us will give you access to new customers who want to place
                orders for delivery and pickup from your shop.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-6 py-10 text-slate-700">
            {DATAS?.map((data: any, i) => (
              <div
                className="flex flex-col items-center gap-4 p-4 text-center"
                key={i}
              >
                <div className="bg-gray-200 rounded-full p-5 text-center">
                  <img src={data?.icon} alt="" />
                </div>
                <div className="flex flex-col">
                  <span className="text-lg">{data?.title}</span>
                  <span className="text-gray-500">{data?.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeSteps;
