import {
  AutoComplete,
  Badge,
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
} from "antd";
import { SearchOutlined, SnippetsOutlined } from "@ant-design/icons";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, MerchantOrderTypeArray } from "../../utils";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import Pagination from "../common/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleOrder from "./Details";
import { isAllowedService } from "../../utils/services";
import cn from "classnames";
const { RangePicker } = DatePicker;

const OrderList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const { type, token, merchantInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const navigate = useNavigate();
  const shopId = getShopId; 
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showSearch, setShowSearch] = useState(true);

  const [selectedOrder, setSelectedOrder] = useState<any>();

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: null,
  });

  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const onClose = () => {
    setSelectedOrder(undefined);
    setSelectedOrder(undefined);
  };

  const getOrders = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setOrderData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/order/v2?type=${type}&shopId=${shopId}` +
          (orderId ? `&orderIdOrBaseOrderId=${orderId}` : ``) +
          (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``) +
          // (shopId ? `&shopId=${shopId}` : ``) +
          (status ? `&status=${status}` : ``) +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (page
            ? `&page=${
                page == orderData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrderData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrderData({ loading: false, data: [] });
        console.error("Order: Error", err);
      });
  }, [limit, page, shopId, orderId, mobileNumber, status, range]);

  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order/v2?page=${page || 0}&shopId=${shopId}` +
            (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
            (type ? `&type=${type}` : ``) +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map(
              (order: { baseOrderId: string; orderId: string }) => ({
                label: `${order?.baseOrderId} || ${order?.orderId}`,
                value: order?.baseOrderId,
              })
            ),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status, type, shopId]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?page=0&limit=20` +
            (key ? `&key=${key}` : ``) +
            (type ? `&type=${type}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: { id: any; name: any }) => ({
              value: shop.id,
              label: shop.name,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Shops: Error", err);
        });
    },
    [type]
  );

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
        if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  useEffect(() => {
    if (showSearch) {
      getOrderOptions("");
      getShopOptions("");
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form?.resetFields();
    setStatus("");
    setOrderId("");
    setMobileNumber("");
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Order List"
        subTitle={`${orderData?.data?.totalElements} Order(s)`}
        childComponent={inShop ? true : false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          
            <Button
              type="primary"
              danger
              shape="round"
              onClick={() => navigate(`/shops/${shopId}/manual-orders`)}
            >
              Manual Order
            </Button>
          ,
        ]}
      />

      {showSearch && (
        <div className={`${styles.searchBox} ${inShop ? `mx-0` : ``}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="baseOrderIdSearch" initialValue={orderId}>
              <AutoComplete
                backfill
                allowClear
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
                dropdownMatchSelectWidth={335}
              >
                <Input.Search
                  placeholder="Order ID or Base Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>

            {/* <Form.Item name="orderId" initialValue={orderId}>
              <AutoComplete
                dropdownMatchSelectWidth={250}
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Shop Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                /> 
              </AutoComplete>
            </Form.Item> */}

            {/* {!inShop && (
              <Form.Item name="shop_search" initialValue={shopId}>
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Shop"
                  optionFilterProp="children"
                  onChange={(val) => setShopId(val)}
                  onSearch={(e) => handleSearch(e, "shop")}
                  filterOption={(input, option) => {
                    console.log(input, option);
                    return true;
                  }}
                  options={shopOptions?.list}
                ></Select>
              </Form.Item>
            )} */}

            <Form.Item name="status_search" initialValue={status}>
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(MerchantOrderTypeArray)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {orderData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                      {/* <th scope="col">Method</th> */}
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderData?.data?.orders?.length ? (
                      orderData?.data?.orders?.map((order: any, index: any) => (
                        <tr
                          className={cn(
                            `border-t hover:bg-gray-100 cursor-pointer`,
                            {
                              ["bg-red-50 border-b border-red-50"]:
                                order?.isPreOrder,
                            }
                          )}
                          key={index}
                          onClick={() =>
                            navigate(
                              `${inShop ? `/shops/${getShopId}` : ``}/orders/${
                                order?.orderId
                              }/details`
                            )
                          }
                          // navigate(
                          //   `${
                          //     inShop ? `/shops/${getShopId}` : ``
                          //   }/products/${product?.slug}/details`
                          // )
                        >
                          <td>
                            <span className="font-medium text-slate-700">
                              {order?.orderId}
                            </span>
                            <br />
                            <span className="font-light text-slate-600">
                              {order?.baseOrderId}
                            </span>
                          </td>
                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(order?.createdAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(order?.createdAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(order?.createdAt).format("lll")}
                            </span>
                          </td>

                          <td>&#2547;{parseInt(order?.merchantAmount || 0)}</td>
                          {/* <td>{order?.paymentMethod}</td> */}
                          <td>
                            <span className="border p-1 px-5 rounded-2xl bg-white">
                              {order?.statusOfMerchant?.split("_").join(" ")}
                            </span>
                          </td>
                          <td>
                            {order?.isPreOrder ? (
                              <Badge.Ribbon
                                text="Pre Order"
                                color="red"
                                placement="end"
                              >
                                <div style={{ minWidth: 150 }}>
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="px-6"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedOrder(order);
                                    }}
                                  >
                                    <SnippetsOutlined />
                                  </Button>
                                </div>
                              </Badge.Ribbon>
                            ) : (
                              <Button
                                shape="round"
                                type="dashed"
                                className="px-6"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedOrder(order);
                                }}
                              >
                                <SnippetsOutlined />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...orderData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        width={`80%`}
        title={`ORDER DETAILS - ${selectedOrder?.orderId}`}
        open={selectedOrder}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
        <SingleOrder
          inShop={true}
          selectedOrder={selectedOrder}
          inModal={true}
        />
      </Modal>
    </>
  );
};

export default OrderList;
