import React, { FC } from "react";
import cn from "classnames";
import s from "./GetStarted.module.css";
// import { Facebook, Twitter, Linkedin, Instagram } from "@components/icons";
import { Link } from "react-router-dom";
import { Avatar, Button } from "antd";
import Container from "../../ui/Container";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const GetStarted: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 py-12 transition-colors duration-150 text-white">
          <div className="col-span-1 lg:col-span-5">
            <div className="flex flex-col">
              <span className="text-4xl font-bold">
                What are you waiting for? Start selling with Jachai today.
              </span>
              <a
                className="w-fit p-4 px-12 rounded-lg bg-yellow-600 mt-8 font-bold text-xl"
                href="/#login"
              >
                GET STARTED
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default GetStarted;
