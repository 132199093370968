// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentMethodView_fieldset__0oDOw {
  
    margin-top: 0.75rem;
  
    margin-bottom: 0.75rem;
  
    display: flex;
  
    flex-direction: column
}
  
  .PaymentMethodView_fieldset__0oDOw .PaymentMethodView_label__YnhZ7 {
  
    margin-bottom: 0.5rem;
  
    font-size: 0.75rem;
  
    line-height: 1rem;
  
    font-weight: 500;
  
    text-transform: uppercase
}
  
  .PaymentMethodView_fieldset__0oDOw .PaymentMethodView_input__xeE5t,
  .PaymentMethodView_fieldset__0oDOw .PaymentMethodView_select__XZyvo {
  
    width: 100%;
  
    font-size: 0.875rem;
  
    line-height: 1.25rem;
  
    font-weight: 400
}
  
  .PaymentMethodView_fieldset__0oDOw .PaymentMethodView_input__xeE5t:focus,
  .PaymentMethodView_fieldset__0oDOw .PaymentMethodView_select__XZyvo:focus {
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MemberShip/PaymentMethodView.module.css"],"names":[],"mappings":"AACI;;IAAA,mBAAyB;;IAAzB,sBAAyB;;IAAzB,aAAyB;;IAAzB;AAAyB;;EAIzB;;IAAA,qBAAyC;;IAAzC,kBAAyC;;IAAzC,iBAAyC;;IAAzC,gBAAyC;;IAAzC;AAAyC;;EAKzC;;;IAAA,WAAiC;;IAAjC,mBAAiC;;IAAjC,oBAAiC;;IAAjC;AAAiC;;EAGnC;;EAEA","sourcesContent":[".fieldset {\n    @apply flex flex-col my-3;\n  }\n  \n  .fieldset .label {\n    @apply uppercase text-xs font-medium mb-2;\n  }\n  \n  .fieldset .input,\n  .fieldset .select {\n    @apply w-full text-sm font-normal;\n  }\n  \n  .fieldset .input:focus,\n  .fieldset .select:focus {\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": `PaymentMethodView_fieldset__0oDOw`,
	"label": `PaymentMethodView_label__YnhZ7`,
	"input": `PaymentMethodView_input__xeE5t`,
	"select": `PaymentMethodView_select__XZyvo`
};
export default ___CSS_LOADER_EXPORT___;
