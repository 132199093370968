import { EditOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./Profile.module.css";
import { Avatar, Button, Card, Checkbox, Col, Radio, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
interface EmptyProps {
  height?: string;
  data?: string;
}

const MemberShipList = () => {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [merchantShopCheck, setMerchantShopCheck] = useState<any>();
  const getMerchantShopCheck = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMerchantShopCheck(res?.data?.shops);
  }, []);
  useEffect(() => {
    getMerchantShopCheck();
  }, [getMerchantShopCheck]);

  return (
    <>
      <div className={styles.memberShipList}>
        <div className=" text-center p-4">
          <Avatar
            src={
              "https://production-jachai-service.s3-ap-southeast-1.amazonaws.com/static_image/2023-08-20T08:59:17.908090_Y10-Ultra-Smart-Watch1.jpg"
            }
            size={150}
            shape="square"
            className="rounded-lg shadow-md text-center m-0"
          />
          {merchantShopCheck?.length > 0 ? (
            <>
              <p>{merchantShopCheck[0]?.name}</p>
            </>
          ) : (
            <>
              <p>Shop</p>
            </>
          )}
        </div>
        <div className="flex">
          <Row className="w-full">
            <Col className="w-full text-right mr-10">
              <Link to="/">
                <Button type="link" className={styles.skipStyle}>
                  SKIP
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  {merchantShopCheck?.length > 0 ? (
                    <>
                      <Checkbox checked></Checkbox>
                    </>
                  ) : (
                    <>
                      <Checkbox></Checkbox>
                    </>
                  )}
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/business/create">Create Business</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/chats">Live Chat</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            {/* <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/business/contact-address">Business & Contact Address</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col> */}
          </Row>
        </div>
        {/* <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/business/info">Business Info</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Personal Details</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
        {/* <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Verify Bank</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Add Product</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
        {/* <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Add Brand</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Refer and get Discounts</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
        {/* <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Switch to Customer</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Facebook Page</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
        {/* <div className="flex">
          <Row className="p-4 w-full">
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="#">Facebook Group</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
            <Col className="w-1/2">
              <Card className={styles.cardMain}>
                <div className={styles.cardListCheck}>
                  <Radio></Radio>
                </div>
                <div className={styles.cardListInfo}>
                  <Link to="/chats">Live Chat</Link>
                </div>
                <div className={styles.cardListArro}>
                  <RightOutlined />
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
      </div>
    </>
  );
};

export default MemberShipList;
