import { Avatar, Divider, Form, Input, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import { useSelector } from "react-redux";
import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import SubmitResetBtn from "../../common/SubmitBtn";

const AddAddon = ({
  addonData,
  productId,
  refetch,
  edit,
  extra,
}: {
  addonData: any;
  productId: string;
  refetch: any;
  edit?: boolean;
  extra?: any;
}) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);

  const onSubmit = async (data: any) => {
    // if (generatedImgUrl) {
    setLoading(true);
    let readyData = data && {
      productId: productId,
      name: data.name,
      type: type,
      isActive: true,
      description: data.description,
      stock: parseFloat(data.stock) || 0,
      imageUrl: generatedImgUrl,
      price: {
        mrp: parseFloat(data.mrp) * 1,
        tp: parseFloat(data.tp) * 1,
        currencyCode: data.currencyCode || null,
        discountedPrice: parseFloat(data.discountedPrice),
      },
    };

    if (addonData) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/add-on`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: addonData?.id,
          isActive: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Product Updated Successfully", "success");
            refetch();
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/add-on`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Product Create Successfully", "success");
            refetch();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
    // } else {
    //   responseNotification(`Product Thumbnail & Image required`, "error");
    // }
  };

  const onStatusChange = async (id?: any, val?: boolean) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/add-on`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
          productId: productId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            refetch();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (addonData) {
      setGeneratedImgUrl(addonData?.imageUrl);
    }
  }, [addonData]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(addonData?.data?.images || []);
    setStartUpload(undefined);
  };

  useEffect(() => {
    if (edit) {
      form?.resetFields();
    }
  }, [edit]);

  return (
    <>
      {edit ? (
        <Form
          name="control-hooks"
          form={form} // like ref
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...addonData,
            mrp: addonData?.price?.mrp,
            tp: addonData?.price?.tp,
            discountedPrice: addonData?.price?.discountedPrice,
          }}
          layout="vertical"
          autoComplete="off"
        >
          <div className="bg-white shadow-sm shadow-gray-200 rounded-2xl p-4 mb-6 grid grid-cols-2 gap-x-4 border">
            <div>
              <Form.Item
                hasFeedback
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is Required!",
                  },
                ]}
              >
                <Input id="name" type="text" placeholder="Enter addon name" />
              </Form.Item>
            </div>

            <div className="">
              <Form.Item hasFeedback label="Stock" name="stock">
                <Input id="stock" type="text" placeholder="Enter stock" />
              </Form.Item>
            </div>

            <div className="">
              <Form.Item hasFeedback label="MRP" name="mrp">
                <Input id="mrp" type="text" placeholder="Enter mrp" />
              </Form.Item>
            </div>

            <div className="">
              <Form.Item hasFeedback label="TP" name="tp">
                <Input id="tp" type="text" placeholder="Enter tp" />
              </Form.Item>
            </div>

            <div className="">
              <Form.Item
                hasFeedback
                label="Discounted Price"
                name="discountedPrice"
              >
                <Input
                  id="discountedPrice"
                  type="text"
                  placeholder="Enter discounted price"
                />
              </Form.Item>
            </div>

            <div className="">
              <Form.Item hasFeedback label="Description" name="description">
                <Input.TextArea
                  id="description"
                  placeholder="Enter description"
                />
              </Form.Item>
            </div>

            <div className="col-span-2">
              <Divider orientation="left">
                Image
                <Tooltip
                  placement="bottom"
                  title={
                    "Thumbnail Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                  }
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                crop={1}
                imgUrl={generatedImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => {
                  setGeneratedImgUrl(url);
                }}
              />
            </div>
            <div className="col-span-2 mt-5">
              <SubmitResetBtn
                onClickSubmit={(e) => {
                  e.preventDefault();
                  setStartUpload("Uploading");
                }}
                onClickReset={resetData}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </Form>
      ) : (
        <div className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move bg-slate-50">
          <div className="flex justify-between items-start pb-4">
            <Avatar
              size={100}
              className="bg-contain rounded-2xl"
              src={addonData?.imageUrl}
              alt="attachment"
            />
            <div className="flex items-center gap-4">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={addonData?.isActive}
                onChange={(val, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onStatusChange(addonData?.id, val);
                }}
                className="mr-1"
                // loading={confirmLoading && shop?.id === confirmLoading}
                // disabled={shop?.hub?.deleted}
              />
              {extra}
            </div>
          </div>

          <div className="flex justify-between py-1">
            <div className="font-medium">Addon Name:</div>
            <div className="text-sm font-normal text-gray-700">
              {addonData?.name}
            </div>
          </div>

          {addonData?.description && (
            <div className="flex justify-between py-1">
              <div className="font-medium">Description</div>
              <div className="text-sm font-normal text-gray-700">
                {addonData?.description}
              </div>
            </div>
          )}

          <div className="flex justify-between py-1">
            <span className="font-medium">MRP</span>
            <span className="text-sm font-normal text-gray-700">
              {addonData?.price?.mrp} {addonData?.price?.currencyCode}
            </span>
          </div>

          <div className="flex justify-between py-1">
            <span className="font-medium">TP</span>
            <span className="text-sm font-normal text-gray-700">
              {addonData?.price?.tp} {addonData?.price?.currencyCode}
            </span>
          </div>

          {addonData?.price?.discountedPrice && (
            <div className="flex justify-between py-1">
              <span className="font-medium">Discounted Price</span>
              <span className="text-sm font-normal text-gray-700">
                {addonData?.price?.discountedPrice}{" "}
                {addonData?.price?.currencyCode}
              </span>
            </div>
          )}

          {addonData?.sellerDiscountedPrice && (
            <div className="flex justify-between py-1">
              <span className="font-medium">Seller Discounted Price</span>
              <span className="text-sm font-normal text-gray-700">
                {addonData?.sellerDiscountedPrice}
              </span>
            </div>
          )}

          <div className="flex justify-between py-1">
            <span className="font-medium">Stock</span>
            <span className="text-sm font-normal text-gray-700">
              <span
                className={`font-bold bg-white border rounded-full py-1 px-3 ${
                  addonData?.stock < 10 ? `text-red-600` : `text-green-600`
                }`}
              >
                {addonData?.stock}
              </span>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAddon;
