import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import ShopLayout from "../Layout";
import SingleProduct from "../../Product/Details";
import SingleOrder from "../../Orders/Details";
import { useSelector } from "react-redux";

const ShopOrderDetails = (): ReactElement => {
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <ShopLayout
        shopId={shopInfo.id}
        title="Order List"
        subTitle="Order List"
        extra={<></>}
      >
        <div className="content-body p-0 rounded-2xl">
          <SingleOrder inShop={true} />
        </div>
        {/* </div> */}
      </ShopLayout>
    </React.Fragment>
  );
};

ShopOrderDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopOrderDetails;
