import React from "react";
import Img from "../../images/empty.jpeg";

interface EmptyProps {
  height?: string;
  data?: string;
}

const Empty = ({ height = "100%", data }: EmptyProps) => {
  return (
    <div
      style={{
        height: height,
        // backgroundColor: height !== "100%" && "#fff",
        // boxShadow: height !== "100vh" && "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="empty-img-container">
        <div style={{ maxWidth: 150, padding: 15, margin: "15px auto" }}>
          <img src={Img} className="empty-img" alt="Loading" />
          <h6
            className="highlighted-txt text-center"
            style={{ whiteSpace: "nowrap" }}
          >
            Empty {data || "data"}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Empty;
