import MainLayout from "../../../components/Layouts";
import BulkProductUpdate from "../../../components/Shop/Product/bulkupdate";

const ShopProductBulkUpdatePage = () => {
  return (
    <MainLayout>
      <BulkProductUpdate />
    </MainLayout>
  );
};

export default ShopProductBulkUpdatePage;
