import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Image, Button, Empty, Form, Avatar } from "antd";
import BreadCrumb from "../../../Layouts/Breadcrumb";
import AddBtn from "../../../common/AddBtn";
import Loader from "../../../common/Loader";
import Pagination from "../../../common/Pagination";
import { getPage, getParamValue } from "../../../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import LoaderFull from "../../../common/LoaderFull";
import _ from "lodash";
import moment from "moment";
import { isAllowedService } from "../../../../utils/services";
import CountdownTimer from "../../../common/CountDown/CountdownTimer";
import ShopLayout from "../../Layout";

const CampaignList = (): ReactElement => {
  const [form] = Form.useForm();
  const route = useParams();
  const getShopId = (route as any)?.shopId;
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);
  const [selectedCampaignForEdit, setSelectedCampaignForEdit] =
    useState(undefined);
  const [isFlashSale, setIsFlashSale] = useState<any>();

  const [campaignData, setCampaignData] = useState<any>({
    loading: false,
    data: null,
  });

  const getCampaigns = useCallback(async () => {
    setCampaignData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/campaign/by-type?type=${type}&isActive=true&deleted=false&withUpcoming=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCampaignData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCampaignData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [isFlashSale]);
  //

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  const onClose = () => {
    getCampaigns();
    setVisible(undefined);
    setSelectedCampaign(undefined);
    setSelectedCampaignForEdit(undefined);
  };

  const reseAllFieldData = () => {
    form.resetFields();
    setIsFlashSale(false);
  };

  return (
    <>

<ShopLayout
        shopId={shopInfo.id}
        title="Product List"
        subTitle="Details"
       
      >

      <BreadCrumb
        title="Campaign List"
        subTitle={`${
          campaignData?.data?.totalElements ||
          campaignData?.data?.campaigns?.length
        } Campaign(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {campaignData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Campaign</th>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Registeration Close In</th>
                      <th scope="col">Campaign Live Period</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {campaignData?.data?.campaigns?.length ? (
                      campaignData?.data?.campaigns?.map(
                        (campaign: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                            onClick={() =>
                              navigate(
                                `/shops/${getShopId}/campaign/${campaign?.id}/details`
                              )
                            }
                          >
                            <td>
                              <div className="flex flex-col items-start">
                                <Avatar.Group
                                  maxCount={3}
                                  size={70}
                                  maxStyle={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                  }}
                                >
                                  <Avatar
                                    src={campaign?.bannerWeb}
                                    shape="square"
                                  />
                                </Avatar.Group>
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-800 uppercase mb-1 ">
                                {campaign?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-800 uppercase mb-1 ">
                                {campaign?.campaignType?.split("_").join(" ") ||
                                  "Undefined !!!"}
                              </span>
                            </td>
                            <td>
                              <CountdownTimer targetDate={campaign?.startAt} />
                            </td>

                            <td>
                              <div className="flex flex-col text-gray-600">
                                <span className="font-semibold">Start at</span>
                                <span>
                                  {moment(campaign?.startAt).format("lll")}
                                </span>
                                <span className="font-semibold mt-2">
                                  End at
                                </span>
                                <span>
                                  {moment(campaign?.endAt).format("lll")}
                                </span>
                              </div>
                            </td>

                            <td>
                              <Button
                                danger
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                size="large"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(
                                    `/shops/${getShopId}/campaign/${campaign?.id}/assign-products`
                                  );
                                }}
                              >
                                Join Now
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...campaignData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
</ShopLayout>
    </>
  );
};

export default CampaignList;
