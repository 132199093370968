import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import searchReducer from "./search/searchReducer";

const persistConfig = {
  key: "jc-root",
  storage,
  reduxStore: ["authReducer", "searchReducer"],
};
const rootReducer = combineReducers({
  authReducer,
  searchReducer,
});

export default persistReducer(persistConfig, rootReducer);
