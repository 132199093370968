import MainLayout from "../../components/Layouts";
import React from "react";
import KAMDetails from "../../components/Profile/KAM";

const KeyAccountPage = () => {
  return (
    <MainLayout>
      <KAMDetails />
    </MainLayout>
  );
};

export default KeyAccountPage;
