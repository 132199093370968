import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ShopLayout from "../Layout";
import AddShopForm from "./AddShopForm";

const AddShop = () => {
  const route = useParams();
  const [form] = Form.useForm();
  const [shopData, setShopData] = useState<any>({ loading: false, data: null });

  useEffect(() => {
    if (shopData.data) {
      form.resetFields(Object.keys(shopData.data as any));
    }
  }, [form, shopData]);

  return (
    <ShopLayout
      shopId={(route as any)?.shopId as string}
      title={shopData.data ? `Shop Edit` : `Shop Add`}
      subTitle={shopData.data ? `Edit Shop` : `Create Shop`}
    >
      <div className="p-4 bg-white rounded-xl">
        <AddShopForm />
      </div>
    </ShopLayout>
  );
};

export default AddShop;
