import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import BusinessCreate from "../../components/MerchantProfile/BusinessCreate";

const businessCreatePage = () => {
  return (
    <MerchantLayout>
      <BusinessCreate />
    </MerchantLayout>
  ); 
};

export default businessCreatePage;
