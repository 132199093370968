import React, { ReactElement } from "react";
import HomeLayout from "./Layouts";
import HomeHero from "./Banner";
import HomeService from "./Service";
import HomeApps from "./Apps";
import HomeWhyUs from "./WhyUs";
import HomeVideo from "./Video";
import HomeSteps from "./Steps";
import GetStarted from "./GetStarted";
import Gallery from "./Gallery";
import HomeFaq from "./Faq";

const Landing = (): ReactElement => {
  return (
    <HomeLayout>
      <main className="">
        <HomeHero />
        <HomeService />
        <HomeApps />
        <HomeWhyUs />
        <HomeVideo />
        <HomeSteps />
        {/* <Gallery /> */}
        <GetStarted />
        <HomeFaq />
      </main>
    </HomeLayout>
  );
};

export default Landing;
