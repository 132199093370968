import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Rate,
  Select,
  Spin,
  Switch,
  TreeSelect,
  TreeSelectProps,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";

import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import LoaderFull from "../common/LoaderFull";
import { debounce } from "lodash";
import { DefaultOptionType } from "antd/lib/select";

const ProductOutOfStockList = ({
  inShop = false,
  getShopId,
  getCategoryId,
}: {
  inShop?: boolean;
  getShopId?: string;
  getCategoryId?: string;
}): ReactElement => {
  const { type, token} = useSelector(
    (state) => (state as any)?.authReducer
  );
  const shopId = getShopId;
  const [form] = Form.useForm();
  const route = useParams();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");
  const [categoryId, setCategoryId] = useState<any>(getCategoryId);
  const [brandId, setBrandId] = useState<any>();
  const [campaignId, setCampaignId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(undefined);
  const [showSearch, setShowSearch] = useState(true);

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    setCampaignId((route as any).campaignId || "");
  }, [route]);

  const getProducts = useCallback(async () => {
    if (typeof campaignId === "string") {
      setProductData({ loading: true, data: null });

      const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUrl}/admin/product/stock-out-products?type=${type}` +
            (shopId ? `&shopId=${shopId}` : ``) +
            (categoryId ? `&categoryId=${categoryId}` : ``) +
            (brandId ? `&brandId=${brandId}` : ``) +
            (campaignId ? `&campaignId=${campaignId}` : ``) +
            (key ? `&key=${key}` : ``) +
            (isActive ? `&isActive=${isActive}` : ``) +
            (page ? `&page=${page}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setProductData({ loading: false, data: [] });
          console.error("Type: Error", err);
        });
    }
  }, [shopId, categoryId, brandId, campaignId, isActive, key, page, limit]);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/product/search?type=${type}&page=0&limit=20` +
            (getShopId ? `&shopId=${getShopId}` : ``) +
            (key ? `&key=${key}` : ``) +
            (shopId ? `&shopId=${shopId}` : ``) +
            (categoryId ? `&categoryId=${categoryId}` : ``) +
            (brandId ? `&brandId=${brandId}` : ``) +
            (campaignId ? `&campaignId=${campaignId}` : ``) +
            (isActive ? `&isActive=${isActive}` : ``) +
            (page ? `&page=${page}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [shopId, categoryId, brandId, campaignId, isActive, key, page, limit]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  const getCampaignOptions = useCallback(async (key?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign?type=${type}` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      })),
    });
  }, []);

  // ************************
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const genTreeNode = (parentId: number, category: any) => {
    return {
      ...category,
      id: category?.id,
      pId: parentId,
      value: category?.id,
      title: category?.title,
      isLeaf: false,
    };
  };

  const getParentCategory = useCallback(async (val: string) => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/parent-by-type?type=${type}&page=0&limit=50` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTreeData(
      res.data?.categories?.map(
        (category: Omit<DefaultOptionType, "label">) => ({
          ...category,
          id: category?.id,
          pId: 0,
          title: category?.title,
          value: category?.id,
          isLeaf: false,
        })
      )
    );
  }, []);

  const onLoadData: TreeSelectProps["loadData"] = ({ id }) => {
    return new Promise((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // ****************************
            setTimeout(() => {
              setTreeData(
                treeData?.concat(
                  data?.data?.categories?.map(
                    (category: Omit<DefaultOptionType, "label">) =>
                      genTreeNode(id, category)
                  )
                )
              );
              resolve(undefined);
            }, 300);
            // ****************************
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onChange = (newValue: string) => {
    setCategoryId(newValue);
  };

  const filteredCategory = treeData.find((category) => {
    return category?.id === categoryId;
  });
  // ************************

  //
  const onStatusChange = async (
    id: SetStateAction<undefined>,
    val: boolean
  ) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getProducts();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "campaign") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getShopOptions, getBrandsOptions, getCampaignOptions]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getParentCategory(``);
      getBrandsOptions("");
      getCampaignOptions();
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setCategoryId("");
    setBrandId("");
    setCampaignId("");
    setIsActive(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Out of Stock List"
        subTitle={`${productData?.data?.totalElements} Product(s)`}
        childComponent={inShop ? true : false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="category_search">
              <TreeSelect
                allowClear
                treeDataSimpleMode
                style={{ width: "100%" }}
                value={categoryId}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Filter by Category"
                onChange={onChange}
                loadData={onLoadData}
                treeData={treeData}
              />
            </Form.Item>

            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>

            <Form.Item name="campaign_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Campaign"
                optionFilterProp="children"
                onChange={(val) => setCampaignId(val)}
                onSearch={(e) => handleSearch(e, "campaign")}
                filterOption={() => {
                  return true;
                }}
                options={campaignOptions?.list}
              ></Select>
            </Form.Item>

            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Ratings</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.products?.length ? (
                      productData?.data?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() =>
                              navigate(
                                `${
                                  inShop ? `/shops/${getShopId}` : ``
                                }/products/${product?.slug}/details`
                              )
                            }
                          >
                            <td>
                              <Avatar
                                size={45}
                                src={product?.productImage}
                                shape="square"
                              />
                              <span className="font-medium text-gray-500 ml-2">
                                {product?.name?.slice(0, 50)}
                              </span>
                            </td>

                            <td>
                              <Rate value={product?.rating || 1} disabled />{" "}
                              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                {product?.numberOfRating || 0}
                              </span>
                            </td>

                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={product?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(product?.id, val);
                                }}
                                className="mr-1"
                                loading={
                                  confirmLoading &&
                                  product?.id === confirmLoading
                                }
                                disabled={product?.hub?.deleted}
                              />
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(
                                    inShop
                                      ? `/shops/${getShopId}/products/${product?.slug}/edit`
                                      : `/products/${product?.slug}/edit`
                                  );
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default ProductOutOfStockList;
