import React from "react";
import MainLayout from "../../../components/Layouts";
import CampaignDetails from "../../../components/Promotions/Campaign/Details";

const CampaignDetailsPage = () => {
  return (
    <MainLayout>
      <CampaignDetails />
    </MainLayout>
  );
};

export default CampaignDetailsPage;
