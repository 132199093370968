import React, { FC } from "react";
import cn from "classnames";
import s from "./Service.module.css";
// import { Facebook, Twitter, Linkedin, Instagram } from "@components/icons";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import Container from "../../ui/Container";
import ServiceImg from "../../../images/services.svg";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const HomeService: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="block transition-colors duration-150 text-secondary">
          <div className="text-center">
            <div className="w-full lg:max-w-4xl mx-auto">
              <h2 className="text-red-600 text-2xl lg:text-4xl font-bold mb-6 uppercase">
                Our service
              </h2>
              <p className="text-md lg:text-lg">
                Jachai.com simplifies the lives of small business owners with
                its all-in-one e-commerce platform that's tailored to meet every
                business's unique merchant service needs. The one-stop app for
                all of your requirements with just one tap, you may order food,
                groceries, e-commerce-related products, and more.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default HomeService;
