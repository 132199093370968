import { DatePicker, Form, Input, Radio, Select } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { promoUsedForArray } from "../../../../utils";
import { responseNotification } from "../../../../utils/notify";
import SubmitResetBtn from "../../../common/SubmitBtn";
import { useParams } from "react-router";

const AddPromoCode = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopId = (route as any)?.shopId;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState(undefined);
  const [singlePromoData, setSinglePromoData] = useState<any>(undefined);
  const [userId, setUserId] = useState<any>();
  const [mobileNumber, setMobileNumber] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [discountType, setDiscountType] = useState<any>(`disFlat`);

  const [usersOptions, setUsersOptions] = useState<any>({
    loading: false,
    list: [],
  });
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [paymentsOptions, setPaymentsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  // ******************************
  const fetchPromoDetails = async (visibleData: any) => {
    if (visibleData) {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/promo/details/${visibleData?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            setSinglePromoData(res?.promoCode);
            setDiscountType(
              res?.promoCode?.value?.percentage ? "disPercentage" : "disFlat"
            );
            setFromDate(res?.promoCode?.startFrom);
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getCustomersOptions = useCallback(
    async (mobileNumber: any) => {
      setUsersOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      axios
        .get(
          `${encodedUri}/admin/user?` +
            `page=${0}` +
            `&limit=${20}` +
            (status ? `&status=${status}` : ``) +
            (mobileNumber ? `&mobileNumber=%2B88${mobileNumber}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUsersOptions({
            loading: false,
            list: res.data?.users?.map((user: any) => ({
              label: user.fullName || user?.mobileNumber?.replace("+88", ""),
              value: user.id,
            })),
          });
        })
        .catch((err) => {
          setUsersOptions({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${
          process.env.REACT_APP_CATALOG_READER_API
        }/shop/admin/search?page=0&limit=50&key=${val || ``}` +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop: { id: any; name: any }) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type]
  );

  const getPaymentOptions = useCallback(
    async (val?: string) => {
      setPaymentsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_PAY_API}/payment-method?page=0&limit=50` +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPaymentsOptions({
        loading: false,
        list: res?.data?.methods?.map((method: { name: any; title: any }) => {
          return {
            value: method?.name,
            label: method?.title,
          };
        }),
      });
    },
    [type]
  );

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      title: data.title,
      customerId: userId || data.customerId,
      joiningDateOfUser: data.joiningDateOfUser,
      endAt: data.endAt,
      startFrom: data.startFrom,
      howManyInitialOrders: data.howManyInitialOrders * 1 || null,
      maximumAllowedUsage: data.maximumAllowedUsage * 1,
      minimumAmountPurchase: data.minimumAmountPurchase,
      paymentMethod: data.paymentMethod,
      promoCode: data.promoCode,
      message: data.message,
      shopId: shopId || data.shopId,
      usedFor: data.usedFor,
      type: type || data?.type,
      value: {
        flat: data.flat * 1,
        maxLimit: data.maxLimit * 1,
        percentage: data.percentage * 1,
      },
    };

    if (singlePromoData) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/promo`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          promoCodeId: singlePromoData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Promo Code Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/promo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Promo Code Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "customer") getCustomersOptions(value);
        else if (field === "shop") getShopsOptions(value);
      }
    };

    return debounce(loadOptions, 1200);
  }, [getCustomersOptions, getShopsOptions]);

  useEffect(() => {
    if (singlePromoData) {
      form.resetFields();
    }
  }, [form, singlePromoData]);

  useEffect(() => {
    if (visibleData) {
      fetchPromoDetails(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    getCustomersOptions("");
    getPaymentOptions();
  }, [getCustomersOptions, getPaymentOptions]);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...singlePromoData,
            maxLimit: singlePromoData?.value?.maxLimit,
            flat: singlePromoData?.value?.flat,
            percentage: singlePromoData?.value?.percentage,
            startFrom: moment(singlePromoData?.startFrom),
            endAt: moment(singlePromoData?.endAt),
            joiningDateOfUser: moment(singlePromoData?.joiningDateOfUser),
            discount: discountType,
            type: type || singlePromoData?.type,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <Form.Item
              hasFeedback
              label="Promo Title"
              rules={[
                {
                  required: true,
                  message: "Promo Title is Required!",
                },
              ]}
              name="title"
            >
              <Input
                id="title"
                type="text"
                placeholder="Enter Promo Title"
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Promo Code"
              rules={[
                {
                  required: true,
                  message: "Promo Code is Required!",
                },
              ]}
              name="promoCode"
            >
              <Input
                id="promoCode"
                type="text"
                placeholder="Enter Promo Code"
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Message"
              rules={[
                {
                  required: true,
                  message: "Promo Message is Required!",
                },
              ]}
              name="message"
            >
              <Input
                id="message"
                type="text"
                placeholder="Enter Promo Message"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Used For"
              name="usedFor"
              rules={[
                {
                  required: true,
                  message: "Used For is Required!",
                },
              ]}
            >
              <Select placeholder="Used For">
                {Object.values(promoUsedForArray)?.map((promoUsedFor, i) => (
                  <Select.Option value={promoUsedFor} key={i}>
                    {promoUsedFor?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Starting Date"
              rules={[
                {
                  required: true,
                  message: "Starting Date is Required!",
                },
              ]}
              name="startFrom"
            >
              <DatePicker
                showTime
                id="startFrom"
                placeholder="Enter Starting Date"
                onChange={(val) => setFromDate(moment(val).format("lll"))}
                // onSelect={(val) => setFromDate(val?.format("lll"))}
                style={{ minWidth: "100%" }}
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Ending Date"
              rules={[
                {
                  required: true,
                  message: "Ending Date is Required!",
                },
              ]}
              name="endAt"
            >
              <DatePicker
                showTime
                id="endAt"
                placeholder="Enter Ending Date"
                onChange={(val) => setToDate(moment(val).format("lll"))}
                disabled={!fromDate}
                disabledDate={(currentDate) =>
                  currentDate.isBefore(moment(fromDate))
                }
                style={{ minWidth: "100%" }}
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Maximum Allowed Usage"
              rules={[
                {
                  required: true,
                  message: "Maximum Allowed Usage is Required!",
                },
              ]}
              name="maximumAllowedUsage"
            >
              <Input
                id="maximumAllowedUsage"
                type="number"
                min="0"
                placeholder="Maximum Allowed Usage"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Minimum Amount Purchase"
              rules={[
                {
                  required: true,
                  message: "Minimum Amount Purchase is Required!",
                },
              ]}
              name="minimumAmountPurchase"
            >
              <Input
                id="minimumAmountPurchase"
                type="number"
                min="0"
                placeholder="Minimum Amount Purchase"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="How Many Initial Orders"
              name="howManyInitialOrders"
            >
              <Input
                id="howManyInitialOrders"
                type="number"
                min="0"
                placeholder="How Many Initial Orders"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Joining Date Of User"
              name="joiningDateOfUser"
            >
              <DatePicker
                showTime
                id="joiningDateOfUser"
                placeholder="Joining Date Of User"
                style={{ minWidth: "100%" }}
              />
            </Form.Item>

            <Form.Item hasFeedback label="Customer" name="customerId">
              <Select
                allowClear
                showSearch
                placeholder="Search by Phone (01...)"
                optionFilterProp="children"
                onChange={(val) => setUserId(val)}
                onSearch={(e) => handleSearch(e, "customer")}
                onSelect={(val: any) => setMobileNumber(val.toString())}
                filterOption={() => {
                  return true;
                }}
                options={usersOptions?.list}
              ></Select>
            </Form.Item>

            <div className="">
              <Form.Item
                hasFeedback
                label="Discount"
                name="discount"
                style={{ minWidth: "95%" }}
              >
                <Radio.Group
                  onChange={(val) => {
                    setDiscountType(val.target.value);
                  }}
                  defaultValue={discountType || `disFlat`}
                >
                  <Radio value={`disFlat`}>Flat</Radio>
                  <Radio value={`disPercentage`}>Percentage</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            {discountType === "disFlat" && (
              <Form.Item hasFeedback label="Flat Amount (&#2547;)" name="flat">
                <Input id="flat" type="number" placeholder="Flat Amount" />
              </Form.Item>
            )}

            {discountType === "disPercentage" && (
              <>
                <Form.Item hasFeedback label="Percentage (%)" name="percentage">
                  <Input
                    id="percentage"
                    type="number"
                    placeholder="Percentage Amount"
                  />
                </Form.Item>

                <Form.Item hasFeedback label="Max Limit" name="maxLimit">
                  <Input id="maxLimit" type="number" placeholder="Max Limit" />
                </Form.Item>
              </>
            )}
          </div>
          <SubmitResetBtn disabled={loading || !!error} loading={loading} />
        </Form>
      </div>
    </div>
  );
};

export default AddPromoCode;
