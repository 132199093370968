const Marker = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      {...props}
    >
      <path
        d="M7.78524 0C3.49244 0 0 3.49247 0 7.78524C0 9.87295 1.25608 12.4731 3.73326 15.5133C5.5422 17.7335 7.32281 19.3443 7.3977 19.4118L7.78524 19.761L8.17278 19.4118C8.24766 19.3443 10.0283 17.7335 11.8372 15.5133C14.3144 12.4731 15.5705 9.87295 15.5705 7.78524C15.5705 3.49247 12.078 0 7.78524 0V0Z"
        fill="#CD113B"
      />
      <path
        d="M7.88432 11.7769C10.0342 11.7769 11.7769 10.0342 11.7769 7.88432C11.7769 5.73448 10.0342 3.9917 7.88432 3.9917C5.73448 3.9917 3.9917 5.73448 3.9917 7.88432C3.9917 10.0342 5.73448 11.7769 7.88432 11.7769Z"
        fill="white"
      />
    </svg>
  )
}

export default Marker
