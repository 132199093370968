import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Navigation } from "swiper";

import "swiper/css/bundle";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router";
import Loader from "../common/Loader";
import CountdownTimer from "../common/CountDown/CountdownTimer";

const settings = {
  slidesPerView: 3,
  spaceBetween: 30,
  // pagination={true}
  pagination: {
    clickable: true,
  },
  navigation: true,
  breakpoints: {
    620: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1600: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
  modules: [Pagination, Navigation],
};

const DashboardCampaign = (): ReactElement => {
  const { token, type } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState<any>({
    loading: false,
    data: null,
  });

  const getCampaigns = useCallback(async () => {
    setCampaignData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/campaign/by-type?type=${type}&isActive=true&deleted=false&withUpcoming=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCampaignData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCampaignData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, []);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  return (
    <>
      {!!campaignData?.data?.campaigns?.length && (
        <div className="bg-white rounded-md">
          <div className="p-5 border-b text-lg font-semibold">
            Campaign Events
          </div>
          <div className="px-4">
            <div className="bg-white rounded-md">
              <Swiper {...settings} className="mySwiper">
                {campaignData?.loading ? (
                  <Loader />
                ) : (
                  campaignData?.data?.campaigns?.map((campaign, i) => (
                    <SwiperSlide key={i}>
                      <div className="bg-white shadow-md shadow-gray-200 hover:shadow-xl  border rounded-md my-6">
                        <div className="header flex w-full justify-around items-start py-2 text-center text-gray-600">
                          <CountdownTimer targetDate={campaign?.endAt} />
                        </div>
                        <div className="imgSection w-full h-48 md:h-48 overflow-hidden">
                          <img
                            src={campaign?.banner}
                            alt=""
                            className="object-cover w-full h-full"
                          />
                        </div>
                        <div className="details-section p-4 flex flex-col">
                          <h3 className="text-base text-gray-600 font-semibold mb-4">
                            {campaign?.name}
                          </h3>

                          <Button type="dashed">
                            {campaign?.campaignType?.split("_").join(" ")}
                          </Button>

                          <span className="mb-2 text-gray-600 mt-4">
                            {moment(campaign?.startAt)?.format("ll")} -{" "}
                            {moment(campaign?.endAt)?.format("ll")}
                          </span>

                          {!!campaign?.minimumDiscount && (
                            <span>
                              Minimum price discount:{" "}
                              {campaign?.minimumDiscount || 0}
                              {campaign?.minimumDiscountType === "PERCENTAGE"
                                ? "%"
                                : "Tk"}
                            </span>
                          )}

                          <span>
                            Registration until:{" "}
                            {moment(campaign?.startAt)?.format("ll")}
                          </span>
                        </div>
                        <div className="footer p-4 flex items-center justify-between border-t">
                          <span className="text-xs">
                            Sellers: {campaign?.totalSeller || 0}
                          </span>
                          <span className="text-xs">
                            Products: {campaign?.totalProducts || 0}
                          </span>
                          <span>
                            <Button
                              // shape="round"
                              danger
                              type="primary"
                              className="px-8"
                              onClick={() =>
                                navigate(
                                  `/promotion/campaign/${campaign?.id}/assign-products`
                                )
                              }
                            >
                              Join
                            </Button>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                )}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardCampaign;
