import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import Img from "../../../images/empty.jpeg";
import styles from "../../../styles/tailwind/List.module.css";
import GetShopShippingZoneList from "./ShippingZoneList";
import GetShopDeliveryList from "./ShopDeliveryList";
import GetShopPickupZoneList from "./ShopPickupZoneList";
import BreadCrumb from "../../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card, 
  Col,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import axios from "axios";
import { debounce } from "lodash";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import ShopLayout from "../Layout";
interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const DeliveryShippingSettings = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}):ReactElement => {
  const [form] = Form.useForm();
  const route = useParams();
  // const { sidebarCollapse, type, shopInfo, merchantInfo, userType } =
  //   useSelector((state) => (state as any)?.authReducer);
  const { type, shopInfo,merchantInfo, token } = useSelector((state) => (state as any)?.authReducer);
  const shopId = (route as any)?.shopId || '66d03dc7f43a473413fe3505';
  const [size, setSize] = useState<SizeType>("large");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [checkDeliveryType, setCheckDeliveryType] = useState();
  const [checkPackageType, setCheckPackageType] = useState();
  const [shippingAndDeliveryPackage, setShippingAndDeliveryPackage] = useState();
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();
    const [zonesOptions, setZonesOptions] = useState<any>({
      list: [],
      loading: false,
    });

    const [singleShopInfo, setSingleShopInfo] = useState<any>({
      loading: false,
      data: null,
    });

//Done
    const fetchShopDetails = useCallback((shopId: any) => {
      if (shopId) {
        try {
          setSingleShopInfo({ loading: true, data: null });
          axios
            .get(
              `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((data) => {
              if (data.status === 200) {
                setSingleShopInfo({
                  loading: false,
                  data: data?.data?.shop,
                });
                //setCheckDeliveryType(data?.data?.shop?.deliveryFulfillBy);
              } else { 
                setSingleShopInfo({ loading: false, data: null });
                responseNotification(
                  data.statusText || "something went wrong",
                  "error"
                );
              }
            })
            .catch((err) => {
              setSingleShopInfo({ loading: false, data: null });
  
              console.log(err);
            });
        } catch (error) {
          setSingleShopInfo({ loading: false, data: null });
  
          console.log(error, "error");
        }
      }
    }, []);

    useEffect(() => {
      fetchShopDetails(shopId);
    }, [fetchShopDetails]);




    const getZonesOptions = useCallback(async (val?: string) => {
      setZonesOptions({ loading: true, list: [] });
  
      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/zone/all`,
  
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setZonesOptions({
        loading: false,
        list: res?.data?.zones?.map((zone: any) => {
          return {
            value: zone?.id,
            label: zone?.name,
          };
        }),
      });
    }, []);
    useEffect(() => {
      getZonesOptions();
    }, [getZonesOptions]);
    const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "zone") getZonesOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getZonesOptions, type]);

 

  //Done
  const onShippingAndDeliveryTypeChange = async (shippingAndDeliveryType:any) => {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: shopId,
          deliveryFulFillBy: shippingAndDeliveryType,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Updated Successfully", "success");
            //getShops();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    
  };
//Done
  const onShippingAndDeliveryPackageChange = async (packageValue:any) => {
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: shopId,
        deliveryPackageFulfillBy: packageValue,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setConfirmLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Updated Successfully", "success");
          //getShops();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  
};

useEffect(() => {
  if (singleShopInfo?.data) {
    form.resetFields();
    setCheckDeliveryType(singleShopInfo?.data?.deliveryFulfillBy);
    setCheckPackageType(singleShopInfo?.data?.deliveryPackageFulfillBy);
  
  }
}, [form, singleShopInfo?.data]);

const shop = singleShopInfo?.data;
  return (
    <>
          <ShopLayout  
        shopId={(route as any)?.shopId}
        title="Shipping & Delivery Settings"
        subTitle="Shipping & Delivery Settings"
        extra={<></>}
      >
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            className="py-2 inline-block min-w-full sm:px-6 lg:px-8 "
            style={contendStyle}
          >
            <div className="content-body">
              <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                Shipping & Delivery Settings 
              </h3>
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className={styles.formStyles}
                  form={form} // like ref
                  layout="horizontal"
                >
                  <div className="px-8">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Type"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Type is Required!",
                        },
                      ]}
                      name="shippingDeliveryType"
                    >
                      <Radio.Group
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onShippingAndDeliveryTypeChange(e.target.value);
                        }}
                        defaultValue={checkDeliveryType}
                       >
                        <Radio value={'JACHAI'} checked>Fulfillment By Jachai (FBJ)</Radio>
                        <Radio value="SELLER" >Fulfillment By Seller (FBS)</Radio>
                        <Radio value={'REFERRER'}>Fulfillment By Agent/Referral</Radio>
                        <Radio value={'DELIVERYMAN'}>Fulfillment By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="px-8">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Packeging"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Packeging is Required!",
                        },
                      ]}
                      name="shippingDeliveryPackeging"
                    >
                      <Radio.Group  
                      onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onShippingAndDeliveryPackageChange(e.target.value);
                        }}
                        defaultValue={checkPackageType}
                        >
                        <Radio value={'JACHAI'}>Packaging By Jachai (PBJ)</Radio>
                        <Radio value={'SELLER'}>Packaging By Seller (PBS)</Radio>
                        <Radio value={'REFERRER'}>Packaging By Agent/Referral</Radio>
                        <Radio value={'DELIVERYMAN'}>Packaging By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  {/* <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Shipping
                  </h3> */}
                  {/* <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                    Choose Where you ship and how much you charge for shipping
                    at checkout.
                  </p> */}

                  {/* <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    General Shipping Rates
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-1/4 border-r-2 h-full font-medium">
                      <h4 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                        General
                      </h4>
                      <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                        All Product Category
                      </p>
                    </p>
                    <p className="float-left px-4 w-1/2 text-center font-medium">
                      No Shipping rates for customers to choose from
                    </p>
                    <p className="float-left px-4 w-1/4 text-right font-medium">
                      <Link
                        title="Edit Product"
                        to={`/delivery-shipping/shipping-rates`}
                        //target="_blank"
                      >
                        Manage
                      </Link>
                    </p>
                  </Card> */}

                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Custom Shipping Rates
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-4/5 h-full font-medium">
                      Add custom rates or destination restrictions for groups of
                      products.
                    </p>

                    <p className="float-left px-4 w-1/5 text-right font-medium">
                    <Link
                        title="Edit Product"
                        to={`/shops/${shopId}/shipping-profile`}
                        //target="_blank"
                      >
                      Create New Profile
                      </Link>
                    </p>
                  </Card>
                  {/* <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Processing time
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-4/5 h-full font-medium">
                      Show delivery dates to your customer by setting a
                      processing time. If you process orders in 2 business days
                      or less. This will be added to the transit time of your
                      shipping rates.
                    </p>

                    <p className="float-left px-4 w-1/5 text-right font-medium">
                    <Link
                        title="Edit Product"
                        to={`/delivery-shipping/processing-time`}
                        //target="_blank"
                      >
                      Manage
                      </Link>
                    </p>
                  </Card>  */}

                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Local Shipping Zones
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                  <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <GetShopShippingZoneList inShop={true} getShopId={shopId} />
          </div>
                 
                  </Card>
                  {/* <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Local Pickup
                  </h3>
                  <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                    Allow local Customers to pick up their orders.
                  </p>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-4/5 h-full font-medium">
                      <p className="float-left">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="text-5xl"
                        />
                      </p>
                      <p className="float-left">
                        <h5 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                          Shop Location/Seller Address
                        </h5>
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                          Banglades
                        </p>
                      </p>
                    </p>

                    <p className="float-left px-4 w-1/5 text-right font-medium">
                      Manage
                    </p>
                  </Card> */}
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Local Pickup Zones 
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                   
                  <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <GetShopPickupZoneList inShop={true} getShopId={shopId} />
          </div>
                   
                  </Card>

                  {/* <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full pb-4">
                      <p className="float-left w-4/5 text-left">
                        Saved Packages
                      </p>

                      <p className="float-left w-1/5 text-right">
                      <Link
                        title="Edit Product"
                        to={`/delivery-shipping/add-package`}
                        // target="_blank"
                      >
                        Add Package
                        </Link>
                        </p>

                    </div>
                    <div className="float-left w-full">
                      <List
                        bordered
                        dataSource={totalData}
                        renderItem={(item) => (
                          <List.Item>
                            <p className="float-left w-11/12 text-left">
                              {item}
                            </p>
                            <p className="float-left w-1/12 text-right">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="text-2xl"
                              />
                            </p>
                          </List.Item>
                        )}
                      />
                    </div>
                  </Card> */}
                  {/* <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full flex">
                      <p className="float-left w-4/5 text-left">
                        <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary">
                          Carrier Shipping
                        </h3>
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                          Buy and print discounted shipping labels from Jachai
                          shipping.
                        </p>
                      </p>
                      <p className="float-left w-1/5 text-right">
                        Calculate shipping rates
                      </p>
                    </div>
                    <div className="float-left w-full mt-4">
                      <List
                        bordered
                        dataSource={totalData}
                        renderItem={(item) => (
                          <List.Item>
                            <p className="float-left w-4/5 text-left">
                              <FontAwesomeIcon
                                icon={faCableCar}
                                className="text-2xl"
                              />{" "}
                              {item}
                            </p>
                            <p className="float-left w-1/5 text-right">
                              Discounts up to 82% off
                            </p>
                          </List.Item>
                        )}
                      />
                      <p className="mt-0 font-medium leading-tight text-primary">
                        Check hardware compatibility by printing Test shipping
                        labels. To buy shipping supplies.
                      </p>
                    </div>
                  </Card> */}
             
                
                
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
      </ShopLayout>
    </>
  );
};

export default DeliveryShippingSettings;
