import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopUsersList from "../../../components/Shop/Users/index";

const ShopUsersPage = () => {
  return (
    <MainLayout>
      <ShopUsersList />
    </MainLayout>
  );
};

export default ShopUsersPage;
