const Facebook = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="21"
      viewBox="0 0 10 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57249 10.2527H6.78164V20.4771H2.55327V10.2527H0.542236V6.65945H2.55327V4.3342C2.55327 2.6714 3.34312 0.067627 6.81928 0.067627L9.95139 0.0807306V3.5686H7.67884C7.30608 3.5686 6.78192 3.75484 6.78192 4.54805V6.66279H9.94191L9.57249 10.2527Z"
        fill="#393E46"
      />
    </svg>
  )
}

export default Facebook
