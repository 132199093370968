import { PageHeader } from "antd";
import React from "react";

const BreadCrumb = ({ title, subTitle, extra, childComponent }: any): any => {
  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        className={`${childComponent ? "bg-white pt-1 pb-0 px-2 rounded-2xl" : "bg-gray-100 px-0"}`}
        ghost={false}
        onBack={() => window.history.back()}
        title={title}
        subTitle={subTitle}
        extra={extra}
      />
    </div>
  );
};

export default BreadCrumb;
