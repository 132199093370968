const NewUser = ({ ...props }) => {
  return (
    <svg
      width="136"
      height="80"
      viewBox="0 0 136 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.3629 14.4332H87.0911C88.2978 14.4332 89.2724 13.4586 89.2724 12.252V2.18123C89.2724 0.97459 88.2978 0 87.0911 0H69.3629C68.1562 0 67.1816 0.97459 67.1816 2.18123V12.252C67.1816 13.4586 68.1562 14.4332 69.3629 14.4332Z"
        fill="#B965E0"
      />
      <path
        d="M77.2983 5.52194C77.2983 5.47553 77.2983 5.42913 77.2983 5.38272C77.2983 4.64017 77.2983 3.94404 77.2983 3.20149C77.2983 3.06226 77.2519 2.92304 77.1126 2.83022C76.9734 2.78381 76.8342 2.83022 76.7414 2.92304C75.3491 4.22249 73.9104 5.56835 72.5181 6.86781C72.3325 7.05344 72.3325 7.23908 72.5181 7.42472C73.9104 8.72417 75.3491 10.07 76.7414 11.3695C76.8342 11.4623 76.9734 11.5087 77.1126 11.4623C77.2519 11.4159 77.2983 11.2767 77.2983 11.091C77.2983 10.3485 77.2983 9.65235 77.2983 8.90981C77.2983 8.8634 77.2983 8.81699 77.2983 8.81699C77.4375 8.81699 77.5767 8.81699 77.7159 8.81699C78.3657 8.8634 79.0154 8.95621 79.6187 9.18826C80.3613 9.46671 80.9182 9.97721 81.2895 10.6269C81.3823 10.8126 81.5215 11.0446 81.6143 11.2303C81.7071 11.4159 81.8464 11.4623 81.9856 11.4159C82.1248 11.3695 82.264 11.2303 82.264 11.0446C82.264 10.2557 82.264 9.51312 82.0784 8.72417C81.9392 7.98162 81.6607 7.28549 81.1502 6.68217C80.6861 6.17167 80.0364 5.84681 79.3867 5.66117C78.737 5.47553 78.0872 5.38272 77.3911 5.38272C77.3911 5.52194 77.3447 5.52194 77.2983 5.52194ZM73.1679 7.19267C74.3281 6.12526 75.4883 5.01144 76.6485 3.94404C76.6485 3.99044 76.6485 4.03685 76.6485 4.08326C76.6485 4.64017 76.6485 5.24349 76.6485 5.8004C76.6485 6.03244 76.7878 6.17167 77.0198 6.17167C77.2519 6.17167 77.4839 6.17167 77.7159 6.17167C78.3657 6.21808 78.969 6.3109 79.5723 6.54294C80.222 6.77499 80.7325 7.19267 81.1038 7.8424C81.3359 8.26008 81.4751 8.77058 81.5215 9.23467C81.5679 9.42031 81.5679 9.65235 81.6143 9.83799C81.5679 9.83799 81.5679 9.83799 81.5679 9.79158C81.011 9.09544 80.3149 8.67776 79.4795 8.44572C78.6905 8.21367 77.9016 8.16726 77.1126 8.16726C76.8342 8.16726 76.7414 8.30649 76.7414 8.53853C76.7414 9.09544 76.7414 9.69876 76.7414 10.2557C76.7414 10.3021 76.7414 10.3485 76.7414 10.3949C75.4419 9.32749 74.3281 8.26008 73.1679 7.19267Z"
        fill="white"
      />
      <path
        d="M80.7317 16.999L78.418 14.2476H83.0141L80.7317 16.999Z"
        fill="#B965E0"
      />
      <path
        d="M78.6334 79.9992C110.01 79.9992 135.447 76.1496 135.447 71.401C135.447 66.6523 110.01 62.8027 78.6334 62.8027C47.2564 62.8027 21.8203 66.6523 21.8203 71.401C21.8203 76.1496 47.2564 79.9992 78.6334 79.9992Z"
        fill="#B965E0"
        fillOpacity="0.24"
      />
      <path
        d="M123.127 73.3702L77.885 73.5578C66.8793 64.8033 65.7537 51.8278 73.6015 43.9487C78.354 39.1962 84.3884 40.1655 90.1101 39.3838C90.1101 39.3838 90.1101 39.3838 90.1414 39.3838C92.1424 39.1337 94.1122 38.6334 95.9569 37.6642C101.866 34.5063 105.212 27.0337 110.183 20.7492C110.808 19.9675 111.465 19.1858 112.122 18.4667C116.217 14.0269 124.347 13.9018 128.599 20.1238C139.417 35.9445 134.477 59.5193 123.127 73.3702Z"
        fill="#AF95BC"
      />
      <path
        d="M124.128 37.1631C122.94 55.9854 101.523 63.0203 88.1096 57.3924C76.4786 52.4836 80.293 41.2903 90.1107 39.4143C90.1107 39.4143 90.1106 39.4143 90.1419 39.4143C92.143 39.1642 94.1127 38.6639 95.9574 37.6947C101.867 34.5368 105.212 27.0641 110.184 20.7796C117.594 16.8713 125.004 23.3747 124.128 37.1631Z"
        fill="#DBDBDB"
      />
      <path
        d="M81.6377 76.2154C82.3255 75.9652 98.1463 63.1461 98.4589 62.2706C98.584 61.9267 96.8644 59.4879 94.7382 56.6114C94.2693 55.9861 93.8003 55.3295 93.3 54.7042C90.1108 50.4832 86.609 46.0747 86.609 46.0747L76.0098 54.6104L82.9821 62.9897L84.5142 64.8345C84.5142 64.8345 80.6372 72.4947 80.387 73.4952C80.1682 74.4645 80.9498 76.4655 81.6377 76.2154Z"
        fill="#F7A9A0"
      />
      <path
        d="M81.6377 76.2133C82.3256 75.9632 98.1463 63.1441 98.459 62.2686C98.584 61.8621 96.0515 58.329 93.3001 54.6709L82.9822 62.9877L84.5142 64.8324C84.5142 64.8324 80.6372 72.4927 80.3871 73.4932C80.1682 74.4624 80.9499 76.4635 81.6377 76.2133Z"
        fill="white"
      />
      <path
        d="M81.6377 76.2167C82.3256 75.9666 98.1463 63.1474 98.459 62.272C98.584 61.9281 96.8644 59.4893 94.7383 56.6128L84.5142 64.8358C84.5142 64.8358 80.6372 72.496 80.3871 73.4966C80.1682 74.4658 80.9499 76.4669 81.6377 76.2167Z"
        fill="#B965E0"
      />
      <path
        d="M81.3857 75.4059C83.9808 73.186 94.6739 64.494 97.394 62.5242C97.4253 62.4929 97.4253 62.5242 97.394 62.5554C94.8615 64.8066 84.1059 73.4048 81.3857 75.4684C81.3544 75.4684 81.3232 75.4371 81.3857 75.4059Z"
        fill="#263238"
      />
      <path
        d="M80.9494 72.0909C81.5435 71.5593 82.419 71.3718 83.0443 71.4656C83.6696 71.5593 84.3575 71.8407 84.7639 72.4348C84.7639 72.4661 84.7327 72.4661 84.7327 72.4661C83.7634 71.5281 82.075 71.3718 80.9494 72.1534C80.9494 72.1221 80.9182 72.0909 80.9494 72.0909Z"
        fill="#263238"
      />
      <path
        d="M83.701 65.9316C84.764 65.0561 86.2648 64.4308 87.6405 64.7747C87.703 64.7747 87.6718 64.8685 87.6405 64.8685C86.2961 65.1499 85.0767 65.5251 83.7948 66.0566C83.701 66.1192 83.6072 65.9941 83.701 65.9316Z"
        fill="#263238"
      />
      <path
        d="M83.1385 66.8989C84.2015 66.0234 85.7023 65.3981 87.078 65.742C87.1405 65.742 87.1093 65.8358 87.078 65.8358C85.7336 66.1172 84.5142 66.4924 83.2323 67.0239C83.1385 67.0865 83.0447 66.9614 83.1385 66.8989Z"
        fill="#263238"
      />
      <path
        d="M82.5745 67.8993C83.6375 67.0239 85.1383 66.3986 86.514 66.7425C86.5765 66.7425 86.5453 66.8363 86.514 66.8363C85.1696 67.1177 83.9502 67.4929 82.6683 68.0244C82.5432 68.0869 82.4807 67.9619 82.5745 67.8993Z"
        fill="#263238"
      />
      <path
        d="M82.012 68.9003C83.075 68.0249 84.5758 67.3995 85.9515 67.7435C86.014 67.7435 85.9828 67.8373 85.9515 67.8373C84.6071 68.1187 83.3877 68.4939 82.1058 69.0254C81.9807 69.0879 81.9182 68.9628 82.012 68.9003Z"
        fill="#263238"
      />
      <path
        d="M94.707 60.49C95.8951 59.5207 97.3646 61.3967 96.1765 62.366C94.9884 63.3352 93.5189 61.4592 94.707 60.49Z"
        fill="white"
      />
      <path
        d="M80.293 63.9919C80.4806 63.1165 81.5124 62.9914 82.2002 63.2103C83.3258 63.523 84.5452 64.3046 85.3269 65.1801C85.3581 65.2113 85.3269 65.2113 85.3269 65.2426C85.3581 65.3364 85.3269 65.4615 85.2018 65.4927C84.0137 65.5865 82.7943 65.8054 81.6374 65.524C80.9183 65.3364 80.1054 64.8361 80.293 63.9919ZM83.3884 65.2113C83.9511 65.1801 84.5452 65.1175 85.108 65.1175C84.4827 64.7423 83.8886 64.3359 83.2633 64.0232C82.9506 63.8669 82.638 63.7105 82.294 63.6168C81.7938 63.4604 80.0429 63.3979 80.8558 64.6485C81.2935 65.3364 82.7005 65.2426 83.3884 65.2113Z"
        fill="#263238"
      />
      <path
        d="M88.2654 63.4273C87.4524 64.334 86.2956 64.8343 85.2638 65.4284C85.17 65.4909 85.0449 65.3971 85.0449 65.3033C85.0137 65.3033 85.0137 65.3033 85.0137 65.272C85.2013 64.0839 85.7641 62.7707 86.5457 61.864C87.0147 61.3012 87.9214 60.801 88.578 61.4575C89.1408 62.0203 88.7344 62.8958 88.2654 63.4273ZM88.4217 62.3017C88.4217 60.801 86.9835 61.8327 86.6708 62.2705C86.4519 62.5519 86.2643 62.8645 86.108 63.1459C85.7328 63.7712 85.4827 64.4591 85.17 65.1157C85.639 64.803 86.1705 64.5216 86.6395 64.209C87.2336 63.8338 88.4217 63.1147 88.4217 62.3017Z"
        fill="#263238"
      />
      <path
        d="M65.2848 38.1622C66.0039 43.7589 79.5735 59.8923 81.168 61.737C81.3869 62.0184 81.512 62.1434 81.512 62.1434L92.7365 53.3889L92.6115 53.2326L82.7314 40.1007L112.215 39.9131L101.022 31.0961C101.022 31.0961 100.115 31.6276 98.2394 31.3462C91.736 30.3769 77.0409 28.2508 73.6016 28.3759C69.1305 28.5635 64.6282 33.0346 65.2848 38.1622Z"
        fill="#37474F"
      />
      <path
        d="M79.1682 35.2234C79.5434 35.6298 79.8561 36.0988 80.2 36.5366C80.5439 37.0055 80.9191 37.4745 81.263 37.9123C81.2005 37.7872 81.138 37.6309 81.0754 37.5058C80.8566 37.0368 80.669 36.5678 80.4188 36.1301C80.3876 36.0988 80.4501 36.0676 80.4501 36.0988C80.7315 36.5366 81.0129 36.943 81.263 37.412C81.3568 37.6309 81.4819 37.8497 81.5757 38.0686C81.6382 38.1937 81.6695 38.3187 81.732 38.4438C81.7633 38.5063 81.7946 38.5689 81.7946 38.6314C81.9509 38.8503 82.1072 39.0691 82.2636 39.288C82.6075 39.7882 82.8889 40.2885 83.2328 40.7888C83.2641 40.82 83.2015 40.8513 83.2015 40.82C82.8576 40.351 82.4824 39.9446 82.1385 39.4756C81.7946 39.0066 81.4506 38.5376 81.1067 38.0686C80.794 37.5996 80.4814 37.1306 80.1374 36.6616C79.7935 36.1926 79.4183 35.7549 79.1682 35.2234C79.1682 35.1921 79.1369 35.2234 79.1682 35.2234Z"
        fill="#263238"
      />
      <path
        d="M110.589 7.14788C112.934 9.02385 115.248 21.2177 116.154 22.5621C116.78 23.5314 117.593 24.4694 117.561 25.0009C117.53 25.5324 115.279 28.1901 111.027 29.003C106.774 29.8472 102.585 27.7836 102.178 27.2521C101.772 26.7205 102.866 24.8446 102.835 23.1875C102.804 21.4991 101.334 12.3693 102.21 9.64918C103.21 6.58508 107.9 5.02177 110.589 7.14788Z"
        fill="#263238"
      />
      <path
        d="M101.867 26.7519C102.336 25.6263 102.523 24.532 102.492 23.3126C102.461 21.9681 102.304 20.6549 102.148 19.3105C101.992 17.9035 101.835 16.5278 101.679 15.1208C101.554 13.8702 101.366 12.6508 101.585 11.4001C101.804 10.212 102.336 9.08642 103.086 8.14844C103.086 8.14844 103.117 8.14844 103.117 8.1797C102.398 9.11769 101.929 10.212 101.71 11.4001C101.491 12.7133 101.71 14.0578 101.835 15.4022C101.992 16.7779 102.148 18.1536 102.273 19.5294C102.398 20.78 102.554 22.0619 102.586 23.3438C102.586 24.5007 102.492 25.8139 101.867 26.8144C101.898 26.8144 101.835 26.7831 101.867 26.7519Z"
        fill="#263238"
      />
      <path
        d="M110.215 7.05469C111.309 7.64875 111.841 8.83686 112.247 9.96245C112.841 11.4945 113.373 13.0578 113.873 14.6524C114.405 16.2782 114.842 17.9353 115.343 19.5612C115.843 21.187 116.624 22.594 117.562 24.001C117.594 24.0323 117.531 24.0635 117.531 24.0323C115.499 21.6248 114.905 18.4043 113.967 15.4653C113.467 13.8395 112.904 12.2136 112.341 10.619C111.872 9.30586 111.403 7.83634 110.215 7.05469C110.184 7.05469 110.184 7.08595 110.215 7.05469Z"
        fill="#263238"
      />
      <path
        d="M109.213 6.61572C111.496 7.52244 112.371 10.0863 113.121 12.3062C113.997 14.9325 114.622 17.5902 115.373 20.2478C115.81 21.7486 116.185 23.3432 117.155 24.5938C117.186 24.6251 117.123 24.6876 117.092 24.6563C116.342 23.7809 115.998 22.6553 115.623 21.561C115.216 20.2478 114.841 18.9346 114.497 17.5902C113.778 14.9638 113.121 12.2436 112.027 9.77361C111.433 8.46043 110.558 7.17852 109.213 6.61572C109.213 6.61572 109.182 6.64699 109.213 6.61572Z"
        fill="#263238"
      />
      <path
        d="M108.527 23.1553C106.526 26.063 99.6784 33.6295 94.9259 34.7238C92.2995 35.3179 79.6992 37.1313 77.9796 35.5367C75.9473 33.6607 76.8853 26.6258 78.5111 26.5633C83.4199 26.3757 92.5184 27.345 94.8008 26.8447C95.8326 26.6258 101.273 23.0615 107.088 20.185C109.277 19.0594 109.84 21.2793 108.527 23.1553Z"
        fill="#F7A9A0"
      />
      <path
        d="M81.1992 26.1254L82.0434 36.6621C82.0434 36.6621 93.4869 36.4745 96.9574 34.5048C100.428 32.535 106.025 27.5011 108.182 23.9993C110.339 20.4975 109.62 18.5902 105.399 20.7476C101.21 22.905 95.5192 26.3443 94.7688 26.5319C94.0496 26.7507 81.1992 26.1254 81.1992 26.1254Z"
        fill="#B965E0"
      />
      <path
        d="M83.0137 26.8765C83.0137 26.8452 83.0449 26.8452 83.0762 26.8765C83.2325 27.5956 83.2638 28.346 83.3576 29.0651C83.4514 29.8155 83.5139 30.5972 83.5765 31.3475C83.7015 32.8483 83.8579 34.3804 83.9204 35.9124C83.9204 35.975 83.8266 35.975 83.8266 35.9124C83.6077 34.4116 83.4827 32.9109 83.3576 31.4101C83.2951 30.6597 83.2325 29.9093 83.17 29.1589C83.1075 28.4085 83.0137 27.6269 83.0137 26.8765Z"
        fill="#263238"
      />
      <path
        d="M118.781 42.414C118.156 42.6329 100.303 37.3801 100.303 37.3801L102.398 31.0956C102.398 31.0956 101.366 29.0633 101.835 27.1248C102.491 24.5297 107.213 20.2462 107.588 19.9961C109.089 18.9956 110.496 18.9956 111.715 19.6834C116.905 22.4661 118.781 42.414 118.781 42.414Z"
        fill="#B965E0"
      />
      <path
        d="M105.181 22.2756C104.712 22.8072 104.243 23.3387 103.743 23.8702C103.962 23.4638 104.181 23.0886 104.431 22.6821C104.431 22.6821 104.431 22.6508 104.431 22.6821C104.087 23.1511 103.774 23.6201 103.524 24.1516C103.18 24.5581 102.836 24.9645 102.555 25.4335C102.211 25.9651 101.898 26.5591 101.742 27.1844C101.585 27.8098 101.585 28.4976 101.679 29.123C101.804 29.8733 102.023 30.5925 102.273 31.2803C102.305 31.3741 102.43 31.3116 102.398 31.2178C102.054 29.9984 101.679 28.6852 101.961 27.4033C102.211 26.1527 103.024 25.0583 103.774 24.0578C104.243 23.4638 104.681 22.8384 105.212 22.3069C105.212 22.2756 105.181 22.2444 105.181 22.2756Z"
        fill="#263238"
      />
      <path
        d="M110.653 20.0607C110.59 20.4046 109.058 22.5307 107.589 22.4994C107.12 22.4682 107.088 19.8105 107.088 19.8105L107.057 19.6229L106.4 16.8715L110.027 14.464C109.996 14.4327 110.653 19.9043 110.653 20.0607Z"
        fill="#F7A9A0"
      />
      <path
        d="M106.369 16.8387L107.026 19.5901C107.807 19.2774 109.433 18.402 110.215 16.3071C110.09 15.3691 109.996 14.5562 109.996 14.4624V14.4312L109.527 14.7125L106.369 16.8387Z"
        fill="#263238"
      />
      <path
        d="M102.148 13.4638C102.461 18.4039 105.4 18.7791 106.463 18.7165C107.432 18.654 110.652 18.3101 110.871 13.37C111.059 8.42994 108.714 6.99169 106.525 7.02296C104.305 7.05422 101.835 8.52374 102.148 13.4638Z"
        fill="#F7A9A0"
      />
      <path
        d="M102.901 12.9954C103.027 12.9642 103.12 12.9329 103.214 12.8704C103.339 12.8391 103.433 12.8078 103.527 12.6828C103.558 12.6202 103.558 12.5264 103.496 12.4639C103.37 12.3388 103.214 12.3701 103.058 12.4326C102.901 12.4952 102.776 12.5577 102.683 12.714C102.589 12.8704 102.745 13.0267 102.901 12.9954Z"
        fill="#263238"
      />
      <path
        d="M106.089 12.5877C105.964 12.5877 105.87 12.5564 105.745 12.5564C105.62 12.5564 105.526 12.5564 105.401 12.4626C105.339 12.4313 105.339 12.3063 105.37 12.2437C105.464 12.1187 105.62 12.0874 105.777 12.0874C105.933 12.0874 106.089 12.1499 106.214 12.2437C106.339 12.3688 106.214 12.5877 106.089 12.5877Z"
        fill="#263238"
      />
      <path
        d="M105.496 13.3984C105.496 13.3984 105.527 13.4297 105.496 13.3984C105.558 13.7736 105.621 14.1488 105.934 14.2114V14.2426C105.558 14.2426 105.465 13.7111 105.496 13.3984Z"
        fill="#263238"
      />
      <path
        d="M105.747 13.0249C106.278 12.9311 106.434 13.9942 105.934 14.0879C105.465 14.1817 105.309 13.1187 105.747 13.0249Z"
        fill="#263238"
      />
      <path
        d="M105.966 13.0562C106.06 13.0875 106.185 13.2125 106.279 13.1813C106.404 13.1813 106.498 13.0562 106.56 12.9312H106.591C106.623 13.15 106.591 13.3689 106.373 13.4314C106.185 13.4939 105.997 13.3376 105.935 13.1187C105.904 13.0875 105.935 13.025 105.966 13.0562Z"
        fill="#263238"
      />
      <path
        d="M103.651 13.5874L103.619 13.6187C103.682 13.9626 103.713 14.3378 103.432 14.4941V14.5254C103.807 14.4316 103.776 13.9001 103.651 13.5874Z"
        fill="#263238"
      />
      <path
        d="M103.307 13.3053C102.776 13.3366 102.901 14.4309 103.401 14.3996C103.901 14.3371 103.776 13.2428 103.307 13.3053Z"
        fill="#263238"
      />
      <path
        d="M103.088 13.4006C103.026 13.4631 102.932 13.5882 102.838 13.5882C102.713 13.6195 102.619 13.4944 102.526 13.4006C102.526 13.4006 102.494 13.4006 102.526 13.4006C102.557 13.6195 102.651 13.8071 102.838 13.8383C103.026 13.8383 103.12 13.6507 103.151 13.4631C103.182 13.4319 103.12 13.3693 103.088 13.4006Z"
        fill="#263238"
      />
      <path
        d="M105.934 16.3096C105.872 16.4034 105.809 16.5285 105.684 16.5597C105.559 16.591 105.434 16.5597 105.309 16.5597C105.309 16.5597 105.278 16.5597 105.309 16.591C105.403 16.7161 105.621 16.7473 105.747 16.6848C105.903 16.6223 105.934 16.4972 105.934 16.3409C105.965 16.2783 105.965 16.2783 105.934 16.3096Z"
        fill="#263238"
      />
      <path
        d="M104.401 15.2751C104.401 15.2751 104.401 15.7754 104.432 16.0255C104.432 16.0568 104.494 16.0568 104.557 16.0568C104.963 16.0568 105.37 15.8692 105.558 15.494C105.558 15.4627 105.526 15.4627 105.526 15.4627C105.245 15.7441 104.932 15.8379 104.557 15.9004C104.526 15.8379 104.588 15.025 104.557 15.025C104.432 15.025 104.182 15.1501 104.057 15.2126C104.025 14.1495 104.151 13.0865 104.119 12.0547C104.119 12.0234 104.057 12.0234 104.057 12.0547C103.838 13.149 103.838 14.3684 103.838 15.4627C103.869 15.5878 104.307 15.3377 104.401 15.2751Z"
        fill="#263238"
      />
      <path
        d="M110.903 13.6519C109.746 13.3705 109.465 12.2449 109.465 12.2449C108.027 11.9635 107.089 11.088 106.995 10.9942C107.057 11.088 107.433 11.7759 107.651 12.0886C104.494 12.1824 103.055 10.025 103.055 10.025C102.274 11.2444 102.211 11.9322 102.149 12.2762C102.149 12.2762 101.742 10.4002 102.43 8.89941C103.274 7.08597 104.931 7.0547 104.931 7.0547C104.931 7.0547 107.151 5.83532 109.402 7.46116C111.81 9.21207 110.903 13.6519 110.903 13.6519Z"
        fill="#263238"
      />
      <path
        d="M105.213 12.0897C105.745 12.246 106.339 12.3398 106.901 12.3398C105.995 12.2773 105.119 11.9959 104.369 11.4644C104.056 11.2455 103.775 10.9641 103.525 10.6827C103.275 10.37 102.993 9.99485 102.868 9.58839C102.868 9.55712 102.806 9.55712 102.837 9.58839C102.962 9.99485 103.087 10.4013 103.337 10.7452C103.587 11.0892 103.869 11.4018 104.244 11.6207C104.525 11.8083 104.869 11.9646 105.213 12.0897Z"
        fill="#263238"
      />
      <path
        d="M110.123 13.8063C110.123 13.8063 110.936 12.1179 111.686 12.3993C112.437 12.6807 111.968 15.0257 111.217 15.4321C110.467 15.8699 110.061 15.1507 110.061 15.1507L110.123 13.8063Z"
        fill="#F7A9A0"
      />
      <path
        d="M111.531 13.0566C111.562 13.0566 111.562 13.0879 111.531 13.0566C110.999 13.4006 110.78 13.9634 110.655 14.5574C110.78 14.3073 110.999 14.1822 111.312 14.3386C111.343 14.3386 111.312 14.3698 111.312 14.3698C111.062 14.3698 110.905 14.4324 110.78 14.62C110.687 14.7763 110.624 14.9639 110.593 15.1202C110.561 15.1827 110.468 15.1515 110.499 15.0889C110.374 14.3698 110.78 13.2442 111.531 13.0566Z"
        fill="#263238"
      />
      <path
        d="M114.434 24.1223C115.872 32.3766 114.497 38.5673 113.09 39.3177C109.369 41.3813 100.427 37.1916 97.8943 36.3787C96.8625 36.0348 101.521 28.1244 102.553 28.6247C104.992 29.7815 108.431 31.72 108.587 31.72C108.869 31.6888 109.181 29.5939 110.62 23.4345C111.776 18.4631 113.871 20.7456 114.434 24.1223Z"
        fill="#F7A9A0"
      />
      <path
        d="M89.7965 24.7179C89.5463 25.7497 92.1102 26.1874 92.1102 26.1874C92.1102 26.1874 91.4223 26.4063 91.0784 27.0316C90.7032 27.7507 91.6412 28.6574 91.6412 28.6574C91.6412 28.6574 90.9846 29.0326 90.7032 29.7205C90.4218 30.4396 91.2347 31.5027 91.2347 31.5027C91.2347 31.5027 90.8283 32.1905 90.9846 32.9722C91.2973 34.3166 93.2983 34.8169 94.4864 35.4109C95.7683 36.0363 97.8632 36.2551 98.8012 36.6929L102.928 29.0326C102.928 29.0326 98.9575 24.7491 96.6751 24.0926C95.4557 23.7174 90.2029 23.1233 89.7965 24.7179Z"
        fill="#F7A9A0"
      />
      <path
        d="M92.1416 26.1572C93.5799 26.0947 95.0181 26.3761 96.4251 26.6575C96.4564 26.6575 96.4564 26.72 96.4251 26.72C95.0494 26.4074 93.5486 26.4699 92.1729 26.2198C92.1104 26.2198 92.1104 26.1572 92.1416 26.1572Z"
        fill="#263238"
      />
      <path
        d="M91.7344 28.5654C92.0471 28.5654 92.391 28.6592 92.7037 28.753C93.0476 28.8468 93.3603 28.9406 93.7042 29.0344C94.3608 29.222 95.0174 29.4409 95.6739 29.691C95.7052 29.691 95.7052 29.7535 95.6739 29.7535C95.0174 29.5659 94.3295 29.3784 93.6729 29.1908C93.329 29.097 92.985 29.0344 92.6724 28.9406C92.3597 28.8781 92.0158 28.8156 91.7344 28.6592C91.6719 28.628 91.6719 28.5654 91.7344 28.5654Z"
        fill="#263238"
      />
      <path
        d="M91.2971 31.5054C91.5785 31.5054 91.8912 31.6304 92.1413 31.7242C92.454 31.818 92.7353 31.9118 93.048 32.0056C93.6421 32.1932 94.2674 32.3808 94.8614 32.5372C94.8927 32.5372 94.8927 32.5997 94.8614 32.5997C94.2361 32.4434 93.6108 32.287 92.9855 32.0994C92.6728 32.0056 92.3914 31.9431 92.0788 31.8493C91.8286 31.7868 91.516 31.7242 91.2971 31.5054C91.2971 31.5054 91.2658 31.5366 91.2971 31.5054Z"
        fill="#263238"
      />
      <path
        d="M102.866 28.5639L98.582 37.85C98.582 37.85 111.87 41.7895 113.652 39.4133C115.466 37.037 115.341 29.5957 114.497 24.468C113.652 19.3091 111.37 19.2153 110.369 23.874C109.369 28.5326 108.775 31.4717 108.587 31.4404C108.4 31.4091 102.866 28.5639 102.866 28.5639Z"
        fill="#B965E0"
      />
      <path
        d="M104.586 29.8118C104.586 29.7805 104.649 29.8118 104.617 29.843C104.367 30.5309 104.054 31.2188 103.773 31.8754C103.46 32.5632 103.148 33.2511 102.835 33.9702C102.21 35.3459 101.584 36.7529 100.865 38.0661C100.834 38.1286 100.771 38.0661 100.771 38.0348C101.334 36.6278 101.96 35.2521 102.616 33.8764C102.929 33.1885 103.242 32.5319 103.554 31.8441C103.898 31.1562 104.211 30.4684 104.586 29.8118Z"
        fill="#263238"
      />
      <path
        d="M105.586 39.5352C105.961 39.5352 106.336 39.6603 106.712 39.7228C107.087 39.7853 107.493 39.8479 107.868 39.9104C108.619 40.0042 109.369 40.098 110.12 40.098C110.87 40.1293 112.683 40.0042 113.277 39.4727C113.778 39.0037 114.122 38.2846 114.247 37.7843C114.466 36.9088 114.591 36.0334 114.716 35.1267C114.935 33.2507 114.966 31.3434 114.903 29.4675C114.872 28.9359 114.841 28.4044 114.809 27.9042C114.778 27.3726 114.716 26.8411 114.684 26.3096C114.684 26.2783 114.716 26.2783 114.716 26.3096C114.903 27.1538 114.997 28.0292 115.06 28.9047C115.091 28.3732 115.153 27.8104 115.153 27.2788C115.153 27.2476 115.216 27.2476 115.216 27.2788C115.216 27.8416 115.247 28.4357 115.216 28.9985C115.216 29.3111 115.185 29.6551 115.122 29.9677C115.153 30.6869 115.185 31.4372 115.185 32.1564C115.153 34.0636 115.028 36.0334 114.528 37.8468C114.309 38.6597 113.965 39.4414 113.246 39.8479C112.808 40.098 112.058 40.2543 111.495 40.3169C110.213 40.4107 108.931 40.3169 107.681 40.0667C107.337 40.0042 106.962 39.9104 106.618 39.8479C106.305 39.7853 105.899 39.7228 105.617 39.5665C105.555 39.5665 105.586 39.5352 105.586 39.5352Z"
        fill="#263238"
      />
      <path
        d="M102.867 28.3721C103.836 28.8723 104.837 29.3726 105.806 29.8728C106.275 30.123 106.775 30.3731 107.244 30.6232C107.713 30.8734 108.589 31.3424 108.589 31.3424C108.589 31.1235 108.777 30.4669 108.87 30.0604C108.964 29.5914 109.089 29.1537 109.183 28.6847C109.214 28.5597 109.246 28.4659 109.277 28.3408C109.308 28.1219 109.308 27.9031 109.339 27.6842C109.371 27.3403 109.402 26.9651 109.464 26.6212H109.496C109.527 26.9651 109.527 27.2777 109.496 27.6217C109.652 27.0589 109.808 26.5274 109.965 25.9958C109.965 25.9646 109.996 25.9958 109.996 25.9958C109.808 26.9026 109.621 27.8405 109.433 28.7473C109.339 29.185 109.214 29.6227 109.12 30.0604C108.995 30.4982 108.777 31.4674 108.745 31.4674C108.245 31.2798 107.776 31.0297 107.276 30.8108C106.776 30.5607 106.275 30.3106 105.775 30.0604C104.806 29.5602 103.836 29.0912 102.93 28.5284L102.867 28.6535C102.836 28.6847 102.805 28.6222 102.836 28.5909C102.773 28.5597 102.867 28.3721 102.867 28.3721Z"
        fill="#263238"
      />
      <path
        d="M94.4547 37.5333H81.9169L76.4453 19.5552H88.9518L94.4547 37.5333Z"
        fill="#263238"
      />
      <path
        d="M93.8297 37.5333H81.3232L75.8203 19.5552H88.3268L93.8297 37.5333Z"
        fill="#455A64"
      />
      <path
        d="M83.5125 27.996C83.7001 28.6839 84.388 29.2154 85.0446 29.2154C85.7324 29.2154 86.1076 28.6526 85.9513 27.996C85.7637 27.3082 85.0759 26.7766 84.4193 26.7766C83.7314 26.7454 83.3249 27.3082 83.5125 27.996Z"
        fill="white"
      />
      <path
        d="M77.7602 25.8701C77.1349 26.9957 75.5403 28.4027 75.3527 29.3719C75.1964 30.2161 75.8843 30.5601 75.8843 30.5601C75.8843 30.5601 74.915 30.9978 74.915 31.8732C74.915 32.3422 75.6341 32.7487 75.6341 32.7487C75.6341 32.7487 74.8837 33.1239 74.915 33.9055C74.9463 34.5309 75.6654 34.8435 75.6654 34.8435C75.6654 34.8435 75.1026 35.5314 75.3215 36.188C75.7905 37.4699 81.1057 37.7826 82.0437 35.8128C82.4814 34.9061 81.1683 34.9061 81.1683 34.9061C81.1683 34.9061 83.0442 34.4371 83.138 33.3428C83.2318 32.2172 81.5435 32.3422 81.5435 32.3422C81.5435 32.3422 83.3569 31.8732 83.3256 30.7164C83.2944 29.497 81.6373 29.9035 81.6373 29.9035C81.6373 29.9035 82.8566 29.1531 82.6378 28.2776C82.2938 26.8081 79.6049 28.559 78.4481 28.2151L77.7602 25.8701Z"
        fill="#F7A9A0"
      />
      <path
        d="M75.7598 34.9034C75.7285 34.9034 75.7285 34.9347 75.7598 34.9034C77.4794 35.4662 79.543 35.4974 81.2626 34.9034C81.3252 34.8721 81.3252 34.7783 81.2314 34.7783C79.3554 35.091 77.6358 35.1848 75.7598 34.9034Z"
        fill="#263238"
      />
      <path
        d="M75.6035 32.7458C75.5723 32.7458 75.5723 32.7771 75.6035 32.7771C77.3857 33.3086 79.6994 33.3086 81.6066 32.3706C81.6692 32.3393 81.7004 32.2455 81.6066 32.2768C80.2935 32.8083 77.3232 32.9959 75.6035 32.7458Z"
        fill="#263238"
      />
      <path
        d="M81.7315 29.7759C79.8868 30.5888 77.9483 30.7139 75.9473 30.5888C75.916 30.5888 75.916 30.6201 75.9473 30.6201C76.9165 30.964 78.1984 30.9327 79.1677 30.8077C80.137 30.6826 80.9499 30.4012 81.7315 29.8384C81.7941 29.8384 81.7941 29.7759 81.7315 29.7759Z"
        fill="#263238"
      />
      <path
        d="M40.6448 61.6788C40.8949 62.3979 53.589 78.375 54.4645 78.6876C54.7771 78.8127 57.1846 77.0618 60.0299 74.8732C60.6552 74.4042 61.2805 73.9039 61.9059 73.4036C66.0643 70.152 70.379 66.5876 70.379 66.5876L61.9371 55.8633L53.6828 62.9607L51.8694 64.524C51.8694 64.524 44.303 60.6157 43.3337 60.3656C42.3644 60.178 40.3947 60.9909 40.6448 61.6788Z"
        fill="#F7A9A0"
      />
      <path
        d="M40.6448 61.678C40.8949 62.3971 53.589 78.3742 54.4645 78.6868C54.8709 78.8119 58.3415 76.2168 61.9371 73.4028L53.7141 62.9599L51.9006 64.5232C51.9006 64.5232 44.3342 60.6149 43.365 60.3648C42.3644 60.1772 40.3947 60.9901 40.6448 61.678Z"
        fill="white"
      />
      <path
        d="M40.6448 61.681C40.8949 62.4002 53.589 78.3772 54.4645 78.6899C54.7771 78.8149 57.1846 77.064 60.0299 74.8754L51.9006 64.5575C51.9006 64.5575 44.3342 60.6493 43.365 60.3991C42.3644 60.1803 40.3947 60.9932 40.6448 61.681Z"
        fill="#B965E0"
      />
      <path
        d="M41.4571 61.3989C43.6457 64.0253 52.2439 74.8434 54.2137 77.5948C54.245 77.6261 54.2137 77.6261 54.1824 77.5948C51.9625 75.031 43.4581 64.1503 41.3945 61.3989C41.3945 61.3989 41.4258 61.3364 41.4571 61.3989Z"
        fill="#263238"
      />
      <path
        d="M44.7404 60.9609C45.2719 61.5863 45.4595 62.4305 45.3657 63.087C45.2719 63.7124 44.9905 64.4002 44.4278 64.838C44.3965 64.838 44.3965 64.8067 44.3965 64.8067C45.3032 63.8062 45.4595 62.1178 44.7091 60.9922C44.6779 60.9609 44.7091 60.9609 44.7404 60.9609Z"
        fill="#263238"
      />
      <path
        d="M50.8062 63.7146C51.6816 64.7777 52.2757 66.3097 51.963 67.6854C51.963 67.748 51.8692 67.7167 51.8692 67.6854C51.5878 66.3097 51.2439 65.0903 50.6811 63.8084C50.6186 63.7146 50.7436 63.6521 50.8062 63.7146Z"
        fill="#263238"
      />
      <path
        d="M49.8355 63.1497C50.7109 64.2127 51.305 65.7448 50.9923 67.1205C50.9923 67.183 50.8985 67.1518 50.8985 67.1205C50.6171 65.7448 50.2732 64.5254 49.7104 63.2435C49.6479 63.1497 49.7729 63.0872 49.8355 63.1497Z"
        fill="#263238"
      />
      <path
        d="M48.8667 62.5867C49.7422 63.6497 50.3362 65.1818 50.0236 66.5575C50.0236 66.62 49.9298 66.5888 49.9298 66.5575C49.6484 65.1818 49.3044 63.9624 48.7417 62.6805C48.6791 62.5867 48.8042 62.5242 48.8667 62.5867Z"
        fill="#263238"
      />
      <path
        d="M47.8657 62.0257C48.7411 63.0887 49.3352 64.6208 49.0225 65.9965C49.0225 66.059 48.9287 66.0277 48.9287 65.9965C48.6473 64.6208 48.3034 63.4014 47.7406 62.1195C47.7093 62.0257 47.8031 61.9319 47.8657 62.0257Z"
        fill="#263238"
      />
      <path
        d="M56.1834 74.8741C57.1214 76.0935 55.2767 77.5943 54.3387 76.3749C53.4008 75.1868 55.2455 73.686 56.1834 74.8741Z"
        fill="white"
      />
      <path
        d="M52.7136 60.2736C53.5891 60.4612 53.6829 61.493 53.4953 62.2121C53.1826 63.369 52.4322 64.5883 51.5567 65.4013C51.5255 65.4325 51.5255 65.4013 51.4942 65.4013C51.4004 65.4325 51.2753 65.4013 51.2441 65.2762C51.1503 64.0881 50.9314 62.8374 51.2128 61.6493C51.4004 60.9302 51.8694 60.086 52.7136 60.2736ZM51.4942 63.4002C51.5255 63.9943 51.588 64.5571 51.588 65.1511C51.9632 64.5258 52.3697 63.9317 52.6823 63.2752C52.8387 62.9625 52.995 62.6186 53.0888 62.3059C53.2451 61.7744 53.3077 60.0235 52.057 60.8364C51.4004 61.3054 51.4629 62.7124 51.4942 63.4002Z"
        fill="#263238"
      />
      <path
        d="M53.2752 68.3704C52.3997 67.5575 51.8995 66.3693 51.3054 65.3375C51.2429 65.2437 51.3367 65.1187 51.4305 65.0874C51.4305 65.0562 51.4305 65.0562 51.4617 65.0562C52.6186 65.2437 53.9318 65.8065 54.8072 66.5882C55.37 67.0572 55.839 67.9952 55.2137 68.6205C54.6822 69.2458 53.8067 68.8394 53.2752 68.3704ZM54.4008 68.5267C55.8703 68.5267 54.8698 67.0572 54.432 66.7445C54.1506 66.5257 53.8692 66.3381 53.5566 66.1817C52.9312 65.8065 52.2747 65.5564 51.6181 65.2438C51.9307 65.744 52.2121 66.2443 52.5248 66.7445C52.8687 67.3073 53.5878 68.5267 54.4008 68.5267Z"
        fill="#263238"
      />
      <path
        d="M54.1523 61.3669L62.9382 73.4045L92.6411 52.5186L95.5176 50.5176C95.5176 50.5176 96.3305 51.0804 97.5499 51.7682C99.2383 52.7062 101.74 53.9256 103.928 54.2382C107.618 54.801 113.777 54.7385 116.872 50.5176C119.499 46.9532 118.061 42.3258 118.061 42.3258L114.027 40.5749C114.027 40.5749 104.46 32.4769 98.2378 32.2268C95.6114 32.133 91.7657 34.134 88.2013 36.2602C84.6682 38.355 54.1523 61.3669 54.1523 61.3669Z"
        fill="#37474F"
      />
      <path
        d="M95.5176 50.4872C95.5176 50.4872 96.3305 51.0499 97.5499 51.7378C99.7073 49.1114 104.272 44.359 104.428 44.2026L95.5176 50.4872Z"
        fill="#263238"
      />
      <path
        d="M110.494 37.8535C111.057 38.26 111.62 38.6977 112.183 39.1042C112.745 39.5106 113.308 39.8858 113.84 40.3235C114.371 40.7613 114.871 41.2615 115.403 41.7305C115.653 41.9807 115.903 42.1995 116.122 42.4497C116.341 42.6998 116.622 42.9186 116.81 43.2C116.841 43.2313 116.779 43.2626 116.779 43.2313C116.497 43.0437 116.247 42.8248 115.997 42.606C115.716 42.3871 115.465 42.137 115.184 41.9181C114.684 41.4804 114.152 41.0427 113.621 40.6049C113.121 40.1672 112.652 39.667 112.12 39.2292C111.62 38.7602 111.057 38.3225 110.494 37.8535Z"
        fill="#263238"
      />
      <path
        d="M56.2161 60.9292C56.7789 61.5545 57.3104 62.2424 57.8419 62.899C58.3735 63.5556 58.905 64.2122 59.4365 64.9C60.4996 66.2445 61.5626 67.5576 62.5944 68.9334C63.1572 69.6837 63.72 70.4341 64.2515 71.1845C64.2828 71.2158 64.2203 71.2471 64.189 71.2158C63.0634 69.9339 62.0003 68.5894 60.9686 67.2762C59.9055 65.9318 58.8737 64.5873 57.8419 63.2429C57.2791 62.4925 56.6851 61.7734 56.1848 60.9917C56.1536 60.9605 56.1848 60.9292 56.2161 60.9292Z"
        fill="#263238"
      />
      <path
        d="M80.6029 61.0552C82.0724 59.867 83.6045 58.7727 85.1365 57.6471C86.6686 56.5528 88.1694 55.4272 89.7327 54.3642C90.1704 54.0515 90.6394 53.7701 91.0771 53.4575C91.5148 53.1448 91.9838 52.8634 92.4216 52.5507C92.4528 52.5195 92.4841 52.582 92.4528 52.582C92.0464 52.8634 91.6712 53.2073 91.2647 53.52C90.8895 53.8014 90.5143 54.1141 90.1391 54.3955C89.3887 54.9582 88.6384 55.4898 87.888 56.0526C86.3559 57.1782 84.8551 58.2725 83.2918 59.3355C82.4164 59.9608 81.5409 60.5549 80.6342 61.149C80.6029 61.1802 80.5404 61.1177 80.6029 61.0552Z"
        fill="#263238"
      />
      <path
        d="M71.0982 48.6426C72.4739 47.4857 73.9121 46.4539 75.3504 45.3909C76.7886 44.3278 78.2269 43.2335 79.6964 42.1705C81.1346 41.1074 82.5729 40.0444 84.0111 39.0126C85.4181 37.9808 86.7938 36.949 88.2633 36.0423C89.0763 35.542 89.8892 35.073 90.7646 34.6666C91.5776 34.2601 92.453 33.8536 93.3285 33.6035C93.3597 33.6035 93.3597 33.6348 93.3597 33.6348C92.6406 34.0412 91.859 34.3852 91.1398 34.7916C90.4207 35.1981 89.7016 35.6046 88.9825 36.0423C87.513 36.949 86.1372 37.9808 84.7303 38.9813C81.8538 41.0761 78.9773 43.2023 76.0695 45.2658C74.4437 46.4227 72.8491 47.6421 71.1607 48.7364C71.0982 48.7676 71.0356 48.6738 71.0982 48.6426Z"
        fill="#263238"
      />
      <path
        d="M21.3825 43.7881C25.1345 48.9783 29.074 54.9815 30.0433 61.4536C30.0433 61.5474 29.887 61.5786 29.887 61.4848C29.4492 59.7027 28.8864 57.983 28.2299 56.2946C25.7598 55.6068 23.3211 55.044 22.0079 52.5427C20.6947 49.9789 20.851 46.6334 21.1012 43.8507C21.1012 43.6943 21.32 43.6943 21.3825 43.7881Z"
        fill="#B965E0"
      />
      <path
        d="M28.1668 56.2618C26.4784 52.416 24.2898 48.539 21.5384 45.3811C21.5071 45.3498 21.4758 45.3811 21.5071 45.4124C23.3205 47.6635 24.6962 50.1336 26.0407 52.6349C24.79 51.353 23.4143 50.1961 22.2575 48.8517C22.2262 48.8204 22.1949 48.8517 22.2262 48.8829C23.4143 50.3212 24.6962 51.8532 26.197 52.9163C26.6035 53.6979 27.01 54.4483 27.4477 55.23C27.3539 55.1675 27.2288 55.1049 27.135 55.0737C27.1038 55.0737 27.1038 55.1049 27.1038 55.1049C27.2288 55.1675 27.3226 55.23 27.4477 55.2925C27.6353 55.6677 27.8541 56.0116 28.0417 56.3868C28.1043 56.4181 28.1981 56.3243 28.1668 56.2618Z"
        fill="#263238"
      />
      <path
        d="M26.9168 54.9178C25.9163 54.3237 24.947 53.6984 24.0403 52.948C24.009 52.9167 23.9778 52.948 24.009 52.9793C24.9158 53.7609 25.8538 54.4175 26.8855 54.9803C26.9168 55.0116 26.9481 54.9491 26.9168 54.9178Z"
        fill="#263238"
      />
      <path
        d="M30.4813 60.5141C30.4813 60.6079 30.3563 60.5766 30.3563 60.5141C30.325 58.388 30.3563 56.2306 30.325 54.0732C29.7935 52.9789 28.6366 52.3536 27.8237 51.4781C27.2296 50.8528 26.7606 50.1337 26.3229 49.352C25.3224 47.5386 24.7909 45.5063 24.572 43.4427C24.3531 41.1603 25.1973 33.6251 26.1666 33.8752C26.6668 34.0003 28.2614 37.7522 28.6679 38.9716C29.3557 41.0039 29.856 43.0988 30.1687 45.2249C30.9191 50.2587 30.8565 55.4489 30.4813 60.5141Z"
        fill="#B965E0"
      />
      <path
        d="M30.4809 51.2894C30.4809 51.2894 30.4809 51.2581 30.4809 51.2894C30.3558 48.5692 29.7617 45.849 29.1989 43.2227C28.6049 40.4087 27.8858 37.7511 26.4475 35.2185C26.4162 35.1873 26.3537 35.2185 26.385 35.2498C27.3542 37.1883 28.0734 39.2831 28.6049 41.4092C28.5111 41.2529 28.386 41.1278 28.2922 40.9715C28.261 40.909 28.1672 40.9715 28.1984 41.034C28.3235 41.2529 28.4798 41.503 28.6361 41.6906C28.6361 41.6906 28.6361 41.6906 28.6674 41.6906C29.1677 43.7229 29.5116 45.7865 29.8243 47.8501C29.793 47.8188 29.793 47.7875 29.7617 47.7563C29.7617 47.725 29.7305 47.7563 29.7305 47.7876C29.7617 47.8501 29.8243 47.9126 29.8555 48.0064C30.0119 49.0382 30.1369 50.1013 30.2933 51.133C28.4485 49.5385 26.8852 47.6625 26.0098 45.3488H25.9785C26.854 47.7563 28.1984 49.8824 30.3245 51.3206C30.3558 51.602 30.3871 51.8834 30.4496 52.1648C30.4496 52.2274 30.5434 52.1961 30.5434 52.1648C30.5434 51.9147 30.5434 51.6646 30.5121 51.4144C30.5121 51.3519 30.5121 51.3206 30.4809 51.2894Z"
        fill="#263238"
      />
      <path
        d="M29.6377 47.3498C28.2307 45.2237 26.9175 43.1602 25.8857 40.8152C25.8857 40.7839 25.8232 40.8152 25.8545 40.8465C26.7299 43.1914 27.918 45.5364 29.5751 47.4124C29.5751 47.4436 29.6377 47.4124 29.6377 47.3498Z"
        fill="#263238"
      />
      <path
        d="M27.7616 40.3134C27.1676 39.2816 26.5735 38.2498 26.0107 37.1868C26.0107 37.1555 25.9482 37.1868 25.9795 37.218C26.4485 38.3124 26.98 39.4067 27.6678 40.3759C27.6991 40.4072 27.7929 40.3447 27.7616 40.3134Z"
        fill="#263238"
      />
      <path
        d="M27.9805 40.752C27.9805 40.752 27.9805 40.7846 27.9805 40.752C28.0131 40.7846 28.0131 40.752 27.9805 40.752Z"
        fill="#263238"
      />
      <path
        d="M31.8888 54.3263C31.9201 55.7333 32.0764 57.1715 32.3891 58.5472C32.3578 58.7036 32.3578 58.8599 32.3266 59.0162C31.8888 57.4842 31.67 55.9209 31.5762 54.3263C31.5449 54.295 31.5449 54.2325 31.5762 54.17C31.4824 52.0439 31.7012 49.9178 32.0452 47.8229C32.7018 43.8834 34.1088 40.1314 36.8289 37.1924C36.9227 37.0986 37.079 37.1611 37.1416 37.2549C38.2359 40.0689 37.8607 43.4144 36.9227 46.2283C36.4225 47.7291 35.7033 49.1986 34.7966 50.4805C33.8274 51.7625 32.6392 52.8881 31.8888 54.3263Z"
        fill="#B965E0"
      />
      <path
        d="M36.518 38.4746C33.9542 42.6956 32.172 47.6982 31.9531 52.6695C31.9531 52.7633 32.1095 52.7946 32.1095 52.7008C32.172 52.263 32.2345 51.8253 32.2971 51.3563C33.3601 50.3245 34.4544 49.2302 35.2048 47.9483C35.2048 47.917 35.1735 47.8858 35.1735 47.917C34.2668 48.9801 33.3914 50.0744 32.3596 51.0437C32.5472 49.9493 32.766 48.855 33.0162 47.792C33.0787 47.6982 33.1412 47.6356 33.2038 47.5418C33.235 47.4793 33.1725 47.4168 33.1412 47.4793C33.11 47.5106 33.11 47.5106 33.0787 47.5418C33.4539 46.0723 33.8916 44.6341 34.4232 43.2271C35.0797 42.4454 35.7989 41.7576 36.2679 40.8196C36.2679 40.7883 36.2366 40.7883 36.2366 40.7883C35.8614 41.3199 35.4862 41.8827 35.0485 42.3829C34.8609 42.5705 34.6733 42.7581 34.517 42.977C35.111 41.4762 35.7989 40.0067 36.6118 38.5372C36.6431 38.4746 36.5493 38.4121 36.518 38.4746Z"
        fill="#263238"
      />
      <path
        d="M35.9884 43.6658C35.1754 44.7914 34.2062 45.8857 33.4558 47.0425C33.4245 47.0738 33.4871 47.1051 33.5183 47.0738C34.5188 46.1358 35.3318 44.8539 36.0509 43.697C36.0822 43.6658 36.0196 43.6345 35.9884 43.6658Z"
        fill="#263238"
      />
      <path
        d="M33.3298 47.2901L33.2982 47.3216C33.2667 47.3532 33.2982 47.3847 33.3298 47.3532L33.3613 47.3216C33.3929 47.3216 33.3613 47.2585 33.3298 47.2901Z"
        fill="#263238"
      />
      <path
        d="M40.5164 46.5697C40.2975 48.727 39.1719 51.3221 37.8275 53.0105C37.2334 53.7609 36.483 54.3237 35.6388 54.7302C34.7008 55.1992 33.7003 55.7619 32.7311 56.1371C32.2621 57.3253 31.9181 58.5759 31.6993 59.8578C31.668 59.764 31.6367 59.639 31.6055 59.5139C32.2308 54.011 35.7326 49.3211 40.2037 46.3195C40.3288 46.2257 40.5164 46.3821 40.5164 46.5697Z"
        fill="#B965E0"
      />
      <path
        d="M38.9545 48.1922C38.9858 48.1609 38.9545 48.0983 38.9232 48.1296C36.547 49.9118 34.4209 52.5069 33.2015 55.2583C33.1703 55.3209 33.2641 55.4147 33.3266 55.3209C33.5455 54.9457 33.733 54.6017 33.9519 54.2265C34.9837 54.0389 35.953 53.2885 36.7033 52.6007C36.7346 52.5694 36.7033 52.5382 36.6721 52.5694C36.2656 52.9134 35.8592 53.2573 35.3902 53.4761C34.9837 53.695 34.5147 53.8201 34.077 54.0077C34.4522 53.3823 34.8586 52.8196 35.2651 52.2568C36.3594 51.8816 37.4225 51.1937 38.3292 50.5059C38.3605 50.4746 38.3292 50.4433 38.2979 50.4433C37.3599 51.0061 36.4219 51.5376 35.4527 52.0066C36.078 51.1624 36.7346 50.3808 37.4537 49.6304C37.7977 49.4428 38.1416 49.2552 38.4855 49.0676C38.5168 49.0363 38.4855 49.0051 38.4543 49.0051C38.1729 49.0989 37.9227 49.2239 37.6726 49.4115C38.1103 48.9738 38.5168 48.5673 38.9545 48.1922Z"
        fill="#263238"
      />
      <path
        d="M38.8607 50.0679C38.7982 50.0991 38.7356 50.1304 38.6731 50.1929C38.6418 50.1929 38.6731 50.2242 38.7044 50.2242C38.7669 50.1929 38.8294 50.1617 38.892 50.1304C38.9232 50.0991 38.892 50.0679 38.8607 50.0679Z"
        fill="#263238"
      />
      <path
        d="M24.5703 71.7411H36.8579L35.8574 60.4227L35.6386 57.9214H25.7897L25.5708 60.4227L24.5703 71.7411Z"
        fill="#455A64"
      />
      <path
        d="M25.5703 60.4227H35.8569L35.638 57.9214H25.7892L25.5703 60.4227Z"
        fill="#263238"
      />
      <path d="M25.1954 58.645H36.2324V56.175H25.1954V58.645Z" fill="#455A64" />
    </svg>
  )
}

export default NewUser
