import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ShopLayout from "../Layout";
import ProductOutOfStockList from "../../../components/Product/out-of-stock";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const ShopProductOutOfStockList = (): ReactElement => {
  const route = useParams();
  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Out of Stock List"
        subTitle="Out of Stock List"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <ProductOutOfStockList
              inShop={true}
              getShopId={(route as any)?.shopId}
            />
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopProductOutOfStockList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopProductOutOfStockList;
