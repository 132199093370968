const User = ({ ...props }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8193 15.75V14.25C15.8193 13.4544 15.5033 12.6913 14.9407 12.1287C14.378 11.5661 13.615 11.25 12.8193 11.25H6.81934C6.02369 11.25 5.26062 11.5661 4.69802 12.1287C4.13541 12.6913 3.81934 13.4544 3.81934 14.25V15.75"
        stroke={props.fill || '#979797'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.81934 8.25C11.4762 8.25 12.8193 6.90685 12.8193 5.25C12.8193 3.59315 11.4762 2.25 9.81934 2.25C8.16248 2.25 6.81934 3.59315 6.81934 5.25C6.81934 6.90685 8.16248 8.25 9.81934 8.25Z"
        stroke={props.fill || '#979797'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default User
