import { ReactElement } from "react";
import React from "react";
import BreadCrumb from "../Layouts/Breadcrumb";
import styles from "../../styles/tailwind/List.module.css";
import InviteEarn from "./InviteEarn/InviteEarn";
import InvitationHistory from "./InviteEarn/InvitationHistory";
import Balance from "./point-banlance";

const ReferralsList = (): ReactElement => {
  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className={`content-body`}>
              <InviteEarn />
              <Balance />
              {/* <InvitationHistory /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralsList;
