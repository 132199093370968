import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopContractAddress from "../../../components/Shop/ContractAddress";

const ShopContractAddressPage = () => {
  return (
    <MainLayout>
      <ShopContractAddress />
    </MainLayout>
  );
};

export default ShopContractAddressPage;
