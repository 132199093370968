const LoginMobile = ({ ...props }) => {
  return (
    <svg
      width="44"
      height="73"
      viewBox="0 0 44 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.6635 20.4698V18.9859C43.6635 13.2129 43.6635 7.43986 43.6635 1.66685C43.6635 0.32524 43.3382 0 41.9966 0H1.60587C0.304912 0 0 0.304912 0 1.6262V70.2315C0 71.7561 0.264258 72.0203 1.74816 72.0203H41.9153C43.3789 72.0203 43.6838 71.7154 43.6838 70.2112L43.6635 20.4698ZM41.3868 69.6827H2.29701V64.7024H41.3868V69.6827ZM41.3461 62.3241H2.29701V22.7871V2.31733H41.3665V20.4291H41.3461V62.3241Z"
        fill="#008598"
      />
      <path
        d="M26.0394 6.20046C26.0394 6.87127 25.5312 7.31847 24.6978 7.31847C23.7424 7.3388 22.7667 7.31847 21.8113 7.31847C20.8559 7.31847 19.8802 7.31847 18.9248 7.31847C18.132 7.29815 17.6645 6.8916 17.6442 6.22079C17.6238 5.50933 18.1117 5.06212 18.9451 5.06212C20.8762 5.04179 22.8074 5.04179 24.7181 5.06212C25.5312 5.06212 26.0394 5.52965 26.0394 6.20046Z"
        fill="#008598"
      />
      <path
        d="M21.9332 66.0247C22.8886 66.0247 23.8643 66.0043 24.8197 66.0247C25.5515 66.045 26.019 66.4719 26.0394 67.102C26.0597 67.7322 25.6125 68.2404 24.8807 68.2607C22.8276 68.281 20.7745 68.3013 18.7214 68.2607C18.01 68.2404 17.5831 67.7322 17.6238 67.0614C17.6644 66.4312 18.0913 66.045 18.8028 66.0247C19.8598 66.0247 20.8965 66.045 21.9332 66.0247Z"
        fill="#008598"
      />
      <path
        d="M41.366 18.9844H41.3457V20.4276H41.366V18.9844Z"
        fill="#008598"
      />
      <path
        d="M43.6834 18.9844H43.6631V20.4683H43.6834V18.9844Z"
        fill="#008598"
      />
      <path
        d="M26.0394 6.20046C26.0394 6.87127 25.5312 7.31847 24.6978 7.31847C23.7424 7.3388 22.7667 7.31847 21.8113 7.31847C20.8559 7.31847 19.8802 7.31847 18.9248 7.31847C18.132 7.29815 17.6645 6.8916 17.6442 6.22079C17.6238 5.50933 18.1117 5.06212 18.9451 5.06212C20.8762 5.04179 22.8074 5.04179 24.7181 5.06212C25.5312 5.06212 26.0394 5.52965 26.0394 6.20046Z"
        fill="#008598"
      />
      <path
        d="M22.3802 21.8516C25.7139 23.2338 29.068 24.6364 32.4017 26.0187C33.1131 26.3033 33.2758 26.5675 33.2758 27.3197C33.2758 28.9052 33.2961 30.4704 33.2758 32.056C33.2351 37.2191 31.304 41.5692 27.4824 45.0452C26.0595 46.3462 24.413 47.3422 22.6241 48.0944C22.3192 48.2163 22.0143 48.2367 21.7094 48.0944C16.3226 45.7364 12.9279 41.7115 11.505 35.9995C11.2001 34.7392 11.0781 33.4586 11.0781 32.1576C11.0781 30.5111 11.0781 28.8442 11.0781 27.1977C11.0781 26.6285 11.2814 26.3033 11.8099 26.0797C15.103 24.7177 18.4164 23.3355 21.7094 21.9735C21.7907 21.9329 21.872 21.8922 21.9737 21.8719C22.116 21.8516 22.2379 21.8516 22.3802 21.8516ZM13.1312 31.0193C13.1109 32.2186 13.1109 33.7228 13.4564 35.2271C14.5745 40.187 17.4406 43.7646 22.0346 45.96C22.116 46.0006 22.2786 46.0006 22.3599 45.96C27.645 43.5817 31.182 38.1949 31.243 32.4015C31.2633 30.9176 31.243 29.454 31.243 27.9701C31.243 27.7872 31.182 27.7262 31.0194 27.6652C28.1329 26.4659 25.2667 25.2666 22.3802 24.0876C22.2786 24.0469 22.116 24.0266 21.994 24.0876C19.1075 25.2869 16.2007 26.4862 13.3142 27.6856C13.1719 27.7465 13.1109 27.8278 13.1109 27.9905C13.1312 28.8645 13.1312 29.7793 13.1312 31.0193Z"
        fill="#008598"
      />
      <path
        d="M21.0387 35.5939C21.12 35.5329 21.181 35.4719 21.242 35.3906C22.6242 34.0083 24.0065 32.6261 25.3888 31.2438C25.6937 30.9389 26.0596 30.7966 26.4865 30.9186C27.1776 31.1218 27.4419 31.9349 26.9947 32.5244C26.9337 32.6057 26.8524 32.687 26.771 32.7684C25.1448 34.3946 23.5187 36.0208 21.8925 37.647C21.3233 38.2161 20.7541 38.2161 20.2053 37.647C19.2905 36.7322 18.3961 35.8378 17.4814 34.9231C17.1765 34.6182 17.0748 34.2726 17.1968 33.866C17.3188 33.4798 17.583 33.2359 17.9896 33.1546C18.3758 33.0733 18.701 33.2156 18.9653 33.5001C19.6158 34.1506 20.2663 34.8011 20.9167 35.4516C20.9371 35.5126 20.9777 35.5532 21.0387 35.5939Z"
        fill="#008598"
      />
    </svg>
  )
}

export default LoginMobile
