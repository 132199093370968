import React from "react";

//
const Rules = () => {
  //   const [contentDetails, setContentDetails] = useState<any>()

  //   const getcontentDetails = useCallback(async (slug: string) => {
  //     if (slug) {
  //       const data = await fetch(
  //         `${process.env.NEXT_PUBLIC_CATALOG_READER_API}/web-content/details?slug=${slug}`
  //       )
  //         .then((res) => res.json())
  //         .then((data) => data)
  //         .catch((err) => console.error('Content:', err))

  //       setContentDetails(data?.webContentResponse)
  //     }
  //   }, [])

  //   useEffect(() => {
  //     getcontentDetails('invite-earning-rules-ipkp')
  //   }, [getcontentDetails])

  return (
    <>
      <div className="mb-3">
        {/* <span
            className="mt-1 space-y-3 text-lg text-gray-700"
            dangerouslySetInnerHTML={{
              __html: contentDetails?.description || <p></p>,
            }}
          ></span> */}

        <p>
          Share this code with as many family members and friends as you want,
          and ask them to use it when signing up or placing an order at
          Jachai.com. In every purchase, both parties will benefit.
        </p>

        <span className="font-semibold mt-8 block">
          Steps on how to Refer a Friend
        </span>
        <ol>
          <li>- Share your referrer code with your friends and family.</li>
          <li>
            - Ask your friends and family to use this code, and he or she will
            get 200 points using the code send by you.
          </li>
          <li>
            - You'll receive 300 points as soon as your friend or family member
            uses that code when sign-up or make a purchase from Jachai.com.
          </li>
        </ol>

        <span className="font-semibold mt-8 block">Terms and Conditions</span>
        <ol>
          <li>
            - These points (200 & 300) will be activated after successful
            delivery with a minimum order of amount 500 tk (excluding discounts
            and delivery charges) by our friends and family.
          </li>
          <li>
            - Your friends and family members will receive 10% points for each
            successful purchase they make, and you will receive 15% of the order
            value in points.
          </li>
        </ol>
        <i className="mt-4 block text-center text-red-600">
          The terms of this offer, without any prior notice, remain unchanged.
        </i>
      </div>
    </>
  );
};

export default Rules;
