const Jachai = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="131"
      height="43"
      viewBox="0 0 131 43"
      fill="none"
      {...props}
    >
      <path
        d="M11.048 7.32C13.064 7.32 14.696 5.688 14.696 3.672C14.696 1.656 13.064 0.0719976 11.048 0.0719976C9.08 0.0719976 7.4 1.656 7.4 3.672C7.4 5.688 9.08 7.32 11.048 7.32ZM5.864 42.408C11.432 42.408 14.12 38.904 14.12 33.624V9.816H8.024V33.624C8.024 36.216 6.728 37.416 4.856 37.416C3.464 37.416 2.648 36.984 1.736 36.216L0.0559996 40.776C1.736 41.928 2.936 42.408 5.864 42.408ZM40.1806 33V18.024C40.1806 11.352 35.3326 9.24 30.0526 9.24C26.4046 9.24 22.7566 10.392 19.9246 12.888L22.2286 16.968C24.1966 15.144 26.5006 14.232 28.9966 14.232C32.0686 14.232 34.0846 15.768 34.0846 18.12V21.24C32.5486 19.416 29.8126 18.408 26.7406 18.408C23.0446 18.408 18.6766 20.472 18.6766 25.896C18.6766 31.08 23.0446 33.576 26.7406 33.576C29.7646 33.576 32.5006 32.472 34.0846 30.6V33H40.1806ZM29.1886 29.448C26.7886 29.448 24.8206 28.2 24.8206 26.04C24.8206 23.784 26.7886 22.536 29.1886 22.536C31.1566 22.536 33.0766 23.208 34.0846 24.552V27.432C33.0766 28.776 31.1566 29.448 29.1886 29.448Z"
        fill="#CD113B"
      />
      <path
        d="M57.0271 33.576C61.7791 33.576 64.6591 31.512 66.1951 29.352L62.2111 25.656C61.1071 27.192 59.4271 28.152 57.3151 28.152C53.6191 28.152 51.0271 25.416 51.0271 21.384C51.0271 17.352 53.6191 14.664 57.3151 14.664C59.4271 14.664 61.1071 15.528 62.2111 17.16L66.1951 13.416C64.6591 11.304 61.7791 9.24 57.0271 9.24C49.8751 9.24 44.7391 14.28 44.7391 21.384C44.7391 28.536 49.8751 33.576 57.0271 33.576ZM91.9723 33V16.536C91.9723 12.024 89.5243 9.24 84.4363 9.24C80.6443 9.24 77.7643 11.064 76.2763 12.84V0.983997H70.1803V33H76.2763V17.4C77.3323 16.008 79.2043 14.664 81.5563 14.664C84.1963 14.664 85.8763 15.672 85.8763 18.888V33H91.9723ZM118.04 33V18.024C118.04 11.352 113.192 9.24 107.912 9.24C104.264 9.24 100.616 10.392 97.784 12.888L100.088 16.968C102.056 15.144 104.36 14.232 106.856 14.232C109.928 14.232 111.944 15.768 111.944 18.12V21.24C110.408 19.416 107.672 18.408 104.6 18.408C100.904 18.408 96.536 20.472 96.536 25.896C96.536 31.08 100.904 33.576 104.6 33.576C107.624 33.576 110.36 32.472 111.944 30.6V33H118.04ZM107.048 29.448C104.648 29.448 102.68 28.2 102.68 26.04C102.68 23.784 104.648 22.536 107.048 22.536C109.016 22.536 110.936 23.208 111.944 24.552V27.432C110.936 28.776 109.016 29.448 107.048 29.448ZM127.111 7.32C129.127 7.32 130.759 5.688 130.759 3.672C130.759 1.656 129.127 0.0719976 127.111 0.0719976C125.143 0.0719976 123.463 1.656 123.463 3.672C123.463 5.688 125.143 7.32 127.111 7.32ZM130.183 33V9.816H124.087V33H130.183Z"
        fill="#393E46"
      />
    </svg>
  )
}

export default Jachai
