import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import MySettlementList from "../../components/Reports/Settlements";
const MySettlementPage = (): ReactElement => {
  return (
    <MainLayout>
      <MySettlementList />
    </MainLayout>
  );
};

export default MySettlementPage;
