const Share = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.fill || '#FFF'}
      {...props}
    >
      <path
        d="M17.8613 8.46676C19.4316 8.46676 20.7046 7.1938 20.7046 5.62352C20.7046 4.05324 19.4316 2.78027 17.8613 2.78027C16.291 2.78027 15.0181 4.05324 15.0181 5.62352C15.0181 7.1938 16.291 8.46676 17.8613 8.46676Z"
        stroke="#585858"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.48826 15.1005C8.05854 15.1005 9.33151 13.8276 9.33151 12.2573C9.33151 10.687 8.05854 9.41406 6.48826 9.41406C4.91798 9.41406 3.64502 10.687 3.64502 12.2573C3.64502 13.8276 4.91798 15.1005 6.48826 15.1005Z"
        stroke="#585858"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8613 21.7353C19.4316 21.7353 20.7046 20.4624 20.7046 18.8921C20.7046 17.3218 19.4316 16.0488 17.8613 16.0488C16.291 16.0488 15.0181 17.3218 15.0181 18.8921C15.0181 20.4624 16.291 21.7353 17.8613 21.7353Z"
        stroke="#585858"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.94287 13.6885L15.416 17.4605"
        stroke="#585858"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4065 7.05469L8.94287 10.8267"
        stroke="#585858"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Share
