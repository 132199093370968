import { Form, Modal, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ImgCrop from "antd-img-crop";
import { useSelector } from "react-redux";

interface FileType {
  originFileObj: any;
  uid: string;
  name: string;
  status: string;
  url: string;
  crop: number;
  type?: "image" | "thumbnail";
}

const ImageUploader = ({
  imgUrl,
  setGeneratedImgUrl,
  startUpload,
  setStartUpload,
  crop,
  multiple,
}: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [visible, setVisible] = useState(false);

  const imgModalRef: any = useRef(null);

  useEffect(() => {
    setFileList(
      multiple
        ? imgUrl?.filter((item: any) => item)?.length
          ? (imgUrl?.map((imgUrl: any, i: string) => ({
              uid: i,
              name: "image.png",
              status: "done",
              url: imgUrl,
            })) as FileType[])
          : []
        : imgUrl
        ? ([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: imgUrl,
            },
          ] as FileType[])
        : []
    );
  }, [imgUrl]);

  const [form] = Form.useForm();

  const onChange = ({ fileList: newFileList }: any) => {
    setStartUpload?.("Initiated");
    setFileList(newFileList);
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    // const imgWindow = window.open(src);
    // imgWindow?.document.write(image.outerHTML);
    if (imgModalRef?.current) {
      (imgModalRef?.current as any).src = src;
    }
    setVisible(true);
  };

  const uploadImage = async () => {
    Promise.all(
      fileList.map((file) => {
        if (file?.originFileObj) {
          const formData = new FormData();
          formData.append("frontImage", file?.originFileObj);
          formData.append("documentType", "static_image");

          return fetch(
            `${process.env.REACT_APP_RIDER_API}/admin/document/upload`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          )
            .then((res) => res.json())
            .then((res) => res)
            .catch((err) => {
              setStartUpload?.(undefined);
              console.error("err", err);
            });
        } else if (file?.url) {
          return Promise.resolve({ url: file.url });
        }
      })
    )
      .then((res) => {
        setStartUpload?.("Uploaded");
        setGeneratedImgUrl?.(
          res?.length
            ? multiple
              ? res.map((item) => item?.url)?.filter((item) => item)
              : res.map((item) => item?.url)?.filter((item) => item)[0]
            : imgUrl
        );
        setFileList([]);
        form.resetFields();
      })
      .catch((e) => {
        console.log("all res error: ", e);
      });
  };

  useEffect(() => {
    if (startUpload === "Uploading") {
      uploadImage();
    }
  }, [startUpload]);

  return (
    <React.Fragment>
      {crop ? (
        <ImgCrop aspect={crop}>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            onChange={onChange}
            onPreview={onPreview}
            fileList={fileList as any}
            multiple={!!multiple}
          >
            {fileList?.length < (multiple ? 5 : 1) && "+ Upload"}
          </Upload>
        </ImgCrop>
      ) : (
        <Upload 
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          onChange={onChange}
          onPreview={onPreview}
          fileList={fileList as any}
          onRemove={(val) => {
            console.log("removed", val);
          }}
          onDrop={(val) => {
            console.log("droped", val);
          }}
          multiple={!!multiple}
        >
          {fileList?.length < (multiple ? 5 : 1) && "+ Upload"}
        </Upload>
      )}

      <Modal
        open={visible}
        title="Preview"
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <img
          alt="example"
          ref={imgModalRef}
          style={{ width: "100%" }}
          src={imgUrl}
        />
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(ImageUploader);
