import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { redirect } from ".";
import { LOGOUT } from "../redux/auth/authType";

export const logout = async (redirect_url: string) => {
  const dispatch = useDispatch();
  try {
    await Cookies.remove("authToken");
    Cookies.remove("services", { path: "/" });

    dispatch({
      type: LOGOUT,
      payload: {
        token: null,
        type: null,
      },
    });
    redirect(redirect_url);
  } catch (e) {
    console.error("Error: logout", e);
  }
};

// Token related
export const authenticateToken = () => {
  const token = Cookies.get("authToken");
  if (token) {
    return token;
  }
  return false;
};

export const setAuthToken = (token: string) => {
  try {
    Cookies.set("authToken", token);
    return true;
  } catch (e) {
    console.error("Error: Token set", e);
    return false;
  }
};

export const removeAuthToken = () => {
  try {
    Cookies.remove("authToken");
    return true;
  } catch (e) {
    console.error("Error: Token remove", e);
    return false;
  }
};
