import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import MerchantLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_MERCHANT_INFO } from "../../../redux/auth/authType";
import Loader from "../../common/Loader";
import { Button, Divider } from "antd";
import WarehouseDetailsAdd from "./Add";

const WerehouseDetails = (): ReactElement => {
  const { token, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [add, setAdd] = useState(false);
  const dispatch = useDispatch();
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchMerchantDetails = useCallback((merchantId: any) => {
    if (merchantId) {
      try {
        setSingleMerchantInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/details?merchantId=${merchantId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleMerchantInfo({
                loading: false,
                data: data?.data?.merchant,
              });
              dispatch({
                type: SET_MERCHANT_INFO,
                payload: {
                  merchantInfo: data?.data?.merchant,
                },
              });
            } else {
              setSingleMerchantInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleMerchantInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleMerchantInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchMerchantDetails(merchantId);
  }, [fetchMerchantDetails]);

  const merchant = singleMerchantInfo?.data;
  if (singleMerchantInfo.loading) return <Loader />;

  const refetch = () => {
    fetchMerchantDetails(merchantId);
    setAdd(false);
  };
  return (
    <React.Fragment>
      <MerchantLayout
        merchantId={merchant?.id}
        title={`Merchant Details`}
        subTitle={`Details`}
      >
        <div className="content-body rounded-2xl bg-white p-4 min-h-full">
          <div className="flex justify-between border-b pb-3 mb-8 border-gray-100">
            <h4 className="font-semibold text-xl text-gray-600">
              Werehouse Details
            </h4>
            {!merchant?.warehouse && (
              <Button shape="round" type="dashed" onClick={() => setAdd(!add)}>
                Add
              </Button>
            )}
          </div>

          {/* WareHouse details */}
          <div className="">
            {merchant?.warehouse && !add ? (
              <div className="grid grid-cols-2 gap-6">
                <div className="shadow-sm_  bg-gray-50 rounded-md">
                  <Divider orientation="left"> WareHouse Address</Divider>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 p-4 pt-0">
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Address
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.address}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        City
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.city}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Postcode
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.postcode}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        District
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.district}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="shadow-sm_ bg-gray-50 rounded-md">
                  <Divider orientation="left">Return Address</Divider>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 p-4 pt-0">
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Address
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.address}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        City
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.city}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Postcode
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.postcode}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        District
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.district}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            ) : (
              <div className="">
                <WarehouseDetailsAdd refetch={refetch} />
              </div>
            )}
          </div>
        </div>
      </MerchantLayout>
    </React.Fragment>
  );
};

WerehouseDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default WerehouseDetails;
