// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../images/bg-breadcrumb.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumb_root__FMDYK {
  min-height: fit-content;
  padding: 2rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background: rgb(2, 0, 36);
  background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0.5) 0%,
      rgba(9, 9, 121, 0.5) 35%,
      rgba(0, 212, 255, 0.5) 100%
    ),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-size: cover;
  /* background-image: url("./../../bg-breadcrumb.svg"); */
  min-height: 350px
}
`, "",{"version":3,"sources":["webpack://./src/components/Landing/Layouts/Breadcrumb/Breadcrumb.module.css"],"names":[],"mappings":"AACE;EAAA,uBAA8B;EAA9B,aAA8B;EAA9B,6EAA8B;EAA9B,iGAA8B;EAA9B,uGAA8B;EAC9B,yBAAyB;EACzB;;;;;;2CAM6C;EAC7C,2BAA2B;EAC3B,sBAAsB;EACtB,wDAAwD;EACxD;AAZ8B","sourcesContent":[".root {\n  @apply min-h-fit shadow-md p-8;\n  background: rgb(2, 0, 36);\n  background: linear-gradient(\n      90deg,\n      rgba(2, 0, 36, 0.5) 0%,\n      rgba(9, 9, 121, 0.5) 35%,\n      rgba(0, 212, 255, 0.5) 100%\n    ),\n    url(\"../../../../images/bg-breadcrumb.svg\");\n  background-position: center;\n  background-size: cover;\n  /* background-image: url(\"./../../bg-breadcrumb.svg\"); */\n  min-height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Breadcrumb_root__FMDYK`
};
export default ___CSS_LOADER_EXPORT___;
