import cn from "classnames";
import React, { useState } from "react";
import Rules from "./Rules";
import SocialShare from "./SocialShare";
import { useSelector } from "react-redux";
import { Merchant, NewUser, Rider } from "../../icons";
import ReferralCard from "../../common/ReferralCard";
import { Modal } from "antd";

//
const InviteEarn = () => {
  const { token, merchantInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const options = [
    {
      title: "Share with Friends",
      description:
        "Share this code with as many family members and friends as you want, and ask them to use it when signing up or placing an order at Jachai.com. In every purchase, both parties will benefit. ",
      icon: <NewUser />,
      referralCode: merchantInfo?.referralCode,
      colorCode: "rgba(185, 101, 224, 0.12)",
    },
    {
      title: "Share with Rider",
      description:
        "Share this code with as many family members and friends as you want, and ask them to use it when signing up or placing an order at Jachai.com. In every purchase, both parties will benefit. ",
      icon: <Rider />,
      referralCode: merchantInfo?.referralCode,
      colorCode: "#FDF0F0",
    },
    {
      title: "Share with Merchant",
      description:
        "Share this code with as many family members and friends as you want, and ask them to use it when signing up or placing an order at Jachai.com. In every purchase, both parties will benefit. ",
      icon: <Merchant />,
      referralCode: merchantInfo?.referralCode,
      colorCode: "#F4ECCE",
    },
  ];
 

  return (
    <>
      <div className="block mb-10">
        <div className={cn(`rounded-xl border bg-white min-h-full`)}>
          <div
            className={cn(
              `w-full text-center rounded-xl bg-white object-cover bg-cover bg-no-repeat bg-center h-48 border`
            )}
            style={{
              background: "url('/earningBg.jpg')",
              backgroundSize: "cover",
            }}
          >
            <div className="p-4 pt-8 flex gap-3 place-content-center items-start relative">
              <span className="text-red-600 text-3xl lg:text-5xl font-extrabold">
                "INVITE & EARN"
              </span>
              <div
                className="absolute right-0 bg-gray-600 px-6 py-3 rounded-l-full text-white font-extrabold leading-3 shadow-md cursor-pointer hover:bg-red-600"
                onClick={() => setVisibleModal(!visibleModal)}
              >
                Rules
              </div>
            </div>
          </div>

          <div className="p-4 lg:p-10 flex flex-col justify-center gap-4">
            <div className="grid grid-cols-3 gap-6">
              {options?.map((data: any, i: number) => (
                <ReferralCard
                  data={data}
                  key={i}
                  setVisible={setVisible}
                  visible={visible}
                />
              ))}
            </div>
            {/* <div className="flex gap-2 lg:gap-6 justify-center w-full">
              <div className="flex border border-gray-300 shadow rounded-full overflow-hidden max-w-lg text-center">
                <span
                  className={cn(
                    `text-gray-500 font-bold text-md lg:text-lg bg-gray-200 p-2 px-4 lg:px-8 border-r border-gray-300`
                  )}
                >
                  Referral Code
                </span>
                <span
                  className={cn(
                    `text-red-600 font-extrabold text-md lg:text-lg p-2 px-4 lg:px-20 text-center`
                  )}
                >
                  {merchant?.referralCode}
                </span>
              </div>
              <span
                className={cn(
                  `bg-gray-300 hover:bg-gray-500 shadow text-white rounded-full cursor-pointer font-bold text-lg p-2 px-4 lg:px-20 text-center flex gap-2 items-center justify-center w-28 lg:w-72`
                )}
                onClick={() => handleCopy(merchant?.referralCode || 'No Data!')}
              >
                {isCopied ? (
                  <>
                    <Check /> Copied!
                  </>
                ) : (
                  <>
                    <Copy />
                    Copy
                  </>
                )}
              </span>
            </div> */}
            {visible && (
              <>
                <span className="text-center font-bold text-xl lg:text-2xl mt-12">
                  Share this code
                </span>
                <SocialShare />
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={visibleModal}
        onCancel={() => setVisibleModal(!visibleModal)}
        width={500}
        title={
          <span className="flex gap-4 justify-center font-semibold">
            “INVITE & EARN"
          </span>
        }
      >
        <Rules />
      </Modal>
    </>
  );
};

export default InviteEarn;
