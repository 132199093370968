import React, { FC } from "react";
import cn from "classnames";
import s from "./Footer.module.css";
import { Facebook, Twitter, Linkedin, Instagram } from "../../../icons";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import Container from "../../../ui/Container";
import AppSticker from "../../../ui/Apps";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const HomeFooter: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);

  return (
    <footer className={cn(rootClassName, "footer-wrapper")}>
      <Container fluid={false}>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 border-b border-accent-5 py-12 transition-colors duration-150 text-secondary">
          <div className="col-span-1 lg:col-span-5">
            <div className="grid text-secondary pr-6">
              <div className="mb-2">
                <span className="mr-4">
                  <Link
                    to={process.env.REACT_APP_ANDROID_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppSticker type="playstore" />
                  </Link>
                </span>
                {/* <span className="mr-2">
                  <a
                    href={process.env.COMMERCE_IOS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppSticker type="appstore" />
                  </a>
                </span> */}
              </div>
              <p>
                Jachai helps online merchants with business expansion, sales
                growth, customer acquisition, and brand exposure. Working with
                us will give you access to new customers who want to place
                orders for delivery and pickup from your shop.
              </p>

              <div className="mt-8">
                <span>Got Question? Call us 24/7</span>
                <h3 className="text-4xl text-white mt-2">9639-333444</h3>
              </div>
            </div>
          </div>
          <div className="lg:col-span-7">
            <div className="grid grid-cols-1 lg:grid-cols-12">
              <div className="col-span-1 lg:col-span-4 ml-4">
                <div className="col-span-1 grid mb-6 lg:mb-0">
                  <h4 className="text-xl text-white font-bold mb-6">
                    Information
                  </h4>
                  {/* {[...sitePages].map((page) => (
                      <span key={page.url} className="py-2 md:py-0 md:pb-2">
                        <Link href={page.url!}>
                          <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                            {page.title}
                          </a>
                        </Link>
                      </span>
                    ))} */}
                  <span className="py-2 md:py-0 md:pb-2">
                    <Link to={"/about-us"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        About Us
                      </a>
                    </Link>
                  </span>
                  <span className="py-2 md:py-0 md:pb-2">
                    <Link to={"/contact-us"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Contact Us
                      </a>
                    </Link>
                  </span>
                  <span className="py-2 md:py-0 md:pb-2">
                    <Link to={"/terms"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Terms & Conditions
                      </a>
                    </Link>
                  </span>
                  <span className="py-2 md:py-0 md:pb-2">
                    <Link to={"/privacy-policy"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Privacy policy
                      </a>
                    </Link>
                  </span>
                  <span className="py-2 md:py-0 md:pb-2">
                    <Link to={"/help"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Help Center
                      </a>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-span-1 lg:col-span-4 ml-4">
                <div className="col-span-1 grid mb-6 lg:mb-0">
                  <h4 className="text-xl text-white font-bold mb-6">
                    Customer Service
                  </h4>
                  {/* {[...sitePages].map((page) => (
                  <span key={page.url} className="py-3 md:py-0 md:pb-2">
                    <Link href={page.url!}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        {page.title}
                      </a>
                    </Link>
                  </span>
                ))} */}
                  <span className="py-3 md:py-0 md:pb-2">
                    <Link to={"/payment-method"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Payment Method
                      </a>
                    </Link>
                  </span>
                  <span className="py-3 md:py-0 md:pb-2">
                    <Link to={"/payment-method"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Money-back
                      </a>
                    </Link>
                  </span>
                  <span className="py-3 md:py-0 md:pb-2">
                    <Link to={"/payment-method"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Returns
                      </a>
                    </Link>
                  </span>
                  <span className="py-3 md:py-0 md:pb-2">
                    <Link to={"/payment-method"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Shipping
                      </a>
                    </Link>
                  </span>
                  <span className="py-3 md:py-0 md:pb-2">
                    <Link to={"/payment-method"}>
                      <a className="text-accent-0 hover:text-accent transition ease-in-out duration-150">
                        Privacy Policy
                      </a>
                    </Link>
                  </span>
                </div>
              </div>

              <div className="col-span-1 lg:col-span-4 flex flex-col">
                <h4 className="text-xl text-white font-bold mb-6">
                  Get In Tuch
                </h4>
                {/* <Link href="/">
                  <a className="flex flex-initial items-center font-bold md:mr-10 mb-8">
                    <span className="rounded-full mr-2">
                      <Logo reverse="true" />
                      <Image src="/logo.svg" width="131" height="40" alt="Jachai" />
                    </span>
                  </a>
                </Link> */}

                <div className="my-auto">
                  <address className=" line-h-2xl mb-2">
                    House# 26, Road# 7, Block# C, <br />
                    Niketon, Gulshan -1,
                    <br />
                    Dhaka-1212.
                  </address>
                  <p>
                    <a href="mailto:support@jachai.com">support@jachai.com</a>
                  </p>
                </div>
                <div className="socials flex mt-8 mb-auto">
                  <Link
                    to={`https://www.instagram.com/jachaicomltd/`}
                    target="_blank"
                  >
                    <a
                      target="_blank"
                      rel="noreferrer nopener"
                      title="Instagram"
                      className="flex flex-initial items-center bg-white font-bold mr-4 md:mr-4 mb-0 p-2 border justify-center rounded-full w-10 h-10"
                    >
                      <span className="rounded-full">
                        <Instagram width="16" height="16" />
                      </span>
                    </a>
                  </Link>
                  <Link
                    to={`https://www.facebook.com/jachaicom`}
                    // to={${process.env.FACEBOOK}`}
                    target="_blank"
                  >
                    <a
                      target="_blank"
                      rel="noreferrer nopener"
                      title="Facebook"
                      className="flex flex-initial items-center bg-white font-bold mr-4 md:mr-4 mb-0 p-2 border justify-center rounded-full w-10 h-10"
                    >
                      <span className="rounded-full">
                        <Facebook width="16" height="16" />
                      </span>
                    </a>
                  </Link>
                  <Link
                    to={`https://www.linkedin.com/company/jachai-com/`}
                    target="_blank"
                  >
                    <a
                      rel="noreferrer nopener"
                      title="Linkedin"
                      className="flex flex-initial items-center bg-white font-bold mr-4 md:mr-4 mb-0 p-2 border justify-center rounded-full w-10 h-10"
                    >
                      <span className="rounded-full">
                        <Linkedin width="16" height="16" />
                      </span>
                    </a>
                  </Link>
                  <Link to={`https://twitter.com/jachaicom`} target="_blank">
                    <a
                      rel="noreferrer nopener"
                      title="Twitter"
                      className="flex flex-initial items-center bg-white font-bold mr-4 md:mr-4 mb-0 p-2 border justify-center rounded-full w-10 h-10"
                    >
                      <span className="rounded-full">
                        <Twitter width="16" height="16" />
                      </span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="py-4 flex flex-col md:flex-row justify-between items-center space-y-4 text-accent-0">
          <div>
            <span>
              &copy; {new Date().getFullYear()} Jachai.com Ltd, Inc. All rights
              reserved.
            </span>
          </div>
          <div className="flex items-center text-primary">
            <span className="text-secondary mr-4">Powered by</span>
            <a
              rel="noopener noreferrer"
              href="/"
              aria-label="Jachai"
              target="_blank"
              className="text-primary"
            >
              {/* <Logo
                className="inline-block h-6 ml-3 text-primary"
                alt="Jachai"
                reverse="true"
              /> */}
              <Avatar
                src="/logo.svg"
                className="inline-block h-6 ml-3 text-primary"
                alt="Jachai"
              />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default HomeFooter;
