const Bag = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="47"
      viewBox="0 0 46 47"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_22:4269)">
        <circle cx="23" cy="23.3333" r="13" fill="white" />
      </g>
      <path
        d="M19 16.6665L17 19.3332V28.6665C17 29.0201 17.1405 29.3593 17.3905 29.6093C17.6406 29.8594 17.9797 29.9998 18.3333 29.9998H27.6667C28.0203 29.9998 28.3594 29.8594 28.6095 29.6093C28.8595 29.3593 29 29.0201 29 28.6665V19.3332L27 16.6665H19Z"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 19.3333H29"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6663 22C25.6663 22.7072 25.3854 23.3855 24.8853 23.8856C24.3852 24.3857 23.7069 24.6667 22.9997 24.6667C22.2924 24.6667 21.6142 24.3857 21.1141 23.8856C20.614 23.3855 20.333 22.7072 20.333 22"
        stroke="#565656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_22:4269"
          x="0"
          y="0.333252"
          width="46"
          height="46"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.764706 0 0 0 0 0.764706 0 0 0 0 0.764706 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_22:4269"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_22:4269"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Bag
