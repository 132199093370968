import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopProductCatelogueList from "../../../components/Shop/Product/ProductCatelogue";

const ShopProductCateloguePage = () => {
  return (
    <MainLayout>
      <ShopProductCatelogueList />
    </MainLayout>
  );
};

export default ShopProductCateloguePage;
