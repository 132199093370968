import React from "react";
import MainLayout from "../../components/Layouts";
import MembershipProfile from "../../components/MemberShip/index";


const MembershipPage = () => {
  return (
    <MainLayout>
      <MembershipProfile />
    </MainLayout>
  );
}; 

export default MembershipPage;
