import React, { useState } from "react";
import { Form, Input } from "antd";
import { Checkbox } from "antd";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import SubmitResetBtn from "../../common/SubmitBtn";

const WarehouseDetailsAdd = ({ refetch }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [wareHouseAddress, setWareHouseAddress] = useState<boolean>(false);
  const [storeChecked, setStoreChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    setLoading(true);
    try {
      fetch(`${process.env.REACT_APP_RIDER_API}/merchant/warehouse`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wareHouseAddressIsSameAsStoreAddress: wareHouseAddress,
          returnAddressIsSameAsStoreAddress: storeChecked,
          wareHouseAddress: {
            address: data.waddress,
            city: data.wcity,
            district: data.wdistrict,
            postcode: data.wpostcode,
          },
          returnAddress: {
            address: data.address,
            city: data.city,
            district: data.district,
            postcode: data.postcode,
          },
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          setLoading(false);
          refetch();
          responseNotification(data?.message, "success");
        });
    } catch (e) {
      setLoading(false);
      responseNotification("Operation successful" || "Operation failed");
    }
  };
  const resetData = () => {
    form?.resetFields();
  };
  return (
    <Form onFinish={onSubmit} form={form} layout="vertical">
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-1">
          <h2 className="mb-2 font-semibold text-lg text-gray-500">
            Warehouse Address
          </h2>
          <Checkbox
            onChange={(e) => {
              setStoreChecked(e.target.checked);
            }}
          >
            Same as Store Address
          </Checkbox>

          <Form.Item name="waddress" hasFeedback label="Address">
            <Input
              id="waddress"
              className="input-box"
              placeholder="Enter address"
            />
          </Form.Item>

          <Form.Item name="wcity" hasFeedback label="City">
            <Input id="wcity" className="input-box" placeholder="Enter city" />
          </Form.Item>

          <Form.Item name="wdistrict" hasFeedback label="District">
            <Input
              id="wdistrict"
              className="input-box"
              placeholder="Enter district"
            />
          </Form.Item>

          <Form.Item name="wpostcode" hasFeedback label="Postcode">
            <Input
              id="wpostcode"
              className="input-box"
              placeholder="Enter postcode"
            />
          </Form.Item>
        </div>
        <div className="col-span-1">
          <h2 className="mb-2 font-semibold text-lg text-gray-500">
            Return Address
          </h2>
          <Checkbox
            onChange={(e) => {
              setWareHouseAddress(e.target.checked);
            }}
          >
            Same as Store Address
          </Checkbox>

          <Form.Item name="address" hasFeedback label="Address">
            <Input
              id="address"
              className="input-box"
              placeholder="Enter address"
            />
          </Form.Item>

          <Form.Item name="city" hasFeedback label="City">
            <Input id="city" className="input-box" placeholder="Enter city" />
          </Form.Item>

          <Form.Item name="district" hasFeedback label="District">
            <Input
              id="district"
              className="input-box"
              placeholder="Enter district"
            />
          </Form.Item>

          <Form.Item name="postcode" hasFeedback label="Postcode">
            <Input
              id="postcode"
              className="input-box"
              placeholder="Enter postcode"
            />
          </Form.Item>
        </div>
      </div>

      <SubmitResetBtn
        onClickReset={resetData}
        disabled={loading}
        loading={loading}
      />
    </Form>
  );
};

export default WarehouseDetailsAdd;
