import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import UserTransactionList from "../../components/Reports/Transaction";
const MyTransactionPage = (): ReactElement => {
  return (
    <MainLayout>
      <UserTransactionList />
    </MainLayout>
  );
};

export default MyTransactionPage;
