// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Add_submitBtn__TPSwU {

    margin-right: 0.75rem;

    border-radius: 0.375rem;

    border-width: 0px;

    --tw-bg-opacity: 1;

    background-color: rgb(29 78 216 / var(--tw-bg-opacity));

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    padding-top: 0.625rem;

    padding-bottom: 0.625rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.Add_submitBtn__TPSwU:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(30 64 175 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.Add_submitBtn__TPSwU:focus {

    outline: 2px solid transparent;

    outline-offset: 2px;

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity))
}

@media (prefers-color-scheme: dark) {

    .Add_submitBtn__TPSwU {

        --tw-bg-opacity: 1;

        background-color: rgb(37 99 235 / var(--tw-bg-opacity))
    }

    .Add_submitBtn__TPSwU:hover {

        --tw-bg-opacity: 1;

        background-color: rgb(29 78 216 / var(--tw-bg-opacity))
    }

    .Add_submitBtn__TPSwU:focus {

        --tw-ring-opacity: 1;

        --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity))
    }
}

.Add_resetBtn__E34L9 {

    border-radius: 0.375rem;

    border-width: 0px;

    --tw-bg-opacity: 1;

    background-color: rgb(250 204 21 / var(--tw-bg-opacity));

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    padding-top: 0.625rem;

    padding-bottom: 0.625rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(75 85 99 / var(--tw-text-opacity))
}

.Add_resetBtn__E34L9:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(234 179 8 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.Add_resetBtn__E34L9:focus {

    outline: 2px solid transparent;

    outline-offset: 2px;

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity))
}

@media (prefers-color-scheme: dark) {

    .Add_resetBtn__E34L9:focus {

        --tw-ring-opacity: 1;

        --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity))
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/tailwind/Add.module.css"],"names":[],"mappings":"AACE;;IAAA,qBAA4O;;IAA5O,uBAA4O;;IAA5O,iBAA4O;;IAA5O,kBAA4O;;IAA5O,uDAA4O;;IAA5O,qBAA4O;;IAA5O,sBAA4O;;IAA5O,qBAA4O;;IAA5O,wBAA4O;;IAA5O,mBAA4O;;IAA5O,oBAA4O;;IAA5O,gBAA4O;;IAA5O,oBAA4O;;IAA5O;AAA4O;;AAA5O;;IAAA,kBAA4O;;IAA5O,uDAA4O;;IAA5O,oBAA4O;;IAA5O;AAA4O;;AAA5O;;IAAA,8BAA4O;;IAA5O,mBAA4O;;IAA5O,2GAA4O;;IAA5O,yGAA4O;;IAA5O,4FAA4O;;IAA5O,oBAA4O;;IAA5O;AAA4O;;AAA5O;;IAAA;;QAAA,kBAA4O;;QAA5O;IAA4O;;IAA5O;;QAAA,kBAA4O;;QAA5O;IAA4O;;IAA5O;;QAAA,oBAA4O;;QAA5O;IAA4O;AAAA;;AAI5O;;IAAA,uBAA0M;;IAA1M,iBAA0M;;IAA1M,kBAA0M;;IAA1M,wDAA0M;;IAA1M,qBAA0M;;IAA1M,sBAA0M;;IAA1M,qBAA0M;;IAA1M,wBAA0M;;IAA1M,mBAA0M;;IAA1M,oBAA0M;;IAA1M,gBAA0M;;IAA1M,oBAA0M;;IAA1M;AAA0M;;AAA1M;;IAAA,kBAA0M;;IAA1M,uDAA0M;;IAA1M,oBAA0M;;IAA1M;AAA0M;;AAA1M;;IAAA,8BAA0M;;IAA1M,mBAA0M;;IAA1M,2GAA0M;;IAA1M,yGAA0M;;IAA1M,4FAA0M;;IAA1M,oBAA0M;;IAA1M;AAA0M;;AAA1M;;IAAA;;QAAA,oBAA0M;;QAA1M;IAA0M;AAAA","sourcesContent":[".submitBtn {\n  @apply mr-3 focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-bold rounded-md text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900 hover:text-white border-0;\n}\n\n.resetBtn {\n  @apply focus:outline-none text-gray-600 bg-yellow-400 hover:bg-yellow-500 focus:ring-1 focus:ring-yellow-300 font-bold rounded-md text-sm px-5 py-2.5 dark:focus:ring-yellow-900 hover:text-white border-0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitBtn": `Add_submitBtn__TPSwU`,
	"resetBtn": `Add_resetBtn__E34L9`
};
export default ___CSS_LOADER_EXPORT___;
