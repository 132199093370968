const Twitter = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2211 2.24044C18.5508 2.53765 17.8298 2.73867 17.0737 2.82844C17.8457 2.3659 18.4382 1.63404 18.7178 0.760535C17.9954 1.18906 17.195 1.49993 16.3438 1.66749C15.6618 0.941202 14.6902 0.487305 13.6143 0.487305C11.55 0.487305 9.87576 2.16154 9.87576 4.22582C9.87576 4.51884 9.90893 4.80406 9.97306 5.07785C6.86604 4.922 4.11116 3.43373 2.26713 1.17122C1.94539 1.72325 1.76082 2.3659 1.76082 3.05093C1.76082 4.34766 2.42131 5.49244 3.4239 6.16269C2.81136 6.14345 2.23451 5.97533 1.73071 5.69457C1.73043 5.71046 1.73043 5.72636 1.73043 5.74197C1.73043 7.55337 3.01963 9.06423 4.72955 9.40744C4.41617 9.49331 4.08523 9.53875 3.7448 9.53875C3.50336 9.53875 3.26944 9.51561 3.04138 9.47212C3.51702 10.957 4.89739 12.038 6.53371 12.0684C5.25398 13.0712 3.6422 13.6687 1.89018 13.6687C1.58907 13.6687 1.29075 13.6511 0.99884 13.6163C2.65244 14.6774 4.61803 15.2961 6.72915 15.2961C13.6056 15.2961 17.3662 9.59953 17.3662 4.65881C17.3662 4.49682 17.3625 4.33539 17.3553 4.17536C18.0863 3.64841 18.7201 2.99015 19.2211 2.24044Z"
        fill="#393E46"
      />
    </svg>
  )
}

export default Twitter
