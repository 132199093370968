import cn from "classnames";
import React, { useState } from "react";
import { Avatar, Modal } from "antd";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import { CopyOutlined } from "@ant-design/icons";
import { responseNotification } from "../../../utils/notify";

//
const SocialShare = () => {
  const { merchantInfo } = useSelector((state) => (state as any)?.authReducer);

  const copyToClipboard = async (text: any) => {
    if ("clipboard" in navigator) {
      responseNotification("Copied the Code!", "success");
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const [selectedMedia, setSelectedMedia] = useState<any>(undefined);
  const [shareText, setShareText] = useState(
    `To sign up or place an order, use the code "${merchantInfo?.referralCode}" With every purchase, you will get attractive benefits from Jachai.com.`
  );

  const options: any = [
    {
      title: "Messenger",
      icon: "/icons/messenger.png",
      color: "#426FFF",
    },
    {
      title: "WhatsApp",
      icon: "/icons/whatsApp.png",
      color: "#1BD741",
    },
    {
      title: "Email",
      icon: "/icons/gmail.png",
      color: "#EA4335",
    },
    // {˜
    //   title: 'Instagram',
    //   icon: '/icons/instagram.png',
    //   color: '#EA4335',
    // },
    {
      title: "Pinterest",
      icon: "/icons/pinterest.png",
      color: "#EA4335",
    },
    {
      title: "Twitter",
      icon: "/icons/twitter.png",
      color: "#EA4335",
    },
    {
      title: "Facebook",
      icon: "/icons/facebook.png",
      color: "#EA4335",
    },
    {
      title: "Linkedin",
      icon: "/icons/linkedin.png",
      color: "#EA4335",
    },
  ];

  return (
    <>
      <div className="w-full grid lg:flex grid-cols-4 gap-4 justify-center place-content-center">
        {options?.map((data: any, i: string) => (
          <div
            key={i}
            className={cn(
              `h-16 w-16 rounded-full bg-gray-200 p-3 flex items-center gap-3 justify-center shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu justify-self-center`
            )}
            onClick={() => setSelectedMedia(data?.title)}
          >
            <Avatar
              src={data?.icon}
              alt={data?.title}
              size="large"
              shape="square"
              // className="object-contain"
            />
          </div>
        ))}

        <span
          className={cn(
            `h-16 w-16 rounded-full bg-gray-200 p-3 flex items-center gap-3 justify-center shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu justify-self-center border`
          )}
          onClick={() => setSelectedMedia(`COPY`)}
        >
          <CopyOutlined />
        </span>
      </div>

      <Modal
        onCancel={() => setSelectedMedia(undefined)}
        width={650}
        title="Copy and share the text"
        open={selectedMedia}
      >
        <div className="mb-3">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            rows={4}
            onChange={(e) => setShareText(e.target.value)}
            className="w-full p-3 font-medium bg-gray-300 mb-2"
            value={shareText}
          ></textarea>

          <div className="gap-2 flex">
            {selectedMedia === "Facebook" && (
              <span
                className={cn(
                  `rounded-md bg-white p-2 px-5 border shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu`
                )}
                onClick={() => copyToClipboard(shareText)}
              >
                <FacebookShareButton
                  quote={shareText}
                  url={"https://jachai.com/"}
                  // hashtag={'#jachai'}
                >
                  Copy to Share
                </FacebookShareButton>
              </span>
            )}

            {selectedMedia === "Messenger" && (
              <span
                className={cn(
                  `rounded-md bg-white p-2 px-5 border shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu`
                )}
                onClick={() => copyToClipboard(shareText)}
              >
                <FacebookMessengerShareButton
                  url={"https://jachai.com/"}
                  appId={"3125186401105466"}
                  windowWidth={600}
                  windowHeight={600}
                >
                  Copy to Share
                </FacebookMessengerShareButton>
              </span>
            )}

            {selectedMedia === "Email" && (
              <span
                className={cn(
                  `rounded-md bg-white p-2 px-5 border shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu`
                )}
                onClick={() => copyToClipboard(shareText)}
              >
                <EmailShareButton
                  url={"https://github.com/next-share"}
                  subject={"Next Share"}
                  body="body"
                >
                  Copy to Share
                </EmailShareButton>
              </span>
            )}

            {selectedMedia === "WhatsApp" && (
              <span
                className={cn(
                  `rounded-md bg-white p-2 px-5 border shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu`
                )}
                onClick={() => copyToClipboard(shareText)}
              >
                <WhatsappShareButton
                  url={"https://jachai.com/"}
                  title={shareText}
                  separator=":: "
                >
                  Copy to Share
                </WhatsappShareButton>
              </span>
            )}

            {selectedMedia === "Pinterest" && (
              <span
                className={cn(
                  `rounded-md bg-white p-2 px-5 border shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu`
                )}
                onClick={() => copyToClipboard(shareText)}
              >
                <PinterestShareButton
                  url={"https://jachai.com/"}
                  media={`https://jachai.com/_next/image?url=https%3A%2F%2Fproduction-jachai-service.s3-ap-southeast-1.amazonaws.com%2Fstatic_image%2F2022-11-10T15%3A04%3A58.731_about-us.webp&w=640&q=75`}
                  description={shareText}
                >
                  Copy to Share
                </PinterestShareButton>
              </span>
            )}

            {selectedMedia === "Twitter" && (
              <span
                className={cn(
                  `rounded-md bg-white p-2 px-5 border shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu`
                )}
                onClick={() => copyToClipboard(shareText)}
              >
                <TwitterShareButton
                  url={"https://jachai.com/"}
                  title={shareText}
                >
                  Copy to Share
                </TwitterShareButton>
              </span>
            )}

            {selectedMedia === "Linkedin" && (
              <span
                className={cn(
                  `rounded-md bg-white p-2 px-5 border shadow hover:shadow-lg cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 duration-300 hover:scale-125 transform-gpu`
                )}
                onClick={() => copyToClipboard(shareText)}
              >
                <LinkedinShareButton url={"https://jachai.com/"}>
                  Copy to Share
                </LinkedinShareButton>
              </span>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SocialShare;
