const CheckCircle = ({ ...props }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8987_22988)">
        <path
          d="M9.32192 5.33552V5.71886C9.32141 6.61737 9.03046 7.49164 8.49248 8.21128C7.95449 8.93093 7.19829 9.45739 6.33665 9.71215C5.47502 9.96691 4.55411 9.93632 3.71128 9.62493C2.86845 9.31355 2.14886 8.73806 1.65982 7.9843C1.17078 7.23054 0.938499 6.33888 0.997618 5.44232C1.05674 4.54576 1.40409 3.69233 1.98787 3.0093C2.57165 2.32628 3.36058 1.85027 4.237 1.65225C5.11342 1.45424 6.03036 1.54483 6.85109 1.91052"
          stroke="#CD113B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.32194 2.38574L5.15527 6.55658L3.90527 5.30658"
          stroke="#CD113B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8987_22988">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0.155273 0.71875)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckCircle
