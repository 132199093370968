import React from "react";
import MerchantLayout from "../../components/MerchantLayouts";
import MerchantProfile from "../../components/MerchantProfile/index";

const MerchantProfilePage = () => {
  return (
    <MerchantLayout>
      <MerchantProfile />
    </MerchantLayout>
  ); 
};

export default MerchantProfilePage;
