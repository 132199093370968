import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ShopLayout from "../Layout";
import AddProduct from "../../Product/AddProduct";
import { useSelector } from "react-redux";

const ShopProductAdd = (): ReactElement => {
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);

  return (
    <React.Fragment>
      <ShopLayout
        shopId={shopInfo?.id}
        title="Product Details"
        subTitle="Details"
        extra={<></>}
      >
        <div className="content-body_ p-0 rounded-2xl">
          <AddProduct getShopId={shopInfo?.id} inShop={true} />
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopProductAdd.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopProductAdd;
