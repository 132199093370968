import {
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Space,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import { responseNotification } from "../../utils/notify";
import SubmitResetBtn from "../common/SubmitBtn";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const QuickUpdate = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const pathname = window.location.pathname;
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [updateEveryWhere, setUpdateEveryWhere] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState<any>(undefined);
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      id: visibleData?.id,
      barCode: visibleData?.barCode,
      bannerName: data.bannerName,
      isUpdateEveryWhere: updateEveryWhere,
      variations: data?.variations?.map((variant: any, i: string) => ({
        variationName: variant.variationName,
        variationId: variant.variationId,
        imageUrl: variant.imageUrl,
        barCode: variant.barCode,
        regularVariationId: data?.variations?.[i + 1]?.variationId,
        maximumOrderLimit: variant.maximumOrderLimit
          ? parseInt(variant.maximumOrderLimit)
          : undefined,
        price: {
          mrp: parseFloat(variant.mrp) * 1,
          tp: parseFloat(variant.tp) * 1,
        },
        productDiscount: {
          flat: parseFloat(variant.flat) || 0,
          percentage: parseFloat(variant.percentage) || 0,
        },

        // quantitativeProductDiscount: {
        //   freeProductId: variant.quantitativeProductDiscount.freeProductId,
        //   minimumQuantity:
        //     variant.quantitativeProductDiscount.minimumQuantity || 0,
        //   productDiscount: {
        //     flat:
        //       parseFloat(
        //         variant.quantitativeProductDiscount.productDiscount.flat
        //       ) || 0,
        //     percentage:
        //       parseFloat(
        //         variant.quantitativeProductDiscount.productDiscount.percentage
        //       ) || 0,
        //   },
        // },
        stock: parseFloat(variant.stock) || 0,
      })),
    };

    if (productData) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/${pathname
          ?.replaceAll("/", "")
          .replace("products", "product")}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: productData?.id,
            deleted: false,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Product Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setProductData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (productData) {
      form.resetFields();
    }
  }, [productData, form, visibleData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...productData,
        }}
      >
        <Form.List
          name="variations"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(
                    new Error("At least 1 variation required!")
                  );
                }
              },
            },
          ]}
          initialValue={[{}]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }: any) => (
                <Card
                  className="mb-6 shadow rounded-xl"
                  size="small"
                  key={key}
                  title={visibleData?.variations?.[
                    name
                  ]?.variationName?.toUpperCase()}
                  extra={
                    <Button
                      type="text"
                      shape="circle"
                      danger
                      onClick={() => {
                        remove(name);
                        setVariationImageUrl((prev) => {
                          const newArr = prev.filter((_, i) => i !== name);
                          return newArr;
                        });
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  }
                >
                  <Space wrap align="center">
                    {variationImageUrl && (
                      <div>
                        <Avatar
                          src={visibleData?.variations?.[name]?.imageUrl}
                          shape="square"
                          size="large"
                        />
                      </div>
                    )}

                    <Form.Item
                      {...restField}
                      name={[name, "barCode"]}
                      fieldKey={[fieldKey, "barCode"]}
                      hasFeedback
                      label="Barcode"
                      style={{ width: 100 }}
                      initialValue={visibleData?.variations?.[name]?.barCode}
                    >
                      <Input placeholder="Enter Barcode" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "variationName"]}
                      fieldKey={[fieldKey, "variationName"]}
                      hasFeedback
                      label="Variation"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                      style={{ width: 90 }}
                      initialValue={
                        visibleData?.variations?.[name]?.variationName
                      }
                    >
                      <Input placeholder="1pc/5kg/1pkt" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "mrp"]}
                      fieldKey={[fieldKey, "mrp"]}
                      hasFeedback
                      label="MRP"
                      style={{ width: 70 }}
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                      initialValue={visibleData?.variations?.[name]?.price?.mrp}
                    >
                      <Input placeholder="Enter MRP" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "tp"]}
                      fieldKey={[fieldKey, "tp"]}
                      hasFeedback
                      label="TP"
                      style={{ width: 70 }}
                      initialValue={visibleData?.variations?.[name]?.price?.tp}
                    >
                      <Input placeholder="Enter TP" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "maximumOrderLimit"]}
                      fieldKey={[fieldKey, "maximumOrderLimit"]}
                      hasFeedback
                      label="Max Limit"
                      initialValue={
                        visibleData?.variations?.[name]?.maximumOrderLimit
                      }
                      style={{ width: 80 }}
                    >
                      <Input placeholder="Maximum Order Limit" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "stock"]}
                      fieldKey={[fieldKey, "stock"]}
                      hasFeedback
                      label="Stock"
                      initialValue={visibleData?.variations?.[name]?.stock}
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                      style={{ width: 70 }}
                    >
                      <Input placeholder="Stock" />
                    </Form.Item>
                  </Space>
                </Card>
              ))}

              <Form.Item
                style={{
                  width: "100%",
                  flex: "1 1 100%",
                  marginBottom: 5,
                }}
              >
                <Button
                  type="primary"
                  danger
                  shape="round"
                  className="float-right"
                  onClick={() => {
                    add();
                    setVariationImageUrl((prev) => {
                      const newArr = Array.from(prev);
                      newArr.push("");
                      return newArr;
                    });
                  }}
                  icon={<PlusOutlined />}
                  title="Add More"
                >
                  Add More
                </Button>
              </Form.Item>

              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>

        {pathname?.replaceAll("/", "") === "global-product" && (
          <Form.Item label="Update Everywhere?">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              className="mr-3"
              onChange={(val) => setUpdateEveryWhere(val)}
            />
          </Form.Item>
        )}

        <Divider />
        <SubmitResetBtn disabled={loading || !!error} loading={loading} />
      </Form>
    </>
  );
};

export default QuickUpdate;
