// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/bg-light.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GetStarted_root__8\\+R6H {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  background-size: contain;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat
}

.GetStarted_link__UwDHO {
  & > svg {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  & > svg {
    transition-duration: 75ms
  }
  & > svg {
    transition-timing-function: linear
  }

  &:hover > svg {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Landing/GetStarted/GetStarted.module.css"],"names":[],"mappings":"AACE;EAAA,kBAA+C;EAA/C,yDAA+C;EAA/C,wBAA+C;EAA/C,iBAA+C;EAA/C,oBAA+C;EAC/C,mDAA+C;EAC/C,sBAAsB;EACtB,2BAA2B;EAC3B;AAJ+C;;AAQjD;EAEI;IAAA;EAAwC;EAAxC;IAAA;EAAwC;EAAxC;IAAA;EAAwC;;EAIxC;IAAA,iBAAgB;IAAhB,iBAAgB;IAAhB;EAAgB;AAEpB","sourcesContent":[".root {\n  @apply bg-gray-50 bg-contain bg-no-repeat py-20;\n  background: url(\"../../../images/bg-light.png\");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n\n}\n\n.link {\n  & > svg {\n    @apply transform duration-75 ease-linear;\n  }\n\n  &:hover > svg {\n    @apply scale-110;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GetStarted_root__8+R6H`,
	"link": `GetStarted_link__UwDHO`
};
export default ___CSS_LOADER_EXPORT___;
