
import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";

import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Rate,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  TreeSelectProps,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import memberShipStyles from "./Profile.module.css";
import PaymentMethodView from './MembershipPaymentCheckout';
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import PaymentMethod from "./PaymentMethod";
import moment from "moment";
import AddMembership from "./AddMembership";
import UserHeader from "./UserHeader";
import { isAllowedService } from "../../utils/services";
import makePayment, { PaymentInput } from '../common/make-payment';
//import {makePayment} from '../common/make-payment';
import {
  AddBtn,
  Loader,
  LoaderFull,
  Pagination,
  TreeSelectTwo,
} from "../common";
import { useUI } from "../ui/context";

const MembershipList = (): ReactElement => {
  const router = useLocation();
  const { type, paymentMethodName, token, shopId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const paymentMethod = 'SSL';
  const [membershipLevelId, setMembershipLevelId] = useState<any>("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [key, setKey] = useState("");
  const [brandId, setBrandId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(true);
  const [showSearch, setShowSearch] = useState(true);
  const [selectedMembershipPay, setSelectedMembershipPay] =
    useState<any>(undefined);
    const [selectedMembership, setSelectedMembership] = useState(undefined);
    const [selectedMembershipForEdit, setSelectedMembershipForEdit] = useState(undefined);
    const [payPaymentMethod,setPayPaymentMethod] = useState<any>();
    
  const [visibleModal, setVisibleModal] = useState<any>(false)
  const [orderId,setOrderId] = useState<any>();
  const [payAmount,setPayAmount] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barCode, setBarCode] = useState(
    getParamValue(router.search, "barCode")
  );
  const [error, setError] = useState("");
  const checkedProducts = "20";
  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: null,
  });
  const getOrders = useCallback(async () => {
    setOrderData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/membership-order/my-orders?page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrderData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrderData({ loading: false, data: [] });
        console.error("Memberships: Error", err);
      });
  }, []);
  
  const makePaymentHandler = async (err?: Function) => {
    try {
      if (orderData.data) {
        await makePayment({
          gateway: paymentMethodName?.name,
          orderId: orderId,
          amount: payAmount,
          token,
          router,
        } as PaymentInput)
        setVisibleModal(false)
      }
    } catch (e) {
      console.error('paymentSuccess:', e)
      err?.()
    }
  }
  


  const reseAllFieldData = () => {
    form.resetFields();
  };
  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const onClose = () => {
    getOrders();
    form.resetFields();
    setVisible(false);
    setVisibleModal(false);
    setSelectedMembershipPay(undefined);
  };

  return (
    <>
          <BreadCrumb
        title="Membership Order List"
        subTitle={`${
          orderData?.data?.totalElements ||
          orderData?.data?.membershipOrders?.length ||
          0
        } Membership(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 membershipMainDiv">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
                <div className={memberShipStyles.memberShipTitle}>
                  <h1 className={memberShipStyles.memberShipH1}>
                    jachai.com Membership Order List
                  </h1>
                  
                </div>
              {orderData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Point Amount</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Due Amount</th>
                      <th scope="col">Paid Amount</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderData?.data?.membershipOrders?.length ? (
                      orderData?.data?.membershipOrders?.map(
                        (membership: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.membershipLevelName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.type.replace("_", " ")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.paymentStatus}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.pointAmount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.amount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.dueAmount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.paidAmount}
                              </span>
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(membership?.updatedAt, "minutes") >=
                                  60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(membership?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(membership?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {membership?.updatedBy?.name ||
                                  membership?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                            
                  {membership?.paymentStatus !== 'paid' && (
                 
                    <Button
                        type="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedMembershipPay(membership);
                          setOrderId(membership?.id);
                          setPayAmount(membership?.dueAmount);
                        }}
                        className="mb-4"
                        style={{background: '#3a494e'}}
                      >
                        Pay
                      </Button>
                  ) }
                  
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Pagination
          {...orderData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title="Payment Method"
        onCancel={onClose}
        open={selectedMembershipPay}
        width={700}
        centered
        footer={false}
      >
        <PaymentMethod
          onCloseMethod={onClose}
          visibleData={selectedMembershipPay}
          onModalSubmit={makePaymentHandler}
        />
      </Modal>

      <Drawer
        title={
          visible
            ? "Add Membership"
            : selectedMembershipForEdit
            ? "Edit Membership"
            : "Membership Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedMembership || selectedMembershipForEdit}
        width={450}
      >
        {visible || selectedMembershipForEdit ? (
          <AddMembership
            onCloseMethod={onClose}
            visibleData={selectedMembershipForEdit}
          />
        ) : (
          ''
        )}
      </Drawer>
    </>
  );
};

export default MembershipList;
