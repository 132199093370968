const Calling = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M11.2876 3.75C12.0201 3.89292 12.6934 4.25119 13.2211 4.77895C13.7489 5.30671 14.1072 5.97995 14.2501 6.7125M11.2876 0.75C12.8095 0.919077 14.2288 1.60063 15.3123 2.68276C16.3957 3.76488 17.0791 5.18326 17.2501 6.705M16.5001 12.69V14.94C16.5009 15.1489 16.4581 15.3556 16.3745 15.547C16.2908 15.7384 16.168 15.9102 16.0141 16.0514C15.8602 16.1926 15.6785 16.3001 15.4806 16.367C15.2828 16.4339 15.0731 16.4588 14.8651 16.44C12.5572 16.1892 10.3403 15.4006 8.39257 14.1375C6.58044 12.986 5.04407 11.4496 3.89257 9.6375C2.62506 7.6809 1.83625 5.45325 1.59007 3.135C1.57133 2.9276 1.59598 2.71857 1.66245 2.52122C1.72892 2.32387 1.83575 2.14252 1.97615 1.98872C2.11654 1.83491 2.28743 1.71203 2.47792 1.62789C2.6684 1.54375 2.87433 1.5002 3.08257 1.5H5.33257C5.69655 1.49642 6.04942 1.62531 6.32539 1.86265C6.60137 2.09999 6.78163 2.42959 6.83257 2.79C6.92754 3.51005 7.10366 4.21705 7.35757 4.8975C7.45848 5.16594 7.48032 5.45769 7.4205 5.73816C7.36069 6.01863 7.22172 6.27608 7.02007 6.48L6.06757 7.4325C7.13524 9.31016 8.68991 10.8648 10.5676 11.9325L11.5201 10.98C11.724 10.7784 11.9814 10.6394 12.2619 10.5796C12.5424 10.5198 12.8341 10.5416 13.1026 10.6425C13.783 10.8964 14.49 11.0725 15.2101 11.1675C15.5744 11.2189 15.9071 11.4024 16.145 11.6831C16.3828 11.9638 16.5092 12.3222 16.5001 12.69Z"
        stroke="#393E46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Calling
