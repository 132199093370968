import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { responseNotification } from "../../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../../common";
import { useSelector } from "react-redux";
import axios from "axios";
import MerchantLayout from "../Layout";
import { useNavigate } from "react-router";

const PersonalDetailsAdd = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [identificationUrl, setIdentificationUrl] = useState<string>();
  const navigate = useNavigate();
  const [profilePicUrl, setProfilePicUrl] = useState<string>();
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>();
  const [identificationUrl2, setIdentificationUrl2] = useState<string>();
  const [frontUrl, setFrontUrl] = useState<string>();
  const [startUploadProfilePic, setStartUploadProfilePic] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();


  const [startIdentificationUpload2, setStartIdentificationUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUploadFront, setStartUploadFront] = useState<
  "Initiated" | "Uploading" | "Uploaded"
>();
  const [startUploadProfilePicture, setStartUploadProfilePicture] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [form] = Form.useForm();

  const fetchMerchantDetails = useCallback(() => {
    try {
      setSingleMerchantInfo({ loading: true, data: null });
      axios
        .get(`${process.env.REACT_APP_RIDER_API}/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setSingleMerchantInfo({
              loading: false,
              data: data?.data?.merchant,
            });
            setIdentificationUrl2(
              data?.data?.merchant?.merchantPersonalDetail?.identificationDocumentUrl2
            );
            setProfilePictureUrl(
              data?.data?.merchant?.merchantPersonalDetail?.profilePicture
            );
            setFrontUrl(data?.data?.merchant?.merchantPersonalDetail?.identificationDocumentUrl);
          } else {
            setSingleMerchantInfo({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleMerchantInfo({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setSingleMerchantInfo({ loading: false, data: null });

      console.log(error, "error");
    }
  }, [token]);

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails, token]);
  useEffect(() => {
    if (singleMerchantInfo.data) {
      form.resetFields(Object.keys(singleMerchantInfo.data as any));
      setIdentificationUrl(
        singleMerchantInfo?.data?.merchantBusinessInfo?.attachTinDocuments
      );
      setFrontUrl(singleMerchantInfo?.data?.merchantBusinessInfo?.identificationDocumentUrl);
    }
  }, [form, singleMerchantInfo.data]);

  function handleChange(value) {
    setDocumentType(value);
  }
  useEffect(() => {
    if (startUploadProfilePicture === "Uploaded") {
      form?.submit();
    }
  }, [form, startUploadProfilePicture]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      dateOfBirth: data.dateOfBirth,
      email: data.email,
      gender: data.gender,
      identificationDocumentType: data.identificationDocumentType,
      identificationDocumentUrl: frontUrl || "",
      identificationDocumentUrl2: identificationUrl2 || "",
      name: data.name,
      profilePicture: profilePictureUrl || "",
    };

    await fetch(`${process.env.REACT_APP_RIDER_API}/merchant/personal-info`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(data?.message, "success");
          form.resetFields(); 
          //window.location.reload();
          navigate(-1);
          // navigate("/account/my-profile"); 
        } else if (res.status === 500) { 
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
  };

  useEffect(() => {
    if (singleMerchantInfo?.data) {
      form.resetFields();
      setIdentificationUrl2(
        singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl2
      );
      setProfilePictureUrl(
        singleMerchantInfo?.data?.merchantPersonalDetail?.profilePicture
      );
      setFrontUrl(
        singleMerchantInfo?.data?.merchantPersonalDetail?.identificationDocumentUrl
      );
    }
  }, [form, singleMerchantInfo?.data]);

  return (
    <MerchantLayout
    merchantId={singleMerchantInfo?.data?.id}
    title={`Merchant Profile Update`}
    subTitle={`Merchant Profile Update`}
  >
    <div className="content-body rounded-2xl bg-white p-4 min-h-full">
      <Form
        name="control-hooks"
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        initialValues={{
          ...singleMerchantInfo?.data?.merchantPersonalDetail,
          name: singleMerchantInfo?.data?.merchantPersonalDetail?.name || "",
          email: singleMerchantInfo?.data?.merchantPersonalDetail?.email || "",
        }}
      >
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name="name"
            hasFeedback
            label="Owner Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
          >
            <Input type="text" id="name" placeholder="Enter name" />
          </Form.Item>

          <Form.Item
            name="email"
            hasFeedback
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Email is Required!",
              },
            ]}
          >
            <Input type="text" id="email" placeholder="Enter email" />
          </Form.Item>

          <Form.Item
            name="gender"
            hasFeedback
            label="Select Gender"
            rules={[
              {
                required: true,
                message: "Gender is Required!",
              },
            ]}
          >
            <Select
              placeholder="Select Gender"
              optionFilterProp="children"
              options={[
                { label: "MALE", value: "MALE" },
                { label: "FEMALE", value: "FEMALE" },
              ]}
            ></Select>
          </Form.Item>

          {/* <Form.Item
            hasFeedback
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: "Date of birth is Required!",
              },
            ]}
          >
            <DatePicker
              id="dateOfBirth"
              placeholder="Enter Date of Birth"
              className="w-full"
            />
          </Form.Item> */}

          <Form.Item
            name="identificationDocumentType"
            hasFeedback
            label="Document Type"
            rules={[
              {
                required: true,
                message: "Document Type is Required!",
              },
            ]}
          >
            <Select
              defaultValue="Select"
              onChange={handleChange}
              options={[
                {
                  label: "NID",
                  value: "NID",
                },
                {
                  label: "PASSPORT",
                  value: "PASSPORT",
                },
                {
                  label: "BIRTH CERTIFICATE",
                  value: "BIRTH_CERTIFICATE",
                },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="basis-0 pr-2">
            <span className="text-md">Front Image</span>
            <ImageUploader
              imgUrl={frontUrl || ""}
              startUpload={startUploadFront}
              setStartUpload={(val: any) => setStartUploadFront(val)}
              setGeneratedImgUrl={(url: any) => setFrontUrl(url)}
            />
          </div>

          {documentType == "NID" && (
            <div className="basis-0">
              <span className="text-md">Back Image</span>
              <ImageUploader
                imgUrl={identificationUrl2}
                startUpload={startIdentificationUpload2}
                setStartUpload={(val) => setStartIdentificationUpload2(val)}
                setGeneratedImgUrl={(url) => setIdentificationUrl2(url)}
              />
            </div>
          )}

          <div className="basis-0">
            <span className="text-md">Profile Photo</span>
            <ImageUploader
              imgUrl={profilePictureUrl || ""}
              startUpload={startUploadProfilePicture}
              setStartUpload={(val: any) => setStartUploadProfilePicture(val)}
              setGeneratedImgUrl={(url: any) => setProfilePictureUrl(url)}
            />
          </div>
        </div>

        <div className="mt-2">
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartIdentificationUpload2("Uploading");
              setStartUploadProfilePicture("Uploading");
              setStartUploadFront("Uploading");
            }}
            onClickReset={() => {
              form?.resetFields();
              setStartIdentificationUpload2(undefined);
              setStartUploadProfilePicture(undefined);
              setStartUploadFront(undefined);
            }}
            disabled={loading || startIdentificationUpload2 === "Uploading" || startUploadProfilePicture === "Uploading" || startUploadFront === "Uploading"}
            loading={loading || startIdentificationUpload2 === "Uploading" || startUploadProfilePicture === "Uploading" || startUploadFront === "Uploading"}
          />
        </div>
      </Form>
    </div>
    </MerchantLayout>
  );
};

export default PersonalDetailsAdd;
