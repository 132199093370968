import React from "react";
import MainLayout from "../../../components/Layouts";
import DeliveryShippingList from "../../../components/Shop/DeliveryShipping/index";

const DeliveryShippingPage = () => {
  return (
    <MainLayout>
      <DeliveryShippingList />
    </MainLayout>
  );
};

export default DeliveryShippingPage;
