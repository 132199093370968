// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhyUs_root__c5c1T {

  --tw-bg-opacity: 1;

  background-color: rgb(248 250 252 / var(--tw-bg-opacity));

  padding-top: 5rem;

  padding-bottom: 6rem
}

.WhyUs_link__bTjZz {
  & > svg {

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  & > svg {

    transition-duration: 75ms
  }
  & > svg {

    transition-timing-function: linear
  }

  &:hover > svg {

    --tw-scale-x: 1.1;

    --tw-scale-y: 1.1;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Landing/WhyUs/WhyUs.module.css"],"names":[],"mappings":"AACE;;EAAA,kBAA8B;;EAA9B,yDAA8B;;EAA9B,iBAA8B;;EAA9B;AAA8B;;AAGhC;EAEI;;IAAA;EAAwC;EAAxC;;IAAA;EAAwC;EAAxC;;IAAA;EAAwC;;EAIxC;;IAAA,iBAAgB;;IAAhB,iBAAgB;;IAAhB;EAAgB;AAEpB","sourcesContent":[".root {\n  @apply py-20 pb-24 bg-slate-50;\n}\n\n.link {\n  & > svg {\n    @apply transform duration-75 ease-linear;\n  }\n\n  &:hover > svg {\n    @apply scale-110;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `WhyUs_root__c5c1T`,
	"link": `WhyUs_link__bTjZz`
};
export default ___CSS_LOADER_EXPORT___;
