import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopContractAddressUpdate from "../../../components/Shop/ContractAddress/edit";

const ShopContractAddressUpdatePage = () => {
  return (
    <MainLayout>
      <ShopContractAddressUpdate />
    </MainLayout>
  );
};

export default ShopContractAddressUpdatePage;
