import MainLayout from "../../../components/Layouts";
import ShopOutOfStockProductList from "../../../components/Shop/Product/out-of-stock";

const ShopOutOfStockProductPage = () => {
  return (
    <MainLayout>
      <ShopOutOfStockProductList />
    </MainLayout>
  );
};

export default ShopOutOfStockProductPage;
