import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopOrdersList from "../../../components/Shop/Order";

const ShopOrderPage = () => {
  return (
    <MainLayout>
      <ShopOrdersList />
    </MainLayout>
  );
};

export default ShopOrderPage;
