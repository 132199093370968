import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Skeleton,
  Tabs,
} from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { responseNotification } from "../../../utils/notify";
import { getPage, getParamValue } from "../../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddPickupZone from "./AddPickupZone";
import moment from "moment";
import React from "react";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import { AddBtn, Loader, Pagination } from "../../common";
import _ from "lodash";
import AddRate from "../../common/AddRate";

const ShopPickupZoneList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopId = (route as any)?.shopId;
  const [form] = Form.useForm();
  const loc = useLocation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [selectedZoneForEdit, setSelectedZoneForEdit] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [zoneType, setZoneType] = useState<any>("DOMESTIC");
  const [pickupZoneData, setPickupZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const getZones = useCallback(async () => {
    setPickupZoneData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUri}/admin/pickup-zone/all?shopId=${shopId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPickupZoneData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPickupZoneData({ loading: false, data: [] });
        console.error("Zones: Error", err);
      });
  }, []);

  useEffect(() => {
    getZones();
  }, [getZones]);

  const onClose = () => {
    getZones();
    setVisible(undefined);
    setSelectedZone(undefined);
    setSelectedZoneForEdit(undefined);
    setReArrangeDrawer(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const reseAllFieldData = () => {
    form.resetFields();
    setZoneType("DOMESTIC");
  };

  return (
    <>
      <BreadCrumb
        title="Pickup Zone List"
        subTitle={`${
          pickupZoneData?.data?.totalElements ||
          pickupZoneData?.data?.pickupZones?.length ||
          0
        } Zone(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {pickupZoneData?.loading ? (
                <Loader />
              ) : (
                <>
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Shipping Rate name</th>
                        <th scope="col">packaging Time</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {pickupZoneData?.data?.pickupZones?.length ? (
                        pickupZoneData?.data?.pickupZones?.map(
                          (zone: any, index: any) => (
                            <>
                              <tr>
                                <td>
                                  {zone?.name}
                                </td>
                                <td>{zone?.packagingTime}</td>
                                <td>
                                <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedZoneForEdit(zone)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                                </div>
                                </td>
                              </tr>
                            </>
                          )
                        )
                      ) : (
                        <Empty />
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...pickupZoneData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Pickup Zone"
            : selectedZoneForEdit
            ? "Edit Pickup Zone"
            : "Pickup Zone Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedZone || selectedZoneForEdit}
        width={450}
      >
        {visible || selectedZoneForEdit ? (
          <AddPickupZone
            onCloseMethod={onClose}
            visibleData={selectedZoneForEdit}
          />
        ) : (
          ""
        )}
      </Drawer>
    </>
  );
};

export default ShopPickupZoneList;
