import React, { FC, useState } from "react";
import cn from "classnames";
import s from "./Video.module.css";
import Container from "../../ui/Container";
import VideoImg from "../../../images/video.jpg";
import AppSticker from "../../ui/Apps";
import { ArrowLeft, ArrowRight, PlayBtn } from "../../icons";
import { Modal } from "antd";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const HomeVideo: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);
  const [visible, setVisible] = useState(false);

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="block transition-colors duration-150 text-secondary">
          <div className="block md:flex items-center justify-between">
            <div className="basis-2/5 w-full lg:max-w-3xl mx-auto text-white py-6 uppercase">
              <h2 className="text-2xl lg:text-4xl font-extrabold mb-6 uppercase text-gray-700 max-w-xs">
                Learn about jachai seller sign up
              </h2>
              <p className="text-md lg:text-3xl text-red-600 font-semibold flex items-center gap-3">
                <span>Watch here</span> <ArrowRight height={35} width={35} />
              </p>
            </div>
            <div className="basis-3/5">
              <div className="mt-0 md:-mt-28 relative">
                <img src={VideoImg} className="rounded-lg shadow-md" />
                <div className={s.PlayBtn} onClick={() => setVisible(true)}>
                  <PlayBtn
                    width={150}
                    height={150}
                    className="hover:shadow-lg rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        open={visible}
        width={600}
        footer={false}
        onCancel={() => setVisible(false)}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/PHu_rdlK9Ws"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>
    </section>
  );
};

export default HomeVideo;
