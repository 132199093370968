import MainLayout from "../../../components/Layouts";
import ShopProductDetails from "../../../components/Shop/Product/details";

const ShopProductDetailsPage = () => {
  return (
    <MainLayout>
      <ShopProductDetails />
    </MainLayout>
  );
};

export default ShopProductDetailsPage;
