const Cart = ({ ...props }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.6285 5.77569C32.2301 5.29748 31.7312 4.91288 31.1673 4.64919C30.6035 4.3855 29.9885 4.2492 29.366 4.24995H6.46158L6.40208 3.75271C6.28032 2.71915 5.78355 1.76619 5.00596 1.0745C4.22837 0.382812 3.22401 0.000484202 2.1833 0L1.8688 0C1.49308 0 1.13275 0.149254 0.867076 0.414928C0.601402 0.680601 0.452148 1.04093 0.452148 1.41665C0.452148 1.79237 0.601402 2.1527 0.867076 2.41838C1.13275 2.68405 1.49308 2.8333 1.8688 2.8333H2.1833C2.53028 2.83335 2.86518 2.96074 3.12448 3.19131C3.38378 3.42189 3.54943 3.7396 3.59003 4.08421L5.53934 20.659C5.74171 22.3828 6.56992 23.9723 7.86677 25.1257C9.16362 26.2792 10.8388 26.9164 12.5744 26.9164H27.3685C27.7442 26.9164 28.1046 26.7671 28.3702 26.5015C28.6359 26.2358 28.7852 25.8754 28.7852 25.4997C28.7852 25.124 28.6359 24.7637 28.3702 24.498C28.1046 24.2323 27.7442 24.0831 27.3685 24.0831H12.5744C11.6976 24.0806 10.843 23.807 10.1278 23.2998C9.41257 22.7925 8.87175 22.0765 8.57948 21.2498H25.466C27.1267 21.2499 28.7347 20.6664 30.0089 19.6014C31.2832 18.5364 32.1427 17.0575 32.4373 15.4231L33.5494 9.25498C33.6604 8.643 33.6354 8.01409 33.4763 7.41282C33.3172 6.81156 33.0277 6.25264 32.6285 5.77569ZM30.7685 8.75207L29.655 14.9202C29.4781 15.9019 28.9615 16.7901 28.1957 17.4293C27.4298 18.0685 26.4635 18.418 25.466 18.4165H8.12898L6.79591 7.08326H29.366C29.5741 7.08202 29.7799 7.12664 29.9688 7.21395C30.1577 7.30127 30.3251 7.42913 30.459 7.58845C30.5929 7.74776 30.69 7.93462 30.7435 8.13574C30.797 8.33686 30.8055 8.54729 30.7685 8.75207Z"
        fill="#393E46"
      />
      <path
        d="M10.3685 34.0006C11.9332 34.0006 13.2018 32.7321 13.2018 31.1673C13.2018 29.6025 11.9332 28.334 10.3685 28.334C8.80367 28.334 7.53516 29.6025 7.53516 31.1673C7.53516 32.7321 8.80367 34.0006 10.3685 34.0006Z"
        fill="#393E46"
      />
      <path
        d="M24.5355 34.0006C26.1002 34.0006 27.3688 32.7321 27.3688 31.1673C27.3688 29.6025 26.1002 28.334 24.5355 28.334C22.9707 28.334 21.7021 29.6025 21.7021 31.1673C21.7021 32.7321 22.9707 34.0006 24.5355 34.0006Z"
        fill="#393E46"
      />
    </svg>
  )
}

export default Cart
