const Cube = ({ ...props }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9053 12.7192V6.71916C15.905 6.45612 15.8356 6.19777 15.7039 5.97004C15.5723 5.7423 15.3831 5.55319 15.1553 5.42166L9.90527 2.42166C9.67724 2.29001 9.41858 2.2207 9.15527 2.2207C8.89197 2.2207 8.6333 2.29001 8.40527 2.42166L3.15527 5.42166C2.92747 5.55319 2.73826 5.7423 2.60662 5.97004C2.47498 6.19777 2.40554 6.45612 2.40527 6.71916V12.7192C2.40554 12.9822 2.47498 13.2406 2.60662 13.4683C2.73826 13.696 2.92747 13.8851 3.15527 14.0167L8.40527 17.0167C8.6333 17.1483 8.89197 17.2176 9.15527 17.2176C9.41858 17.2176 9.67724 17.1483 9.90527 17.0167L15.1553 14.0167C15.3831 13.8851 15.5723 13.696 15.7039 13.4683C15.8356 13.2406 15.905 12.9822 15.9053 12.7192Z"
        stroke="#838383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.60779 5.93848L9.15529 9.72598L15.7028 5.93848"
        stroke="#838383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.15527 17.2787V9.71875"
        stroke="#838383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Cube
