import { KeyOutlined, SolutionOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

const MerchantSidebar = (): ReactElement => {
  const pathname = window.location?.pathname;

  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/my-profile"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/my-profile") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/account/my-profile`}>Personal Details</Link>
            </Menu.Item>
            {/* <Menu.Item
              key="/bank-info"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/bank-info") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/merchant/bank-info`}>Bank Details</Link>
            </Menu.Item> */}
            {/* <Menu.Item
              key="/warehouse-info"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/warehouse-info") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/merchant/warehouse-info`}>Warehouse Info</Link>
            </Menu.Item> */}

            {/* <Menu.Item
              key="/key-account-manager"
              icon={<KeyOutlined />}
              className={
                pathname.includes("/key-account-manager")
                  ? "active bg-gray-100"
                  : ""
              }
            >
              <Link to={`/merchant/key-account-manager`}>
                Key Account Manager
              </Link>
            </Menu.Item> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default MerchantSidebar;
