export { default as Bag } from "./Bag";
export { default as Copy } from "./Copy";
export { default as Exclamatory } from "./Exclamatory";
import LoginMobile from "./LoginMobile";
export { default as Heart } from "./Heart";
export { default as Share } from "./Share";
export { default as Trash } from "./Trash";
export { default as Cross } from "./Cross";
export { default as Plus } from "./Plus";
export { default as Minus } from "./Minus";
export { default as Sun } from "./Sun";
export { default as Moon } from "./Moon";
export { default as Github } from "./Github";
export { default as Info } from "./Info";
export { default as Jachai } from "./Jachai";
export { default as MapPin } from "./MapPin";
export { default as Star } from "./Star";
export { default as ArrowLeft } from "./ArrowLeft";
export { default as ArrowRight } from "./ArrowRight";
export { default as Check } from "./Check";
export { default as CheckCircle } from "./CheckCircle";
export { default as CreditCard } from "./CreditCard";
export { default as ChevronUp } from "./ChevronUp";
export { default as ChevronLeft } from "./ChevronLeft";
export { default as ChevronDown } from "./ChevronDown";
export { default as ChevronRight } from "./ChevronRight";
export { default as DoubleChevron } from "./DoubleChevron";
export { default as Calling } from "./Calling";
export { default as Envelope } from "./Envelope";
export { default as Phone } from "./Phone";
export { default as Watch } from "./Watch";
export { default as HappyFace } from "./HappyFace";
export { default as Power } from "./Power";
export { default as Marker } from "./Marker";
export { default as Magnifier } from "./Magnifier";
export { default as Currency } from "./Currency";
export { default as Edit } from "./Edit";
export { default as ChevronRightDouble } from "./ChevronRightDouble";
export { default as Filter } from "./Filter";
export { default as Home } from "./Home";
export { default as Location } from "./Location";
export { default as LoginMobile } from "./LoginMobile";
export { default as Otp } from "./Otp";
export { default as Facebook } from "./Facebook";
export { default as Instagram } from "./Instagram";
export { default as Twitter } from "./Twitter";
export { default as Linkedin } from "./Linkedin";
export { default as FlashSale } from "./FlashSale";
export { default as Follow } from "./Follow";
export { default as Cube } from "./Cube";
export { default as Cubes } from "./Cubes";
export { default as UserCheck } from "./UserCheck";
export { default as Cart } from "./Cart";
export { default as CartVan } from "./CartVan";
export { default as Message } from "./Message";
export { default as Redirect } from "./Redirect";
export { default as User } from "./User";
export { default as PlayBtn } from "./Play";
export { default as Merchant } from "./Merchant";
export { default as NewUser } from "./NewUser";
export { default as Rider } from "./Rider";
export { default as HistoryIcon } from "./History";
