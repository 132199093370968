import { Button, Checkbox, Form, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HomeLayout from "../components/Landing/Layouts";
import HomeBreadCrumb from "../components/Landing/Layouts/Breadcrumb";
import Container from "../components/ui/Container";
import { responseNotification } from "../utils/notify";
import { Loader } from "../components/common";
import { FieldValues } from "react-hook-form";
import { useForm } from "antd/lib/form/Form";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../redux/auth/authType";
import JC from "../images/jc.png";
import Flight from "./../images/flight.svg";

const SignUpPage = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [serviceType, setServiceType] = useState();
  const [merchantType, setMerchantType] = useState<string>();
  const [typeList, setTypeList] = useState<any>({
    loading: false,
    data: null,
  });

  const [searchParams] = useSearchParams();
  
  const mobileNumber = searchParams?.get("mobileNumber");
  const password = searchParams?.get("password");
  const referrerCode = searchParams?.get("referrerCode");

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);

    const readyData = data && {
      mobileNumber: `+88${mobileNumber?.split("+88")}`,
      password: password,
      serviceType: serviceType,
      merchantType: merchantType?.replace(" ", "_")?.toUpperCase(),
      referrerCode: referrerCode,
    };

    try {
      fetch(`${process.env.REACT_APP_RIDER_API}/merchant/registration/v3`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      })
        .then((data) => data.json())
        .then(async (data) => {
          setLoading(false);
          navigate("/");
          const { token } = data;

          if (token) {
            const res2 = await fetch(
              `${process.env.REACT_APP_RIDER_API}/details`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const merchantInfo = await res2.json();
            const { merchant } = merchantInfo;

            if (merchant) {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                  ...data,
                  token: data?.token,
                  merchantInfo: merchant,
                  merchantId: merchant?.merchantId,
                  iat: new Date(),
                  type: merchant?.serviceType || "JC_COMMERCE",
                },
              });

              responseNotification(
                merchantInfo?.message?.replace("Operation", "Registration"),
                "success"
              );

              return true;
            } else {
              responseNotification(
                merchantInfo?.message?.replace(
                  "Operation Successful",
                  "No data found"
                ),
                "error"
              );

              return false;
            }
          } else {
            responseNotification(data?.message || "No data found", "error");
            return false;
          }
        });
    } catch (e) {
      setLoading(false);
      responseNotification(e?.message || "Registration failed", "error");
    }
  };

  useEffect(() => {
    if (serviceType !== "JC_COMMERCE") {
      onSubmit;
    }
  }, [serviceType]);

  useEffect(() => {
    try {
      setTypeList({ loading: true, data: null });
      fetch(`${process.env.REACT_APP_RIDER_API}/merchant/type`)
        .then((data) => data.json())
        .then((data) => {
          if (data) {
            setTypeList({ loading: false, data: data });
          } else {
            responseNotification(data?.message || "Operation failed", "error");
            setTypeList({ loading: false, data: null });
          }
        });
    } catch (e) {
      responseNotification(e);
      setTypeList({ loading: false, data: null });
    }
  }, []);

  return (
    <HomeLayout>
      <>
        <HomeBreadCrumb />
        {serviceType !== "JC_COMMERCE" ? (
          <div className="-mt-28 flex items-center justify-center pb-40">
            <div className="max-w-md bg-white p-6 mx-4 rounded-xl">
              {/* <SignUp setServiceType={setServiceType} /> */}

              <div className="xl:max-w-screen-xs">
                <div className="">
                  <img src={Flight} />
                  <div className="text-center flex flex-col">
                    <span className="text-lg lg:text-2xl text-gray-900 font-display font-semibold">
                      Create your shop with Jachai and become a partner today!
                    </span>
                    <span>Enjoy flexibility and a dedicated support team.</span>
                  </div>
                  <div className="my-10">
                    <Form layout="vertical" form={form}>
                      <Form.Item label="Business Type">
                        <Select
                          size="large"
                          placeholder="Select your business type"
                          onChange={(val) => setServiceType(val)}
                          options={[
                            {
                              label: "ECOMMERCE",
                              value: "JC_COMMERCE",
                            },
                            {
                              label: "MART",
                              value: "JC_MART",
                            },
                            {
                              label: "GROCERY",
                              value: "JC_GROCERY",
                            },
                            {
                              label: "MALL",
                              value: "JC_MALL",
                            },
                            {
                              label: "GLOBAL",
                              value: "JC_GLOBAL",
                            },
                            {
                              label: "LANDING",
                              value: "JC_LANDING",
                            },
                            {
                              label: "FASHION",
                              value: "JC_FASHION",
                            },
                            {
                              label: "MEDICINE",
                              value: "JC_MEDICINE",
                            },
                            {
                              label: "PARCEL",
                              value: "JC_PARCEL",
                            },
                            {
                              label: "FOOD",
                              value: "JC_FOOD",
                            },
                            {
                              label: "BOOKING",
                              value: "JC_BOOKING",
                            },
                            {
                              label: "PAY",
                              value: "JC_PAY",
                            },
                          ]}
                        />
                      </Form.Item>

                      <div className="mt-6">
                        <Button
                          // loading={loading}
                          disabled={loading}
                          className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-700 hover:text-white
                                shadow-lg border-none rounded-md p-4 text-lg uppercase w-full h-16"
                          style={{ background: "#CD113B" }}
                          onClick={serviceType !== "JC_COMMERCE" && onSubmit}
                        >
                          {serviceType == "JC_COMMERCE" ? "NEXT" : "REGISTER"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Container fluid={false}>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-7 md:gap-7 py-20">
              {typeList?.loading ? (
                <Loader />
              ) : (
                typeList?.data?.merchantTypes?.map((type: any, i: string) => (
                  <div
                    className="max-w-md bg-white rounded-md shadow-md hover:shadow-2xl grid content-between"
                    key={i}
                  >
                    <div className="header px-2 py-2 flex items-center justify-between shadow-md rounded-b-md">
                      <div className="flex items-center gap-2">
                        <div>
                          <img
                            src={type?.icon || JC}
                            alt=""
                            className="rounded-full shadow h-12 w-12"
                          />
                        </div>
                        <span className="font-bold text-lg">{type?.name}</span>
                      </div>
                      {/* <Link to="">See More</Link> */}
                    </div>

                    <div className="body p-4">
                      <p>{type?.description}</p>

                      {/* <ul className="my-4">
                      <li>You are a brand owner or authorized distributor</li>
                      <li>Exclusive access to dedicated DarazMall campaigns</li>
                      <li>Benefit from higher overall search ranking</li>
                      <li>Access to thousands of customers</li>
                      <li>Pay commission only when you sell</li>
                    </ul> */}
                    </div>

                    <div className="footer">
                      <div className="p-4">
                        <Checkbox
                          className="mr-2"
                          onChange={(val) => setMerchantType(val.target?.value)}
                          value={type?.name?.replace(" ", "_")?.toUpperCase()}
                          checked={
                            type?.name?.replace(" ", "_")?.toUpperCase() ==
                            merchantType
                          }
                        >
                          <span className="text-sm">
                            I agree with the{" "}
                            <Link to="" className="text-red-600">
                              terms and conditions
                            </Link>
                          </span>
                        </Checkbox>
                      </div>
                      <div className="">
                        <Button
                          className="block bg-red-600 hover:bg-red-500  font-bold rounded-b-md text-white hover:text-white border-none w-full h-14"
                          disabled={
                            merchantType !==
                            type?.name?.replace(" ", "_")?.toUpperCase()
                          }
                          onClick={onSubmit}
                        >
                          Register as {type?.name}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Container>
        )}
      </>
    </HomeLayout>
  );
};

export default SignUpPage;
