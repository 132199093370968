import React from "react";
import MainLayout from "../../components/Layouts";
import ReferralsList from "../../components/Referrals";

const ReferralsPage = () => {
  return (
    <MainLayout>
      <ReferralsList />
    </MainLayout>
  );
};

export default ReferralsPage;
