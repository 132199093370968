const UserCheck = ({ ...props }) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0781 19.6934V17.6934C16.0781 16.6325 15.6567 15.6151 14.9066 14.8649C14.1564 14.1148 13.139 13.6934 12.0781 13.6934H5.07812C4.01726 13.6934 2.99984 14.1148 2.2497 14.8649C1.49955 15.6151 1.07813 16.6325 1.07812 17.6934V19.6934"
        stroke="#3A30CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57812 9.69336C10.7873 9.69336 12.5781 7.9025 12.5781 5.69336C12.5781 3.48422 10.7873 1.69336 8.57812 1.69336C6.36899 1.69336 4.57812 3.48422 4.57812 5.69336C4.57812 7.9025 6.36899 9.69336 8.57812 9.69336Z"
        stroke="#3A30CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0781 9.69336L19.0781 11.6934L23.0781 7.69336"
        stroke="#3A30CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserCheck
