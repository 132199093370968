import React from "react";
import MainLayout from "../../../../components/Layouts";
import CampaignList from "../../../../components/Shop/Promotions/Campaign";

const CampaignPage = () => {
  return (
    <MainLayout>
      <CampaignList />
    </MainLayout>
  );
};

export default CampaignPage;
