import { Button, Card, Form, Input, Select, Space, Switch } from "antd";
import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import styles from "../../../styles/tailwind/Add.module.css";
import { useParams } from "react-router";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { jcDayArray } from "../../../utils";
import ShopLayout from "../Layout";

const AddPickupZone = ({ visibleData, onCloseMethod }: any) => {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopID = shopInfo?.id;
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [zoneData, setZoneData] = useState<any>(undefined);
  const [deliveryZoneId, setDeliveryZoneId] = useState<any>("");
  const [countryId, setCountryId] = useState<any>(0);
  const [stateId, setStateId] = useState<any>(0);
  const [cityId, setCityId] = useState<any>(0);
  const [businessDay, setBusinessDay] = useState();
  const [pickupZoneType, setPickupZoneType] = useState("");
  const [zoneOptions, setZoneOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${countryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [countryId]
  );

  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${stateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [stateId]
  );

  const getZoneOptions = useCallback(async (key?: string) => {
    setZoneOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    const res = await axios.get(`${encodedUri}/delivery-zone/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setZoneOptions({
      loading: false,
      list: res?.data?.deliveryZones,
    });

    setZoneOptions({
      loading: false,
      list: res?.data?.deliveryZones?.map((zone: any) => {
        return {
          value: zone?.id,
          label: zone?.name,
        };
      }),
    });
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      pickupZoneType: pickupZoneType,
      packagingTime: data.packagingTime,
      countryId: countryId,
      stateId: stateId,
      cityId: cityId,
      address: data.address,
      shopId: shopID,
      schedules: data?.businessDayes?.map(
        (scheduleList: any, scheduleListId: string) => ({
          businessDayName: scheduleList.businessDay,
          chargeBySchedules: scheduleList?.list?.map(
            (scheduleConfigList: any, scheduleConfigListId: string) => ({
              charge: scheduleConfigList.scheduleCharge,
              scheduleDeliveryTimeFrom:
                scheduleConfigList.scheduleDeliveryTimeFrom,
              scheduleDeliveryTimeTo: scheduleConfigList.scheduleDeliveryTimeTo,
            })
          ),
        })
      ),
    };

    if (zoneData) {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/pickup-zone`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: zoneData?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Updated Successfully", "success");
            //form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/pickup-zone/add-zone-v2`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Create Successfully", "success");
            //form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setZoneData(visibleData);
      // setDeliveryZoneId(visibleData?.bannerType);
    }
  }, [visibleData]);

  useEffect(() => {
    if (zoneData) {
      form.resetFields(Object.keys(visibleData));
      setPickupZoneType(zoneData?.id);
    }
  }, [zoneData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "zone") getZoneOptions(value);
          if (field === "country") getCountryOptions(value);
          if (field === "state") getStateOptions(value);
          if (field === "city") getCityOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getZoneOptions,
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    type,
  ]);

  // **********************************
  // Search Options
  // **********************************

  useEffect(() => {
    getZoneOptions();
    getCountryOptions();
    getStateOptions();
    getCityOptions();
  }, [getZoneOptions, getCountryOptions, getStateOptions, getCityOptions]);

  return (
    <>
 
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.formStyles}
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...zoneData,
          }}
        >
          <Form.Item
            hasFeedback
            label="Pickup Zone Name"
            rules={[
              {
                required: false,
                message: "Pickup Zone Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Pickup Zone Name" />
          </Form.Item>
          <Form.Item hasFeedback label="Pickup Zone Type" name="pickupZoneType">
            <Select
              options={[
                { value: "SHOP", label: "SHOP" },
                {
                  value: "WAREHOUSE",
                  label: "WAREHOUSE",
                },
                {
                  value: "LOCKER",
                  label: "LOCKER",
                },
              ]}
              placeholder="Select Pickup Zone Type"
              onChange={(val) => setPickupZoneType(val)}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            label="Packaging Time"
            rules={[
              {
                required: false,
                message: "Packaging Time is Required!",
              },
            ]}
            name="packagingTime"
          >
            <Input
              id="packagingTime"
              type="text"
              placeholder="Enter Packaging Time"
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            label="Address"
            rules={[
              {
                required: false,
                message: "Address is Required!",
              },
            ]}
            name="address"
          >
            <Input id="address" type="text" placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            hasFeedback
            label={`Country/Region`}
            rules={[
              {
                required: false,
                message: "Region is required!",
              },
            ]}
            name="countryId"
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Country"
              optionFilterProp="children"
              onChange={(e) => setCountryId(e)}
              onSearch={(val) => {
                handleSearch?.(val, "country");
              }}
              filterOption={() => {
                return true;
              }}
              options={countryOptions?.list}
            ></Select>
          </Form.Item>
          <Form.Item
            hasFeedback
            label={`State/Province`}
            rules={[
              {
                required: false,
                message: "State is required!",
              },
            ]}
            name="stateId"
          >
            <Select
              allowClear
              showSearch
              placeholder="Select State"
              optionFilterProp="children"
              onChange={(val) => setStateId(val)}
              onSearch={(val) => {
                handleSearch?.(val, "state");
              }}
              filterOption={() => {
                return true;
              }}
              options={stateOptions?.list}
            ></Select>
          </Form.Item>
          <Form.Item
            hasFeedback
            label={`City`}
            rules={[
              {
                required: false,
                message: "City is required!",
              },
            ]}
            name="cityId"
          >
            <Select
              allowClear
              showSearch
              placeholder="Select City"
              optionFilterProp="children"
              onChange={(val) => setCityId(val)}
              onSearch={(val) => {
                handleSearch?.(val, "city");
              }}
              filterOption={() => {
                return true;
              }}
              options={cityOptions?.list}
            ></Select>
          </Form.Item>

          <div className="float-left w-full">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
              <h3 className="mb-4 text-xl font-bold">Based on Schedule</h3>

              {/* new schedule add  */}
              <Form.List name="businessDayes">
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: "flex",
                      rowGap: 16,
                      flexDirection: "column",
                    }}
                  >
                    {fields.map((field) => (
                      <Card
                        size="small"
                        title={`Schedule ${field.name + 1}`}
                        key={field.key}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Business Day"
                          name={[field.name, "businessDay"]}
                        >
                          <Select
                            placeholder="Day"
                            onChange={(val) => setBusinessDay(val)}
                            value={businessDay}
                            style={{ width: 250 }}
                          >
                            {Object.values(jcDayArray)?.map(
                              (dayType, dayIndex) => (
                                <Option value={dayType} key={dayIndex}>
                                  {dayType.replace("_", " ")}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>

                        {/* Nest Form.List */}
                        <Form.Item label="Based on Schedule Configuration">
                          <Form.List name={[field.name, "list"]}>
                            {(subFields, subOpt) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: 16,
                                }}
                              >
                                {subFields.map((subField) => (
                                  <Space key={subField.key}>
                                    <Form.Item
                                      hasFeedback
                                      label="Schedule Delivery Time( From)"
                                      noStyle
                                      name={[
                                        subField.name,
                                        "scheduleDeliveryTimeFrom",
                                      ]}
                                    >
                                      <Input placeholder="From Time (12:10)" />
                                    </Form.Item>
                                    <Form.Item
                                      hasFeedback
                                      label="Schedule Delivery Time (to)"
                                      noStyle
                                      name={[
                                        subField.name,
                                        "scheduleDeliveryTimeTo",
                                      ]}
                                    >
                                      <Input placeholder="To Time (16:10)" />
                                    </Form.Item>
                                    <Form.Item
                                      hasFeedback
                                      label="Charge"
                                      noStyle
                                      name={[subField.name, "scheduleCharge"]}
                                    >
                                      <Input placeholder="Charge" />
                                    </Form.Item>
                                    <CloseOutlined
                                      onClick={() => {
                                        subOpt.remove(subField.name);
                                      }}
                                    />
                                  </Space>
                                ))}
                                <Button
                                  type="dashed"
                                  onClick={() => subOpt.add()}
                                  block
                                >
                                  + Add Schedule
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      </Card>
                    ))}

                    <Button type="dashed" onClick={() => add()} block>
                      + Add Business Day
                    </Button>
                  </div>
                )}
              </Form.List>
              {/* new schedule end */}
            </div>
          </div>

          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>

            <Button
              type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      
    </>
  );
};

export default AddPickupZone;
