import React, { FC } from "react";
import cn from "classnames";
import s from "./Apps.module.css";
import Container from "../../ui/Container";
import DownloadImg from "../../../images/apps.png";
import AppSticker from "../../ui/Apps";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const HomeApps: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="block transition-colors duration-150 text-secondary">
          <div className="block md:flex items-start">
            <div className="basis-1/2 w-full lg:max-w-3xl mx-auto text-white py-6">
              <span className="uppercase">GO MOBILE</span>
              <h2 className="text-2xl lg:text-4xl font-bold mb-6 uppercase text-white">
                Use the free Jachai Seller App
              </h2>
              <p className="text-md lg:text-lg">
                The Jachai Seller app is packed with features to help you manage
                and grow your ecommerce business wherever you are. It gives you
                the freedom to take care of business details right from your
                phone.
              </p>
              <div className="basis-1/2 flex gap-8 mt-6">
                <a
                  href={process.env.REACT_APP_ANDROID_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppSticker type="playstore" />
                </a>

                {/* <AppSticker type="appstore" /> */}
              </div>
            </div>
            <div className="relatice">
              <img src={DownloadImg} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeApps;
