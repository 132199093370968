const HappyFace = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}
    >
      <path
        d="M9 16.512C13.1421 16.512 16.5 13.1541 16.5 9.01196C16.5 4.86983 13.1421 1.51196 9 1.51196C4.85786 1.51196 1.5 4.86983 1.5 9.01196C1.5 13.1541 4.85786 16.512 9 16.512Z"
        stroke="#393E46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.512C6 10.512 7.125 12.012 9 12.012C10.875 12.012 12 10.512 12 10.512"
        stroke="#393E46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 6.76196H6.75833"
        stroke="#393E46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 6.76196H11.2583"
        stroke="#393E46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HappyFace
