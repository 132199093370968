const Linkedin = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9855 10.8986V17.3469H14.247V11.3308C14.247 9.82022 13.7073 8.78863 12.3536 8.78863C11.3207 8.78863 10.707 9.48314 10.436 10.1556C10.3376 10.396 10.3122 10.7297 10.3122 11.0668V17.3466H6.57343C6.57343 17.3466 6.62362 7.15734 6.57343 6.10261H10.3125V7.69599C10.305 7.70854 10.2944 7.72081 10.2877 7.73279H10.3125V7.69599C10.8093 6.9315 11.6954 5.83858 13.6819 5.83858C16.1415 5.83858 17.9855 7.44562 17.9855 10.8986ZM2.66234 0.682617C1.38345 0.682617 0.546753 1.5221 0.546753 2.62506C0.546753 3.7046 1.35919 4.56834 2.61327 4.56834H2.63752C3.9415 4.56834 4.75227 3.7046 4.75227 2.62506C4.72745 1.5221 3.9415 0.682617 2.66234 0.682617ZM0.768961 17.3469H4.50636V6.10261H0.768961V17.3469Z"
        fill="#393E46"
      />
    </svg>
  )
}

export default Linkedin
