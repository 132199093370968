const Instagram = ({ ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="13" fill="#393E46" />
      <path
        d="M20.3027 8.32251H10.9792C9.49102 8.32251 8.28027 9.53325 8.28027 11.0214V20.3449C8.28027 21.8331 9.49102 23.0442 10.9792 23.0442H20.3027C21.7908 23.0442 23.0019 21.8334 23.0019 20.3449V11.0214C23.0019 9.53325 21.7912 8.32251 20.3027 8.32251ZM15.6409 20.5296C12.9689 20.5296 10.7948 18.3556 10.7948 15.6832C10.7948 13.0111 12.9689 10.8371 15.6409 10.8371C18.3133 10.8371 20.4874 13.0111 20.4874 15.6832C20.4874 18.3552 18.313 20.5296 15.6409 20.5296ZM20.6432 11.839C20.0106 11.839 19.4962 11.3246 19.4962 10.6924C19.4962 10.0602 20.0106 9.5458 20.6432 9.5458C21.2754 9.5458 21.7898 10.0602 21.7898 10.6924C21.7898 11.3246 21.2754 11.839 20.6432 11.839Z"
        fill="white"
      />
      <path
        d="M15.6411 12.885C14.0985 12.885 12.8425 14.1404 12.8425 15.6829C12.8425 17.2261 14.0985 18.4818 15.6411 18.4818C17.1843 18.4818 18.4393 17.2261 18.4393 15.6829C18.4393 14.1407 17.1839 12.885 15.6411 12.885Z"
        fill="white"
      />
      <path
        d="M15.641 0C7.00308 0 0 7.00308 0 15.641C0 24.279 7.00308 31.2821 15.641 31.2821C24.279 31.2821 31.2821 24.279 31.2821 15.641C31.2821 7.00308 24.279 0 15.641 0ZM25.0496 20.345C25.0496 22.9627 22.9205 25.0917 20.3028 25.0917H10.9793C8.36194 25.0917 6.23251 22.9627 6.23251 20.345V11.0215C6.23251 8.40411 8.36194 6.27468 10.9793 6.27468H20.3028C22.9205 6.27468 25.0496 8.40411 25.0496 11.0215V20.345Z"
        fill="white"
      />
    </svg>
  )
}

export default Instagram
