import { Url } from "url";
import { PropsWithChildren } from "react";

export interface AppsStickerProps extends PropsWithChildren {
  className?: string;
  width?: string | number;
  height?: string | number;
  style?: object;
  type: "playstore" | "appstore";
}

const AppSticker: React.FC<Omit<AppsStickerProps, "href">> = ({ ...props }) => {
  const { className, width = 180, height = 60, style = {}, ...rest } = props;

  return (
    <img
      width={width}
      height={height}
      src={rest.type === "playstore" ? "/play-store.svg" : "/app-store.svg"}
      alt={rest.type}
      className={className}
    />
  );
};

export default AppSticker;
