import React, { FC, useState } from "react";
import cn from "classnames";
import s from "./Hero.module.css";
import { Link } from "react-router-dom";
import Container from "../../ui/Container";
import Login from "../../Auth";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const HomeHero: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);
  const [formType, setFormType] = useState<boolean>(false);

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="block lg:flex justify-between items-center  gap-8 py-12 transition-colors duration-150 text-secondary">
          <div className="basis-2/3 text-white">
            <div className="w-full lg:max-w-lg">
              <h2 className="text-white text-2xl lg:text-5xl md:text-4xl font-bold mb-6">
                Dedicated to Make Small Business Thrive
              </h2>
              <p className="text-md lg:text-lg leading-normal">
                Jachai.com simplifies the lives of small business owners with
                its all-in-one e-commerce platform that's tailored to meet every
                business's unique merchant service needs.
              </p>
              <div className="flex gap-4 lg:gap-6 my-8">
                <Link
                  to={`/login`}
                  className="bg-red-600 border border-white text-white text-center text-lg p-2 px-8 font-bold rounded-full uppercase w-60 h-10"
                >
                  Login
                </Link>
                <Link
                  to={`/signup`}
                  className="bg-black border border-white text-white text-center text-lg p-2 px-8 font-bold rounded-full uppercase w-60 h-10"
                  onClick={() => setFormType(true)}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
          <div className="basis-1/3">
            <div className="bg-white rounded-xl p-8" id="login">
              <Login formType={formType} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeHero;
