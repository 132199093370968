import MainLayout from "../../../components/Layouts";
import ShopOrderDetails from "../../../components/Shop/Order/details";

const ShopOrderDetailsPage = () => {
  return (
    <MainLayout>
      <ShopOrderDetails />
    </MainLayout>
  );
};

export default ShopOrderDetailsPage;
