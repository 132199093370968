import { Alert, Button, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import styles from "../../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn } from "../../common";
import axios from "axios";
import { debounce } from "lodash";
import { jcTypeArray } from "../../../utils";
import { useParams } from "react-router";

const AddUser = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const route = useParams();
  const shopId = (route as any)?.shopId;
  console.log("get shop id :::", (route as any)?.shopId);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [userData, setUserData] = useState<any>(undefined);
  const [bannerType, setBannerType] = useState<any>(null);
  const [serviceType, setServiceType] = useState<any>(null);
  const [shopSlug, setShopSlug] = useState<any>();
  const [typeId, setTypeId] = useState<any>();
  const [countryId, setCountryId] = useState<any>();

  const [key, setKey] = useState("");
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      fullName: data.fullName,
      countryCode: countryId,
      mobileNumber: data.mobileNumber,
      password: data.password,
      shopId: shopId,
    };
    await fetch(
      `${process.env.REACT_APP_AUTH_API}/merchant/user/register-by-merchant`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("User Create Successfully", "success");
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });
    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.iso2,
          label: country?.name,
        };
      }),
    });
  }, []);


  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions]);

  useEffect(() => {
    getCountryOptions();
  }, [getCountryOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...userData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Full Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="fullName"
        >
          <Input id="fullName" type="text" placeholder="Enter User Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Mobile Number"
          rules={[
            {
              required: false,
              message: "Mobile Number is Required!",
            },
          ]}
          name="mobileNumber"
        >
          <Input
            type="number"
            id="mobileNumber"
            min={0}
            placeholder="Enter Mobile Number"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`Country/Region`}
          rules={[
            {
              required: false,
              message: "Region is required!",
            },
          ]}
          name="businessCountry"
          initialValue={countryOptions}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={(e) => setCountryId(e)}
            onSearch={(val) => {
              handleSearch?.(val, "country");
            }}
            filterOption={() => {
              return true;
            }}
            options={countryOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Password"
          rules={[
            {
              required: true,
              message: "Password is Required!",
            },
          ]}
          name="password"
        >
          <Input id="password" type="password" placeholder="Enter Password" />
        </Form.Item>
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddUser;
