import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ShopLayout from "../Layout";
import RequestrdProductList from "../../RequestedProduct/index";
import { useSelector } from "react-redux";
import { Button } from "antd"; 
import { useNavigate } from "react-router";
import { UploadOutlined } from "@ant-design/icons";
 
const ShopRequestedProductList = (): ReactElement => {
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ShopLayout
        shopId={shopInfo.id}
        title="Requested Product List"
        subTitle="Details"
        extra={
          <>
          </>
        }
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <RequestrdProductList inShop={true} getShopId={shopInfo.id} />
          </div>
        </div>
      </ShopLayout>
    </React.Fragment> 
  );
};

ShopRequestedProductList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopRequestedProductList;
