import React from "react";
import MainLayout from "../../../../components/Layouts";
import PromoCodeList from "../../../../components/Shop/Promotions/PromoCode";

const PromoCodePage = () => {
  return (
    <MainLayout>
      <PromoCodeList />
    </MainLayout>
  );
};

export default PromoCodePage;
