import React from "react";
import MainLayout from "../../components/Layouts";
import MembershipPaymentCheckout from "../../components/MemberShip/MembershipPaymentCheckout";


const MembershipPaymentCheckoutPage = () => {
  return (
    <MainLayout>
      <MembershipPaymentCheckout />
    </MainLayout>
  );
}; 

export default MembershipPaymentCheckoutPage;
