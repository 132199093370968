import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopBanksList from "../../../components/Shop/Banks";

const ShopBanksPage = () => {
  return (
    <MainLayout>
      <ShopBanksList />
    </MainLayout>
  );
};

export default ShopBanksPage;
