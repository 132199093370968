import React from "react";
import MainLayout from "../../../components/Layouts";
import CampaignProductList from "../../../components/Promotions/Campaign/Product";

const CampaignProductPage = () => {
  return (
    <MainLayout>
      <CampaignProductList />
    </MainLayout>
  );
};

export default CampaignProductPage;
