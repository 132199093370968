import MainLayout from "../../components/Layouts";
import React from "react";
import MerchantBankDetails from "../../components/Profile/Bankdetails";

const BankDetailsPage = () => {
  return (
    <MainLayout>
      <MerchantBankDetails />
    </MainLayout>
  );
};

export default BankDetailsPage;
