// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Product_productTitle__6HXej{
    width: 75% !important;
    float: left;
    margin-right: 10px;
}
.Product_productTitle2__ImoHR{
    width: 25% !important;
    float: left;
    margin-right: 10px;
}
.Product_productTitle3__lYwrK{
    width:90% !important;
    float: left;
    margin-right: 10px;
}
.Product_translateButton__J6\\+Gm{
    width: 10% !important;
    float: left;
    margin-left: 5px;
}
.Product_removeButton__YYjyI{
    width: 10% !important;
    float: left;
    margin-left: 5px;
    color: red;
    font-weight: bold;
    font-size: 30px;
}

.Product_addButton__fHR7e{
    width: 99% !important;
    text-align: center;
    float: none;
    margin: 0 auto;
}
.Product_returnRefundTrams__lsZb2{
    width: 100% !important;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/Product.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,oBAAoB;IACpB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,cAAc;AAClB;AACA;IACI,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".productTitle{\n    width: 75% !important;\n    float: left;\n    margin-right: 10px;\n}\n.productTitle2{\n    width: 25% !important;\n    float: left;\n    margin-right: 10px;\n}\n.productTitle3{\n    width:90% !important;\n    float: left;\n    margin-right: 10px;\n}\n.translateButton{\n    width: 10% !important;\n    float: left;\n    margin-left: 5px;\n}\n.removeButton{\n    width: 10% !important;\n    float: left;\n    margin-left: 5px;\n    color: red;\n    font-weight: bold;\n    font-size: 30px;\n}\n\n.addButton{\n    width: 99% !important;\n    text-align: center;\n    float: none;\n    margin: 0 auto;\n}\n.returnRefundTrams{\n    width: 100% !important;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productTitle": `Product_productTitle__6HXej`,
	"productTitle2": `Product_productTitle2__ImoHR`,
	"productTitle3": `Product_productTitle3__lYwrK`,
	"translateButton": `Product_translateButton__J6+Gm`,
	"removeButton": `Product_removeButton__YYjyI`,
	"addButton": `Product_addButton__fHR7e`,
	"returnRefundTrams": `Product_returnRefundTrams__lsZb2`
};
export default ___CSS_LOADER_EXPORT___;
