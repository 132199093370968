import { SolutionOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const CampaignSidebar = ({ campaignId }: any): ReactElement => {
  const pathname = window.location?.pathname;
  const { token, type, shopInfo, merchantInfo, merchantId } =
  useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const shopId = (route as any)?.shopId;
  
  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 h-full">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/details"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/shops/${shopId}/campaign/${campaignId}/details`}>
                Campaign Details
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/campaign-products"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/campaign-products") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/shops/${shopId}/campaign/${campaignId}/campaign-products`}>
              {type === "JC_BOOKING" ? (
                <>
                My Tricket
                </>
              ):(
                <>
                My Products
                </>
              )}
                
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/assign-products"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/assign-products") ? "active bg-gray-100" : ""
              }
            >
              
              <Link to={`/shops/${shopId}/campaign/${campaignId}/assign-products`}>
              {type === "JC_BOOKING" ? (
                <>
                Assign Tricket
                </>
              ):(
                <>
                Assign Products
                </>
              )}
                 
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default CampaignSidebar;
