const Redirect = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5997 13.1632H1.71083V2.27431H7.15527V0.71875H1.71083C0.847496 0.71875 0.155273 1.41875 0.155273 2.27431V13.1632C0.155273 14.0188 0.847496 14.7188 1.71083 14.7188H12.5997C13.4553 14.7188 14.1553 14.0188 14.1553 13.1632V7.71875H12.5997V13.1632ZM8.71083 0.71875V2.27431H11.5031L3.8575 9.91986L4.95416 11.0165L12.5997 3.37097V6.16319H14.1553V0.71875H8.71083Z"
        fill="#838383"
      />
    </svg>
  )
}

export default Redirect
