import React, { FC } from "react";
import cn from "classnames";
import s from "./Faq.module.css";
import { Collapse } from "antd";
import Container from "../../ui/Container";
const { Panel } = Collapse;

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}
const arrayData = [
  {
    question: "What categories can I sell on Jachai?",
    answere:
      "Jachai has over 30 main categories of goods that you can sell your products in, including fashion, electronics, FMCG, lifestyle, health & beauty to name a few. However, we do have items that are counterfeit, dangerous and prohibited by law or cultural norms which sellers must refrain from listing. Click here to learn more",
  },
  {
    question: "What is Jachai Commission?",
    answere:
      "Jachai has over 30 main categories of goods that you can sell your products in, including fashion, electronics, FMCG, lifestyle, health & beauty to name a few. However, we do have items that are counterfeit, dangerous and prohibited by law or cultural norms which sellers must refrain from listing. Click here to learn more",
  },
  {
    question: "What documents do I need to provide during signup?",
    answere:
      "Jachai has over 30 main categories of goods that you can sell your products in, including fashion, electronics, FMCG, lifestyle, health & beauty to name a few. However, we do have items that are counterfeit, dangerous and prohibited by law or cultural norms which sellers must refrain from listing. Click here to learn more",
  },
  {
    question: "What if incorrect information is submitted during signup?",
    answere:
      "Jachai has over 30 main categories of goods that you can sell your products in, including fashion, electronics, FMCG, lifestyle, health & beauty to name a few. However, we do have items that are counterfeit, dangerous and prohibited by law or cultural norms which sellers must refrain from listing. Click here to learn more",
  },
];

const HomeFaq: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="flex justify-end gap-8 py-12 transition-colors duration-150 ">
          <div className="col-span-1 lg:col-span-5">
            <div className="flex flex-col">
              <div className="lg:max-w-xl mx-auto max-w-md">
                <h2 className="text-red-600 text-2xl lg:text-4xl font-bold mb-4 uppercase">
                  FAQ
                </h2>
                <p className="text-md lg:text-lg text-gray-500">
                  Jachai.com simplifies the lives of small business owners with
                  its all-in-one e-commerce platform that's tailored to meet
                  every business's unique merchant service needs.
                </p>
                <div className="mt-8 min-h-60 h-72" id="faq">
                  <Collapse defaultActiveKey={["0"]} ghost accordion>
                    {arrayData?.map((data: any, i) => (
                      <Panel header={data?.question} key={i}>
                        <p>{data?.answere}</p>
                      </Panel>
                    ))}
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeFaq;
