import cn from "classnames";
import React, { Component, ReactElement } from "react";
import { Avatar } from "antd";
import { HistoryIcon } from "../../../icons";

const PointBalance = ({
  balanceData,
  dissaled,
  children,
  extra,
}: {
  balanceData: any;
  dissaled?: boolean;
  children?: ReactElement | Component | any;
  extra?: ReactElement | any;
}) => {
  return (
    <div className="block">
      <div
        className={cn(`shadow hover:shadow-md rounded-xl bg-white min-h-full`)}
      >
        <div
          className={cn(`w-full text-center rounded-xl bg-gray-300`, !dissaled)}
          style={{
            borderColor: dissaled ? "#6D6E70" : "#EAA92A",
            background: dissaled ? "#cdcfd3" : "#F4ECCE",
          }}
        >
          <div
            className="border-b p-4 pt-6 flex gap-3 place-content-center items-start"
            style={{ borderColor: dissaled ? "#CDCFD3" : "#EAA92A" }}
          >
            <Avatar
              src={balanceData?.icon || "/wallet.png"}
              // height={24}
              // width={24}
              alt={balanceData?.title}
            />
            <span
              className={cn(
                {
                  ["text-gray-500"]: !!!dissaled,
                },
                `text-gray-600 text-2xl font-extrabold`
              )}
            >
              {balanceData?.title}
            </span>
          </div>
          <div className="p-4 flex flex-col gap-4">
            <span
              className={cn(`text-red-600 font-bold text-2xl`, {
                ["text-gray-600"]: dissaled,
              })}
            >
              {balanceData?.balance}
            </span>
            <div className="w-full flex place-content-center gap-2">
              <span
                className="rounded-full p-3 px-5 border font-semibold flex gap-2"
                style={{
                  borderColor: dissaled ? "#6D6E70" : "#EAA92A",
                  background: dissaled ? "#cdcfd3" : "#F4ECCE",
                }}
              >
                <HistoryIcon color={dissaled ? "#3A494E" : "#EAA92A"} /> History
              </span>
              {extra}
            </div>
          </div>
        </div>
        <div className={cn(`w-full rounded-xl p-4`)}>{children}</div>
      </div>

      {/* <div className="mt-8 text-center">
        <span className="p-3 px-10 border border-red-600 hover:bg-gray-600 rounded-full text-center text-gray-600 hover:text-white font-semibold cursor-pointer">
          Show More
        </span>
      </div> */}
    </div>
  );
};

export default PointBalance;
