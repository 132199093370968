import {
  BarcodeOutlined,
  CopyOutlined,
  EyeOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Rate,
  Select,
  Spin,
  TreeSelectProps,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import QuickUpdate from "../Product/QuickUpdate";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import {
  AddBtn,
  Loader,
  LoaderFull,
  Pagination,
  TreeSelectTwo,
} from "../common"; 

const GlobalProductList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const router = useLocation();
  const { merchantInfo, type, token, shopId, shopInfo } = useSelector(
    (state) => (state as any)?.authReducer
  ); 
  const isResellAllowed = "true";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [key, setKey] = useState("");
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(true);
  const [showSearch, setShowSearch] = useState(true);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barCode, setBarCode] = useState(
    getParamValue(router.search, "barCode")
  );
  // --------------------------------
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  // --------------------------------

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const getProducts = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setProductData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    merchantInfo?.type !== "RESELLER"
      ? axios
          .get(
            `${encodedUrl}/global-product?type=${type}` +
              (isResellAllowed ? `&isResellAllowed=${isResellAllowed}` : ``) +
              (categoryId ? `&categoryId=${categoryId}` : ``) +
              (brandId ? `&brandId=${brandId}` : ``) +
              (barCode ? `&barCode=${barCode}` : ``) +
              (key ? `&key=${key}` : ``) +
              (from ? `&startDate=${from}` : ``) +
              (to ? `&endDate=${to}` : ``) +
              (isActive ? `&isActive=${isActive}` : ``) +
              (page
                ? `&page=${
                    page == productData.data?.currentPageNumber ? 0 : page || 0
                  }`
                : ``) +
              (limit ? `&limit=${limit}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setProductData({ loading: false, data: res.data });

            setCheckedList(
              res.data.products.map((product: any) => product?.barCode)
            );
          })
          .catch((err) => {
            setProductData({ loading: false, data: [] });
            console.error("Type: Error", err);
          })
      : axios
          .get(
            `${encodedUrl}/merchant/product/resell/resell-catalog?type=${type}` +
              (isResellAllowed ? `&isResellAllowed=${isResellAllowed}` : ``) +
              (categoryId ? `&categoryId=${categoryId}` : ``) +
              (brandId ? `&brandId=${brandId}` : ``) +
              (barCode ? `&barCode=${barCode}` : ``) +
              (key ? `&key=${key}` : ``) +
              (from ? `&startDate=${from}` : ``) +
              (to ? `&endDate=${to}` : ``) +
              (isActive ? `&isActive=${isActive}` : ``) +
              (page
                ? `&page=${
                    page == productData.data?.currentPageNumber ? 0 : page || 0
                  }`
                : ``) +
              (limit ? `&limit=${limit}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setProductData({ loading: false, data: res.data });

            setCheckedList(
              res.data.products.map((product: any) => product?.barCode)
            );
          })
          .catch((err) => {
            setProductData({ loading: false, data: [] });
            console.error("Type: Error", err);
          });
  }, [
    type,
    isResellAllowed,
    categoryId,
    brandId,
    barCode,
    range,
    isActive,
    key,
    page,
    limit,
  ]);

  const getProductByBarcodeOptions = useCallback(
    async (bCode: any) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/global-product?` +
          `page=${page || 0}` +
          `&limit=${limit || 16}` +
          // `&isDeleted=${false}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (key ? `&key=${key}` : ``) +
          (type ? `&type=${type}` : ``) +
          (isActive !== "" ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [page, limit, key, type, isActive, status]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/global-product?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.products?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  // ************************
  const onChangeCheckbox = (e: any) => {
    setSelectedProductBarcode((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length &&
        checkedList.length <= selectedProductBarcode.length
    );
    setCheckAll(checkedList.length === selectedProductBarcode.length);
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getBrandsOptions, getProductByBarcodeOptions]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getBrandsOptions("");
      getProductByBarcodeOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (visible) {
      getProductByBarcodeOptions("");
    }
  }, [visible]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setCategoryId("");
    setBrandId("");
    setBarCode("");
    setRange({
      from: null,
      to: null,
    });
    setIsActive(undefined);
  };

  // ----------------------------
  // ----------------------------

  //
  {
    shopInfo?.merchant?.type !== "RESELLER" ? (
      <>
      
        <h1>okkk</h1>
      </>
    ) : (
      <>
        <h1>okkk okkk</h1>
      </>
    );
  }
  const onSubmit = async (data: any,shopInfo:any) => {
    setLoading(true);
      const readyData = data && {
        productIds: selectedProductBarcode,
        shopIds: [getShopId],
      }
      const readyMerchantData = data && {
        barCodes: selectedProductBarcode,
        shopIds: [getShopId],
      }
    shopInfo?.merchant?.type !== "RESELLER" ? (
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/add-from-global-product`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyMerchantData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
  
          if (res.statusCode === 200) {
            responseNotification(
              "Product Cloned Successfully in Selected Shop(s)",
              "success"
            );
            // getShopsOptions();
            info(res?.productUpdateResponseRestList);
  
            setVisible(!visible);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        })
    ):(
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/add-from-product-v2`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
  
          if (res.statusCode === 200) {
            responseNotification(
              "Product Cloned Successfully in Selected Shop(s)",
              "success"
            );
            // getShopsOptions();
            info(res?.productUpdateResponseRestList);
  
            setVisible(!visible);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        })
    );
    
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedProductForEdit(undefined);
  };

  function info(alreadyExistsData: any) {
    Modal.info({
      title: "Clone Info",
      content: (
        <>
          {alreadyExistsData?.map((data, i) => (
            <div key={i} className="mb-3">
              <p className="text-gray-800 font-semibold">
                Added {data?.newAdded} item(s) and already exists{" "}
                {data?.alreadyExists} item(s) in{" "}
                <span className="font-bold text-red-900">{data?.shopName}</span>
              </p>
              <ol className="text-gray-500">
                {data?.alreadyExistsProductName?.map((product, index) => (
                  <li key={index}>
                    {index + 1}. {product}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </>
      ),
      width: 600,
      onOk() {},
      okText: "GOT IT",
      okButtonProps: { danger: true },
    });
  }
  // ----------------------------

  return (
    <>
      <BreadCrumb
        title="Product Catalogue"
        subTitle={`${productData?.data?.totalElements} Catalogue(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          isAllowedService(`Product Catalogue Add`) && (
            <AddBtn
              onClick={() => {
                navigate(`/global-product/create`);
              }}
            />
          ),
          <Button
            type="default"
            shape="round"
            onClick={() => {
              showDrawer();
            }}
            disabled={!selectedProductBarcode.length}
            className="bg-blue-500 text-white"
          >
            <SwapOutlined /> Action
          </Button>,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="barcode_search" initialValue={barCode}>
              <AutoComplete
                onSearch={(e) => handleSearch(e, "productByBarcode")}
                onSelect={(val: any) => setBarCode(val)}
                options={productBarcodeOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productBarcodeOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Bar Code"
                  onSearch={(val) => {
                    setBarCode(val);
                  }}
                  enterButton
                  loading={productBarcodeOptions.loading}
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="category_search">
              <TreeSelectTwo setCategoryId={setCategoryId} />
            </Form.Item>
            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">
                        <div className="checkbox-large">
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={
                              checkAll ||
                              selectedProductBarcode?.length ===
                                checkedList?.length
                            }
                            className="mr-3"
                            disabled={!productData.data?.products?.length}
                          >
                            Check all
                          </Checkbox>
                        </div>
                      </th>
                      <th scope="col">Ratings</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.products?.length ? (
                      productData?.data?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <div className="checkbox-large flex items-center gap-2">
                                {shopInfo?.merchant?.type !== "RESELLER" ? (
                                  <>
                                    <Checkbox
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onChangeCheckbox(product?.barCode);
                                      }}
                                      checked={selectedProductBarcode?.find(
                                        (item: any) => product?.barCode === item
                                      )}
                                      value={product?.barCode}
                                    >
                                      <Avatar
                                        size={45}
                                        src={product?.productImage}
                                        shape="square"
                                      />
                                    </Checkbox>
                                  </>
                                ) : (
                                  <>
                                    <Checkbox
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onChangeCheckbox(product?.id);
                                      }}
                                      checked={selectedProductBarcode?.find(
                                        (item: any) => product?.id === item
                                      )}
                                      value={product?.id}
                                    >
                                      <Avatar
                                        size={45}
                                        src={product?.productImage}
                                        shape="square"
                                      />
                                    </Checkbox>
                                  </>
                                )}

                                <div className="flex flex-col">
                                  <span className="font-medium text-gray-500">
                                    {product?.name?.slice(0, 50)}
                                  </span>
                                  {product?.barCode && (
                                    <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                      <BarcodeOutlined /> {product?.barCode}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>

                            <td>
                              <Rate value={product?.rating || 1} disabled />{" "}
                              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                {product?.numberOfRating || 0}
                              </span>
                            </td>

                            <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white mr-1"
                                  onClick={() =>
                                    navigate(
                                      `/merchant-product-catalogue/${product?.slug}/details`
                                    )
                                  }
                                >
                                  <EyeOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title="Quick Update"
        // placement="right"
        // onClose={onClose}
        onCancel={onClose}
        open={selectedProductForEdit}
        width={650}
        footer={false}
        centered
      >
        <QuickUpdate
          onCloseMethod={onClose}
          visibleData={selectedProductForEdit}
        />
      </Modal>

      <Drawer
        title={"Clone Product"}
        placement="right"
        onClose={onClose}
        open={visible}
        width={400}
      >
        <div className="">
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            onFinish={onSubmit}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item label="Products Barcodes">
              <Input.TextArea
                value={selectedProductBarcode}
                disabled
                rows={8}
              />
            </Form.Item>

            <Button
              type="primary"
              danger
              title="Clone Selected Items"
              disabled={!getShopId || loading}
              size="large"
              htmlType="submit"
              className="mr-3"
              loading={(loading ? "loading" : undefined) as any}
            >
              <CopyOutlined /> Clone to Shop
            </Button>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default GlobalProductList;
