import { Disclosure, Menu, Transition } from "@headlessui/react";
import { logout } from "../../../../utils/auth";

import { Avatar, Form, Input, Layout, Modal } from "antd";
import React, { Fragment, ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../../../../redux/auth/authType";
import { responseNotification } from "../../../../utils/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faKey,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import LogoImg from "../../../../images/logo.png";
import IconImg from "../../../../images/favicon.svg";
import noImage from "../../../../images/noImage.png";
import Container from "../../../ui/Container";
const { Header } = Layout;

const HomeHeader = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const Logout = () => {
    navigate("/");
    logout("/");
    dispatch({
      type: LOGOUT,
      payload: {
        token: null,
      },
    });
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      email: data?.email,
      oldPassword: data?.oldPassword,
      newPassword: data?.newPassword,
    };

    await fetch(`${process.env.REACT_APP_AUTH_API}/admin/password/update`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          form.resetFields();
          setVisible(false);
          responseNotification("Password Updated Successfully", "success");
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Header
      className="site-layout-background opacity-80 bg-red-200 sticky_ fixed min-w-full top-0 z-10"
      style={{
        padding: 0,
      }}
    >
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-white shadow-sm">
          <Container fluid={false}>
            <div className="flex justify-between py-4">
              <Link to="/" className="">
                <img
                  src={false ? IconImg : LogoImg}
                  alt="jachai parcel"
                  className="logoImg"
                />
              </Link>
              {/* <div className="">
                <img
                  src={false ? IconImg : LogoImg}
                  alt="jachai parcel"
                  className="logoImg"
                />
              </div> */}
            </div>
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between h-16">
                    <div className="flex items-center"></div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative">
                          <div>
                            <Menu.Button className="max-w-xs bg-gray-200 rounded-full flex items-center text-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-400 focus:ring-white">
                              <span className="sr-only">Open user menu</span>

                              <span className="px-3 font-medium text-sm">
                                {merchantInfo?.merchantPersonalDetail?.name}
                              </span>


                              {merchantInfo?.profilePicture ? (
                                <img
                                  className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                                  src={
                                    merchantInfo?.merchantPersonalDetail
                                      ?.profilePicture
                                  }
                                  alt=""
                                />
                              ) : (
                                <svg
                                  className="mx-auto w-9 h-9 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                              {!!merchantInfo?.profilePicture && (
                                <div className="px-4 text-center pb-2 border-b z-50">
                                  <Avatar
                                    size={120}
                                    src={merchantInfo?.profilePicture}
                                    alt=""
                                    className="border mb-3"
                                  />
                                  <p className="font-medium text-sm">
                                    {merchantInfo?.fullName}
                                  </p>
                                  <p className="font-light text-sm">
                                    {merchantInfo?.email}
                                  </p>
                                </div>
                              )}
                              {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "flex align-middle px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  <CogIcon
                                    style={{ height: 18, marginRight: 5 }}
                                  />
                                  Settings
                                </Link>
                              )}
                            </Menu.Item> */}
                              {/* <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "flex items-center px-4 py-2 text-sm text-gray-500 hover:text-gray-600 font-medium "
                                  )}
                                  onClick={() => setVisible(true)}
                                >
                                  <FontAwesomeIcon icon={faKey} />
                                  <span className="ml-2">Change Password</span>
                                </Link>
                              )}
                            </Menu.Item> */}
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="#"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "flex items-center px-4 py-2 text-sm text-gray-500 hover:text-red-500 font-bold "
                                    )}
                                    onClick={Logout}
                                  >
                                    <FontAwesomeIcon icon={faPowerOff} />
                                    <span className="ml-2">Logout</span>
                                  </Link>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <FontAwesomeIcon icon={faClose} />
                        ) : (
                          <FontAwesomeIcon icon={faBars} />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="pt-4 pb-0 border-t shadow-md border-gray-700 z-50 absolute right-2 bg-white">
                    <div className="px-4 text-center pb-2 border-b">
                      <Avatar
                        size={120}
                        src={merchantInfo?.profilePicture}
                        alt=""
                        className="border mb-3 shadow-md"
                      />
                      <p className="font-medium text-sm">
                        {merchantInfo?.name}
                      </p>
                      <p className="font-light text-sm">
                        {merchantInfo?.email}
                      </p>
                    </div>
                    <div className="p-2 space-y-1">
                      <Disclosure.Button
                        as="a"
                        href="#"
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                        onClick={() => setVisible(true)}
                      >
                        <FontAwesomeIcon icon={faKey} />
                        <span className="ml-2">Change Password</span>
                      </Disclosure.Button>
                      <Disclosure.Button
                        as="a"
                        href="#"
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-red-700"
                        onClick={Logout}
                      >
                        <FontAwesomeIcon icon={faPowerOff} />
                        <span className="ml-2">Logout</span>
                      </Disclosure.Button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Container>
        </Disclosure>

        <Modal
          title="Change Password"
          open={visible}
          okText="Change"
          onOk={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.submit();
          }}
          onCancel={onClose}
        >
          <Form
            layout="vertical"
            size="large"
            onFinish={onSubmit}
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            form={form} // like ref
          >
            <Form.Item
              label="Email Address"
              name="email"
              initialValue={merchantInfo?.email}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hidden
            >
              <Input placeholder="Enter Email Address" disabled />
            </Form.Item>

            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              // hasFeedback
            >
              <Input.Password maxLength={11} placeholder="Enter Old Password" />
            </Form.Item>

            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              // hasFeedback
            >
              <Input.Password maxLength={11} placeholder="Enter Password" />
            </Form.Item>

            <Form.Item
              label="Re-Type New Password"
              name="newPassword"
              dependencies={["password"]}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password maxLength={11} placeholder="Re-Type Password" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Header>
  );
};

export default HomeHeader;
