import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopBusinessInfo from "../../../components/Shop/BusinessInfo";

const ShopBusinessInfoPage = () => {
  return (
    <MainLayout>
      <ShopBusinessInfo />
    </MainLayout>
  );
};  

export default ShopBusinessInfoPage;
