import {
    BarcodeOutlined,
    CopyOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    SearchOutlined,
    SwapOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import React, {
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import {
    AutoComplete,
    Avatar,
    Button,
    Card,
    Checkbox,
    Drawer,
    Empty,
    Form,
    Input,
    Modal,
    Radio,
    RadioChangeEvent,
    Rate,
    Select,
    Space,
    Spin,
    Tooltip,
    TreeSelectProps,
  } from "antd";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { responseNotification } from "../../utils/notify";
  import { getPage, getParamValue } from "../../utils";
  import { useLocation, useNavigate } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import memberShipStyles from "./Profile.module.css";
  import { useSelector } from "react-redux";
  import { debounce } from "lodash";
  import QuickUpdate from "../Product/QuickUpdate";
  import moment from "moment";
  import UserHeader from "./UserHeader";
  import { isAllowedService } from "../../utils/services";
  import {
    AddBtn,
    Loader,
    LoaderFull,
    Pagination,
    TreeSelectTwo,
  } from "../common";
  
  const MembershipList = ({ visibleData, onCloseMethod }: any): ReactElement => {
    const router = useLocation();
    const { type, token, shopId } = useSelector(
      (state) => (state as any)?.authReducer
    );
    const [membershipLevelId, setMembershipLevelId] = useState<any>("");
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [key, setKey] = useState("");
    const [brandId, setBrandId] = useState<any>();
    const [isActive, setIsActive] = useState<any>(true);
    const [showSearch, setShowSearch] = useState(true);
    const [selectedProductForEdit, setSelectedProductForEdit] =
      useState<any>(undefined);
  
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [barCode, setBarCode] = useState(
      getParamValue(router.search, "barCode")
    );
    const [error, setError] = useState("");
  
    const [memberShipLevelData, setMemberShipLevelData] = useState<any>({
      loading: false,
      data: null,
    });
    const getMembershipLevelId = (e: RadioChangeEvent) => {
      setMembershipLevelId(e.target.value);
    };
    const getMemberShipLavel = useCallback(async () => {
      setMemberShipLevelData({ loading: true, data: null });
  
      const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUrl}/membership/level?userType=USER` +
            (page ? `&page=${page || 0}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setMemberShipLevelData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setMemberShipLevelData({ loading: false, data: [] });
          console.error("Type: Error", err);
        });
    }, [type, page, limit]);
  
    useEffect(() => {
      getMemberShipLavel();
    }, [getMemberShipLavel]);
    const reseAllFieldData = () => {
      form.resetFields();
    };
  
    const onClose = () => {
      form.resetFields();
      setVisible(false);
    };
    const onSubmit = async (data: any) => {
        setLoading(true);
  
        const readyData = data && {
          membershipLevelId: membershipLevelId,
        };
          await fetch(`${process.env.REACT_APP_RIDER_API}/membership-order`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(readyData),
          })
            .then((res) => res.json())
            .then((res) => {
              setLoading(false);
              if (res.statusCode === 200) {
                responseNotification("Membership Create Successfully", "success");
                form.resetFields();
                if (onCloseMethod) {
                  onCloseMethod();
                }
              } else if (res.status === 500) {
                responseNotification("Internal server error", "error");
              } else {
                responseNotification(res.message || "something wrong", "warning");
              }
            })
            .catch((err) => {
              setLoading(false);
              responseNotification(`${"Internal server error"} ${err}`, "error");
              console.error("err", err);
            });
        
    
    };
  
    return (
      <>
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 membershipMainDiv">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                <div className={memberShipStyles.memberShipTitle}>
                  <h1 className={memberShipStyles.memberShipH1}>
                    jachai.com Membership
                  </h1>
                  <p>Explore US Tk 100 of wih a new seller and more benefits</p>
                </div>
  
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  onFinish={onSubmit}
                  form={form} // like ref
                  layout="vertical"
                  autoComplete="off"
                >
                  <div className="flex float-left w-full">
                    <Card className={memberShipStyles.memberShipCard}>
                      <p>Membership Type</p>
                      {memberShipLevelData?.data?.membershipLevels?.length ? (
                        memberShipLevelData?.data?.membershipLevels?.map(
                          (membershipLevel: any, index: any) => (
                            <div className="flex">
                              <Radio.Group onChange={getMembershipLevelId} value={membershipLevelId}>
                                <Space direction="vertical">
                                  <Radio value={membershipLevel?.id}>
                                    {membershipLevel?.name} (
                                    {membershipLevel?.amount || 0})
                                    <Tooltip
                                      placement="bottom"
                                      title={"Seller Warehouse Address"}
                                    >
                                      <InfoCircleOutlined className="px-2 -mt-2" />
                                    </Tooltip>
                                  </Radio>
                                </Space>
                              </Radio.Group>
                            </div>
                          )
                        )
                      ) : (
                        <Empty />
                      )}
                    </Card>
                  </div>
  
                  <div className="flex float-left w-full">
                    <div className="buttons-container mt-10">
                      <Button
                        //type="ghost"
                        htmlType="button"
                        onClick={() => {
                          form?.resetFields();
                        }}
                        className="reset-submit-btn text-center mr-2"
                      >
                        Cancel
                      </Button>
  
                      <Button
                        disabled={loading || !!error}
                        loading={(loading ? "loading" : undefined) as any}
                        type="primary"
                        htmlType="submit"
                        className="add-submit-btn text-center mr-2"
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default MembershipList;
  