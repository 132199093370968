const ChevronUp = ({ ...props }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.129619 14.12L1.90261 16L9.44727 8L1.90261 0L0.129619 1.88L5.8887 8L0.129619 14.12Z"
        // fill="white"
      />
      <path
        d="M7.37669 14.12L9.14968 16L16.6943 8L9.14968 0L7.37669 1.88L13.1358 8L7.37669 14.12Z"
        // fill="white"
      />
    </svg>
  )
}

export default ChevronUp
