import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import DeliveryReportList from "../../components/Reports/DeliveryReport";
const DeliveryReportPage = (): ReactElement => {
  return (
    <MainLayout>
      <DeliveryReportList />
    </MainLayout>
  );
};

export default DeliveryReportPage;
