const Filter = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
        stroke="#CCC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5003 2.5H11.667V8.33333H17.5003V2.5Z"
        stroke="#CCC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5003 11.6666H11.667V17.5H17.5003V11.6666Z"
        stroke="#CCC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 11.6666H2.5V17.5H8.33333V11.6666Z"
        stroke="#CCC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Filter
