import { Button, DatePicker, Empty, Form, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, OrderTypeArray } from "../../utils";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const OrderSalesReportList = ({
  inShop = false,
}: {
  inShop?: boolean;
}): ReactElement => {
  const { type, token, shopId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [pmethodId, setPmethodId] = useState();
  const [showSearch, setShowSearch] = useState(true);
  const [ordersData, setOrdersData] = useState<any>({
    loading: false,
    data: null,
  });
  const [paymentMethod, setPaymentMethod] = useState<any>({
    loading: false,
    list: [],
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const fatchPaymentMethod = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_PAY_API}/payment-method?type=JC_GROCERY`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    );
    const response = await data.json();

    setPaymentMethod({
      loading: false,
      list: response?.methods?.map((method: { name: any }) => ({
        value: method.name,
        label: method.name,
      })),
    });
  };

  useEffect(() => {
    fatchPaymentMethod();
  }, []);

  const getRevenueReport = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setOrdersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/finance/revenue?page=${page || 0}&limit=${limit || 16}` +
          (from ? `&fromDate=${from}` : ``) +
          (to ? `&toDate=${to}` : ``) +
          (shopId ? `&shopId=${shopId}` : ``) +
          (paymentMethod ? `&paymentMethod=${paymentMethod}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrdersData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrdersData({ loading: false, data: [] });
        console.error("Data: Error", err);
      });
  }, [paymentMethod, range.from, range.to, shopId, status, page, limit]);

  useEffect(() => {
    getRevenueReport();
  }, [getRevenueReport]);

  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Revenue Report"
        subTitle={`${ordersData?.data?.totalElements} Data(s)`}
        childComponent={inShop ? true : false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox} ${inShop ? `mx-0` : ``}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>

            <Form.Item name="status_search" initialValue={status}>
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(OrderTypeArray)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="payment_method" initialValue={pmethodId}>
              <Select
                showSearch
                placeholder="Filter by Payment Method"
                onChange={(val) => setPmethodId(val)}
                options={paymentMethod?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {ordersData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Shop Name</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Status</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">MRP</th>
                      <th scope="col">TP</th>
                      <th scope="col">Revenue</th>
                    </tr>
                  </thead>

                  <tbody>
                    {ordersData?.data?.orders?.length ? (
                      ordersData?.data?.orders?.map(
                        (orderData: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">
                                {moment(orderData?.date).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {orderData?.shopName}
                              </span>
                            </td>
                            <td>
                              <span className="name">{orderData?.orderId}</span>
                            </td>
                            <td>
                              <span
                                className={`radius-btn ${orderData?.status?.toLowerCase()}-btn text-nowrap`}
                              >
                                {orderData?.status?.split("_").join(" ")}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                {orderData?.paymentMethod}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                {parseFloat(orderData?.mrp)}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {parseFloat(orderData?.tp)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`name ${
                                  orderData?.revenue <= 0
                                    ? `text-danger font-weight-bold`
                                    : ``
                                }`}
                              >
                                {orderData?.revenue}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...ordersData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default OrderSalesReportList;
