import MainLayout from "../../../components/Layouts";
import ShopCategoryList from "../../../components/Shop/Categories";

const ShopCategoryPage = () => {
  return (
    <MainLayout>
      <ShopCategoryList />
    </MainLayout>
  );
};

export default ShopCategoryPage;
