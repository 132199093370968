import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Rate,
  Select,
  Spin,
  Switch,
  Tag,
} from "antd";
import axios from "axios";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { CSVLink } from "react-csv";

import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { DefaultOptionType } from "antd/lib/select";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import React from "react";
import QuickUpdate from "./QuickUpdate";
import { Link } from "react-router-dom";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import { Loader, LoaderFull, Pagination, TreeSelectTwo } from "../common";

const ProductList = ({
  inCampaign = false,
  inShop = false,
  inBrand = false,
  getShopId,
  getCategoryId,
  getBrandId,
}: {
  inCampaign?: boolean;
  inShop?: boolean;
  inBrand?: boolean;
  getShopId?: string;
  getCategoryId?: string;
  getBrandId?: string;
}): ReactElement => {
  const liveUrl = `${process.env.REACT_APP_LIVE_URL}`;

  const { merchantInfo, token, type, merchantId, shopId, shopInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // --------------------------------
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  // --------------------------------

  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const route = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");

  const [categoryId, setCategoryId] = useState<any>(getCategoryId);
  const [brandId, setBrandId] = useState<any>(getBrandId);
  const [campaignId, setCampaignId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(undefined);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);

  const [productDataForDownload, setProductDataForDownload] = useState<any>({
    loading: false,
    data: null,
  });

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    setCampaignId((route as any).campaignId || "");
  }, [route]);

  useEffect(() => {
    setBrandId((route as any)?.brandId || "");
  }, [route]);

  const getProductsForDownload = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    if (typeof campaignId === "string") {
      setProductDataForDownload({ loading: true, data: null });

      const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=2000` +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (merchantId ? `&merchantId=${merchantId}` : ``) +
        (from ? `&startDate=${from}` : ``) +
        (to ? `&endDate=${to}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);
      axios
        .get(`${encodedUrl}/admin/product/v2${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductDataForDownload({ loading: false, data: res.data });
        })
        .catch((err) => {
          setProductDataForDownload({ loading: false, data: [] });
          console.error("Download Data: Error", err);
        });
    }
  }, [
    shopId,
    categoryId,
    merchantId,
    brandId,
    campaignId,
    range,
    isActive,
    page,
    limit,
  ]);

  const getProducts = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    if (typeof campaignId === "string") {
      setProductData({ loading: true, data: null });

      const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}` +
        (getShopId ? `&shopId=${getShopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (key ? `&key=${key}` : ``) +
        (from ? `&startDate=${from}` : ``) +
        (to ? `&endDate=${to}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``) +
        (page
          ? `&page=${
              page == productData.data?.currentPageNumber ? 0 : page || 0
            }`
          : ``) +
        (limit ? `&limit=${limit}` : ``);
      url = encodeURI(url);
      axios
        .get(`${encodedUrl}/product${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductData({ loading: false, data: res.data });
          setCheckedList(res.data.products.map((product: any) => product?.id));
        })
        .catch((err) => {
          setProductData({ loading: false, data: [] });
          console.error("Type: Error", err);
        });
    }
  }, [
    getShopId,
    categoryId,
    merchantId,
    brandId,
    campaignId,
    range,
    isActive,
    key,
    page,
    limit,
  ]);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [shopId, categoryId, brandId, campaignId, isActive, key, page, limit]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``) +
            (merchantId ? `&merchantId=${merchantId}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  const getCampaignOptions = useCallback(async (key?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign/by-type?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      })),
    });
  }, []);

  const onStatusChange = async (
    id: SetStateAction<undefined>,
    val: boolean
  ) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getProducts();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "campaign") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getShopOptions, getBrandsOptions, getCampaignOptions]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getBrandsOptions("");
      getCampaignOptions();
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setCategoryId("");
    setBrandId("");
    setCampaignId("");
    setRange({
      from: null,
      to: null,
    });
    setIsActive(undefined);
  };

  function UnLoadWindow() {
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        shopId: shopId,
        categoryId: categoryId,
        brandId: brandId,
        campaignId: campaignId,
      },
    });
  }

  const initBeforeUnLoad = (showExitPrompt: any) => {
    window.onbeforeunload = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        UnLoadWindow();
        return "";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(shopId);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(shopId);
  }, [shopId]);

  const onClose = () => {
    setVisible(false);
    getProducts();
    setSelectedProductForEdit(undefined);
  };

  const downloadProduct = () => {
    getProductsForDownload();
    Modal.success({
      title: "Ready to Download!",
      okButtonProps: { danger: true },
      okText: "Close",
      onOk() {},

      content: (
        <div>
          <p className="mb-2 mt-6 text-red-600">
            Download {productDataForDownload?.data?.products?.length} items of{" "}
            {productDataForDownload?.data?.totalElements}
          </p>
          <p className="mb-4">Max Download Limit 2k</p>

          <CSVLink data={productDataForDownload?.data?.products}>
            <Button shape="round" type="dashed">
              <DownloadOutlined /> Download
            </Button>
          </CSVLink>
        </div>
      ),
    });
  };

  // ----------------------------
  // ----------------------------

  const showDrawer = () => {
    setVisible(true);
  };

  //
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      productIds: selectedProductBarcode,
    };

    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/merchant/product/resell/requests`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Request successfully placed for resale selected items!",
            "success"
          );

          setVisible(!visible);
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  // ************************
  const onChangeCheckbox = (e: any) => {
    setSelectedProductBarcode((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate( 
      !!checkedList.length &&
        checkedList.length <= selectedProductBarcode.length
    );
    setCheckAll(checkedList.length === selectedProductBarcode.length);
  };

  return (
    <>
      { merchantInfo?.type !== "RESELLER" && (
            <>
          
       
        <BreadCrumb
          title="Product List"
          subTitle={`${productData?.data?.totalElements} Product(s)`}
          childComponent={inShop ? true : false}
        

          extra={[
            

            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,

            <Button
              type="default"
              size="large"
              onClick={() => {
                showDrawer();
              }}
              disabled={!selectedProductBarcode.length}
              className="bg-green-600 hover:bg-green-500 hover:text-white text-white font-semibold border-none rounded-md"
            >
              Allow for Resale
            </Button>,
          ]}
        />
         </>
          )}

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="category_search">
              <TreeSelectTwo setCategoryId={setCategoryId} />
            </Form.Item>

            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>

            {inCampaign && (
              <Form.Item name="campaign_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Campaign"
                  optionFilterProp="children"
                  onChange={(val) => setCampaignId(val)}
                  onSearch={(e) => handleSearch(e, "campaign")}
                  filterOption={() => {
                    return true;
                  }}
                  options={campaignOptions?.list}
                ></Select>
              </Form.Item>
            )}

            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      {merchantInfo?.type !== "RESELLER" ? (
                        <>
                          <th scope="col">
                            <div className="checkbox-large">
                              <Checkbox
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={
                                  checkAll ||
                                  selectedProductBarcode?.length ===
                                    checkedList?.length
                                }
                                className="mr-3"
                                disabled={!productData.data?.products?.length}
                              >
                                Check all
                              </Checkbox>
                            </div>
                          </th>
                        </>
                      ) : (
                        <>
                          <th scope="col">Name</th>
                        </>
                      )}

                      <th scope="col">Ratings</th>
                      {!inBrand && (
                        <>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </>
                      )}
                      <th scope="col">Price & Stock</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.products?.length ? (
                      productData?.data?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <div className="checkbox-large flex items-center gap-2">
                                {merchantInfo?.type !== "RESELLER" && (
                                  <>
                                    <Checkbox
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onChangeCheckbox(product?.id);
                                      }}
                                      checked={selectedProductBarcode?.find(
                                        (item: any) => product?.id === item
                                      )}
                                      value={product?.id}
                                    ></Checkbox>
                                  </>
                                )}

                                <td className="flex items-center gap-2">
                                  <a
                                    href={liveUrl + `products/` + product?.slug}
                                    target="_blank"
                                    rel="noreferer noopener"
                                  >
                                    <Avatar
                                      size={45}
                                      src={product?.productImage}
                                      shape="square"
                                    />
                                  </a>
                                  <div className="flex flex-col">
                                    <span className="font-medium text-gray-500">
                                      {product?.name?.slice(0, 50)}
                                    </span>
                                    {product?.barCode && (
                                      <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                        <BarcodeOutlined /> {product?.barCode}
                                      </span>
                                    )}
                                  </div>
                                </td>
                              </div>
                            </td>

                            <td>
                              <Rate value={product?.rating || 1} disabled />
                              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                {product?.numberOfRating || 0}
                              </span>
                            </td>

                            {!inBrand && (
                              <>
                                <td>
                                  <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={product?.isActive}
                                    onChange={(val, e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onStatusChange(product?.id, val);
                                    }}
                                    className="mr-1"
                                    loading={
                                      confirmLoading &&
                                      product?.id === confirmLoading
                                    }
                                    disabled={
                                      product?.shop?.deleted ||
                                      product?.status === "REJECTED"
                                    }
                                  />
                                </td>

                                <td>
                                  <div className="flex flex-grow">
                                    {/* <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      dispatch({
                                        type: SHOW_SEARCHBAR,
                                        payload: {
                                          shopId: shopId,
                                          categoryId: categoryId,
                                          brandId: brandId,
                                          campaignId: campaignId,
                                        },
                                      });
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button> */}
                                    <Link
                                      title="Edit Product"
                                      to={
                                        inShop
                                          ? `/shops/${getShopId}/products/${product?.slug}/details`
                                          : `/products/${product?.slug}/details`
                                      }
                                      className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                    >
                                      <EyeOutlined />
                                    </Link>
                                    {type !== "JC_FOOD" && (
                                      <Button
                                        shape="round"
                                        type="primary"
                                        danger
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setSelectedProductForEdit(product);
                                        }}
                                      >
                                        <FileDoneOutlined />11
                                      </Button>
                                    )}{" "}
                                    {isAllowedService(`Products Edit`) && (
                                      <>
                                        <Link
                                          title="Edit Product"
                                          to={
                                            inShop
                                              ? `/shops/${getShopId}/products/${product?.slug}/edit`
                                              : `/products/${product?.slug}/edit`
                                          }
                                          className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                        >
                                          <EditOutlined />
                                        </Link>
                                      </>
                                    )}
                                  </div>
                                </td>
                              </>
                            )}
                            <td>
                              <div className="flex_">
                                {product?.variations?.length
                                  ? product?.variations?.map(
                                      (variant: any, index: string) => (
                                        <React.Fragment key={index}>
                                          <div className="flex justify-content-start mb-1">
                                            <Tag
                                              color={
                                                variant?.stock < 10
                                                  ? `red`
                                                  : `green`
                                              }
                                            >
                                              {variant?.variationName?.toUpperCase()}
                                              : <b>{variant?.stock || 0}</b>
                                            </Tag>

                                            <Tag>
                                              MRP {variant?.price?.currencyCode}
                                              {variant?.price
                                                ?.discountedPrice ||
                                                variant?.price?.mrp}
                                              {variant?.price
                                                ?.discountedPrice &&
                                                variant?.price
                                                  ?.discountedPrice !==
                                                  variant?.price?.mrp && (
                                                  <span className="text-red-600 ml-1">
                                                    <del>
                                                      {variant?.price
                                                        ?.currencyCode || "Tk"}
                                                      {variant?.price?.mrp}
                                                    </del>
                                                  </span>
                                                )}
                                            </Tag>
                                            <Tag>
                                              TP 
                                              {merchantInfo?.type === "RESELLER" ? variant?.price?.resellerTp || '0' : variant?.price?.tp || '0'}
                                              
                                              {variant?.price?.currencyCode}
                                            </Tag>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title="Quick Update"
        onCancel={onClose}
        open={selectedProductForEdit}
        width={700}
        centered
        footer={false}
      >
        <QuickUpdate
          onCloseMethod={onClose}
          visibleData={selectedProductForEdit}
        />
      </Modal>

      <Drawer
        title={"Request for Resale"}
        placement="right"
        onClose={onClose}
        open={visible}
        width={400}
      >
        <div className="">
          <Form
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="ant-form ant-form-vertical"
            onFinish={onSubmit}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item label="Products Barcodes">
              <Input.TextArea
                value={selectedProductBarcode}
                disabled
                rows={8}
              />
            </Form.Item>

            <Button
              type="primary"
              danger
              title="Submit"
              // disabled={!shopId || loading}
              size="large"
              htmlType="submit"
              loading={(loading ? "loading" : undefined) as any}
            >
              Submit
            </Button>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default ProductList;
