const CartVan = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      {...props}
    >
      <path
        d="M3.82488 11.1396C3.1336 11.1396 2.57324 11.7799 2.57324 12.5698C2.57324 13.3598 3.13363 14.0001 3.82488 14.0001C4.51615 14.0001 5.07652 13.3597 5.07652 12.5698C5.07654 11.7799 4.51615 11.1396 3.82488 11.1396ZM3.82488 13.3644C3.44084 13.3644 3.12953 13.0087 3.12953 12.5699C3.12953 12.131 3.44084 11.7753 3.82488 11.7753C4.20891 11.7753 4.52023 12.131 4.52023 12.5699C4.52025 13.0087 4.20891 13.3644 3.82488 13.3644Z"
        fill="white"
      />
      <path
        d="M9.10906 11.1396C8.41778 11.1396 7.85742 11.7799 7.85742 12.5698C7.85742 13.3598 8.41781 14.0001 9.10906 14.0001C9.80031 14.0001 10.3607 13.3597 10.3607 12.5698C10.3607 11.7799 9.80033 11.1396 9.10906 11.1396ZM9.10906 13.3644C8.72502 13.3644 8.41371 13.0087 8.41371 12.5699C8.41371 12.131 8.72502 11.7753 9.10906 11.7753C9.4931 11.7753 9.80441 12.131 9.80441 12.5699C9.80443 13.0087 9.4931 13.3644 9.10906 13.3644Z"
        fill="white"
      />
      <path
        d="M11.9324 2.7961C11.8744 2.7238 11.7952 2.67854 11.7099 2.66895L2.65628 2.52593L2.40595 1.65186C2.2296 1.06756 1.75077 0.673097 1.20992 0.666557H0.278145C0.124525 0.666557 0 0.808853 0 0.984397C0 1.15994 0.124525 1.30224 0.278145 1.30224H1.20992C1.51235 1.30987 1.77865 1.53177 1.87748 1.85846L3.6437 7.94508L3.50464 8.31058C3.34953 8.76764 3.39611 9.28207 3.6298 9.69318C3.86126 10.0969 4.25064 10.3461 4.67285 10.3606H10.0828C10.2364 10.3606 10.3609 10.2183 10.3609 10.0428C10.3609 9.86726 10.2364 9.72496 10.0828 9.72496H4.67282C4.43439 9.71815 4.21492 9.57482 4.08872 9.34355C3.96393 9.11511 3.93829 8.83368 4.01919 8.58072L4.13046 8.29467L9.98539 7.59542C10.6285 7.51448 11.1575 6.97997 11.3066 6.26049L11.9741 3.06622C12.0042 2.97423 11.9882 2.87078 11.9324 2.7961ZM10.7642 6.11749C10.6739 6.58064 10.3298 6.92226 9.91586 6.95977L4.13046 7.64311L2.83708 3.16161L11.3622 3.30463L10.7642 6.11749Z"
        fill="white"
      />
    </svg>
  )
}

export default CartVan
