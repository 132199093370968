import { Button, DatePicker, Empty, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import { DailySalesExportToExcel } from "./DailySalesExportToExcel";
import Pagination from "../common/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";

const { RangePicker } = DatePicker;

const OrderSalesReportList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");

  const [driverId, setDriverId] = useState("");
  const [pmethodId, setPmethodId] = useState();
  const [mobileNumber, setMobileNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [searchShopName, setSearchShopName] = useState("");
  const [minimumOrderAmount, setMinimumOrderAmount] = useState("");
  const [categoryId, setCategoryId] = useState<any>();
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [secondDrawer, setSecondDrawer] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [orderCSV, setOrderCSV] = useState([]);
  const [dailySalesReport, setDailySalesReport] = useState([]);

  const [transactionData, setTransactionData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  //excel sheet export start
  const [getOrderData, setGetOrderData] = useState([]);
  const fileName = "daily_sales_report"; // here enter filename for your excel file

  const csvDataGet = transactionData?.data?.orders;
  //excel sheet export end

  const onClose = () => {
    setSelectedOrder(undefined);
    setSecondDrawer(false);
    setVisibleDetails(false);
  };

  const onSecondDrawerClose = () => {
    setSecondDrawer(false);
  };

  const getTransactions = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTransactionData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/transaction/history` +
          `?page=${page || 0}` +
          `&limit=${limit || 16}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTransactionData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTransactionData({ loading: false, data: [] });
        console.error("Drivers: Error", err);
      });
  }, [range.from, range.to, page, limit]);
  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const onChange = (newValue: string) => {
    setCategoryId(newValue);
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
    if (showSearch) {
    }
  }, [showSearch]);

  useEffect(() => {
    if (secondDrawer) {
      setDriverId(``);
      form.resetFields();
    }
  }, [secondDrawer, form]);

  const reseAllFieldData = () => {
    form?.resetFields();
    setProductName("");
    setStatus("");
    setOrderId("");
    setMobileNumber("");
    setStatus("");
    setCategoryId("");
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      <BreadCrumb
        title="Transaction List"
        subTitle={`${transactionData?.data?.totalElements} Transaction(s)`}
        childComponent={inShop ? true : false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          <DailySalesExportToExcel
            apiData={dailySalesReport}
            fileName={fileName}
          />,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox} ${inShop ? `mx-0` : ``}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {transactionData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Transaction Id</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transactionData?.data?.transactions?.length ? (
                      transactionData?.data?.transactions?.map(
                        (transaction: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium">
                                {transaction?.transactionId}
                              </span>
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    transaction?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(transaction?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(transaction?.createdAt).format("lll")}
                              </span>
                            </td>

                            <td>&#2547;{parseInt(transaction?.amount)}</td>
                            <td>
                              <span
                                className={`radius-btn ${transaction?.type?.toLowerCase()}-btn`}
                              >
                                {transaction?.type?.split("-").join(" ")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...transactionData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default OrderSalesReportList;
