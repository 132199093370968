import MainLayout from "../../components/Layouts";
import ShopDetails from "../../components/Shop/Details";

const ShopDetailsPage = () => {
  return (
    <MainLayout>
      <ShopDetails />
    </MainLayout>
  );
};

export default ShopDetailsPage;
