const Exclamatory = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_16641_24260)">
        <path
          d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z"
          stroke={props?.color || '#838383'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.3335V8.00016"
          stroke={props?.color || '#838383'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.6665H8.00667"
          stroke={props?.color || '#838383'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Exclamatory
