const PlayBtn = ({ ...props }) => {
  return (
    <svg
      width="201"
      height="201"
      viewBox="0 0 201 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12142_11524)">
        <path
          d="M100.766 200.972C156.033 200.972 200.836 156.169 200.836 100.901C200.836 45.6341 156.033 0.831055 100.766 0.831055C45.4983 0.831055 0.695312 45.6341 0.695312 100.901C0.695312 156.169 45.4983 200.972 100.766 200.972Z"
          fill="#C11111"
          fillOpacity="0.2"
        />
        <path
          d="M100.766 182.816C146.006 182.816 182.68 146.141 182.68 100.901C182.68 55.6615 146.006 18.9873 100.766 18.9873C55.5258 18.9873 18.8516 55.6615 18.8516 100.901C18.8516 146.141 55.5258 182.816 100.766 182.816Z"
          fill="#C11111"
          fillOpacity="0.5"
        />
        <path
          d="M100.765 163.815C135.511 163.815 163.678 135.648 163.678 100.901C163.678 66.1553 135.511 37.988 100.765 37.988C66.0189 37.988 37.8516 66.1553 37.8516 100.901C37.8516 135.648 66.0189 163.815 100.765 163.815Z"
          fill="url(#paint0_linear_12142_11524)"
        />
        <path
          d="M117.935 94.5678L96.5416 82.1822L90.6303 78.8043C88.3784 77.5376 85.7042 77.5376 83.4522 78.8043C81.2003 80.071 79.9336 82.4637 79.9336 84.9971V116.524C79.9336 119.058 81.3411 121.45 83.4522 122.717C84.5782 123.421 85.8449 123.702 86.9709 123.702C88.2376 123.702 89.3636 123.421 90.4895 122.717L95.1342 120.043L101.608 116.243L117.654 106.953C119.905 105.687 121.172 103.294 121.172 100.761C121.454 98.2272 120.187 95.9753 117.935 94.5678ZM114.979 102.027L97.8083 112.02L95.5564 113.287L87.8154 117.791C87.1116 118.213 86.5487 117.932 86.4079 117.791C86.1264 117.65 85.7042 117.369 85.7042 116.524V85.1379C85.7042 84.4341 86.2672 84.0119 86.4079 83.8711C86.5487 83.7304 86.8301 83.7304 87.1116 83.7304C87.3931 83.7304 87.5339 83.7304 87.8154 83.8711L96.4009 88.7972L115.12 99.6347C115.824 100.057 115.824 100.62 115.824 100.901C115.824 101.042 115.683 101.746 114.979 102.027Z"
          fill="white"
        />
        <path
          d="M101.89 116.383L90.6302 122.858C89.5042 123.561 88.2375 123.843 87.1115 123.843C85.8448 123.843 84.7188 123.561 83.5929 122.858C81.3409 121.591 80.0742 119.198 80.0742 116.665V92.4565L85.8448 98.6494V116.524C85.8448 117.228 86.4078 117.65 86.5485 117.791C86.83 117.932 87.393 118.213 87.956 117.791L97.8082 112.02L101.89 116.383Z"
          fill="#FAD6E8"
        />
        <path
          d="M121.456 100.901C121.456 103.435 120.049 105.828 117.937 107.094L101.892 116.384L95.418 120.184L95.5587 113.428L97.8106 112.02L114.982 102.168C115.685 101.746 115.685 101.183 115.685 100.901C115.685 100.62 115.685 100.057 114.982 99.6347L96.4032 88.7973L96.5439 82.323L117.937 94.7086C120.189 95.9753 121.456 98.2273 121.456 100.901Z"
          fill="#FFFCE5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_12142_11524"
          x1="37.8516"
          y1="100.902"
          x2="163.68"
          y2="100.902"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#880101" />
          <stop offset="1" stopColor="#CD1515" />
        </linearGradient>
        <clipPath id="clip0_12142_11524">
          <rect
            width="200"
            height="200"
            fill="white"
            transform="translate(0.695312 0.831055)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlayBtn;
