import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import RevenueReportList from "../../components/Reports/RevenueReport";
const RevenueReportPage = (): ReactElement => {
  return (
    <MainLayout>
      <RevenueReportList />
    </MainLayout>
  );
};

export default RevenueReportPage;
