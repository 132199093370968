// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/banner-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hero_root__0NQlM {
  margin-top: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0.5) 0%,
      rgba(9, 9, 121, 0.5) 35%,
      rgba(0, 212, 255, 0.5) 100%
    ),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-size: cover
}

.Hero_link__TNm1c {
  & > svg {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  & > svg {
    transition-duration: 75ms
  }
  & > svg {
    transition-timing-function: linear
  }

  &:hover > svg {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
} 
`, "",{"version":3,"sources":["webpack://./src/components/Landing/Banner/Hero.module.css"],"names":[],"mappings":"AACE;EAAA,gBAAmD;EAAnD,aAAmD;EAAnD,iBAAmD;EAAnD,mBAAmD;EAAnD,iBAAmD;EAAnD,oBAAmD;EACnD,yBAAyB;EACzB;;;;;;2CAMsC;EACtC,2BAA2B;EAC3B;AAVmD;;AAarD;EAEI;IAAA;EAAwC;EAAxC;IAAA;EAAwC;EAAxC;IAAA;EAAwC;;EAIxC;IAAA,iBAAgB;IAAhB,iBAAgB;IAAhB;EAAgB;AAEpB","sourcesContent":[".root {\n  @apply mt-auto py-28 min-h-screen flex items-center;\n  background: rgb(2, 0, 36);\n  background: linear-gradient(\n      90deg,\n      rgba(2, 0, 36, 0.5) 0%,\n      rgba(9, 9, 121, 0.5) 35%,\n      rgba(0, 212, 255, 0.5) 100%\n    ),\n    url(\"../../../images/banner-bg.jpg\");\n  background-position: center;\n  background-size: cover;\n}\n\n.link {\n  & > svg {\n    @apply transform duration-75 ease-linear;\n  }\n\n  &:hover > svg {\n    @apply scale-110;\n  }\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Hero_root__0NQlM`,
	"link": `Hero_link__TNm1c`
};
export default ___CSS_LOADER_EXPORT___;
