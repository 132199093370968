import { ReactElement } from "react";
import { Layout } from "antd";
import MainFooter from "./Footer";
import MainHeader from "./Header";
import MainSidebar from "./Sidebar";
import React from "react";
const { Content } = Layout;

const MainLayout = ({ children }: { children: ReactElement }) => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
 

      <Layout className="site-layout">
        <MainHeader />

        <Content className="site-layout-background mx-4 mt-20">
          <div className="site-layout-background">{children}</div>
        </Content>

        <MainFooter />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
