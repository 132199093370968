import React, { useState } from "react";
import cn from "classnames";
import { Copy, Exclamatory } from "../../icons";
import Rules from "../../Referrals/InviteEarn/Rules";
import { responseNotification } from "../../../utils/notify";
import { Modal } from "antd";

export default function ReferralCard({
  data,
  inHome = false,
  visible,
  setVisible,
}: {
  data: any;
  inHome?: boolean;
  visible?: boolean;
  setVisible?: any;
}) {
  const [visibleModal, setVisibleModal] = useState(false);
  const copyToClipboard = async (text: any) => {
    if ("clipboard" in navigator) {
      responseNotification("Copied the Code!", "success");
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  return (
    <>
      <div
        className={cn(`relative rounded-lg p-4 bg-accent-0 flex-grow`)}
        style={{ background: data?.colorCode || "" }}
      >
        <span
          onClick={() => setVisibleModal(true)}
          className="absolute right-3 top-3"
        >
          <Exclamatory />
        </span>

        <div className="flex justify-center w-full py-4">{data?.icon}</div>
        <h4 className="font-semibold mt-4">{data?.title}</h4>
        <span className="text-sm leading-none">{data?.description}</span>

        <div className="flex gap-3 mt-2">
          <div className="flex border border-accent-2 rounded-md">
            <span className="border-r text-center p-1.5 px-4">
              {data?.referralCode}
            </span>
            <span
              className="p-1.5 px-3 cursor-pointer"
              onClick={() => copyToClipboard(data?.referralCode || "No Data!")}
            >
              <Copy color={`#838383`} />
            </span>
          </div>
          <div className="border border-accent rounded-md">
            {inHome ? (
              <a href={"/profile/invite-earn"}>
                <span className="p-1.5 px-3 font-semibold text-accent block cursor-pointer">
                  Invite
                </span>
              </a>
            ) : (
              <button
                className="p-1.5 px-3 font-semibold text-accent block cursor-pointer"
                onClick={() => setVisible(!visible)}
              >
                Invite
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={visibleModal}
        onCancel={() => setVisibleModal(!visibleModal)}
        width={500}
        title={
          <span className="flex gap-4 justify-center font-semibold">
            “INVITE & EARN"
          </span>
        }
      >
        <Rules />
      </Modal>
    </>
  );
}
