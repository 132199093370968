import React, { ReactElement } from "react";
import { Layout } from "antd";
import HomeHeader from "./Header";
import HomeFooter from "./Footer/Footer";
const { Content } = Layout;

const HomeLayout = ({ children }: { children: ReactElement }) => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {/* <MainSidebar /> */}
      <Layout className="site-layout">
        <HomeHeader />

        <Content className="site-layout-background">
          <div className="site-layout-background">{children}</div>
        </Content>

        <HomeFooter />
      </Layout>
    </Layout>
  );
};

export default HomeLayout;
