// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Video_root__lQBgo {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  background-size: contain;
  padding-top: 3rem;
  padding-bottom: 4rem;
  background: #f1ebeb;
  background-position: top center;
  background-repeat: no-repeat
}

.Video_PlayBtn__GuXAV {
  position: absolute;
  top: 32%;
  left: -10%
}
`, "",{"version":3,"sources":["webpack://./src/components/Landing/Video/Video.module.css"],"names":[],"mappings":"AACE;EAAA,kBAAqD;EAArD,yDAAqD;EAArD,wBAAqD;EAArD,iBAAqD;EAArD,oBAAqD;EACrD,mBAAmB;EACnB,+BAA+B;EAC/B;AAHqD;;AAOrD;EAAA,kBAAe;EACf,QAAQ;EACR;AAFe","sourcesContent":[".root {\n  @apply bg-gray-50 bg-contain bg-no-repeat pt-12 pb-16;\n  background: #f1ebeb;\n  background-position: top center;\n  background-repeat: no-repeat;\n}\n\n.PlayBtn {\n  @apply absolute;\n  top: 32%;\n  left: -10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Video_root__lQBgo`,
	"PlayBtn": `Video_PlayBtn__GuXAV`
};
export default ___CSS_LOADER_EXPORT___;
