import {
    AutoComplete,
    Avatar,
    Button,
    Col,
    Empty,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Spin,
    Tag,
  } from "antd";
  
  import {
    BarcodeOutlined,
    DeleteOutlined,
    MinusOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    ShoppingCartOutlined,
  } from "@ant-design/icons";
  
  import React, {
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
  } from "react";
  
  import axios from "axios";
  import { debounce } from "lodash";
  import { getImgUrl, getParamValue } from "../../../utils/index";
  import { useLocation, useParams } from "react-router";
  import { responseNotification } from "../../../utils/notify";
  import { Link } from "react-router-dom";
  import { Loader, PaginationThree, TreeSelectTwo } from "../../common";
  import styles from "../../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  
  const OrderProductList = ({
    selectedProducts,
    setSelectedProducts,
  }: any): ReactElement => {
    const { type, token } = useSelector((state) => (state as any)?.authReducer);
    const liveUrl = `${process.env.REACT_APP_LIVE_URL}`;
    const router = useLocation();
    const page = getParamValue(router?.search, "page");
    const limit = getParamValue(router?.search, "limit");
    const [form] = Form.useForm();
    const route = useParams();
    const shopId = (route as any)?.shopId;
    //const [shopId, setShopId] = useState<any>((route as any)?.shopId);
  
    const [supplierId, setSupplierId] = useState();
    const [barCode, setBarCode] = useState<any>();
    const [parentCatId, setParentCatId] = useState<any>();
    const [categoryId, setCategoryId] = useState<any>();
    const [brandId, setBrandId] = useState<any>();
    const [key, setKey] = useState("");
    const [selectedVisibleProduct, setSelectedVisibleProduct] = useState<any>();
  
    const [productData, setProductData] = useState<any>({
      loading: false,
      item: [],
    });
  
    const [productOptions, setProductOptions] = useState<any>({
      list: [],
      loading: false,
    });
  
    const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
      list: [],
      loading: false,
    });
  
    const [brandsOptions, setBrandsOptions] = useState<any>({
      list: [],
      loading: false,
    });
  
    const [shopsOptions, setShopsOptions] = useState<any>({
      list: [],
      loading: false,
    });
  
    const [suppliersOptions, setSuppliersOptions] = useState<any>({
      list: [],
      loading: false,
    });
  
    const reseAllFieldData = () => {
      setKey("");
      setParentCatId("");
      setCategoryId("");
      setBrandId("");
      setBarCode("");
      form.resetFields();
    };
  
    const fetchProducts = useCallback(() => {
      try {
        setProductData({ loading: true, item: [] });
        const url =
          `${process.env.REACT_APP_CATALOG_READER_API}/product?shopId=${shopId}` +
          (page 
            ? `&page=${
                page == productData.item?.currentPageNumber ? 0 : page || 0
              }`
            : ``) + 
          `&limit=${limit || 16}` +
          `&isActive=${true}`;
  
        fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setProductData({ loading: false, item: data });
          })
          .catch(() => {
            setProductData({ loading: true, item: [] });
          });
      } catch (error) {
        setProductData({ loading: true, item: [] });
        console.log(error, "error");
      }
    }, [
      limit,
      page,
      categoryId,
      brandId,
      shopId,
      supplierId,
      key,
      type,
      barCode,
    ]);
  
    const getProductOptions = useCallback(
      async (val: any) => {
        setProductOptions({ loading: false, list: [] });
  
        if (val) {
          setProductOptions({ loading: true, list: [] });
          const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
  
          const res = await axios.get(
            `${encodedUri}/product?page=0&limit=20` +
              `&isActive=${true}` +
              (val ? `&key=${val}` : ``) +
              (type ? `&type=${type}` : ``) +
              (shopId ? `&shopId=${shopId}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProductOptions({
            loading: false,
            list: res?.data?.keys?.map((product: { name: any }) => {
              return {
                value: product?.name,
                label: product?.name,
              };
            }),
          });
        }
      },
      [type, shopId, supplierId]
    );
  
    const getProductByBarcodeOptions = useCallback(
      async (bCode: string) => {
        setProductBarcodeOptions({ loading: false, list: [] });
  
        setProductBarcodeOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
  
        const res = await axios.get(
          `${encodedUri}/admin/product/search?page=0&limit=20` +
            `&isActive=${true}` +
            (bCode ? `&barCode=${bCode}` : ``) +
            (shopId ? `&shopId=${shopId}` : ``) +
            (key ? `&key=${key}` : ``) +
            (type ? `&type=${type}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProductBarcodeOptions({
          loading: false,
          list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
            return {
              value: product?.barCode,
              label: product?.name,
            };
          }),
        });
      },
      [type, key, shopId]
    );
  
    const getBrandsOptions = useCallback(
      async (val?: string) => {
        setBrandsOptions({ loading: true, list: [] });
  
        const res = await axios.get(
          `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?page=0&limit=20` +
            (type ? `&type=${type}` : ``) +
            (val ? `&key=${val}` : ``),
  
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        setBrandsOptions({
          loading: false,
          list: res?.data?.brands?.map((brand: any) => {
            return {
              value: brand?.id,
              label: brand?.name,
            };
          }),
        });
      },
      [type]
    );
  
    const getShopsOptions = useCallback(
      async (val?: string) => {
        setShopsOptions({ loading: true, list: [] });
  
        const res = await axios.get(
          `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?page=0&limit=20` +
            (type ? `&type=${type}` : ``) +
            (val ? `&key=${val}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShopsOptions({
          loading: false,
          list: res?.data?.shops?.map((shop: any) => {
            return {
              value: shop?.id,
              label: shop?.name,
            };
          }),
        });
      },
      [type]
    );
  
    const getSupplierOptions = useCallback(
      async (val?: string) => {
        setSuppliersOptions({ loading: true, list: [] });
  
        const res = await axios.get(
          `${process.env.REACT_APP_CATALOG_READER_API}/admin/supplier/by-shop` +
            (shopId ? `?shopId=${shopId}` : ``) +
            (val ? `&key=${val}` : ``) +
            (page ? `&page=${page}` : ``) +
            (limit ? `&limit=${limit}` : ``) +
            (type ? `&type=${type}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSuppliersOptions({
          loading: false,
          list: res?.data?.suppliers?.map((supplier: any) => {
            return {
              value: supplier?.id,
              label: supplier?.name,
            };
          }),
        });
      },
      [shopId, page, limit, type]
    );
  
    const hasProduct = (id: string) =>
      selectedProducts?.find((item: any) => item?.id === id);
  
    const fetchRef = useRef(0);
    const handleSearch = React.useMemo(() => {
      const loadOptions = (value: string, field: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
  
        if (fetchId !== fetchRef.current) {
          return;
        }
  
        if (type) {
          if (value) {
            if (field === "product") getProductOptions(value);
            else if (field === "brand") getBrandsOptions(value);
            else if (field === "shop") getShopsOptions(value);
            else if (field === "productByBarcode")
              getProductByBarcodeOptions(value);
          }
        } else {
          responseNotification("Select a type before search", "warning");
        }
      };
  
      return debounce(loadOptions, 800);
    }, [
      getBrandsOptions,
      getProductOptions,
      getShopsOptions,
      getProductByBarcodeOptions,
      type,
    ]);
  
    useEffect(() => {
      setShopsOptions({ list: [], loading: false });
      setBrandId("");
      setBrandsOptions({ list: [], loading: false });
      setCategoryId("");
      form.resetFields([
        "product_search",
        "parentCat_search",
        "category_search",
        "brand_search",
        "shop_search",
      ]);
    }, [form, type]);
  
    useEffect(() => {
      fetchProducts();
    }, [fetchProducts]);
  
    useEffect(() => {
      if (type) {
        getShopsOptions();
        getBrandsOptions();
      }
    }, [type, getShopsOptions, getBrandsOptions]);
  
    useEffect(() => {
      if (shopId) {
        getSupplierOptions();
      }
    }, [getSupplierOptions, shopId]);
  
    useEffect(() => {
      if (parentCatId) {
        setCategoryId(undefined);
        form.resetFields(["category_search"]);
      }
    }, [parentCatId]);
  
    return (
      <div className="product-lists">
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
           
  
            <Form.Item name="barcode_search" initialValue={barCode}>
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "productByBarcode")}
                onSelect={(val: string) => setBarCode(val)}
                options={productBarcodeOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productBarcodeOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Search by BarCode"
                  onSearch={(val) => {
                    setBarCode(val);
                  }}
                  enterButton
                  loading={productBarcodeOptions.loading}
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>
  
            <Form.Item name="product_search" initialValue={key}>
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: string) => setKey(val?.toString())}
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Name"
                  onSearch={(val) => {
                    setKey(val);
                  }}
                  enterButton
                  loading={productOptions.loading}
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>
  
            <Form.Item name="parentCat_search" initialValue={parentCatId}>
              <TreeSelectTwo setCategoryId={setCategoryId} />
            </Form.Item>
  
            <Form.Item name="brand_search">
              <Select
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandsOptions.list}
              />
            </Form.Item>
          </Form>
          <div className="search_btn">
            <Button type="primary" size="large" danger onClick={reseAllFieldData}>
              Reset
            </Button>
          </div>
        </div>
  
        <div className="product-list-item-area white-bg section-padding-bottom">
          <Row gutter={10}>
            <Col span={18} className="">
              <div className="product-table bg-white rounded-md overflow-hidden">
                <div
                  className="content-body"
                  style={{
                    maxHeight: "60vh",
                    height: "60vh",
                    overflow: "scroll",
                  }}
                >
                  <table className={styles.mainTable}>
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Price & Stock</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {productData.loading ? (
                      <Loader />
                    ) : (
                      <tbody>
                        {productData.item?.products?.length ? (
                          productData?.item?.products?.map(
                            (product: any, index: any) => (
                              <tr
                                className="border-t hover:bg-gray-100"
                                key={index}
                              >
                                <td className="flex items-center gap-2">
                                  <Avatar
                                    size={45}
                                    src={product?.productImage}
                                    shape="square"
                                  />
  
                                  <div className="flex flex-col">
                                    <span className="font-medium text-gray-500">
                                      {product?.name?.slice(0, 50)}
                                    </span>
                                    {product?.barCode && (
                                      <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                        <BarcodeOutlined /> {product?.barCode}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="flex_">
                                    {product?.variations?.length
                                      ? product?.variations?.map(
                                          (variant: any, index: string) => (
                                            <React.Fragment key={index}>
                                              <div className="flex justify-content-start mb-1">
                                                <Tag
                                                  color={
                                                    variant?.stock < 10
                                                      ? `red`
                                                      : `green`
                                                  }
                                                >
                                                  {variant?.variationName?.toUpperCase()}
                                                  : <b>{variant?.stock || 0}</b>
                                                </Tag>
  
                                                <Tag>
                                                  MRP{" "}
                                                  {variant?.price?.currencyCode}
                                                  {variant?.price
                                                    ?.discountedPrice ||
                                                    variant?.price?.mrp}
                                                  {variant?.price
                                                    ?.discountedPrice &&
                                                    variant?.price
                                                      ?.discountedPrice !==
                                                      variant?.price?.mrp && (
                                                      <span className="text-red-600 ml-1">
                                                        <del>
                                                          {variant?.price
                                                            ?.currencyCode ||
                                                            "Tk"}
                                                          {variant?.price?.mrp}
                                                        </del>
                                                      </span>
                                                    )}
                                                </Tag>
                                                <Tag>
                                                  TP {variant?.price?.tp}{" "}
                                                  {variant?.price?.currencyCode}
                                                </Tag>
                                              </div>
                                            </React.Fragment>
                                          )
                                        )
                                      : undefined}
                                  </div>
                                </td>
                                <td>
                                  <Button
                                    type="primary"
                                    danger
                                    style={{ minWidth: 105 }}
                                    onClick={async () => {
                                      product?.variations?.length > 1
                                        ? setSelectedVisibleProduct(product)
                                        : await setSelectedProducts(
                                            (prevProducts: any) => {
                                              let arr = Array.from(prevProducts);
                                              arr?.find(
                                                (item: any) =>
                                                  item?.id === product?.id
                                              )
                                                ? (arr = arr.filter(
                                                    (item: any) =>
                                                      item?.id !== product?.id
                                                  ))
                                                : arr.push({
                                                    ...product,
                                                    quantity: 1,
                                                    variation:
                                                      product?.variations?.[0],
                                                  });
                                              return arr;
                                            }
                                          );
                                    }}
                                    disabled={product?.deleted}
                                  >
                                    {selectedProducts?.find(
                                      (item: any) => item?.id === product?.id
                                    ) ? (
                                      product?.variations?.length > 1 ? (
                                        <>
                                          <PlusCircleOutlined /> Change
                                        </>
                                      ) : (
                                        <>
                                          <DeleteOutlined /> Remove
                                        </>
                                      )
                                    ) : product?.variations?.length > 1 ? (
                                      <>
                                        <PlusCircleOutlined /> Add
                                      </>
                                    ) : (
                                      <>
                                        <PlusOutlined /> Add
                                      </>
                                    )}
                                  </Button>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td>
                              <Empty />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <PaginationThree
                {...productData.item}
                limit={limit}
                page={productData?.item?.currentPageNumber || 0}
              />
            </Col>
  
            <Col span={6}>
              <div className="cart-rigth-sidebar bg-white rounded-md h-full">
                {selectedProducts.length ? (
                  selectedProducts?.map((product: any) => (
                    <div className="cart-box" key={product?.id}>
                      <Avatar
                        size={55}
                        shape="square"
                        src={product?.productImage}
                      />
                      <div className="ml-2">
                        <small>{product?.name?.substring(0, 40)}</small>
                        <br />
                        <div className="d-flex align-items-center justify-content-between">
                          <b>{product?.variation?.variationName}</b>
                          <small>
                            &#2547;
                            {product?.variation?.price?.discountedPrice ||
                              product?.variation?.price?.mrp}{" "}
                            {product?.variation?.price?.discountedPrice && (
                              <del className="text-danger">
                                &#2547; {product?.variation?.price?.mrp}
                              </del>
                            )}
                          </small>
                        </div>
  
                        {hasProduct(product.id) && (
                          <span className="cart-action">
                            {product?.quantity !== 1 ? (
                              <Button
                                className="dec-btn"
                                onClick={() => {
                                  setSelectedProducts((prevProducts: any) => {
                                    const arr = Array.from(prevProducts);
                                    const newArr = arr.map((item: any) => {
                                      if (item?.id === product?.id) {
                                        if (item && (item as any).quantity > 1) {
                                          return {
                                            ...item,
                                            quantity:
                                              ((item as any).quantity || 0) - 1,
                                          };
                                        } else {
                                          return item;
                                        }
                                      }
                                      return item;
                                    });
  
                                    return newArr;
                                  });
                                }}
                                disabled={product?.quantity < 2}
                              >
                                <MinusOutlined />
                              </Button>
                            ) : (
                              <Button
                                className="close-btn"
                                onClick={async () => {
                                  await setSelectedProducts(
                                    (prevProducts: any) => {
                                      const arr = Array.from(prevProducts);
                                      const newArr = arr.filter(
                                        (item: any) => item?.id !== product?.id
                                      );
                                      return newArr;
                                    }
                                  );
                                }}
                              >
                                <DeleteOutlined />
                              </Button>
                            )}
                            <Input
                              name="quantity"
                              type="number"
                              value={hasProduct(product.id)?.quantity}
                              onChange={(e) => {
                                const qty = parseInt(e.target.value) || 0;
                                setSelectedProducts((prevProducts: any) => {
                                  const arr = Array.from(prevProducts);
                                  const newArr = arr.map((item: any) => {
                                    if (item?.id === product?.id) {
                                      if (qty > 0) {
                                        return {
                                          ...item,
                                          quantity: qty,
                                        };
                                      } else {
                                        return item;
                                      }
                                    }
                                    return item;
                                  });
  
                                  return newArr;
                                });
                              }}
                              min={1}
                              minLength={1}
                              disabled={
                                product?.quantity >=
                                  product?.variations?.[0]?.stock ||
                                (product?.variations?.[0]?.maximumOrderLimit &&
                                  product?.quantity >=
                                    product?.variations?.[0]?.maximumOrderLimit)
                              }
                            />
  
                            <Button
                              className="inc-btn"
                              onClick={() => {
                                setSelectedProducts((prevProducts: any) => {
                                  const arr = Array.from(prevProducts);
                                  const newArr = arr.map((item: any) => {
                                    if (item?.id === product?.id) {
                                      if (item && (item as any).quantity > 0) {
                                        return {
                                          ...item,
                                          quantity:
                                            ((item as any).quantity || 0) + 1,
                                        };
                                      } else {
                                        return item;
                                      }
                                    }
                                    return item;
                                  });
  
                                  return newArr;
                                });
                              }}
                              disabled={
                                product?.quantity >=
                                  product?.variations?.[0]?.stock ||
                                (product?.variations?.[0]?.maximumOrderLimit &&
                                  product?.quantity >=
                                    product?.variations?.[0]?.maximumOrderLimit)
                              }
                            >
                              <PlusOutlined />
                            </Button>
                          </span>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <Empty />
                )}
              </div>
            </Col>
          </Row>
        </div>
  
        <Modal
          open={selectedVisibleProduct}
          onCancel={() => setSelectedVisibleProduct(undefined)}
          footer={false}
          bodyStyle={{ padding: 0, borderRadius: 15 }}
          keyboard
          mask={true}
        >
          <div className="multiple_variation_modal">
            <div className="text-center mb-4">
              <Avatar
                size={150}
                shape="square"
                src={getImgUrl(selectedVisibleProduct?.productImage)}
              />
              <p className="font-semibold text-gray-500">
                {selectedVisibleProduct?.name}
              </p>
              <Link
                // to={`/shops/${
                //   selectedVisibleProduct?.shop?.id as string
                // }/products/${selectedVisibleProduct?.slug}/details`}
                title="View Details"
                className="text-xs text-gray-500"
                to={liveUrl + `products/` + selectedVisibleProduct?.slug}
                target="_blank"
                rel="noreferer noopener"
              >
                View Details
              </Link>
            </div>
            {selectedVisibleProduct?.variations?.length
              ? selectedVisibleProduct?.variations?.map(
                  (variant: any, index: string) => (
                    <React.Fragment key={index}>
                      <div className="flex justify-between items-center gap-4 border-t border-dotted border-gray-200 py-2">
                        <div className="content">
                          <p>{variant?.variationName?.toUpperCase()}</p>
                          <p>
                            {variant?.price?.currencyCode || "Tk"}{" "}
                            {variant?.price?.discountedPrice ||
                              variant?.price?.mrp}{" "}
                            {variant?.price?.discountedPrice &&
                              variant?.price?.discountedPrice !==
                                variant?.price?.mrp && (
                                <del>
                                  {variant?.price?.currencyCode || "Tk"}{" "}
                                  {variant?.price?.mrp}
                                </del>
                              )}
                          </p>
                        </div>
                        <Button
                          shape="round"
                          type="dashed"
                          onClick={async () => {
                            await setSelectedProducts((prevProducts: any) => {
                              let arr = Array.from(prevProducts);
                              arr?.find(
                                (item: any) =>
                                  item?.id === selectedVisibleProduct?.id &&
                                  variant?.variationId ===
                                    item?.variation?.variationId
                              )
                                ? (arr = arr.filter((item: any) => {
                                    if (
                                      !(
                                        item?.id === selectedVisibleProduct?.id &&
                                        variant?.variationId ===
                                          item?.variation?.variationId
                                      )
                                    ) {
                                      return {
                                        ...item,
                                      };
                                    }
                                  }))
                                : arr.push({
                                    ...selectedVisibleProduct,
                                    quantity: 1,
                                    variation: variant,
                                  });
                              return arr;
                            });
                          }}
                        >
                          {selectedProducts?.find(
                            (item: any) =>
                              item?.variation?.variationId ===
                              variant?.variationId
                          ) ? (
                            <>
                              <DeleteOutlined />{" "}
                              <span className="text-danger">REMOVE</span>
                            </>
                          ) : (
                            <>
                              <ShoppingCartOutlined /> ADD
                            </>
                          )}
                        </Button>
                      </div>
                    </React.Fragment>
                  )
                )
              : undefined}
          </div>
        </Modal>
      </div>
    );
  };
  
  export default OrderProductList;