const Heart = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 22 17"
      fill={props.fill || '#FFF'}
      {...props}
    >
      <path
        d="M19.5012 2.25514C19.0172 1.77085 18.4424 1.38667 17.8098 1.12456C17.1773 0.862448 16.4992 0.727539 15.8145 0.727539C15.1298 0.727539 14.4517 0.862448 13.8192 1.12456C13.1866 1.38667 12.6118 1.77085 12.1278 2.25514L11.1231 3.25976L10.1185 2.25514C9.14075 1.27736 7.81459 0.728046 6.43179 0.728046C5.049 0.728046 3.72284 1.27736 2.74505 2.25514C1.76727 3.23293 1.21796 4.55909 1.21796 5.94188C1.21796 7.32468 1.76727 8.65084 2.74505 9.62862L3.74967 10.6332L11.1231 18.0067L18.4966 10.6332L19.5012 9.62862C19.9855 9.14455 20.3697 8.56981 20.6318 7.93723C20.8939 7.30464 21.0288 6.62662 21.0288 5.94188C21.0288 5.25715 20.8939 4.57912 20.6318 3.94654C20.3697 3.31395 19.9855 2.73921 19.5012 2.25514Z"
        fill={props.fill || '#FFF'}
        stroke="#EAA92A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Heart
