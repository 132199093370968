import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";
import cn from "classnames";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Rate,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  TreeSelectProps,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { UseUIType } from "@components/ui/context";
import { responseNotification } from "../../utils/notify";
import { getImgUrl, getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import memberShipStyles from "./Profile.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import QuickUpdate from "../Product/QuickUpdate";
import moment from "moment";
import AddMembership from "./AddMembership";
import UserHeader from "./UserHeader";
import { isAllowedService } from "../../utils/services";
import {
  AddBtn,
  Loader,
  LoaderFull,
  Pagination,
  TreeSelectTwo,
} from "../common";
import s from "./PaymentMethodView.module.css";
import { useUI } from "../ui/context";

interface PaymentMethodViewProps {
  view: "cart" | "sidebar" | "modal" | "prePayment";
  onModalSubmit?: Function;
  headingSize?: "sm";
  setFinalPayment?: any;
  finalPayment?: number;
  total?: number;
  minPayableAmount?: number;
}

const MembershipList = ({
  view,
  onModalSubmit,
  headingSize,
  total = 0,
  minPayableAmount = 0,
  setFinalPayment,
  finalPayment = 0,
}): ReactElement => {
  const {
    paymentMethod,
    setPaymentMethod,
    setToken,
    setUserData,
    openModal,
    setModalView,
    setLoginFrom,
  }: UseUIType = useUI();

  const router = useLocation();
  const { token, shopId } = useSelector((state) => (state as any)?.authReducer);

  const [membershipLevelId, setMembershipLevelId] = useState<any>("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [key, setKey] = useState("");
  const [brandId, setBrandId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(true);
  const [showSearch, setShowSearch] = useState(true);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);
  const [selectedMembership, setSelectedMembership] = useState(undefined);
  const [selectedMembershipForEdit, setSelectedMembershipForEdit] =
    useState(undefined);

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [barCode, setBarCode] = useState(
    getParamValue(router.search, "barCode")
  );
  const [error, setError] = useState("");
  const checkedProducts = "20";
  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: null,
  });
  const [partialPayment, setPartialPayment] = useState(false);
  const [merchantPaymentMethods, setMerchantPaymentMethods] = useState({
    loading: true,
    list: [],
  });
  const [dataOptions, setDataOptions] = useState<any>({
    loading: false,
    list: [],
  }); 
  const type = "JC_COMMERCE";
  const getProductOptions = useCallback(async () => {
    setDataOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    let url = `?type=${type}`;
    url = encodeURI(url);

    return axios
      .get(`${encodedUri}/payment-method${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMerchantPaymentMethods({
          loading: false,
          list: res?.data?.methods,
        });
        // if (res?.data?.methods?.length) {
        //     res?.data?.methods?.forEach((method: any) => {
        //       if (method.name === 'COD') {
        //         setPaymentMethod(method.name)
        //       }
        //     })
        //     if (!paymentMethod) {
        //       setPaymentMethod(res?.data?.methods[0].name)
        //     }
        //   } else {
        //     setPaymentMethod(null)
        //   }
      })
      .catch((err) => {
        setMerchantPaymentMethods({ loading: false, list: [] });
        console.error("Products: Error", err);
      });
  }, [token]);
  useEffect(() => {
    getProductOptions();
  }, [getProductOptions]);

  const methods = [...merchantPaymentMethods?.list];
  methods?.sort((a: any, b: any) => (a.name.length < b.name.length ? -1 : 1));

  const getOrders = useCallback(async () => {
    setOrderData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/membership-order/my-orders?page=0&limit=20`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOrderData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrderData({ loading: false, data: [] });
        console.error("Memberships: Error", err);
      });
  }, []);

  const reseAllFieldData = () => {
    form.resetFields();
  };
  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const onClose = () => {
    getOrders();
    form.resetFields();
    setVisible(false);
  };
  return (
    <>
      <BreadCrumb
        title="Membership Order List"
        subTitle={`${
          orderData?.data?.totalElements ||
          orderData?.data?.membershipOrders?.length ||
          0
        } Membership(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 membershipMainDiv">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              <div className={memberShipStyles.memberShipTitle}>
                <h1 className={memberShipStyles.memberShipH1}>
                  jachai.com Membership Payment
                </h1>

                {token && (
                  <div
                    className="flex-1"
                    style={headingSize === "sm" ? { padding: 0 } : {}}
                  >
                    <span className="font-semibold text-lg block">
                      Payment Method
                    </span>

                    <div
                      className={s.fieldset}
                      style={headingSize === "sm" ? { display: "none" } : {}}
                    >
                      <label className={s.label}>Available Payments 22</label>
                    </div>

                    <div>
                      {merchantPaymentMethods?.loading ? (
                        <Loader size="sm" />
                      ) : (
                        methods?.map((item: any) => {
                          return (
                            (item?.name === "COD" && view === "prePayment") ||
                            (item?.name === "COD" && view === "modal") || (
                              <div className="flex flex-nowrap items-center mb-3 justify-between min-w-full">
                                <Radio
                                  name="payment"
                                  value={item?.name}
                                  checked={item?.name === paymentMethod}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setPaymentMethod(item?.name);
                                  }}
                                  className="w-full"
                                >
                                  <div className="flex gap-2 items-center justify-between w-full">
                                    <span className="ml-1 whitespace-nowrap">
                                      {item?.name}
                                    </span>
                                    <div className="text-xs text-accent text-right">
                                      {item?.description}
                                    </div>
                                  </div>
                                </Radio>
                                {item?.logo && (
                                  <img
                                    src={getImgUrl(item?.logo)}
                                    height="20"
                                    alt={item?.name}
                                    style={{ height: 20 }}
                                  />
                                )}
                              </div>
                            )
                          );
                        })
                      )}

                      {paymentMethod !== "COD" && (
                        <>
                          <div className="flex items-center bg-accent-1 p-2 rounded-md mb-4">
                            <Radio.Group
                              onChange={(e: any) =>
                                setPartialPayment(e.target.value)
                              }
                              value={partialPayment}
                            >
                              <Radio name="paymentType" value={false}>
                                Full
                              </Radio>
                              <Radio name="paymentType" value={true}>
                                Partial
                              </Radio>
                            </Radio.Group>
                            {partialPayment && (
                              <Input
                                type="number"
                                min={1}
                                onChange={(value: any) => {
                                  const val = value ? parseFloat(value) : 0;
                                  if (val > total) {
                                  } else if (val < minPayableAmount) {
                                    setFinalPayment(minPayableAmount);
                                  } else setFinalPayment(val);
                                }}
                                defaultValue={total}
                              />
                            )}
                          </div>

                          {partialPayment && minPayableAmount > 0 && (
                            <span className={cn("py-2 text-sm text-accent-12")}>
                              Minimun payable amount {minPayableAmount}
                            </span>
                          )}
                        </>
                      )}
                      <div id="msg-pay-amount" className="text-sm py-2" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipList;
