const Message = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8987_22997)">
        <path
          d="M12.4053 9.46875C12.4053 9.77817 12.2824 10.0749 12.0636 10.2937C11.8448 10.5125 11.548 10.6354 11.2386 10.6354H4.23861L1.90527 12.9687V3.63542C1.90527 3.326 2.02819 3.02925 2.24698 2.81046C2.46577 2.59167 2.76252 2.46875 3.07194 2.46875H11.2386C11.548 2.46875 11.8448 2.59167 12.0636 2.81046C12.2824 3.02925 12.4053 3.326 12.4053 3.63542V9.46875Z"
          stroke="#047E94"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8987_22997">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.155273 0.71875)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Message
