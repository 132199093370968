import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Select, Switch, Tooltip } from "antd";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import { ImageUploader, SubmitResetBtn } from "../../common";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { jcBankTypeArray } from "../../../utils";

const BankDetailsAdd = ({ refetch, visibleData,getBankType, onCloseMethod }: any) => {
  const { token, shopInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [error, setError] = useState("");
  const shopId = shopInfo?.id;
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [bankType, setBankType] = useState<any>(getBankType);
  const [isDefault, setIsDefault] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [shopData, setShopData] = useState<any>(undefined);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [form] = Form.useForm();
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      bankingType: bankType,
      accountName: `${data.accountName}`,
      accountNumber: `${data.accountNumber}`,
      bankName: `${data.name}`,
      branchName: `${data.branchName}`,
      routingNumber: `${data.routingNumber}`,
      isDefault: isDefault,
      shopId: shopId,
      uploadBankChequeCopy: generatedImgUrl ? generatedImgUrl : "",
    };

    if (shopData) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop-bank/update-bank`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: shopData?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Updated Successfully", "success");
            form.resetFields();
            window.location.reload();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop-bank/add-bank`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Create Successfully", "success");
            form.resetFields();
            window.location.reload();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    if (visibleData) {
      setShopData(visibleData);
      setBankType(visibleData?.bankingType || getBankType);
      setIsDefault(visibleData?.isDefault);
      setGeneratedImgUrl(visibleData?.uploadBankChequeCopy);
    }
  }, [visibleData]);
  useEffect(() => {
    if (shopData) {
      form.resetFields(Object.keys(visibleData));
      setBankType(shopData?.bankingType || getBankType);
      setIsDefault(shopData?.isDefault);
      setGeneratedImgUrl(visibleData?.uploadBankChequeCopy);
    }
  }, [shopData, form, visibleData]);

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      layout="vertical"
      initialValues={{
        ...shopData,
        accountName: shopData?.accountName || "",
        bankName: shopData?.name || "",
      }}
    >
      <div className="grid grid-cols-2 gap-4">
        <Form.Item
          hasFeedback
          label="Bank Type"
          rules={[
            {
              required: false,
              message: "Bank Type is Required!",
            },
          ]}
          name="bankType"
          initialValue={bankType}
        >
          <Select placeholder="Bank Type" onChange={(val) => setBankType(val)}>
            {Object.values(jcBankTypeArray)?.map((type, i) => (
              <Select.Option value={type} key={i}>
                {type?.replace("_", " ")}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="accountName"
          hasFeedback
          label="Account Name"
          rules={[
            {
              required: true,
              message: "Account Name is Required!",
            },
          ]}
        >
          <Input
            type="text"
            id="accountName"
            className="input-box"
            placeholder="Enter account name"
          />
        </Form.Item>

        <Form.Item
          name="accountNumber"
          hasFeedback
          label="Account Number"
          rules={[
            {
              required: true,
              message: "Account Number is Required!",
            },
          ]}
        >
          <Input
            id="accountNumber"
            type="text"
            className="input-box"
            placeholder="Enter account number"
          />
        </Form.Item>

        <Form.Item
          name="name"
          hasFeedback
          label="Bank Name"
          rules={[
            {
              required: true,
              message: "Bank Name is Required!",
            },
          ]}
        >
          <Input
            id="name"
            type="text"
            className="input-box"
            placeholder="Enter bank name"
          />
        </Form.Item>

        {bankType === "BANKING" && (
          <>
            <Form.Item
              name="branchName"
              hasFeedback
              label="Branch Name"
              rules={[
                {
                  required: true,
                  message: "Branch Name is Required!",
                },
              ]}
            >
              <Input
                id="branchName"
                type="text"
                className="input-box"
                placeholder="Enter branch name"
              />
            </Form.Item>

            <Form.Item
              name="routingNumber"
              hasFeedback
              label="Routing Number"
              rules={[
                {
                  required: true,
                  message: "Routing Number is Required!",
                },
              ]}
            >
              <Input
                id="routingNumber"
                type="text"
                className="input-box"
                placeholder="Enter routing number"
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          hasFeedback
          label="IS Defaul?"
          name="isDefault"
          style={{ minWidth: "100%" }}
        >
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={shopData?.isDefault}
            onChange={setIsDefault}
          />
        </Form.Item>

        <div className="mr-4">
          <Divider orientation="left">
          {bankType === "BANKING" && (
            <>
            Upload Bank Cheque Copy
            </>
          )}
          {bankType === "MOBILE_BANKING" && (
            <>
           Logo
            </>
          )}

            <Tooltip
              placement="bottom"
              title={
                "Upload Bank Cheque Copy should be square. We prefer height 200px and width 200px (Ratio 1)"
              }
            >
              <QuestionCircleOutlined className="px-2 -mt-2" />
            </Tooltip>
          </Divider>
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => {
              setStartUpload(val);
            }}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
          />
        </div>


      </div>

      <SubmitResetBtn
        onClickSubmit={(e) => {
          e.preventDefault();
          setStartUpload("Uploading");
        }}
        onClickReset={() => {
          form?.resetFields();
          setStartUpload(undefined);
        }}
        disabled={loading || !!error}
        loading={loading}
      />
    </Form>
  );
};

export default BankDetailsAdd;
