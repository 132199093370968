import {
  DownOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import styles from "./Profile.module.css";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  TimePicker,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
interface EmptyProps {
  height?: string;
  data?: string;
}
import { debounce } from "lodash";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import ShopLayout from "../Layout";
import moment from "moment";
import { ImageUploader, SubmitResetBtn } from "../../common";
import TabPane from "antd/lib/tabs/TabPane";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const { CheckableTag } = Tag;
const businessDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const ShopBusinessInfo = () => {
  const { type, shopInfo, merchantInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams(); 
  const shopId = shopInfo?.id;
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tinUrl, setTinUrl] = useState<string>();
  const [memorandumUrl, setMemorandumUrl] = useState<string>();
  const [resolutionUrl, setResolutionUrl] = useState<string>();
  const [tradeUrl, setTradeUrl] = useState<string>();
  const [binUrl, setBinUrl] = useState<string>();
  const [utilityUrl, setUtilityUrl] = useState<string>();
  const [bankUrl, setBankUrl] = useState<string>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [bannerUrl, setBannerUrl] = useState<string>();
  const [selectedStartTime, setSelectedStartTime] = useState<any>("00:00");
  const [selectedCloseTime, setSelectedCloseTime] = useState<any>("00:00");

  const [singleBusinessInfo, setSingleBusinessInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedDays = checked
      ? [...selectedDays, tag]
      : selectedDays.filter((t) => t !== tag);
    setSelectedDays(nextSelectedDays);
  };

  const fetchBusinessDetails = useCallback((shopId: any) => {
    if (shopId) {
      try {
        setSingleBusinessInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/merchant-shop/my-shop-details?shopId=${shopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleBusinessInfo({
                loading: false,
                data: data?.data?.shop,
              });
              if(data?.data?.shop?.merchantBusinessInfo?.businessDay){
                setSelectedDays(
                  data?.data?.shop?.merchantBusinessInfo?.businessDay.map(
                    (tag) => tag?.businessDayName
                  )
                );
              }

              setTinUrl(
                data?.data?.shop?.merchantBusinessInfo?.attachTinDocuments
              );
              setTradeUrl(
                data?.data?.shop?.merchantBusinessInfo
                  ?.attachTradeLicenceDocument
              );
              setBankUrl(data?.data?.shop?.merchantBusinessInfo?.bankDocuments);
              setMemorandumUrl(
                data?.data?.shop?.merchantBusinessInfo?.memorandum
              );
              setResolutionUrl(
                data?.data?.shop?.merchantBusinessInfo?.resolution
              );
              setUtilityUrl(
                data?.data?.shop?.merchantBusinessInfo?.utilityBillDocuments
              );
              setLogoUrl(data?.data?.shop?.merchantBusinessInfo?.businessLogo);
              setBannerUrl(data?.data?.shop?.merchantBusinessInfo?.bannerLogo);
              setSelectedStartTime(
                data?.data?.shop?.merchantBusinessInfo?.shopOpeningTime ||
                  "00:00"
              );
              setSelectedCloseTime(
                data?.data?.shop?.merchantBusinessInfo?.shopClosingTime ||
                  "00:00"
              );
            } else {
              setSingleBusinessInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleBusinessInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleBusinessInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchBusinessDetails(shopId);
  }, [fetchBusinessDetails, shopId]);

  useEffect(() => {
    if (singleBusinessInfo?.data) {
      form.resetFields();
      setTinUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.attachTinDocuments
      );
      setMemorandumUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.memorandum
      );
      setResolutionUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.resolution
      );
      setTradeUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo
          ?.attachTradeLicenceDocument
      );
      setBankUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.bankDocuments);

      setUtilityUrl(
        singleBusinessInfo?.data?.merchantBusinessInfo?.utilityBillDocuments
      );
      setLogoUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.businessLogo);
      setBannerUrl(singleBusinessInfo?.data?.merchantBusinessInfo?.bannerLogo);
      setSelectedStartTime(
        singleBusinessInfo?.data?.merchantBusinessInfo?.shopOpeningTime ||
          "00:00"
      );
      setSelectedCloseTime(
        singleBusinessInfo?.data?.merchantBusinessInfo?.shopClosingTime ||
          "00:00"
      );
    }
  }, [form, singleBusinessInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    setTinUrl(
      singleBusinessInfo?.data?.merchantBusinessInfo?.attachTinDocuments
    );
  };

  return (
    <>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Business Information"
        subTitle="Business Information"
        extra={
          <>
            <Link
              to={`/shops/${singleBusinessInfo?.data?.id}/business-info-update`}
              className="rounded-full h-8 w-8 border-2 border-white hover:bg-slate-50 text-center text-red-600 align-center"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
              <span className="ml-2">Edit</span>
            </Link>
          </>
        }
      >
        <div className="content-body bg-white rounded-2xl p-4 shadow">
          <div className="grid">
            <dt className="text-sm font-medium text-gray-900">Business Day</dt>
            <dd className="text-sm font-semibold text-gray-500">
              <Space size={[0, 12]} wrap>
                {businessDays.map((tag) => (
                  <CheckableTag
                    key={tag}
                    checked={selectedDays.includes(tag)}
                    onChange={(checked) => handleChange(tag, checked)}
                    className={styles.businessDay}
                  >
                    {tag}
                  </CheckableTag>
                ))}
              </Space>
            </dd>
          </div>
          <dl className="grid grid-cols-1 mt-10 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-medium text-gray-900">
                Shop Opening Time ({selectedStartTime})
              </dt>
              <dd className="text-sm font-semibold text-gray-500">
                {selectedStartTime}
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-900">
                Shop Closing Time (${selectedCloseTime})
              </dt>
              <dd className="text-sm font-semibold text-gray-500">
                {selectedCloseTime}
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-900">
                Business Description
              </dt>
              <dd className="text-sm font-semibold text-gray-500">
                {
                  singleBusinessInfo?.data?.merchantBusinessInfo
                    ?.businessDescription
                }
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-900">E-Tin No</dt>
              <dd className="text-sm font-semibold text-gray-500">
                {singleBusinessInfo?.data?.merchantBusinessInfo?.etinNo}
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-900">
                E-Tin No File
              </dt>
              <dd className="text-sm font-semibold text-gray-500">
                <img
                  className="mb-3 w-24 h-24 rounded-full shadow-lg"
                  src={
                    singleBusinessInfo?.data?.merchantBusinessInfo
                      ?.attachTinDocuments
                  }
                  alt="Bonnie image"
                />
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-900">Memorandum</dt>
              <dd className="text-sm font-semibold text-gray-500">
                <img
                  className="mb-3 w-24 h-24 rounded-full shadow-lg"
                  src={
                    singleBusinessInfo?.data?.merchantBusinessInfo?.memorandum
                  }
                  alt="Bonnie image"
                />
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-900">Resolution</dt>
              <dd className="text-sm font-semibold text-gray-500">
                <img
                  className="mb-3 w-24 h-24 rounded-full shadow-lg"
                  src={
                    singleBusinessInfo?.data?.merchantBusinessInfo?.resolution
                  }
                  alt="Bonnie image"
                />
              </dd>
            </div>
            {singleBusinessInfo?.data?.businessOperationType === "COMPANY" && (
              <>
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Trade License
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {
                      singleBusinessInfo?.data?.merchantBusinessInfo
                        ?.attachTradeLicence
                    }
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Trade License File
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    <img
                      className="mb-3 w-24 h-24 rounded-full shadow-lg"
                      src={
                        singleBusinessInfo?.data?.merchantBusinessInfo
                          ?.attachTradeLicenceDocument
                      }
                      alt="Bonnie image"
                    />
                  </dd>
                </div>

                <div>
                  <dt className="text-sm font-medium text-gray-900">Bin No</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {singleBusinessInfo?.data?.merchantBusinessInfo?.binNumber}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Bin No File
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    <img
                      className="mb-3 w-24 h-24 rounded-full shadow-lg"
                      src={
                        singleBusinessInfo?.data?.merchantBusinessInfo
                          ?.binNumber
                      }
                      alt="Bonnie image"
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Utility Bill
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    <img
                      className="mb-3 w-24 h-24 rounded-full shadow-lg"
                      src={
                        singleBusinessInfo?.data?.merchantBusinessInfo
                          ?.utilityBillDocuments
                      }
                      alt="Bonnie image"
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Bank Document
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    <img
                      className="mb-3 w-24 h-24 rounded-full shadow-lg"
                      src={
                        singleBusinessInfo?.data?.merchantBusinessInfo
                          ?.bankDocuments
                      }
                      alt="Bonnie image"
                    />
                  </dd>
                </div>
              </>
            )}

            <div>
              <dt className="text-sm font-medium text-gray-900">
                Business Logo
              </dt>
              <dd className="text-sm font-semibold text-gray-500">
                <img
                  className="mb-3 w-24 h-24 rounded-full shadow-lg"
                  src={
                    singleBusinessInfo?.data?.merchantBusinessInfo?.businessLogo
                  }
                  alt="Bonnie image"
                />
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-900">
                Business Banner
              </dt>
              <dd className="text-sm font-semibold text-gray-500">
                <img
                  className="mb-3 w-24 h-24 rounded-full shadow-lg"
                  src={
                    singleBusinessInfo?.data?.merchantBusinessInfo?.bannerLogo
                  }
                  alt="Bonnie image"
                />
              </dd>
            </div>
          </dl>
        </div>
      </ShopLayout>
    </>
  );
};

export default ShopBusinessInfo;
