import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopBrandsList from "../../../components/Shop/Brands";

const ShopBrandsPage = () => {
  return (
    <MainLayout>
      <ShopBrandsList />
    </MainLayout>
  );
};

export default ShopBrandsPage;
