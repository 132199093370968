import MainLayout from "../../components/Layouts";
import React from "react";
import MerchantDetails from "../../components/Profile/Personaldetails";


const MerchantDetailsPage = () => {
  return (
    <MainLayout>
      <MerchantDetails />
    </MainLayout>
  ); 
};

export default MerchantDetailsPage;
