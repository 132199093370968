const Cubes = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}

    >
      <path
        d="M17.3759 1.07422H1.82031V16.6298H17.3759V1.07422Z"
        stroke={props.color ?? '#CD113B'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.8192 1.07422H31.2637C28.5022 1.07422 26.2637 3.3128 26.2637 6.07422V11.6298C26.2637 14.3912 28.5023 16.6298 31.2637 16.6298H36.8192C39.5807 16.6298 41.8192 14.3912 41.8192 11.6298V6.07422C41.8192 3.31279 39.5806 1.07422 36.8192 1.07422Z"
        stroke={props.color ?? '#CD113B'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8192 25.5195H26.2637V41.0751H41.8192V25.5195Z"
        stroke={props.color ?? '#CD113B'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3759 25.5195H1.82031V41.0751H17.3759V25.5195Z"
        stroke={props.color ?? '#CD113B'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Cubes
