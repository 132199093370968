import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopRequestedProductList from "../../../components/Shop/Product/RequestedProducts";

const ShopRequestedProductPage = () => {
  return (
    <MainLayout>
      <ShopRequestedProductList />
    </MainLayout>
  );
};

export default ShopRequestedProductPage;
