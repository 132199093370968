import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ShopLayout from "../Layout";
import OrderList from "../../Orders";
import { useSelector } from "react-redux";

const ShopOrdersList = (): ReactElement => {
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <ShopLayout
        shopId={shopInfo?.id}
        title="Order List"
        subTitle="Order List"
        extra={<></>}
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <OrderList inShop={true} getShopId={shopInfo?.id} />
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopOrdersList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopOrdersList;
