import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactElement } from 'react'

const AddBtn = (props: any): ReactElement => {
  return (
    <div className="search-box-shadow add-search-box-shadow">
      <Button
        {...props}
        type="primary"
        danger
        shape="round"
        className="bg-white flex items-center"
      >
        <PlusOutlined /> Add New
      </Button>
    </div>
  )
}
AddBtn.propTypes = {
  onClick: PropTypes.func,
}

export default AddBtn
