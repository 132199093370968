import {
  Avatar,
  Layout,
  Empty,
  Steps,
  Row,
  Col,
  Button,
  Form,
  Input,
} from "antd";
import { CloseOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import { MerchantOrderType, MerchantOrderTypeArray } from "../../../utils";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import { useSelector } from "react-redux";

const SingleOrder = ({
  inModal = false,
  selectedOrder,
  inShop = false,
}: {
  selectedOrder?: any;
  inShop?: boolean;
  inModal?: boolean;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const route = useParams();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [singleOrderInfo, setSingleOrderInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchOrderDetails = useCallback((getOrderId: any) => {
    if (getOrderId) {
      try {
        setSingleOrderInfo({ loading: true, data: undefined });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/order/details-by-merchant?orderId=${getOrderId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleOrderInfo({
                loading: false,
                data: data?.data?.order,
              });
              setSelectedProducts(data?.data?.order?.products || []);
            } else {
              setSingleOrderInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleOrderInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleOrderInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  const editOrder = useCallback(
    (data) => {
      try {
        axios
          .put(
            `${process.env.REACT_APP_ORDER_API}/admin/order`,
            {
              orderId: (route as any)?.orderId || selectedOrder,
              shippingAddress: data?.shippingAddress,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              responseNotification(
                "Sipping Address updated successfully!",
                "success"
              );
              setEdit(false);
              fetchOrderDetails((route as any)?.orderId || selectedOrder);
            } else {
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error, "error");
      }
    },
    [fetchOrderDetails, route, selectedOrder]
  );

  const editOrderProducts = useCallback(() => {
    try {
      axios
        .put(
          `${process.env.REACT_APP_ORDER_API}/admin/order`,
          {
            orderId: (route as any)?.orderId || selectedOrder,
            products: selectedProducts?.map((product) => ({
              productId: product?.id,
              quantity: product?.quantity || 1,
              variationId:
                product?.variations?.[0]?.variationId ||
                product?.variation?.variationId,
            })),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            responseNotification("Product added successfully!", "success");
            fetchOrderDetails((route as any)?.orderId || selectedOrder);
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error, "error");
    }
  }, [fetchOrderDetails, route, selectedOrder, selectedProducts]);

  const [current, setCurrent] = useState(0);

  const updateOrderStatus = (value?: string) => {
    setLoading(true);
    try {
      fetch(
        `${process.env.REACT_APP_ORDER_API}/order/by-merchant?orderId=${
          (route as any).orderId
        }`,

        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            orderId: (route as any).orderId,
            status: value,
            //  || current >= 2
            //   ? "ACCEPTED_BY_MERCHANT"
            //   : "READY_TO_PICKUP",
          }),
        }
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setLoading(false);
            responseNotification(data?.message, "success");
            fetchOrderDetails((route as any).orderId);
          } else {
            setLoading(false);
            responseNotification(data?.message, "error");
          }
        });
    } catch (e) {
      responseNotification(e, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails((route as any)?.orderId);
  }, [fetchOrderDetails, route]);

  useEffect(() => {
    fetchOrderDetails(selectedOrder?.orderId);
  }, [fetchOrderDetails, selectedOrder]);

  const order = singleOrderInfo?.data;

  useEffect(() => {
    if (order) {
      setCurrent(
        Object.values(MerchantOrderTypeArray).indexOf(order?.statusOfMerchant) -
          1
      );
    }
  }, [order]);

  

  return (
    <React.Fragment>
      <main>
        <div
          className={
            inShop
              ? "grid grid-cols-1 xl:grid-cols-1 xl:gap-0"
              : "grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6"
          }
        >
          <div className="col-span-full bg-white rounded-xl">
            <Steps
              size="small"
              percent={100}
              type="navigation"
              current={current}
              // onChange={(val: any) => updateOrderStatus(val)}
              className="site-navigation-steps"
              style={{
                width: "100%",
                overflowX: "scroll",
              }}
              items={Object.values(MerchantOrderType)?.map((type, i) => ({
                title: type
                  // ?.split("ACCEPTED_BY_MERCHANT")
                  // .join("ACCEPTED")
                  ?.split("_")
                  .join(" "),
                subTitle:
                  order?.statusLogs?.find(
                    (log: any) => log?.value === MerchantOrderType[i]
                  ) &&
                  moment(
                    order?.statusLogs?.find(
                      (log: any) => log?.value === MerchantOrderType[i]
                    )?.datetime
                  ).format("lll"),
                disabled: order?.statusLogs?.find(
                  (log: any) => log?.value !== MerchantOrderType[i]
                ),
              }))}
            ></Steps>
          </div>
          <div className="col-span-full">
            <Layout>
              <Content className="main-content-layout">
                <div className="content-body_ rounded-2xl">
                  <div
                    className={`${
                      inShop
                        ? "bg-white rounded- p-2"
                        : "bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6"
                    }`}
                  >
                    {!inModal && (
                      <>
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Base Order ID
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.baseOrderId}
                            </dd>
                          </div>
                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Order ID
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.orderId}
                            </dd>
                          </div>
                          <div className="">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Order Date:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {moment(order?.createdAt).format("lll")}
                            </dd>
                          </div>

                          {/* <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Payment Status
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.paymentStatus?.replace("_", " ")}
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Payment Method
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {order?.paymentMethod}
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Total Paid:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              &#2547;{order?.totalPaid}
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              DUE:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              &#2547;{order?.total - order?.totalPaid}
                            </dd>
                          </div> */}

                          {/* <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              <div className="flex items-center gap-8 justify-between">
                                <span>Shipping Address</span>
                                <Button
                                  type="dashed"
                                  danger
                                  shape="round"
                                  size="small"
                                >
                                  <EditOutlined
                                    onClick={() => setEdit(!edit)}
                                    disabled
                                  />
                                </Button>
                              </div>
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {edit ? (
                                <div style={{ width: "100%" }}>
                                  <Form
                                    name="control-hooks"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    className="ant-form ant-form-vertical"
                                    onFinish={editOrder}
                                    // form={form} // like ref
                                    layout="vertical"
                                    autoComplete="off"
                                  >
                                    <Form.Item
                                      hasFeedback
                                      name="shippingAddress"
                                      initialValue={order?.shippingAddress}
                                    >
                                      <TextArea
                                        placeholder="Shipping Address"
                                        rows={3}
                                      />
                                    </Form.Item>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      danger
                                    >
                                      Done
                                    </Button>
                                  </Form>
                                </div>
                              ) : (
                                <span>{order?.shippingAddress}</span>
                              )}
                            </dd> 
                          </div>*/}
                        </dl>

                        <div className="h-6"></div>
                      </>
                    )}

                    <dl className="grid grid-cols-6 gap-x-4 gap-y-8 sm:grid-cols-6 bg-slate-50 p-4 mt-4 border-b border-gray-100">
                      <dt className="col-span-2 font-semibold">
                        <span>Product</span>
                      </dt>
                      <dt className="font-semibold text-right">
                        <span>Variation</span>
                      </dt>
                      <dt className="font-semibold text-right">
                        <span>Merchant Price</span>
                      </dt>
                      <dt className="font-semibold text-right">
                        <span>Quantity</span>
                      </dt>
                      <dt className="font-semibold text-right">
                        <span className="">Total</span>
                      </dt>
                    </dl>

                    <div>
                      {selectedProducts?.length ? (
                        selectedProducts?.map((product: any, index) => (
                          <React.Fragment key={index}>
                            <dl className="grid grid-cols-6 gap-x-4 gap-y-8 sm:grid-cols-6 items-center border-b border-gray-100 p-2 hover:bg-red-50 align-middle">
                              <dd className="col-span-2">
                                <Avatar
                                  shape="square"
                                  size={45}
                                  alt={product?.name}
                                  src={product?.productImage}
                                  className="mr-2"
                                />

                                <span>{product?.name?.slice(0, 100)}</span>
                              </dd>

                              <div className="single-product text-right">
                                <span className="name">
                                  {product?.variation?.variationName}
                                </span>
                              </div>
                              <dd className="text-right">
                                &#2547;
                                {product?.variation?.price?.tp}
                              </dd>

                              {/* <dd>
                                    <span className="name">
                                      &#2547;
                                      {product?.variation?.price?.mrp}
                                      {product?.variation?.price
                                        ?.discountedPrice !==
                                      product?.variation?.price?.mrp ? (
                                        <>
                                          <span className="text-danger px-1">
                                            {product?.variation?.productDiscount
                                              ?.flat ? (
                                              <>
                                                {" - "}
                                                <del>
                                                  &#2547;
                                                  {
                                                    product?.variation
                                                      ?.productDiscount?.flat
                                                  }
                                                </del>
                                              </>
                                            ) : undefined}
                                            {product?.variation?.productDiscount
                                              ?.percentage ? (
                                              <>
                                                -
                                                <del>
                                                  {
                                                    product?.variation
                                                      ?.productDiscount
                                                      ?.percentage
                                                  }
                                                  %
                                                </del>
                                              </>
                                            ) : undefined}
                                          </span>
                                          {product?.variation?.price
                                            ?.discountedPrice ? (
                                            <span className=" px-1">
                                              {"= "}
                                              &#2547;
                                              {
                                                product?.variation?.price
                                                  ?.discountedPrice
                                              }
                                            </span>
                                          ) : (
                                            <span className="px-1">
                                              {"= "}
                                              &#2547;
                                              {product?.variation?.price?.mrp}
                                            </span>
                                          )}
                                        </>
                                      ) : undefined}
                                    </span>
                                  </dd> */}

                              {order?.statusOfMerchant === "READY_TO_PICKUP" ||
                              order?.status === "COMPLETED" ||
                              order?.status === "DELIVERED" ||
                              order?.status === "CANCELLED" ||
                              order?.status === "ON_DELIVERY" ? (
                                <span className="name text-right">
                                  x {product?.quantity}
                                </span>
                              ) : (
                                <div className="flex justify-center self-center">
                                  <Form.Item
                                    hasFeedback
                                    name="productId"
                                    initialValue={product?.id}
                                    hidden
                                  >
                                    <Input type="hidden" />
                                  </Form.Item>

                                  <Button
                                    shape="circle"
                                    className="rounded-full -mr-6 z-10 bg-white"
                                    onClick={() => {
                                      setSelectedProducts(
                                        (prevProducts: any) => {
                                          const arr = Array.from(prevProducts);
                                          const newArr = arr.map(
                                            (item: any) => {
                                              if (item?.id === product?.id) {
                                                if (
                                                  item &&
                                                  (item as any).quantity > 0
                                                ) {
                                                  return {
                                                    ...item,
                                                    quantity:
                                                      ((item as any).quantity ||
                                                        0) - 1,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                              return item;
                                            }
                                          );

                                          return newArr;
                                        }
                                      );
                                    }}
                                    disabled={product?.quantity <= 1}
                                  >
                                    <MinusOutlined />
                                  </Button>

                                  <Input
                                    name="quantity"
                                    type="number"
                                    value={product?.quantity}
                                    style={{
                                      maxWidth: 90,
                                      textAlign: "center",
                                    }}
                                    readOnly
                                    className="rounded-full"
                                  />

                                  <Button
                                    shape="circle"
                                    className="rounded-full -ml-6 z-10 bg-white"
                                    onClick={() => {
                                      setSelectedProducts(
                                        (prevProducts: any) => {
                                          const arr = Array.from(prevProducts);
                                          const newArr = arr.map(
                                            (item: any) => {
                                              if (item?.id === product?.id) {
                                                if (
                                                  item &&
                                                  (item as any).quantity > 0
                                                ) {
                                                  return {
                                                    ...item,
                                                    quantity:
                                                      ((item as any).quantity ||
                                                        0) + 1,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                              return item;
                                            }
                                          );

                                          return newArr;
                                        }
                                      );
                                    }}
                                    disabled={
                                      product?.quantity >=
                                      product?.variation?.stock
                                    }
                                  >
                                    <PlusOutlined />
                                  </Button>

                                  {selectedProducts?.length > 1 && (
                                    <Button
                                      type="dashed"
                                      danger
                                      shape="circle"
                                      className="ml-2"
                                      onClick={async () => {
                                        await setSelectedProducts(
                                          (prevProducts: any) => {
                                            const arr =
                                              Array.from(prevProducts);
                                            const newArr = arr.filter(
                                              (item: any) =>
                                                item?.id !== product?.id
                                            );
                                            return newArr;
                                          }
                                        );
                                      }}
                                    >
                                      <CloseOutlined />
                                    </Button>
                                  )}
                                </div>
                              )}

                              <dd className="text-right">
                                {product?.variation?.price?.discountedPrice ? (
                                  <span className="name">
                                    &#2547;
                                    {product?.variation?.price?.tp *
                                      product?.quantity}
                                  </span>
                                ) : (
                                  <span className="name">
                                    &#2547;
                                    {product?.variation?.price?.tp *
                                      product?.quantity}{" "}
                                  </span>
                                )}
                              </dd>
                            </dl>
                          </React.Fragment>
                        ))
                      ) : (
                        <Empty />
                      )}
                    </div>

                    <Row justify="end" align="top">
                      <Col span={15} className="mb-5">
                        <div className="flex gap-4 pt-20 h-full place-items-end">
                          {Object.values(MerchantOrderType)?.find(
                            (type, i) => i === current + 1
                          ) && (
                            <Button
                              onClick={() =>
                                updateOrderStatus(
                                  Object.values(MerchantOrderType)?.find(
                                    (type, i) => i === current + 1
                                  )
                                )
                              }
                              size="large"
                              shape="round"
                              type="primary"
                              className="bg-green-600 hover:bg-green-700 text-white font-semibold !px-12 rounded-full border-none"
                            >
                              {Object.values(MerchantOrderType)
                                ?.find((type, i) => i === current + 1)
                                ?.split("ED_TO_MERCHANT")
                                ?.join(" ")
                                ?.split("ED_BY_MERCHANT")
                                ?.join(" ")
                                ?.split("_")
                                ?.join(" ")}
                            </Button>
                          )}

                          <Button
                            onClick={() => {
                              editOrderProducts();
                            }}
                            disabled={
                              selectedProducts?.reduce((a, b) => {
                                return (
                                  a +
                                  b?.quantity *
                                    (b?.variation?.price?.discountedPrice ||
                                      b?.variations?.[0]?.price?.mrp)
                                );
                              }, 0) +
                                order?.deliveryCharge +
                                order?.vat ===
                                order?.total ||
                              order?.status === "COMPLETED" ||
                              order?.status === "DELIVERED" ||
                              order?.status === "CANCELLED" ||
                              order?.statusOfMerchant === "READY_TO_PICKUP"
                            }
                            size="large"
                            danger
                            shape="round"
                            type="primary"
                            className="text-white font-semibold !px-8 rounded-full border-none"
                          >
                            Update Order
                          </Button>
                        </div>
                      </Col>
                      <Col span={9} className="mb-5">
                        <div className="flex justify-between p-1">
                          <span className="font-medium">Sub Total:</span>
                          <span className="info-desc text-right">
                            &#2547;
                            {selectedProducts?.reduce((a: any, b: any) => {
                              return a + b?.quantity * b?.variation?.price?.tp;
                            }, 0)}
                          </span>
                        </div>

                        <div className="flex justify-between p-1">
                          <span className="font-medium">Vat :</span>
                          <span className="info-desc text-right">
                            &#2547;{order?.vat}
                          </span>
                        </div>

                        <div className="flex justify-between p-1">
                          <span className="font-medium">Delivery Charge:</span>
                          <span className="info-desc text-right">
                            &#2547;{order?.deliveryCharge}
                          </span>
                        </div>

                        <div className="flex justify-between p-1">
                          <span className="font-medium">Discount:</span>
                          <span className="info-desc text-right">
                            &#2547;
                            {/* {selectedProducts?.reduce((a, b) => {
                                      return (
                                        a +
                                        b?.quantity *
                                          (b?.variation?.price?.mrp -
                                            (b?.variations?.[0]?.price
                                              ?.discountedPrice ||
                                              b?.variation?.price
                                                ?.discountedPrice))
                                      );
                                    }, 0) + order?.discount} */}
                            {parseInt(order?.discount) -
                              parseInt(order?.promoDiscount)}
                          </span>
                        </div>

                        <div className="flex justify-between p-1">
                          <span className="font-medium">
                            Promo ({order?.promo?.promoCode}):
                          </span>
                          <span className="info-desc text-right">
                            &#2547;
                            {parseInt(order?.promoDiscount)}
                          </span>
                        </div>

                        {/* <div className="flex justify-between p-1">
                              <span className="font-medium">Total Paid</span>
                              <span className="info-desc text-right">
                                &#2547;
                                {parseInt(order?.totalPaid)}
                              </span>
                            </div> */}

                        <div className="flex justify-between p-1">
                          <span className="font-medium">Total:</span>
                          <span className="info-desc text-right">
                            &#2547;
                            {parseInt(order?.merchantAmount)}
                            {/* {selectedProducts?.reduce((a, b) => {
                                      return (
                                        a +
                                        b?.quantity *
                                          (b?.variation?.price?.discountedPrice ||
                                            b?.variations?.[0]?.price?.mrp)
                                      );
                                    }, 0) +
                                      order?.deliveryCharge +
                                      order?.vat -
                                      order?.promoDiscount} */}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Content>
            </Layout>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

SingleOrder.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  selectedProduct: PropTypes.string,
};
export default SingleOrder;
