const Magnifier = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.52062 18.7776C14.4298 18.7776 18.4094 14.7979 18.4094 9.88878C18.4094 4.97964 14.4298 1 9.52062 1C4.61148 1 0.631836 4.97964 0.631836 9.88878C0.631836 14.7979 4.61148 18.7776 9.52062 18.7776Z"
        stroke="#C4C4C4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6301 20.9895L15.7969 16.1562"
        stroke="#C4C4C4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Magnifier
