import { responseNotification } from "../../utils/notify";
import { LOGIN_REQUEST, LOGIN_SUCCESS } from "./authType";

export const login = async (
  dispatch: (arg0: { type: string; payload?: any }) => void,
  { mobileNumber, password, type }: any
) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_API}/merchant/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobileNumber,
          password,
        }),
      }
    );

    const response1 = await res.json();
    const { token } = response1;
    if (token) {
      const merchantShopCheck = await fetch(
        `${process.env.REACT_APP_CATALOG_READER_API}/merchant/all-shops-by-merchant`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const merchantShopInfo = await merchantShopCheck.json();
      const merchantShopInfoCheck = merchantShopInfo?.shops;
      if (merchantShopInfoCheck?.length === 0) {
        //add new condition
        if (response1?.userType === "SUPER_MERCHANT") {
          const res2 = await fetch(
            `${process.env.REACT_APP_RIDER_API}/details`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const merchantInfo = await res2.json();
          const { merchant } = merchantInfo;

          if (merchant) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                ...response1,
                token: token,
                merchantInfo: merchant,
                merchantId: merchant?.merchantId,
                iat: new Date(),
                //type: merchant?.serviceType,
              },
            });

            responseNotification(
              merchantInfo?.message?.replace("Operation", "Login"),
              "success"
            );
            return "newMerchant";
            //return true;
          } else {
            responseNotification(
              merchantInfo?.message?.replace(
                "Operation Successful",
                "No data found"
              ),
              "error"
            );

            return false;
          }
        } else {
          const res2 = await fetch(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/details`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const shopInfo = await res2.json();
          const { shop } = shopInfo;

          if (shop) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                ...response1,
                token: token,
                shopInfo: shop,
                shopId: shop?.id,
                iat: new Date(),
                //type: shop?.type || "JC_COMMERCE",
              },
            });

            responseNotification(
              shopInfo?.message?.replace("Operation", "Login"),
              "success"
            );
            return "newMerchant";
            //return true;
          } else {
            responseNotification(
              shopInfo?.message?.replace(
                "Operation Successful",
                "No data found"
              ),
              "error"
            );

            return false;
          }
        }
        //add new condition end
        //return "newMerchant";
      } else {
        if (response1?.userType === "SUPER_MERCHANT") {
          const res2 = await fetch(
            `${process.env.REACT_APP_RIDER_API}/details`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const merchantInfo = await res2.json();
          const { merchant } = merchantInfo;

          if (merchant) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                ...response1,
                token: token,
                merchantInfo: merchant,
                merchantId: merchant?.merchantId,
                iat: new Date(),
                //type: merchant?.serviceType,
              },
            });

            responseNotification(
              merchantInfo?.message?.replace("Operation", "Login"),
              "success"
            );
            return "oldMerchant";
            //return true;
          } else {
            responseNotification(
              merchantInfo?.message?.replace(
                "Operation Successful",
                "No data found"
              ),
              "error"
            );

            return false;
          }
        } else {
          const res2 = await fetch(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/details`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const shopInfo = await res2.json();
          const { shop } = shopInfo;

          if (shop) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                ...response1,
                token: token,
                shopInfo: shop,
                shopId: shop?.id,
                iat: new Date(),
                //type: shop?.type || "JC_COMMERCE",
              },
            });

            responseNotification(
              shopInfo?.message?.replace("Operation", "Login"),
              "success"
            );
            return "oldMerchant";
            //return true;
          } else {
            responseNotification(
              shopInfo?.message?.replace(
                "Operation Successful",
                "No data found"
              ),
              "error"
            );

            return false;
          }
        }
      }
    } else {
      responseNotification(response1?.message || "No data found", "error");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
