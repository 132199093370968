import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopProductAdd from "../../../components/Shop/Product/RequestProductAdd";

const ShopProductEditPage = () => {
  return (
    <MainLayout>
      <ShopProductAdd />
    </MainLayout>
  );
};

export default ShopProductEditPage;
