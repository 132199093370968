import axios from "axios";
import PropTypes from "prop-types";

import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import MerchantLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_MERCHANT_INFO } from "../../../redux/auth/authType";
import Loader from "../../common/Loader";
import { Button, Divider, Drawer, Popconfirm, Radio, Tabs } from "antd";
import BankDetailsAdd from "./Add";
import AddShopAddress from "./Add";
import { Edit, Trash } from "../../icons";
import { AddBtn } from "../../common";

const MerchantBankDetails = ({
  inCampaign = false,
  inShop = false,
  getShopId,
}: {
  inCampaign?: boolean;
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const { token, shopInfo, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const shopId = shopInfo?.id || getShopId;
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [getBankType, setGetBankType] = useState("BANKING");
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedBank, setSelectedBank] = useState(undefined);
  const [selectedShopAddressForEdit, setSelectedShopAddressForEdit] =
    useState(undefined);
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const fetchMerchantDetails = useCallback(() => {
    try {
      setSingleMerchantInfo({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/shop-bank/getAllBank?shopId=${shopId}&bankingType=${getBankType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setSingleMerchantInfo({
              loading: false,
              data: data?.data?.shopBanks,
            });
          } else {
            setSingleMerchantInfo({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleMerchantInfo({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setSingleMerchantInfo({ loading: false, data: null });

      console.log(error, "error");
    }
  }, [getBankType]);

  useEffect(() => {
    fetchMerchantDetails();
  }, [fetchMerchantDetails, getBankType]);

  const refetch = () => {
    fetchMerchantDetails();
    setAdd(false);
  };

  const merchant = singleMerchantInfo?.data;
  const onClose = () => {
    setVisible(undefined);
    setSelectedBank(undefined);
    setSelectedShopAddressForEdit(undefined);
  };

  if (singleMerchantInfo.loading) return <Loader />;

  return (
    <React.Fragment>
      <div className="content-body rounded-2xl bg-white p-4 min-h-full">
        <div className="flex justify-between border-b pb-3 mb-8 border-gray-100">
          <h4 className="font-semibold text-xl text-gray-600">Bank Details</h4>
          <Radio.Group
            onChange={(e) => setGetBankType(e.target.value)}
            defaultValue={getBankType}
          >
            <Radio value={"BANKING"} checked>
              BANKING
            </Radio>
            <Radio value="MOBILE_BANKING">MOBILE BANKING</Radio>
          </Radio.Group>
          <AddBtn onClick={() => setVisible(true)} key={2} />,
          {/* <Button shape="round" type="dashed" onClick={() => setVisible(true)} >
            Add
          </Button> */}
        </div>

        {/* Business Bank Detail */}

        <div className="">
          <div className="grid grid-cols-2 gap-6">
            {merchant?.map((bankData: any) => (
              <>
                <div className="shadow-sm_  bg-gray-50 rounded-md">
                  <Divider orientation="left">
                    {bankData?.name}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e?.stopPropagation();
                        setSelectedShopAddressForEdit(bankData);
                      }}
                      className="px-2"
                    >
                      <Edit height={14} width={14} />
                    </button>
                  </Divider>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 p-4 pt-0">
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Account Name
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {bankData?.accountName}
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Account Number
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {bankData?.accountNumber}
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Branch Name
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {bankData?.branchName}
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Routing Number
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {bankData?.routingNumber}
                      </dd>
                    </div>
                  </dl>
                </div>
              </>
            ))}
          </div>
          <Drawer
            title={
              visible
                ? "Add Bank Information"
                : selectedShopAddressForEdit
                ? "Edit Bank Information"
                : "Shop Bank Details"
            }
            placement="right"
            onClose={onClose}
            open={visible || selectedBank || selectedShopAddressForEdit}
            width={450}
          >
            {visible || selectedShopAddressForEdit ? (
              <AddShopAddress
                onCloseMethod={onClose}
                visibleData={selectedShopAddressForEdit}
                getBankType={getBankType}
              />
            ) : (
              ""
            )}
          </Drawer>
        </div>
      </div>
    </React.Fragment> 
  );
};

MerchantBankDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MerchantBankDetails;
