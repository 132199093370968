import React, { FC } from "react";
import cn from "classnames";
import s from "./WhyUs.module.css";
import Container from "../../ui/Container";
import ServiceImg from "../../../images/services.svg";
import Advertising from "../../../images/icon/advertising.png";
import Registration from "../../../images/icon/registration.png";
import Trust from "../../../images/icon/trust.png";
import PaymentDay from "../../../images/icon/payment-day.png";
import Coupon from "../../../images/icon/coupon.png";
import Comments from "../../../images/icon/comments.png";

interface Props {
  className?: string;
  children?: any;
  pages?: any;
}

const HomeWhyUs: FC<Props> = ({ className, pages }: any) => {
  const rootClassName = cn(s.root, className);

  const DATAS = [
    {
      icon: Advertising,
      title: "Reach",
      description:
        "Millions of customers on Jachai visited the platform for the purpose of shopping",
    },
    {
      icon: Registration,
      title: "Free Registration",
      description:
        "There is no hassle with registration. Anyone can register and do their business freely",
    },
    {
      icon: Trust,
      title: "Reliable Shipping",
      description:
        "Fast and reliable delivery service through the Jachai logistic network",
    },
    {
      icon: PaymentDay,
      title: "Timely Payments",
      description:
        "Cash on Delivery, and there are so many payment methods available",
    },
    {
      icon: Coupon,
      title: "Marketing Tools",
      description:
        "Find new customers and grow more with advertising and our whole range of marketing tools",
    },
    {
      icon: Comments,
      title: "Support & Training",
      description:
        "Learn all about e-commerce for free and get help with seller support and the Jachai Merchant Hub",
    },
  ];

  return (
    <section className={cn(rootClassName)}>
      <Container fluid={false}>
        <div className="block transition-colors duration-150 text-secondary">
          <div className="text-center">
            <div className="w-full lg:max-w-3xl mx-auto">
              <h2 className="text-red-600 text-2xl lg:text-4xl font-bold mb-6 uppercase">
                Why Sell on Jachai?
              </h2>
              <p className="text-md lg:text-lg">
                Jachai.com simplifies the lives of small business owners with
                its all-in-one e-commerce platform that's tailored to meet every
                business's unique merchant service needs.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 py-10 text-slate-700">
            {DATAS?.map((data: any, i) => (
              <div
                className={cn(
                  "flex items-center md:flex-row gap-4 bg-white p-4 rounded-md border border-slate-100",
                  { ["flex-row-reverse"]: i % 2 !== 0 }
                )}
                key={i}
              >
                <div className="bg-gray-200 rounded-full p-4">
                  <img src={data?.icon} alt="" />
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-lg">{data?.title}</span>
                  <span>{data?.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeWhyUs;
