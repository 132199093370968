import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import SubmitResetBtn from "../../common/SubmitBtn";
import { ImageUploader } from "../../common";
import { useNavigate } from "react-router";

const AddKam = ({ refetch }: any) => {
  const { token, type } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrl2, setImageUrl2] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >(undefined);
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >(undefined);

  const onSubmit = (data) => {
    if (imageUrl || imageUrl2) {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_RIDER_API}/merchant/acquisition-info`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            agreementDocument: imageUrl,
            agreementSigningPicture: imageUrl2,
            mobileNumber: data.mobileNumber,
          }),
        })
          .then((data) => data.json())
          .then((data) => {
            setLoading(false);
            refetch();
            responseNotification(data?.message, "success");
            navigate("/merchant/my-profile");
          });
      } catch (e) {
        setLoading(false);
        responseNotification("Operation successful" || "Operation failed");
      }
    } else {
      responseNotification(`Product Thumbnail & Image required`, "error");
    }
  };

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form.submit();
    }
  }, [startUpload, startUpload2]);

  const resetData = () => {
    form?.resetFields();
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <Form onFinish={onSubmit} form={form} layout="vertical">
      <Form.Item
        name="mobileNumber"
        hasFeedback
        label="KAM Mobile Number"
        rules={[
          {
            required: true,
            message: "Mobile Number is Required!",
          },
        ]}
      >
        <Input
          id="mobileNumber"
          className="input-box"
          placeholder="Enter Mobile Number"
        />
      </Form.Item>

      <div className="flex gap-10 mb-6">
        <div className="basis-0">
          <span className="text-md">Agreement Copy</span>
          <ImageUploader
            imgUrl={imageUrl}
            startUpload={startUpload}
            setStartUpload={(val) => setStartUpload(val)}
            setGeneratedImgUrl={(url) => setImageUrl(url)}
          />
        </div>

        <div className="basis-0">
          <span className="text-md">
            {type === "JC_FOOD" ? "Restaurant Menu" : "Product List"}
          </span>
          <ImageUploader
            imgUrl={imageUrl2}
            startUpload={startUpload}
            setStartUpload={(val) => setStartUpload2(val)}
            setGeneratedImgUrl={(url) => setImageUrl2(url)}
          />
        </div>
      </div>

      <SubmitResetBtn
        onClickSubmit={(e) => {
          e.preventDefault();
          setStartUpload("Uploading");
          setStartUpload2("Uploading");
        }}
        onClickReset={resetData}
        disabled={
          loading ||
          (startUpload === "Uploading" && startUpload2 === "Uploading")
        }
        loading={
          loading ||
          (startUpload === "Uploading" && startUpload2 === "Uploading")
        }
      />
    </Form>
  );
};

export default AddKam;
