const Edit = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 512 512"
      height={18}
      width={18}
      {...props}
    >
      <g data-name="<Group>">
        <path
          fill="#fff493"
          d="M436.52,88.24a34.988,34.988,0,0,1,0,49.49l-17.68,17.68L357.61,94.18,375.29,76.5a35,35,0,0,1,49.5,0Z"
        ></path>
        <polygon
          fill="#fedb41"
          points="418.84 155.41 258.69 315.57 258.68 315.57 197.45 254.34 357.61 94.18 418.84 155.41"
        ></polygon>
        <polygon
          fill="#fedb41"
          points="197.45 254.34 258.68 315.57 258.11 316.14 173.42 339.6 196.88 254.91 197.45 254.34"
        ></polygon>
        <path
          fill="#083863"
          d="M425.82,306.96a10,10,0,0,0-10,10v88.79a30.034,30.034,0,0,1-30,30H105.23a30.034,30.034,0,0,1-30-30V145.16a30.034,30.034,0,0,1,30-30h88.789a10,10,0,0,0,0-20H105.23a50.057,50.057,0,0,0-50,50V405.75a50.057,50.057,0,0,0,50,50H385.82a50.057,50.057,0,0,0,50-50V316.96A10,10,0,0,0,425.82,306.96Z"
        ></path>
        <path
          fill="#083863"
          d="M431.861,69.429a45.054,45.054,0,0,0-63.643,0l-178.41,178.41a10,10,0,0,0-2.565,4.4l-23.46,84.69a10,10,0,0,0,12.306,12.307l84.69-23.46a10,10,0,0,0,4.407-2.572l.569-.57,0,0L443.59,144.8a44.985,44.985,0,0,0,0-63.629ZM202.186,273.217,239.8,310.835l-52.03,14.413Zm56.5,42.357v-.01h0l6.521,6.52Zm0-14.141L211.592,254.34,357.61,108.322,404.7,155.41ZM429.448,130.659,418.84,141.268,371.752,94.18l10.609-10.609a25.027,25.027,0,0,1,35.354,0L429.45,95.314a24.988,24.988,0,0,1,0,35.345Z"
        ></path>
      </g>
    </svg>
  )
}

export default Edit
