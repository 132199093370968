import MainLayout from "../../../components/Layouts";
import { ReactElement } from "react";
import OrderCreate from "../../../components/Shop/Order/ManualOrderCreate";
import React from "react";

const OrderCreatePage = (): ReactElement => {
  return (
    <MainLayout> 
      <OrderCreate />
    </MainLayout>
  );
};

export default OrderCreatePage;