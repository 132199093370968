const Currency = ({ ...props }) => {
  return (
    <span className="flex items-baseline">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="14"
        viewBox="0 0 8 12"
        fill="none"
        {...props}
        // className="mr-1"
        color={props.color || 'inherit'}
      >
        <path
          d="M7.25177 4.94823C7.25177 4.21407 6.54392 3.63899 5.64027 3.63899C4.73661 3.63899 4.02876 4.21407 4.02876 4.94823C4.02876 5.06397 4.07121 5.17498 4.14676 5.25682C4.22231 5.33866 4.32479 5.38464 4.43164 5.38464C4.53849 5.38464 4.64096 5.33866 4.71651 5.25682C4.79207 5.17498 4.83451 5.06397 4.83451 4.94823C4.83451 4.77028 5.1484 4.51182 5.64027 4.51182C6.13213 4.51182 6.44602 4.77028 6.44602 4.94823C6.44602 7.67581 4.67286 9.92256 2.41726 10.1637V5.38464H2.82013C2.92698 5.38464 3.02946 5.33866 3.10501 5.25682C3.18056 5.17498 3.22301 5.06397 3.22301 4.94823C3.22301 4.83249 3.18056 4.72148 3.10501 4.63964C3.02946 4.5578 2.92698 4.51182 2.82013 4.51182H2.41726V2.76617C2.41666 2.18765 2.20424 1.63301 1.8266 1.22393C1.44896 0.81486 0.936939 0.584756 0.402876 0.584106C0.296027 0.584106 0.193553 0.630085 0.117999 0.711928C0.0424454 0.793771 0 0.904775 0 1.02052C0 1.13626 0.0424454 1.24727 0.117999 1.32911C0.193553 1.41095 0.296027 1.45693 0.402876 1.45693C0.72331 1.45734 1.03051 1.5954 1.25709 1.84084C1.48367 2.08629 1.61113 2.41906 1.6115 2.76617V4.51182H0.402876C0.296027 4.51182 0.193553 4.5578 0.117999 4.63964C0.0424454 4.72148 0 4.83249 0 4.94823C0 5.06397 0.0424454 5.17498 0.117999 5.25682C0.193553 5.33866 0.296027 5.38464 0.402876 5.38464H1.6115V10.6216C1.6115 10.7373 1.65395 10.8483 1.7295 10.9302C1.80506 11.012 1.90753 11.058 2.01438 11.058C4.9023 11.058 7.25177 8.31717 7.25177 4.94823Z"
          fill={props?.color ?? '#3A494E'}
        />
      </svg>

      {props?.amount !== undefined && (
        <span
          className="text-lg font-semibold"
          style={{
            color: props.color,
            display: 'inline-block',
          }}
        >
          {`${props?.amount}`?.replace('BDT', '')}
        </span>
      )}
    </span>
  )
}

export default Currency
