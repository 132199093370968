import React from "react";
import MainLayout from "../../../components/Layouts";
import CampaignProductAssign from "../../../components/Promotions/Campaign/Product/AssignProduct";

const CampaignAssignProductPage = () => {
  return (
    <MainLayout>
      <CampaignProductAssign />
    </MainLayout>
  );
};

export default CampaignAssignProductPage;
