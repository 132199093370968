const Follow = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      {...props}
    >
      <path
        d="M16.9883 19.6841V17.6841C16.9883 16.6232 16.5669 15.6058 15.8167 14.8557C15.0666 14.1055 14.0491 13.6841 12.9883 13.6841H5.98828C4.92742 13.6841 3.91 14.1055 3.15985 14.8557C2.40971 15.6058 1.98828 16.6232 1.98828 17.6841V19.6841"
        stroke="#6D6E70"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.48828 9.68408C11.6974 9.68408 13.4883 7.89322 13.4883 5.68408C13.4883 3.47494 11.6974 1.68408 9.48828 1.68408C7.27914 1.68408 5.48828 3.47494 5.48828 5.68408C5.48828 7.89322 7.27914 9.68408 9.48828 9.68408Z"
        stroke="#6D6E70"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9883 9.68408L19.9883 11.6841L23.9883 7.68408"
        stroke="#6D6E70"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Follow
