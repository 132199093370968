//@ts-nocheck
import React, {FC, useCallback, useMemo} from 'react'
import {
  Product,
  ProductVariation,
  ProductWithVariation,
} from '@commerce/types/product'
import {AddressFields} from '@commerce/types/customer/address'
import {Coordinate, CurrentLoc, Customer} from '@commerce/types/customer'
import {Shop} from '@commerce/types/shop'
import {Category} from '@commerce/types/content'
import {Promo} from '@commerce/types/promo'
import {Page} from '@commerce/types/page'
import {DeliveryMethodsState} from '@commerce/types/deliveryMethods'
import {ProductCheckedState} from '@commerce/types/productChecked'
import productCard from "@components/product/ProductCard";

export type UseUIType = State & {
  openSidebar: () => void
  closeSidebar: () => void
  toggleSidebar: () => void
  closeSidebarIfPresent: () => void
  openDropdown: () => void
  closeDropdown: () => void
  openModal: () => void
  closeModal: () => void
  setModalView: (value: MODAL_VIEWS) => void
  setSidebarView: (value: SIDEBAR_VIEWS) => void
  setUserAvatar: (value: string) => void
  setPaymentMethod: (value: PAYMENT_METHODS | null) => void
  setShippingAddress: (value: AddressFields | null) => void

  addDeliveryMethods: (value: { shopId: any, schedule: any, methodName: any, deliveryCharge: any }) => void
  productChecked: (value: { shopId: any, type: any, productId: any }) => void
  addProductToCart: (value: ProductWithVariation) => void
  updateProductFromCart: (value: ProductWithVariation) => void
  removeProductFromCart: (value: ProductWithVariation) => void
  setToken: (value: string) => void
  setInitialDeliveryCharge: (value: string) => void
  setPromoDiscount: (value: string) => void
  resetCart: () => void
  addPromo: (value: Promo) => void
  removePromo: () => void
  addProductToWishlist: (value: Product) => void
  removeProductFromWishlist: (value: Product) => void
  resetWishlist: () => void
  setUserData: (value: Customer | null) => void
  setCoor: (value: Coordinate | null) => void
  setMyShop: (value: string | null) => void
  setMyShopInfo: (value: Shop | null) => void
  setCurrentLoc: (value: AddressFields | null) => void
  setLoginFrom: (value: LOGIN_FROM | null) => void
  setCategories: (value: Category[]) => void
  setDynamicPage: (value: Page[]) => void
}

export interface State {
  displaySidebar: boolean
  displayDropdown: boolean
  displayModal: boolean
  sidebarView: string
  modalView: string
  userAvatar: string | null
  paymentMethod: string | null
  shippingAddress: AddressFields | null
  products: ProductWithVariation[]
  productsCart: []
  subTotalSalesPriceV1: 0
  subTotalMRPPriceV1: 0
  subTotalSalesPricePickupV1: 0
  subTotalMRPPricePickupV1: 0
  deliveryMethods: []
  token: string
  initialDeliveryCharge: string
  promoDiscount: string
  promo: Promo | null
  wishlist: Product[]
  user: Customer | null
  coor: Coordinate | null
  myShop: string
  myShopInfo: Shop | null
  currentLoc: AddressFields | null
  loginFrom: LOGIN_FROM | null
  categories: Category[]
  dynamicPage: Page[]
}

type Action =
  | {
  type: 'OPEN_SIDEBAR'
}
  | {
  type: 'CLOSE_SIDEBAR'
}
  | {
  type: 'OPEN_DROPDOWN'
}
  | {
  type: 'CLOSE_DROPDOWN'
}
  | {
  type: 'OPEN_MODAL'
}
  | {
  type: 'CLOSE_MODAL'
}
  | {
  type: 'SET_MODAL_VIEW'
  view: MODAL_VIEWS
}
  | {
  type: 'SET_SIDEBAR_VIEW'
  view: SIDEBAR_VIEWS
}
  | {
  type: 'SET_USER_AVATAR'
  value: string
}
  | {
  type: 'SET_PAYMENT_METHOD'
  value: PAYMENT_METHODS
}
  | {
  type: 'SET_SHIPPING_ADDRESS'
  value: AddressFields
}
  | {
  type: 'ADD_PRODUCT_TO_CART'
  value: ProductWithVariation
}
  | {
  type: 'ADD_DELIVERY_METHODS'
  value: DeliveryMethodsState
}
  | {
  type: 'PRODUCT_CHECKED'
  value: ProductCheckedState
}
  | {
  type: 'UPDATE_PRODUCT_FROM_CART'
  value: ProductWithVariation
}
  | {
  type: 'REMOVE_PRODUCT_FROM_CART'
  value: ProductWithVariation
}
  | {
  type: 'RESET_CART'
}
  | {
  type: 'SET_TOKEN'
  value: string
}
  | {
  type: 'SET_DELIVERY_CHARGE'
  value: string
}
  | {
  type: 'SET_PROMO_DISCOUNT'
  value: string
}
  | {
  type: 'SET_USERDATA'
  value: Customer | null
}
  | {
  type: 'ADD_PROMO'
  value: Promo
}
  | {
  type: 'REMOVE_PROMO'
}
  | {
  type: 'ADD_PRODUCT_TO_WISHLIST'
  value: Product
}
  | {
  type: 'REMOVE_PRODUCT_FROM_WISHLIST'
  value: Product
}
  | {
  type: 'RESET_WISHLIST'
}
  | {
  type: 'SET_COOR'
  value: Coordinate | null
}
  | {
  type: 'SET_MYSHOP'
  value: string
}
  | {
  type: 'SET_MYSHOP_INFO'
  value: Shop | null
}
  | {
  type: 'SET_CURRENT_LOC'
  value: AddressFields | null
}
  | {
  type: 'LOGIN_FROM'
  value: LOGIN_FROM | null
}
  | {
  type: 'SET_CATEGORIES'
  value: Category[]
}
  | {
  type: 'SET_DYNAMICPAGE'
  value: Page[]
}

type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'OTP_VIEW'
  | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'
  | 'MAP_VIEW'
  | 'LOCATION_ISSUE'
  | 'SHOP_ISSUE'
  | 'NEW_USER_VIEW'
  | 'IMAGE_ORDER_VIEW'

type SIDEBAR_VIEWS =
  | 'CART_VIEW'
  | 'CHECKOUT_VIEW'
  | 'SHIPPING_VIEW'
  | 'PAYMENT_VIEW'
  | 'NOTIFICATION_VIEW'

type PAYMENT_METHODS = 'COD' | 'SSL'

type LOGIN_FROM = 'CART' | 'ADDRESS'

const initialState = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: '',
  paymentMethod: '',
  shippingAddress: null,
  products: [],
  productsCart: [],
  subTotalSalesPriceV1: 0,
  subTotalMRPPriceV1: 0,
  subTotalSalesPricePickupV1: 0,
  subTotalMRPPricePickupV1: 0,
  deliveryMethods: [],
  token: '',
  initialDeliveryCharge: '',
  promoDiscount: '',
  promo: null,
  wishlist: [],
  user: null,
  coor: null,
  myShop: '',
  myShopInfo: null,
  currentLoc: null,
  loginFrom: null,
  categories: [],
  dynamicPage: [],
}

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'SET_PAYMENT_METHOD': {
      return {
        ...state,
        paymentMethod: action.value,
      }
    }
    case 'SET_SHIPPING_ADDRESS': {
      return {
        ...state,
        shippingAddress: action.value,
      }
    }

    case 'ADD_PRODUCT_TO_CART': {
      const products = action.value;
      const shopId = products?.product?.shop?.id;
      var selectedObject = state.productsCart?.find(p => p.shopId === shopId);
      var othersObject = state.productsCart.filter((p) => p.shopId !== shopId);

      if (selectedObject === undefined) {
        var initialProductsObjectArray = [{
          "shopId": shopId,
          "pickUp": products?.product?.shop?.pickUp,
          "shopCheck": 1,
          "deliveryCheck": 1,
          "name": products?.product?.shop?.name,
          "slug": products?.product?.shop?.slug,
          "address": products?.product?.shop?.address,
          "area": products?.product?.shop?.area,
          "logo": products?.product?.shop?.logo,
          "category": [{
            "categoryId": products?.product?.category?.id,
            "title": products?.product?.category?.title,
            "slug": products?.product?.category?.slug,
            "categoryCheck": 1,
            "product": [{
              "productId": products?.product?.id,
              "productName": products?.product?.name,
              "productShopId": shopId,
              "productPickUp": products?.product?.shop?.pickUp,
              "productCheck": 1,
              "product": action.value,
            }]
          }]
        }]
        var prevState = [...othersObject, ...initialProductsObjectArray];
      } else {
        const categoryId = products?.product?.category?.id;
        var selectedCategory = selectedObject.category?.find(p => p.categoryId === categoryId);
        var othersCategory = selectedObject.category?.filter((p) => p.categoryId !== categoryId);
        var productArrayPrev = selectedCategory?.product;

        const productArrayNew = [{
          "productId": products?.product?.id,
          "productName": products?.product?.name,
          "productShopId": shopId,
          "productPickUp": products?.product?.shop?.pickUp,
          "productCheck": 1,
          "product": action.value,
        }]

        if (selectedCategory === undefined) {
          var productMerge = [...productArrayNew];
        } else {
          var productMerge = [...productArrayNew, ...productArrayPrev];
        }

        const categoryArrayNew = [{
          "categoryId": products?.product?.category?.id,
          "title": products?.product?.category?.title,
          "slug": products?.product?.category?.slug,
          "categoryCheck": 1,
          "product": productMerge
        }]

        if (selectedCategory === undefined) {
          var categoryMerge = [...categoryArrayNew, ...othersCategory];
        } else {
          if (othersCategory === undefined) {
            var categoryMerge = [...categoryArrayNew];
          } else {
            var categoryMerge = [...categoryArrayNew, ...othersCategory];
          }
        }

        var initialProductsArray = [{
          "shopId": shopId,
          "pickUp": products?.product?.shop?.pickUp,
          "shopCheck": 1,
          "deliveryCheck": 1,
          "name": products?.product?.shop?.name,
          "slug": products?.product?.shop?.slug,
          "address": products?.product?.shop?.address,
          "area": products?.product?.shop?.area,
          "logo": products?.product?.shop?.logo,
          "category": categoryMerge
        }]

        if (othersObject.length > 0) {
          if (selectedObject === undefined) {
            var prevState = [...initialProductsArray];
          } else {
            var prevState = [...othersObject, ...initialProductsArray];
          }
        } else {
          if (selectedObject === undefined) {
            var prevState = [...othersObject];
          } else {
            var prevState = [...initialProductsArray];
          }
        }
      }

      const exist = state.products?.find(
        (product) =>
          product?.product?.id === action.value?.product?.id &&
          product.variation.variationId === action.value.variation.variationId
      )

      if (products?.product?.shop?.pickUp === true) {
        var calculateSalesPricePickup = state.subTotalSalesPricePickupV1 + action.value?.product?.variations[0].price?.sellerDiscountedPrice;
        var calculateMRPPricePickup = state.subTotalMRPPricePickupV1 + action.value?.product?.variations[0].price?.mrp;
      } else {
        var calculateSalesPricePickup = state.subTotalSalesPricePickupV1;
        var calculateMRPPricePickup = state.subTotalMRPPricePickupV1;
      }

      const calculateSalesPrice = state.subTotalSalesPriceV1 + action.value?.product?.variations[0].price?.sellerDiscountedPrice;
      const calculateMRPPrice = state.subTotalMRPPriceV1 + action.value?.product?.variations[0].price?.mrp;

      return {
        ...state,
        subTotalSalesPriceV1: calculateSalesPrice,
        subTotalMRPPriceV1: calculateMRPPrice,
        subTotalSalesPricePickupV1: calculateSalesPricePickup,
        subTotalMRPPricePickupV1: calculateMRPPricePickup,
        productsCart: [...prevState],
        products: exist ? state.products : [...(state.products || []), action.value],

      }
    }

    case 'ADD_DELIVERY_METHODS': {
      return {
        ...state,
        deliveryMethods: [action.value],
      }
    }


    case 'PRODUCT_CHECKED': {

      const productList = state.productsCart;

      var productTotalPrice = 0;
      var productTotalPriceMrp = 0;
      var productTotalPricePickup = 0;
      var productTotalPriceMrpPickup = 0;

      const filterProduct = (category, index) => {
        const productData = category[index]?.product;
        const product = [];
        for (let i = 0; i < productData.length; i++) {
          const mrpPrice = productData[i].product?.variation?.price?.mrp * productData[i].product?.quantity;
          const sellerDiscountedPrice = productData[i].product?.variation?.price?.sellerDiscountedPrice * productData[i].product?.quantity
          if (action.value?.productId === null) {
            if (productData[i].productPickUp === true) {
              if (productData[i].productShopId === action.value.shopId) {
                productTotalPricePickup += sellerDiscountedPrice;
                productTotalPriceMrpPickup += mrpPrice;
              }
              if (productData[i].productShopId === action.value.shopId) {
                productTotalPrice += sellerDiscountedPrice;
                productTotalPriceMrp += mrpPrice;
              }
            } else {
              if (productData[i].productShopId === action.value.shopId) {
                productTotalPrice += sellerDiscountedPrice;
                productTotalPriceMrp += mrpPrice;
              }
            }
          } else {
            if (productData[i].productPickUp === true) {
              if (productData[i].productId === action.value.productId) {
                productTotalPricePickup += sellerDiscountedPrice;
                productTotalPriceMrpPickup += mrpPrice;
              }
              if (productData[i].productId === action.value.productId) {
                productTotalPrice += sellerDiscountedPrice;
                productTotalPriceMrp += mrpPrice;
              }
            } else {
              if (productData[i].productId === action.value.productId) {
                productTotalPrice += sellerDiscountedPrice;
                productTotalPriceMrp += mrpPrice;
              }
            }
          }

          const data = {
            "productId": productData[i].productId,
            "productName": productData[i].productName,
            "productShopId": productData[i].productShopId,
            "productPickUp": productData[i].productPickUp,
            "productCheck":
              action.value?.shopId === "all" ?
                action.value.type === 1 ? 1 : 0 :
                action.value?.productId === null ?
                  action.value.shopId === productData[i].productShopId ? action.value.type === 1 ? 1 : 0 : productData[i].productCheck :
                  action.value.productId === productData[i].productId ?
                    action.value.type === 1 ? 1 : 0 :
                    productData[i].productCheck,
            "product": productData[i].product,
          }
          product.push(data);
        }
        return product;
      }

      const filterCategory = (shopId) => {
        const shop = productList?.find(ps => ps.shopId === shopId);
        const category = [];
        for (let i = 0; i < shop?.category?.length; i++) {
          const product = filterProduct(shop?.category, i);
          const data = {
            "categoryId": shop?.category[i]?.categoryId,
            "title": shop?.category[i]?.title,
            "slug": shop?.category[i]?.slug,
            "categoryCheck": 1,
            "product": product
          }
          category.push(data);
        }
        return category;
      }

      const checkedArray = [];
      for (let i = 0; i < productList.length; i++) {

        if ((action.value.type === "schedule") || (action.value.type === "delivery")) {
          if (action.value.shopId === productList[i].shopId) {
            if (action.value.type === "schedule") {
              var set_delivery_check = 2
            } else {
              var set_delivery_check = 1
            }
          } else {
            var set_delivery_check = productList[i].deliveryCheck
          }
        } else {
          var set_delivery_check = productList[i].deliveryCheck
        }


        const category = filterCategory(productList[i].shopId);

        var data = {
          "shopId": productList[i].shopId,
          "pickUp": productList[i].pickUp,
          "deliveryCheck": set_delivery_check,
          "shopCheck":
            action.value?.shopId === "all" ?
              action.value.type === 1 ? 1 : 0 :
              action.value?.productId === null ?
                productList[i].shopId === action.value.shopId ? action.value.type === 1 ? 1 : 0 : productList[i].shopCheck :
                productList[i].shopCheck,
          "name": productList[i].name,
          "slug": productList[i].slug,
          "address": productList[i].address,
          "area": productList[i].area,
          "logo": productList[i].logo,
          "category": category
        }
        checkedArray.push(data);
      }

      const calculateSalesPrice = action.value.type === 1 ? state.subTotalSalesPriceV1 + productTotalPrice : state.subTotalSalesPriceV1 - productTotalPrice
      const calculateMRPPrice = action.value.type === 1 ? state.subTotalMRPPriceV1 + productTotalPriceMrp : state.subTotalMRPPriceV1 - productTotalPriceMrp

      const calculateSalesPricePickup = action.value.type === 1 ? state.subTotalSalesPricePickupV1 + productTotalPricePickup : state.subTotalSalesPricePickupV1 - productTotalPricePickup
      const calculateMRPPricePickup = action.value.type === 1 ? state.subTotalMRPPricePickupV1 + productTotalPriceMrpPickup : state.subTotalMRPPricePickupV1 - productTotalPriceMrpPickup

      var filteredShop = checkedArray?.filter((p) => p.shopId === action.value.shopId);
      var shopChecked = 0;
      for (let i = 0; i < filteredShop?.length; i++) {
        for (let j = 0; j < filteredShop[i]?.category?.length; j++) {
          for (let k = 0; k < filteredShop[i]?.category[j]?.product?.length; k++) {
            if (filteredShop[i]?.category[j]?.product[k]?.productCheck === 1) {
              shopChecked += 1;
            }
          }
        }
      }
      var othersFilteredShop = checkedArray?.filter((p) => p.shopId !== action.value.shopId);

      if (filteredShop.length > 0) {
        var newData = [{
          "shopId": filteredShop[0]?.shopId,
          "pickUp": filteredShop[0]?.pickUp,
          "deliveryCheck": filteredShop[0]?.deliveryCheck,
          "shopCheck": shopChecked === 0 ? 0 : 1,
          "name": filteredShop[0]?.name,
          "slug": filteredShop[0]?.slug,
          "address": filteredShop[0]?.address,
          "area": filteredShop[0]?.area,
          "logo": filteredShop[0]?.logo,
          "category": filteredShop[0]?.category
        }];
      } else {
        var newData = []
      }

      const newCheckedArray = [...othersFilteredShop, ...newData];


      return {
        ...state,
        subTotalSalesPriceV1: calculateSalesPrice,
        subTotalMRPPriceV1: calculateMRPPrice,
        subTotalSalesPricePickupV1: calculateSalesPricePickup,
        subTotalMRPPricePickupV1: calculateMRPPricePickup,
        productsCart: [...newCheckedArray],
      }
    }

    case 'UPDATE_PRODUCT_FROM_CART': {
      const shopId = action.value.product?.shop?.id;
      const categoryId = action.value.product?.category?.id;
      const productId = action.value.product?.id;

      var selectedObjectShop = state.productsCart?.find(ps => ps.shopId === shopId);
      var othersObjectShop = state.productsCart.filter((ps) => ps.shopId !== shopId);

      var selectedObjectCategory = selectedObjectShop?.category?.find(pc => pc.categoryId === categoryId);
      var othersObjectCategory = selectedObjectShop?.category.filter((pc) => pc.categoryId !== categoryId);

      var selectedObjectProduct = selectedObjectCategory?.product?.find(p => p.productId === productId);
      var othersObjectProduct = selectedObjectCategory?.product.filter((p) => p.productId !== productId);

      const productArrayNew = [{
        "productId": selectedObjectProduct?.productId,
        "productName": selectedObjectProduct?.productName,
        "productShopId": selectedObjectProduct?.productShopId,
        "productPickUp": selectedObjectProduct?.productPickUp,
        "productCheck": 1,
        "product": action.value,
      }]

      const productMerge = [...productArrayNew, ...othersObjectProduct];
      const categoryArrayNew = [{
        "categoryId": selectedObjectCategory?.categoryId,
        "title": selectedObjectCategory?.title,
        "slug": selectedObjectCategory?.slug,
        "productCheck": 1,
        "product": productMerge
      }]
      const categoryMerge = [...categoryArrayNew, ...othersObjectCategory];
      var customizedProductArray = [{
        "shopId": shopId,
        "pickUp": selectedObjectShop?.pickUp,
        "shopCheck": 1,
        "deliveryCheck": 1,
        "name": selectedObjectShop?.name,
        "slug": selectedObjectShop?.slug,
        "address": selectedObjectShop?.address,
        "area": selectedObjectShop?.area,
        "logo": selectedObjectShop?.logo,
        "category": categoryMerge
      }]
      const finalArray = [...customizedProductArray, ...othersObjectShop];

      if (selectedObjectProduct?.product?.quantity > action.value?.quantity) {
        const prevPrice = (action.value?.product?.variations[0].price?.sellerDiscountedPrice) * selectedObjectProduct?.product?.quantity;
        const afterMinus = state.subTotalSalesPriceV1 - prevPrice;
        var calculateSalesPrice = afterMinus + ((action.value?.product?.variations[0].price?.sellerDiscountedPrice) * action.value?.quantity);

        const prevPriceMRP = (action.value?.product?.variations[0].price?.mrp) * selectedObjectProduct?.product?.quantity;
        const afterMinusMRP = state.subTotalMRPPriceV1 - prevPriceMRP;
        var calculateMRPPrice = afterMinusMRP + ((action.value?.product?.variations[0].price?.mrp) * action.value?.quantity);

        if (action.value?.product?.shop?.pickUp === true) {
          const prevPricePickup = (action.value?.product?.variations[0].price?.sellerDiscountedPrice) * selectedObjectProduct?.product?.quantity;
          const afterMinusPickup = state.subTotalSalesPricePickupV1 - prevPricePickup;
          var calculateSalesPricePickup = afterMinusPickup + ((action.value?.product?.variations[0].price?.sellerDiscountedPrice) * action.value?.quantity);

          const prevPriceMRPPickup = (action.value?.product?.variations[0].price?.mrp) * selectedObjectProduct?.product?.quantity;
          const afterMinusMRPPickup = state.subTotalMRPPricePickupV1 - prevPriceMRPPickup;
          var calculateMRPPricePickup = afterMinusMRPPickup + ((action.value?.product?.variations[0].price?.mrp) * action.value?.quantity);

        } else {
          var calculateSalesPricePickup = state.subTotalSalesPricePickupV1;
          var calculateMRPPricePickup = state.subTotalSalesPricePickupV1;
        }
      } else {
        const prevPrice = (action.value?.product?.variations[0].price?.sellerDiscountedPrice) * (action.value?.quantity - 1);
        const afterMinus = state.subTotalSalesPriceV1 - prevPrice;
        var calculateSalesPrice = afterMinus + ((action.value?.product?.variations[0].price?.sellerDiscountedPrice) * action.value?.quantity);

        const prevPriceMRP = (action.value?.product?.variations[0].price?.mrp) * (action.value?.quantity - 1);
        const afterMinusMRP = state.subTotalMRPPriceV1 - prevPriceMRP;
        var calculateMRPPrice = afterMinusMRP + ((action.value?.product?.variations[0].price?.mrp) * action.value?.quantity);

        if (action.value?.product?.shop?.pickUp === true) {
          const prevPricePickup = (action.value?.product?.variations[0].price?.sellerDiscountedPrice) * (action.value?.quantity - 1);
          const afterMinusPickup = state.subTotalSalesPricePickupV1 - prevPricePickup;
          var calculateSalesPricePickup = afterMinusPickup + ((action.value?.product?.variations[0].price?.sellerDiscountedPrice) * action.value?.quantity);

          const prevPriceMRPPickup = (action.value?.product?.variations[0].price?.mrp) * (action.value?.quantity - 1);
          const afterMinusMRPPickup = state.subTotalMRPPricePickupV1 - prevPriceMRPPickup;
          var calculateMRPPricePickup = afterMinusMRPPickup + ((action.value?.product?.variations[0].price?.mrp) * action.value?.quantity);

        } else {
          var calculateSalesPricePickup = state.subTotalSalesPricePickupV1;
          var calculateMRPPricePickup = state.subTotalSalesPricePickupV1;
        }
      }

      return {
        ...state,
        subTotalSalesPriceV1: calculateSalesPrice,
        subTotalMRPPriceV1: calculateMRPPrice,
        subTotalSalesPricePickupV1: calculateSalesPricePickup,
        subTotalMRPPricePickupV1: calculateMRPPricePickup,
        productsCart: [...finalArray],
        products: state.products.map((product) => {
          return product.product?.id === action.value.product?.id ?
            action.value.variation ?
              product.variation.variationId === action.value.variation.variationId ?
                action.value : product
              : action.value
            : product
        })
      }
    }

    case 'REMOVE_PRODUCT_FROM_CART': {


      const shopId = action.value.product?.shop?.id;
      const categoryId = action.value.product?.category?.id;
      const productId = action.value.product?.id;

      var selectedObjectShop = state.productsCart?.find(ps => ps.shopId === shopId);
      var othersObjectShop = state.productsCart.filter((ps) => ps.shopId !== shopId);
      var selectedObjectCategory = selectedObjectShop?.category?.find(pc => pc.categoryId === categoryId);
      var othersObjectCategory = selectedObjectShop?.category.filter((pc) => pc.categoryId !== categoryId);
      var othersObjectProduct = selectedObjectCategory?.product.filter((p) => p.productId !== productId);

      const categoryArrayNew = [{
        "categoryId": selectedObjectCategory?.categoryId,
        "title": selectedObjectCategory?.title,
        "slug": selectedObjectCategory?.slug,
        "categoryCheck": 1,
        "product": othersObjectProduct
      }]

      if (selectedObjectCategory?.product?.length > 1) {
        var categoryMerge = [...categoryArrayNew, ...othersObjectCategory];
      } else {
        var categoryMerge = [...othersObjectCategory];
      }

      var customizedShopArray = [{
        "shopId": shopId,
        "pickUp": selectedObjectShop?.pickUp,
        "shopCheck": 1,
        "deliveryCheck": 1,
        "name": selectedObjectShop?.name,
        "slug": selectedObjectShop?.slug,
        "address": selectedObjectShop?.address,
        "area": selectedObjectShop?.area,
        "logo": selectedObjectShop?.logo,
        "category": categoryMerge
      }]

      if (selectedObjectShop?.category?.length > 1) {
        var finalArray = [...customizedShopArray, ...othersObjectShop];
      } else {
        if (selectedObjectShop?.category[0]?.product?.length > 1) {
          var finalArray = [...customizedShopArray, ...othersObjectShop];
        } else {
          var finalArray = [...othersObjectShop];
        }
      }

      const prevPrice = (action.value?.product?.variations[0].price?.sellerDiscountedPrice) * action.value?.quantity;
      const calculateSalesPrice = state.subTotalSalesPriceV1 - prevPrice;
      const prevPriceMRP = (action.value?.product?.variations[0].price?.mrp) * action.value?.quantity;
      const calculateMRPPrice = state.subTotalMRPPriceV1 - prevPriceMRP;

      if (action.value?.product?.shop?.pickUp === true) {
        const prevPrice = (action.value?.product?.variations[0].price?.sellerDiscountedPrice) * action.value?.quantity;
        var calculateSalesPricePickup = state.subTotalSalesPriceV1 - prevPrice;
        const prevPriceMRP = (action.value?.product?.variations[0].price?.mrp) * action.value?.quantity;
        var calculateMRPPricePickup = state.subTotalSalesPriceV1 - prevPriceMRP;
      } else {
        var calculateSalesPricePickup = state.subTotalSalesPricePickupV1;
        var calculateMRPPricePickup = state.subTotalSalesPricePickupV1;
      }

      return {
        ...state,
        subTotalSalesPriceV1: calculateSalesPrice,
        subTotalMRPPriceV1: calculateMRPPrice,
        subTotalSalesPricePickupV1: calculateSalesPricePickup,
        subTotalMRPPricePickupV1: calculateMRPPricePickup,
        productsCart: [...finalArray],
        products: state.products.filter((product) => product.product?.id !== action.value.product?.id || product.variation.variationId !== action.value.variation.variationId)
      }
    }

    case 'RESET_CART': {
      return {
        ...state,
        products: [],
        productsCart: [],
        subTotalSalesPriceV1: 0,
        subTotalMRPPriceV1: 0,
        subTotalSalesPricePickupV1: 0,
        subTotalMRPPricePickupV1: 0,
      }
    }

    case 'SET_TOKEN': {
      return {
        ...state,
        token: action.value,
      }
    }
    case 'SET_DELIVERY_CHARGE': {
      return {
        ...state,
        initialDeliveryCharge: action.value,
      }
    }
    case
    'SET_PROMO_DISCOUNT': {
      return {
        ...state,
        promoDiscount: action.value,
      }
    }
    case
    'SET_USERDATA'
    : {
      return {
        ...state,
        user: action.value,
      }
    }
    case
    'ADD_PROMO'
    : {
      return {
        ...state,
        promo: action.value,
      }
    }
    case
    'REMOVE_PROMO'
    : {
      return {
        ...state,
        promo: null,
      }
    }
    case
    'ADD_PRODUCT_TO_WISHLIST'
    : {
      const exist = state.wishlist?.find(
        (product) => product.id === action.value.id
      )
      return {
        ...state,
        wishlist: exist ? state.wishlist : [...state.wishlist, action.value],
      }
    }
    case
    'REMOVE_PRODUCT_FROM_WISHLIST'
    : {
      return {
        ...state,
        wishlist: state.wishlist.filter(
          (product) => product.id !== action.value.id
        ),
      }
    }
    case
    'RESET_WISHLIST'
    : {
      return {
        ...state,
        wishlist: [],
      }
    }
    case
    'SET_COOR'
    : {
      return {
        ...state,
        coor: action.value,
      }
    }
    case
    'SET_MYSHOP'
    : {
      return {
        ...state,
        myShop: action.value,
      }
    }
    case
    'SET_MYSHOP_INFO'
    : {
      return {
        ...state,
        myShopInfo: action.value,
      }
    }
    case
    'SET_CURRENT_LOC'
    : {
      return {
        ...state,
        currentLoc: action.value,
      }
    }
    case
    'LOGIN_FROM'
    : {
      return {
        ...state,
        loginFrom: action.value,
      }
    }
    case
    'SET_CATEGORIES'
    : {
      return {
        ...state,
        categories: action.value,
      }
    }
    case
    'SET_DYNAMICPAGE'
    : {
      return {
        ...state,
        dynamicPage: action.value,
      }
    }
  }
}

export const UIProvider: FC = (props) => {
  const storageKey = 'JACHAI_LTD_!@£'


  const openSidebar = useCallback(
    () => dispatch({type: 'OPEN_SIDEBAR'}),
    [dispatch]
  )
  const closeSidebar = useCallback(
    () => dispatch({type: 'CLOSE_SIDEBAR'}),
    [dispatch]
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({type: 'CLOSE_SIDEBAR'})
        : dispatch({type: 'OPEN_SIDEBAR'}),
    [dispatch, state?.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({type: 'CLOSE_SIDEBAR'}),
    [dispatch, state?.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({type: 'OPEN_DROPDOWN'}),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({type: 'CLOSE_DROPDOWN'}),
    [dispatch]
  )

  const openModal = useCallback(
    () => dispatch({type: 'OPEN_MODAL'}),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({type: 'CLOSE_MODAL'}),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({type: 'SET_MODAL_VIEW', view}),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({type: 'SET_SIDEBAR_VIEW', view}),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({type: 'SET_USER_AVATAR', value}),
    [dispatch]
  )

  const setPaymentMethod = useCallback(
    (value: PAYMENT_METHODS) => dispatch({type: 'SET_PAYMENT_METHOD', value}),
    [dispatch]
  )

  const setShippingAddress = useCallback(
    (value: AddressFields) => dispatch({type: 'SET_SHIPPING_ADDRESS', value}),
    [dispatch]
  )

  const addDeliveryMethods = useCallback(
    (value: DeliveryMethodsState) =>
      dispatch({type: 'ADD_DELIVERY_METHODS', value}),
    [dispatch]
  )

  const productChecked = useCallback(
    (value: ProductCheckedState) =>
      dispatch({type: 'PRODUCT_CHECKED', value}),
    [dispatch]
  )


  const addProductToCart = useCallback(
    (value: ProductWithVariation) =>
      dispatch({type: 'ADD_PRODUCT_TO_CART', value}),
    [dispatch]
  )

  const updateProductFromCart = useCallback(
    (value: ProductWithVariation) =>
      dispatch({type: 'UPDATE_PRODUCT_FROM_CART', value}),
    [dispatch]
  )

  const removeProductFromCart = useCallback(
    (value: ProductWithVariation) =>
      dispatch({type: 'REMOVE_PRODUCT_FROM_CART', value}),
    [dispatch]
  )

  const resetCart = useCallback(
    () => dispatch({type: 'RESET_CART'}),
    [dispatch]
  )

  const setToken = useCallback(
    (value: string) => dispatch({type: 'SET_TOKEN', value}),
    [dispatch]
  )

  const setInitialDeliveryCharge = useCallback(
    (value: string) => dispatch({type: 'SET_DELIVERY_CHARGE', value}),
    [dispatch]
  )

  const setPromoDiscount = useCallback(
    (value: string) => dispatch({type: 'SET_PROMO_DISCOUNT', value}),
    [dispatch]
  )

  const setUserData = useCallback(
    (value: Customer | null) => dispatch({type: 'SET_USERDATA', value}),
    [dispatch]
  )

  const addPromo = useCallback(
    (value: Promo) => dispatch({type: 'ADD_PROMO', value}),
    [dispatch]
  )

  const removePromo = useCallback(
    () => dispatch({type: 'REMOVE_PROMO'}),
    [dispatch]
  )

  const addProductToWishlist = useCallback(
    (value: Product) => dispatch({type: 'ADD_PRODUCT_TO_WISHLIST', value}),
    [dispatch]
  )

  const removeProductFromWishlist = useCallback(
    (value: Product) =>
      dispatch({type: 'REMOVE_PRODUCT_FROM_WISHLIST', value}),
    [dispatch]
  )

  const resetWishlist = useCallback(
    () => dispatch({type: 'RESET_WISHLIST'}),
    [dispatch]
  )

  const setCoor = useCallback(
    (value: Coordinate | null) => dispatch({type: 'SET_COOR', value}),
    [dispatch]
  )

  const setMyShop = useCallback(
    (value: string) => dispatch({type: 'SET_MYSHOP', value}),
    [dispatch]
  )

  const setMyShopInfo = useCallback(
    (value: Shop | null) => dispatch({type: 'SET_MYSHOP_INFO', value}),
    [dispatch]
  )

  const setCurrentLoc = useCallback(
    (value: AddressFields | null) =>
      dispatch({type: 'SET_CURRENT_LOC', value}),
    [dispatch]
  )

  const setLoginFrom = useCallback(
    (value: LOGIN_FROM | null) => dispatch({type: 'LOGIN_FROM', value}),
    [dispatch]
  )

  const setCategories = useCallback(
    (value: Category[]) => dispatch({type: 'SET_CATEGORIES', value}),
    [dispatch]
  )

  const setDynamicPage = useCallback(
    (value: Page[]) => dispatch({type: 'SET_DYNAMICPAGE', value}),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setUserAvatar,
      setPaymentMethod,
      setShippingAddress,
      addProductToCart,
      addDeliveryMethods,
      productChecked,
      updateProductFromCart,
      removeProductFromCart,
      setToken,
      setInitialDeliveryCharge,
      setPromoDiscount,
      resetCart,
      addPromo,
      removePromo,
      addProductToWishlist,
      removeProductFromWishlist,
      resetWishlist,
      setUserData,
      setCoor,
      setMyShop,
      setMyShopInfo,
      setCurrentLoc,
      setLoginFrom,
      setCategories,
      setDynamicPage,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}


