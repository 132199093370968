import React from "react";
import MainLayout from "../../components/Layouts";
import SingleProduct from "../../components/Product/Details";
import SingleReqProduct from "../../components/RequestedProduct/Details";

const ReqProductDetailsPage = () => {
  return (
    <MainLayout>
      <SingleReqProduct />
    </MainLayout>
  );
};

export default ReqProductDetailsPage;
