import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopBusinessInfoUpdate from "../../../components/Shop/BusinessInfo/create";

const ShopBusinessInfoUpdatePage = () => {
  return (
    <MainLayout>
      <ShopBusinessInfoUpdate />
    </MainLayout>
  );
};  

export default ShopBusinessInfoUpdatePage;
