import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import MerchantLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_MERCHANT_INFO } from "../../../redux/auth/authType";
import Loader from "../../common/Loader";
import { Avatar, Button, Divider, Image } from "antd";
import AddKam from "./Add";

const KAMDetails = (): ReactElement => {
  const { token, type, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [add, setAdd] = useState(false);
  const dispatch = useDispatch();
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchMerchantDetails = useCallback((merchantId: any) => {
    if (merchantId) {
      try {
        setSingleMerchantInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/details?merchantId=${merchantId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleMerchantInfo({
                loading: false,
                data: data?.data?.merchant,
              });
              dispatch({
                type: SET_MERCHANT_INFO,
                payload: {
                  merchantInfo: data?.data?.merchant,
                },
              });
            } else {
              setSingleMerchantInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleMerchantInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleMerchantInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchMerchantDetails(merchantId);
  }, [fetchMerchantDetails]);

  const merchant = singleMerchantInfo?.data;
  if (singleMerchantInfo.loading) return <Loader />;

  const refetch = () => {
    fetchMerchantDetails(merchantId);
    setAdd(false);
  };
  return (
    <React.Fragment>
      <MerchantLayout
        merchantId={merchant?.id}
        title={`Merchant Details`}
        subTitle={`Details`}
      >
        <div className="content-body rounded-2xl bg-white p-4 min-h-full">
          <div className="flex justify-between border-b pb-3 mb-8 border-gray-100">
            <h4 className="font-semibold text-xl text-gray-600">
              Merchant Acquisition Info
            </h4>
            {!merchant?.merchantAcquisitionInfo && (
              <Button shape="round" type="dashed" onClick={() => setAdd(!add)}>
                Add
              </Button>
            )}
          </div>

          {/* WareHouse details */}
          <div className="">
            {merchant?.merchantAcquisitionInfo && !add ? (
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 p-4 pt-0">
                <div className="p-4 bg-slate-100 rounded-md shadow-md">
                  <dt className="text-sm font-medium text-gray-900">
                    KAM Mobile Number
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {merchant?.merchantAcquisitionInfo?.mobileNumber?.replace(
                      "+88",
                      ""
                    )}
                  </dd>
                </div>

                <div className="grid grid-cols-2 gap-10">
                  <div>
                    <Divider>
                      <span>Agreement Document</span>
                    </Divider>

                    <Avatar
                      src={
                        <Image
                          src={
                            merchant?.merchantAcquisitionInfo?.agreementDocument
                          }
                          width={200}
                          height={200}
                          
                        />
                      }
                      shape="square"
                      size={200}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <Divider>
                      <span className="text-lg font-semibold">
                        {type === "JC_FOOD"
                          ? "Restaurant Menu"
                          : "Product List"}
                      </span>
                    </Divider>
                    <Avatar
                      src={
                        <Image
                          src={
                            merchant?.merchantAcquisitionInfo
                              ?.agreementSigningPicture
                          }
                          width={200}
                          height={200}
                        />
                      }
                      shape="square"
                      size={200}
                    />
                  </div>
                </div>
              </dl>
            ) : (
              <div className="">
                <AddKam refetch={refetch} />
              </div>
            )}
          </div>
        </div>
      </MerchantLayout>
    </React.Fragment>
  );
};

KAMDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default KAMDetails;
