import MainLayout from "../../components/Layouts";
import React from "react";
import WarehouseDetails from "../../components/Profile/Warehouse";

const WarehouseInfoPage = () => {
  return (
    <MainLayout>
      <WarehouseDetails />
    </MainLayout>
  );
};

export default WarehouseInfoPage;
