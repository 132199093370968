import {
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  InboxOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import {
  Alert,
  AutoComplete,
  Avatar,
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Select,
  Spin,
  TreeSelect,
  TreeSelectProps,
} from "antd";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";
import { getImgUrl, getPage, getParamValue } from "../../../../utils/index";
import { responseNotification } from "../../../../utils/notify";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import React from "react";
import * as d3 from "d3";
// @ts-ignore
import campaignProducts from "../../../../demo-files/campaign-products.csv";
import Dragger from "antd/lib/upload/Dragger";
import Pagination from "../../../common/Pagination";
import { useLocation, useParams } from "react-router-dom";
import styles from "../../../../styles/tailwind/List.module.css";
import LoaderFull from "../../../common/LoaderFull";
import Loader from "../../../common/Loader";
import { DefaultOptionType } from "antd/lib/cascader";
import CampaignLayout from "../Layout";
import Meta from "antd/lib/card/Meta";

type CSVData = {
  products: any[];
};

const CampaignProductList = ({
  onAssignModal,
  submitForm,
}: any): ReactElement => {
  const { type, token, campaignInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fetchedCSVData, setFetchedCSVdata] = useState<CSVData | any>();
  const route = useParams();
  const shopId = (route as any)?.shopId;
  const campaignId = (route as any)?.campaignId;
  // --------------------------------
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  // --------------------------------

  const simpleFileDownload = () => {
    window.location.href = `${campaignProducts}`;
  };
  // --------------------------------

  // const campaignId = campaignInfo?.id;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [status, setStatus] = useState("");
  const [limit, setLimit] = useState(50);
  const [minimumStock, setMinimumStock] = useState<any>(10000000);
  const [key, setKey] = useState("");
  const [barCode, setBarCode] = useState("");
  const [isActive, setIsActive] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [supplierId, setSupplierId] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  // --------------------------------

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [campaignProductData, setCampaignProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [brandsOptions, setBrandsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const reseAllFieldData = () => {
    setKey("");
    setBarCode("");
    setBrandId("");
    setCategoryId("");
    setSupplierId("");
    setMinimumStock(10000000);
    setIsActive("");
    form.resetFields();
  };

  // **********************************
  // Search Options
  // **********************************

  const getBrandsOptions = useCallback(
    async (val?: string) => {
      setBrandsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/brand/search?type=${type}&page=0&limit=20` +
          (val ? `&key=${val}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBrandsOptions({
        loading: false,
        list: res?.data?.brands?.map((brand: any) => {
          return {
            value: brand?.id,
            label: brand?.name,
          };
        }),
      });
    },
    [type]
  );

  const getProductByBarcodeOptions = useCallback(
    async (bCode: any) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/admin/product/search?type=${type}&shopId=${shopId}&page=0&limit=20&deleted=${false}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (campaignId ? `&campaignId=${campaignId}` : ``) +
          (key ? `&key=${key}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [status, type, key, isActive, campaignId]
  );

  const getProductOptions = useCallback(
    async (val: any) => {
      setProductOptions({ loading: false, list: [] });

      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/admin/product/search?type=${type}&shopId=${shopId}&page=0&limit=20&isDeleted=${false}` +
          (val ? `&key=${val}` : ``) +
          (!onAssignModal
            ? campaignId
              ? `&campaignId=${campaignId}`
              : ``
            : ``) +
          (barCode ? `&barCode=${barCode}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any }) => {
          return {
            value: product?.name,
            label: product?.name,
          };
        }),
      });
    },
    [campaignId, shopId, barCode, type, isActive, status]
  );

  const getCampaignProduct = useCallback(() => {
    if (campaignId) {
      try {
        setCampaignProductData({ loading: true, data: null });
        axios
          .get(
            `${
              process.env.REACT_APP_CATALOG_READER_API
            }/admin/campaign/products?isDeleted=${false}&shopId=${shopId}` +
              `&page=${page || 0}` +
              `&limit=${limit || 16}` +
              (key ? `&key=${key}` : ``) +
              (!onAssignModal
                ? campaignId
                  ? `&campaignId=${campaignId}`
                  : ``
                : ``) +
              // (isActive ? `&isActive=${isActive}` : ``) +
              (barCode ? `&barCode=${barCode}` : ``) +
              (supplierId ? `&supplierId=${supplierId}` : ``) +
              (categoryId ? `&categoryId=${categoryId}` : ``) +
              // (shopId ? `&shopId=${shopId}` : ``) +
              (brandId ? `&brandId=${brandId}` : ``) +
              (minimumStock ? `&minimumStock=${minimumStock}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setCampaignProductData({
                loading: false,
                data: data?.data,
              });

              setCheckedList(
                data.data.products.map(
                  (product: { barCode: any }) => product?.barCode
                )
              );
            } else {
              setCampaignProductData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            // setCampaignProductData({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setCampaignProductData({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, [
    limit,
    page,
    supplierId,
    shopId,
    brandId,
    key,
    barCode,
    type,
    isActive,
    minimumStock,
    categoryId,
    shopId,
  ]);

  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(
    []
  );

  const genTreeNode = (parentId: number, category: any) => {
    return {
      ...category,
      id: category?.id,
      pId: parentId,
      value: category?.id,
      title: category?.title,
      isLeaf: false,
    };
  };

  const getParentCategory = useCallback(async (val: string) => {
    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/parent-by-type?type=${type}&page=0&limit=50` +
        (val ? `&key=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTreeData(
      res.data?.categories?.map(
        (category: Omit<DefaultOptionType, "label">) => ({
          ...category,
          id: category?.id,
          pId: 0,
          title: category?.title,
          value: category?.id,
          isLeaf: false,
        })
      )
    );
  }, []);

  const onLoadData: TreeSelectProps["loadData"] = ({ id }) => {
    return new Promise((resolve) => {
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      axios
        .get(
          `${encodedUri}/admin/category/child-category-by-parent?categoryId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            // ****************************
            setTimeout(() => {
              setTreeData(
                treeData?.concat(
                  data?.data?.categories?.map(
                    (category: Omit<DefaultOptionType, "label">) =>
                      genTreeNode(id, category)
                  )
                )
              );
              resolve(undefined);
            }, 300);
            // ****************************
          } else {
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onChangeCategory = (newValue: string) => {
    setCategoryId(newValue);
  };
  // **********************************
  // Search Options
  // **********************************

  const onChange = (e: any) => {
    setSelectedProductBarcode((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length &&
        checkedList.length <= selectedProductBarcode.length
    );
    setCheckAll(checkedList.length === selectedProductBarcode.length);
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const fetchRef = useRef<any>(0);

  const handleSearch = React.useMemo<any>(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
        else if (field === "brand") getBrandsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getBrandsOptions, getProductOptions, getProductByBarcodeOptions]);

  useEffect(() => {
    if (submitForm) {
      form.submit();
    }
  }, [submitForm]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      campaignId: campaignId,
      barCodeShopIds: fetchedCSVData?.products?.map((product: any) => ({
        barCode: product?.barCode,
        shopId: product?.shopId,
        commission: parseFloat(product?.commission),
        minimumDiscountAmount: parseFloat(product?.minimumDiscountAmount),
        stock: parseFloat(product?.stock),
        subsidy: parseFloat(product?.subsidy),
      })),
    };

    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/add-products`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Products assigned Successfully", "success");
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onRemove = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      campaignId: campaignId,
      shopId: shopId,
      barCodes: selectedProductBarcode || [],
    };

    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/remove-products`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Products removed Successfully", "success");
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    getCampaignProduct();
  }, [getCampaignProduct, campaignId]);

  useEffect(() => {
    if (showSearch) {
      getParentCategory(``);
      getBrandsOptions();
    }
  }, [getParentCategory, getBrandsOptions, showSearch]);

  const handleUpload = async (data: any[]) => {
    const filteredData: {
      barCode: any;
      shopId: any;
      commission: any;
      minimumDiscountAmount: any;
      stock: any;
      subsidy: any;
    }[] = [];

    data?.forEach((row) => {
      filteredData.push({
        barCode: row.barCode?.trim(),
        shopId: row.shopId?.trim(),
        commission: row.commission?.trim(),
        minimumDiscountAmount: row.minimumDiscountAmount?.trim(),
        stock: row.stock?.trim(),
        subsidy: row.subsidy?.trim(),
      });
    });

    setFetchedCSVdata({
      products: filteredData,
    });
  };

  const readUploadFile = (val: { fileList: { originFileObj: any }[] }) => {
    const mainFile = val.fileList[0].originFileObj;

    if (mainFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        d3.csv(data).then((res: any[]) => {
          handleUpload(res);
        });
      };
      reader.readAsDataURL(mainFile);
    }
  };

  const resetData = () => {
    form?.resetFields();
    setFetchedCSVdata(null);
    setCategoryId(null);
  };

  const onClose = () => {
    getCampaignProduct();
    resetData();
    form?.resetFields();
    setDrawerVisible(false);
    setSelectedProduct(undefined);
  };

  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info: { file: { name?: any; status?: any }; fileList: any }) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <React.Fragment>
      <>
        <CampaignLayout
          campaignId={campaignId}
          title={"Assign Product"}
          subTitle={""}
          extra={undefined}
        >
          <section className="content-body rounded-2xl">
            <div className="bg-white shadow-sm shadow-gray-200 rounded-2xl px-2">
              <div className="sticky top-2 shadow-sm border border-slate-100 z-30 bg-white rounded-lg px-2 py-1 mb-4">
                {!onAssignModal && (
                  <div className="flex justify-between items-center ">
                    <h3 className="text-xl text-gray-700 font-bold">
                      Product List
                      <span className="text-xs font-light ml-2">
                        {campaignProductData?.data?.totalElements ||
                          campaignProductData?.data?.length ||
                          0}
                        Product(s)
                      </span>
                    </h3>
                    <div className="d-flex items-center">
                      <Button
                        type="dashed"
                        shape="circle"
                        onClick={() => setShowSearch(!showSearch)}
                        key={1}
                      >
                        <SearchOutlined />
                      </Button>

                      <Button
                        type="primary"
                        danger={selectedProductBarcode?.length}
                        shape="round"
                        title="Buld Upload/Remove"
                        className="ml-2 bg-slate-100 border-0 text-slate-600"
                        onClick={() => setDrawerVisible(!drawerVisible)}
                      >
                        {selectedProductBarcode?.length
                          ? `Remove ${
                              selectedProductBarcode?.length || 0
                            } items`
                          : "Upload"}
                      </Button>
                    </div>
                  </div>
                )}

                {showSearch && (
                  <div className="flex py-1 mt-2">
                    <Form
                      layout="inline"
                      form={form}
                      className={styles.formInline}
                    >
                      {(onAssignModal ? shopId : true) && (
                        <>
                          <Form.Item
                            name="barcode_search"
                            initialValue={barCode}
                          >
                            <AutoComplete
                              onSearch={(e) =>
                                handleSearch(e, "productByBarcode")
                              }
                              onSelect={(val: React.SetStateAction<string>) =>
                                setBarCode(val)
                              }
                              options={productBarcodeOptions?.list}
                              defaultActiveFirstOption={false}
                              notFoundContent={
                                productBarcodeOptions?.loading ? (
                                  <Spin size="small" />
                                ) : null
                              }
                            >
                              <Input.Search
                                size="large"
                                placeholder="Search by BarCode"
                                onSearch={(val) => {
                                  setBarCode(val);
                                }}
                                enterButton
                                loading={productBarcodeOptions.loading}
                              />
                            </AutoComplete>
                          </Form.Item>

                          <Form.Item name="product_search" initialValue={key}>
                            <AutoComplete
                              onSearch={(e) => handleSearch(e, "product")}
                              onSelect={(val: {
                                toString: () => React.SetStateAction<string>;
                              }) => setKey(val.toString())}
                              options={productOptions?.list}
                              defaultActiveFirstOption={false}
                              notFoundContent={
                                productOptions?.loading ? (
                                  <Spin size="small" />
                                ) : null
                              }
                            >
                              <Input.Search
                                size="large"
                                placeholder="Search by Name"
                                onSearch={(val) => {
                                  setKey(val);
                                }}
                                enterButton
                                loading={productOptions.loading}
                              />
                            </AutoComplete>
                          </Form.Item>

                          <Form.Item name="category_search">
                            <TreeSelect
                              allowClear
                              treeDataSimpleMode
                              style={{ width: "100%" }}
                              value={categoryId}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                              placeholder="Filter by Category"
                              onChange={onChangeCategory}
                              loadData={onLoadData}
                              treeData={treeData}
                            />
                          </Form.Item>

                          <Form.Item name="brand_search">
                            <Select
                              allowClear
                              showSearch
                              // defaultValue={getShopName}
                              placeholder="Filter by Brand"
                              optionFilterProp="children"
                              onChange={(val) => setBrandId(val)}
                              onSearch={(e) => handleSearch(e, "brand")}
                              filterOption={() => {
                                return true;
                              }}
                              options={brandsOptions.list}
                            ></Select>
                          </Form.Item>

                          {/* <Form.Item name="status_search">
                            <Select
                              showSearch
                              placeholder="Status"
                              onChange={(val) => setIsActive(val as string)}
                            >
                              <Select.Option value="">ALL</Select.Option>
                              <Select.Option value={"true"}>
                                ACTIVE
                              </Select.Option>
                              <Select.Option value={"false"}>
                                INACTIVE
                              </Select.Option>
                            </Select>
                          </Form.Item> */}
                        </>
                      )}
                    </Form>

                    <Button
                      type="primary"
                      size="large"
                      danger
                      onClick={reseAllFieldData}
                    >
                      Reset
                    </Button>
                  </div>
                )}
              </div>

              <div className={styles.contentWrapper}>
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                      className={
                        showSearch ? `content-body-withSearch` : `content-body`
                      }
                    >
                      {confirmLoading && <LoaderFull />}
                      {campaignProductData?.loading ? (
                        <Loader />
                      ) : (
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              {/* <th scope="col">
                              <Checkbox
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={
                                  checkAll ||
                                  selectedProductBarcode?.length ===
                                    checkedList?.length
                                }
                                className="mr-3"
                              >
                                Check all
                              </Checkbox>
                            </th> */}
                              <th scope="col">Product</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {campaignProductData?.data?.campaignProducts
                              ?.length ? (
                              campaignProductData?.data?.campaignProducts?.map(
                                (campaignProduct: any, index: any) => (
                                  <tr
                                    className="border-t hover:bg-gray-100"
                                    key={index}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedProduct(campaignProduct);
                                    }}
                                  >
                                    <td>
                                      {/* <Checkbox
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onChange(
                                          campaignProduct?.product?.barCode
                                        );
                                      }}
                                      checked={selectedProductBarcode?.find(
                                        (item: any) =>
                                          campaignProduct?.product?.barCode ===
                                          item
                                      )}
                                      className="mr-3"
                                      value={campaignProduct?.product?.barCode}
                                    /> */}
                                      <Avatar
                                        size={45}
                                        src={getImgUrl(
                                          campaignProduct?.product?.productImage
                                        )}
                                        shape="square"
                                      />
                                      <span className="font-medium text-gray-500 ml-2">
                                        {campaignProduct?.product?.name?.slice(
                                          0,
                                          50
                                        )}
                                      </span>
                                    </td>

                                    {/* <td>
                                    {campaignProduct?.variations?.length
                                      ? campaignProduct?.variations?.map(
                                          (variant: any, index: any) => (
                                            <React.Fragment key={index}>
                                              <div className="d-flex justify-content-start mb-1">
                                                {variant?.barCode ? (
                                                  <Tag className="d-flex align-items-center">
                                                    <BarcodeOutlined className="mr-1" />{" "}
                                                    {variant?.barCode}
                                                  </Tag>
                                                ) : campaignProduct?.barCode ? (
                                                  <Tag className="d-flex align-items-center">
                                                    <BarcodeOutlined className="mr-1" />{" "}
                                                    {campaignProduct?.barCode}
                                                  </Tag>
                                                ) : undefined}
                                                <Tag
                                                  color={
                                                    variant?.stock < 10
                                                      ? `red`
                                                      : `green`
                                                  }
                                                >
                                                  {variant?.variationName?.toUpperCase()}
                                                  : <b>{variant?.stock || 0}</b>
                                                </Tag>
                                              </div>
                                              <div className="d-flex justify-content-start mb-1">
                                                <Tag>
                                                  MRP{" "}
                                                  {variant?.price?.currencyCode}
                                                  {variant?.price
                                                    ?.discountedPrice ||
                                                    variant?.price?.mrp}
                                                  {variant?.price
                                                    ?.discountedPrice &&
                                                    variant?.price
                                                      ?.discountedPrice !==
                                                      variant?.price?.mrp && (
                                                      <span className="text-danger">
                                                        {" "}
                                                        <del>
                                                          {variant?.price
                                                            ?.currencyCode ||
                                                            "Tk"}
                                                          {variant?.price?.mrp}
                                                        </del>
                                                      </span>
                                                    )}
                                                </Tag>
                                                <Tag>
                                                  TP {variant?.price?.tp}{" "}
                                                  {variant?.price?.currencyCode}
                                                </Tag>
                                              </div>
                                            </React.Fragment>
                                          )
                                        )
                                      : undefined}
                                  </td> */}

                                    <td>
                                      <span
                                        className={`border rounded-full p-1 px-4 font-semibold ${
                                          campaignProduct?.status
                                            ? "bg-green-100 text-green-600"
                                            : "bg-red-100 text-red-600"
                                        }`}
                                      >
                                        {campaignProduct?.status
                                          ?.split("_")
                                          .join(" ")}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>
                                  <Empty />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>

                <Pagination
                  {...campaignProductData?.data}
                  limit={limit}
                  page={getPage(loc.search)}
                />
              </div>
            </div>
          </section>
        </CampaignLayout>
      </>

      <Drawer
        title={
          !selectedProductBarcode.length ? `Upload Products` : `Remove Products`
        }
        onClose={onClose}
        visible={drawerVisible}
        extra={
          !selectedProductBarcode.length ? (
            <Button onClick={simpleFileDownload} type="dashed" shape="round">
              <DownloadOutlined />
              Sample File
            </Button>
          ) : (
            ``
          )
        }
      >
        {selectedProductBarcode?.length ? (
          <Form layout="vertical" onFinish={onRemove} form={form}>
            <Form.Item label="Product Barcodes">
              <Input.TextArea
                value={selectedProductBarcode}
                disabled
                rows={8}
              />
            </Form.Item>

            <div className="buttons-container">
              <Button
                disabled={loading || !selectedProductBarcode.length}
                loading={(loading ? "loading" : undefined) as any}
                type="primary"
                htmlType="submit"
                danger
              >
                Remove {selectedProductBarcode?.length} Items?
              </Button>
            </div>
          </Form>
        ) : (
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            {" "}
            <>
              <div className="mb-4">
                <Dragger {...props} onChange={readUploadFile as any}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other band files
                  </p>
                  <Alert
                    message="Upload Only (.csv) File"
                    type="warning"
                    className="m-3"
                  />
                </Dragger>
              </div>

              <div className="mb-4">
                {fetchedCSVData?.products?.length >= 0 ? (
                  <Alert
                    type="success"
                    message={`Uploaded ${fetchedCSVData?.products?.length} Item(s)`}
                  />
                ) : (
                  <Alert
                    type="error"
                    message="Please Upload exact format for Campaign"
                  />
                )}
              </div>

              <div className="buttons-container">
                <Button
                  disabled={loading || !fetchedCSVData?.products?.length}
                  loading={(loading ? "loading" : undefined) as any}
                  type="primary"
                  htmlType="submit"
                  className="add-submit-btn text-center mr-2"
                >
                  Upload Products
                </Button>
                <Button
                  type="ghost"
                  htmlType="button"
                  onClick={resetData}
                  className="reset-submit-btn text-center mr-2"
                >
                  Reset
                </Button>
              </div>
            </>
          </Form>
        )}
      </Drawer>

      <Drawer
        title="Product Details"
        onClose={onClose}
        visible={selectedProduct}
        width={600}
      >
        <div className="flex flex-col items-center bg-white border rounded-lg shadow-md md:flex-row w-full hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 mb-8">
          <img
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            src={selectedProduct?.product?.productImage}
            alt=""
          />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {selectedProduct?.product?.name}
            </h5>
            <p className="mb-0 font-normal text-gray-700 dark:text-gray-400">
              <span className="font-semibold">Barcode:</span>{" "}
              {selectedProduct?.product?.barCode}
            </p>
            <p className="mb-0 font-normal text-gray-700 dark:text-gray-400">
              <span className="font-semibold">Variation:</span>{" "}
              {selectedProduct?.product?.variations[0].variationName}
            </p>
            <p className="mb-0 font-normal text-gray-700 dark:text-gray-400">
              <span className="font-semibold">Price:</span>{" "}
              {selectedProduct?.product?.variations[0].price?.discountedPrice}
            </p>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              <span className="font-semibold">Stock:</span>{" "}
              {selectedProduct?.product?.variations[0].stock}
            </p>
          </div>
        </div>
        <Divider orientation="left">Free Gift Items</Divider>
        <div className="grid grid-cols-2 gap-4">
          {selectedProduct?.additionalProducts?.length ? (
            selectedProduct?.additionalProducts?.map((item) => (
              <Card
                // style={{ width: 295 }}
                className="border hover:shadow-lg p-1 rounded-lg"
                cover={
                  <img alt={item?.name} src={getImgUrl(item?.productImage)} />
                }
                // actions={[
                //   <SettingOutlined key="setting" />,
                //   <EditOutlined key="edit" />,
                //   <EllipsisOutlined key="ellipsis" />,
                // ]}
              >
                <Meta
                  title={item?.name}
                  // description="This is the description"
                  // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                />
              </Card>
            ))
          ) : (
            <Empty />
          )}
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default CampaignProductList;
