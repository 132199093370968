import React, { ReactElement } from "react";
import SalesReportList from "../../components/Reports/SalesReport";
import MainLayout from "../../components/Layouts";

const SalesReportPage = (): ReactElement => {
  return (
    <MainLayout>
      <SalesReportList />
    </MainLayout>
  );
};

export default SalesReportPage;
