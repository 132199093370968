const Power = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7029 6.89014C19.9613 8.14893 20.8181 9.75258 21.1652 11.4983C21.5122 13.2441 21.3338 15.0535 20.6525 16.6979C19.9713 18.3423 18.8177 19.7477 17.3377 20.7365C15.8577 21.7253 14.1178 22.2531 12.3379 22.2531C10.558 22.2531 8.81805 21.7253 7.33807 20.7365C5.85809 19.7477 4.70454 18.3423 4.02326 16.6979C3.34198 15.0535 3.16358 13.2441 3.51061 11.4983C3.85764 9.75258 4.71452 8.14893 5.97289 6.89014"
        stroke={props.fill || '#979797'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3428 2.25V12.25"
        stroke={props.fill || '#979797'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Power
