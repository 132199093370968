import MainLayout from "../../components/Layouts";
import React from "react";
import MerchantProfileUpdate from "../../components/Profile/Personaldetails/add";


const MerchantProfieUpdatePage = () => {
  return (
    <MainLayout>
      <MerchantProfileUpdate />
    </MainLayout>
  ); 
};

export default MerchantProfieUpdatePage;
