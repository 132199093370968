import cn from 'classnames'
import React, { FC } from 'react'

interface ContainerProps {
  className?: string
  children?: any
  el?: HTMLElement
  clean?: boolean
  fluid: boolean
}

const Container: FC<ContainerProps> = ({
  children,
  className,
  el = 'div',
  clean,
  fluid = false,
}) => {
  const rootClassNameWithFluid = cn(className, {
    'lg:container mx-auto max-w-8xl  px-4 lg:px-10 w-full': !clean,
  })
  const rootClassNameWithoutFluid = cn(className, {
    'mx-auto max-w-7xl px-4 lg:px-6 w-full': !clean,
  })

  let Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el as any

  return (
    <Component
      className={fluid ? rootClassNameWithFluid : rootClassNameWithoutFluid}
    >
      {children}
    </Component>
  )
}

export default Container
